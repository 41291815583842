import { useEffect, useState, useContext } from "react";
import { observer } from "mobx-react-lite";
import { contrastColor } from "contrast-color";
import { removeAllHightlight } from "../../../../Helpers/RemoveAllHighlight";

import { useStyles } from "./ScreenListStyle";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { StoreContext } from "../../../../stores/store.context";

const ScreenList = observer((props) => {
  const { project } = useContext(StoreContext);
  const classes = useStyles();

  return (
    <Box className={classes.bigBox}>
      {project.screenList.map((__, index) => (
        <ScreenButton index={index} key={index} />
      ))}
    </Box>
  );
});

export default ScreenList;

const ScreenButton = (props) => {
  const { project, app, dialogs } = useContext(StoreContext);
  const { index } = props;
  const screen = project.screenList[index];
  const classes = useStyles();

  function setScreen(index) {
    const screen = project.screenList[index];
    project.selectScreenEdit(screen);
    app.setSelectedEditValue(2);
  }

  const deleteDialog = (index) => {
    const screen = project.screenList[index];
    dialogs.setIdToDelete(screen.id);
    dialogs.setDeleteDialogType('screen')
    dialogs.setIsDeleteDialogOpen(true)
  };

  const [fontColor, setFontColor] = useState("#fff");

  useEffect(() => {
    const fontColor = contrastColor({ bgColor: screen.color });
    setFontColor(fontColor);
  }, []);

  return (
    <Paper
      elevation={3}
      style={{ marginRight: 10, marginLeft: 10, marginTop: 10 }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        style={{
          width: "100%",
          backgroundColor: screen.color,
          borderStyle: "solid",
          borderColor: "lightgrey",
          borderRadius: 4,
          borderWidth: 0,
          padding: 3,
          cursor: "pointer",
          marginTop: 5,
          paddingLeft: 10,
          paddingRight: 10,
        }}
        onClick={() => setScreen(index)}
      >
        <Typography
          noWrap
          style={{ maxWidth: "50%", color: fontColor, fontWeight: 500 }}
        >
          {screen.name.toUpperCase()}
        </Typography>
        <IconButton
          edge="end"
          onClick={() => deleteDialog(index)}
          className={classes.DeleteIcon}
        >
          <DeleteIcon fontSize="small" style={{ color: fontColor }} />
        </IconButton>
      </Grid>
    </Paper>
  );
};