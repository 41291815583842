import { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import lodash from "lodash";

import DashboardToolbar from "../../components/Toolbar/Toolbar";
import { NewUserPanel } from "./new-panel.dialog";
import { EditPanelDialog } from "./edit-panel.dialog";
import { StoreContext } from "../../stores/store.context";

import "./styles.css";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

export const PanelPage = observer(() => {
  const { customPanels, user } = useContext(StoreContext);
  const [unSortedPanelList, setUnSortedPanelList] = useState(user.panelTypes);
  const [sortedPanelList, setSortedPanelList] = useState([]);
  const [lastSortClicked, setLasSortClicked] = useState("");
  const [isAscending, setIsAscending] = useState(false);
  const [filterValue, setFilterValue] = useState({
    make: "",
    model: "",
    pixelWidth: "",
    pixelHeight: "",
    weight: "",
    maxWatt: "",
    avgWatt: "",
  });

  useEffect(() => {
    setUnSortedPanelList(user.panelTypes);
    let sorted = lodash.sortBy(user.panelTypes, (panel) =>
      panel.make.toLowerCase()
    );
    setSortedPanelList(sorted);
  }, [JSON.stringify(user.panelTypes)]);

  const filter = (key, value) => {
    setFilterValue({ ...filterValue, [key]: value });
  };

  const sort = (sortBy) => {
    let newList = sortedPanelList;
    let x = lodash.sortBy(newList, (panel) => {
      if (typeof panel[sortBy] === "string") {
        return panel[sortBy].toLowerCase();
      } else {
        return panel[sortBy];
      }
    });
    if (sortBy == lastSortClicked && !isAscending) {
      lodash.reverse(x);
      setIsAscending(true);
    } else {
      setIsAscending(false);
    }
    setSortedPanelList(x);
    setLasSortClicked(sortBy);
  };

  const handlePanelCardClick = (panel) => {
    customPanels.setEditPanelDialogSelectedPanel(panel);
  };

  useEffect(() => {
    let newList = lodash.clone(unSortedPanelList);
    let t = newList.filter((panel) =>
      panel.make.toLowerCase().includes(filterValue.make)
    );
    let u = t.filter((panel) =>
      panel.model.toLowerCase().includes(filterValue.model)
    );
    setSortedPanelList(u);
  }, [filterValue.make, filterValue.model]);

  return (
    <>
      <div className="root">
        <NewUserPanel />
        <EditPanelDialog />
        <DashboardToolbar heading="Panels" />
        <div className="top-toolbar-padding" />
        <div className="under-toolbar-container">
          <div className="side-toolbar-padding"></div>
          <div className="body-container">
            <div className="new-and-edit-container">
              <div
                className="new-panel-button"
                onClick={() => customPanels.setNewPanelDialogIsOpen(true)}
              >
                ADD PANEL
              </div>

              <div className="selected-panel-container">
                <div>
                  Make: {customPanels.editPanelDialogSelectedPanel.make}
                </div>
                <div>
                  Model: {customPanels.editPanelDialogSelectedPanel.model}
                </div>
              </div>
              {customPanels.editPanelDialogSelectedPanel.isUserPanel ? (
                <div
                  className="new-panel-button"
                  onClick={() => customPanels.setEditPanelDialogIsOpen(true)}
                >
                  EDIT PANEL
                </div>
              ) : (
                <div className="new-panel-button-placeholder" />
              )}
            </div>

            <div className="filter-container">
              {/* <div>Filter:</div> */}
              <div className="filter-header filter-make">
                <div>Filter </div>
                <div style={{ marginLeft: 5 }}> Make </div>{" "}
                <input
                  className="filter-make-input filter-input"
                  value={filterValue.make}
                  onChange={(e) => filter("make", e.target.value)}
                ></input>
                {/* <div
                  className="filter-make-button filter-button"
                  onClick={() => filter("make", "")}
                >
                  X
                </div> */}
              </div>
              <div style={{ width: 75 }} />
              <div className="filter-header filter-model">
                <div>Filter </div>
                <div style={{ marginLeft: 5 }}> Model </div>
                <input
                  className="filter-model-input filter-input"
                  value={filterValue.model}
                  onChange={(e) => filter("model", e.target.value)}
                ></input>
                {/* <div
                  className="filter-model-button filter-button"
                  onClick={() => filter("model", "")}
                >
                  X
                </div> */}
              </div>
            </div>

            <div className="panel-container-header">
              <div
                className="panel-data make sort"
                onClick={() => sort("make")}
                style={{ fontWeight: lastSortClicked == "make" && 700 }}
              >
                MAKE
                {isAscending && lastSortClicked == "make" && (
                  <ArrowUpwardIcon style={{ color: "" }} />
                )}
                {!isAscending && lastSortClicked == "make" && (
                  <ArrowDownwardIcon style={{ color: "" }} />
                )}
              </div>
              <div
                className="panel-data model sort"
                onClick={() => sort("model")}
                style={{ fontWeight: lastSortClicked == "model" && 700 }}
              >
                MODEL
                {isAscending && lastSortClicked == "model" && (
                  <ArrowUpwardIcon style={{ color: "" }} />
                )}
                {!isAscending && lastSortClicked == "model" && (
                  <ArrowDownwardIcon style={{ color: "" }} />
                )}
              </div>
              <div
                className="panel-data pixel-width sort"
                onClick={() => sort("pixelWidth")}
                style={{ fontWeight: lastSortClicked == "pixelWidth" && 700 }}
              >
                PIXEL WIDTH
                {isAscending && lastSortClicked == "pixelWidth" && (
                  <ArrowUpwardIcon style={{ color: "" }} />
                )}
                {!isAscending && lastSortClicked == "pixelWidth" && (
                  <ArrowDownwardIcon style={{ color: "" }} />
                )}
              </div>
              <div
                className="panel-data pixel-height sort"
                onClick={() => sort("pixelHeight")}
                style={{ fontWeight: lastSortClicked == "pixelHeight" && 700 }}
              >
                PIXEL HEIGHT
                {isAscending && lastSortClicked == "pixelHeight" && (
                  <ArrowUpwardIcon style={{ color: "" }} />
                )}
                {!isAscending && lastSortClicked == "pixelHeight" && (
                  <ArrowDownwardIcon style={{ color: "" }} />
                )}
              </div>
              <div
                className="panel-data weight sort"
                onClick={() => sort("weight")}
                style={{ fontWeight: lastSortClicked == "weight" && 700 }}
              >
                WEIGHT
                {isAscending && lastSortClicked == "weight" && (
                  <ArrowUpwardIcon style={{ color: "" }} />
                )}
                {!isAscending && lastSortClicked == "weight" && (
                  <ArrowDownwardIcon style={{ color: "" }} />
                )}
              </div>
              <div
                className="panel-data max-watt sort"
                onClick={() => sort("maxWatt")}
                style={{ fontWeight: lastSortClicked == "maxWatt" && 700 }}
              >
                MAX WATT
                {isAscending && lastSortClicked == "maxWatt" && (
                  <ArrowUpwardIcon style={{ color: "" }} />
                )}
                {!isAscending && lastSortClicked == "maxWatt" && (
                  <ArrowDownwardIcon style={{ color: "" }} />
                )}
              </div>
              <div
                className="panel-data average-watt sort"
                onClick={() => sort("avgWatt")}
                style={{ fontWeight: lastSortClicked == "avgWatt" && 700 }}
              >
                AVG WATT
                {isAscending && lastSortClicked == "avgWatt" && (
                  <ArrowUpwardIcon style={{ color: "" }} />
                )}
                {!isAscending && lastSortClicked == "avgWatt" && (
                  <ArrowDownwardIcon style={{ color: "" }} />
                )}
              </div>
            </div>

            <div className="panel-list-container">
              {sortedPanelList.map((panel, i) => (
                <div
                  className="panel-container"
                  key={i}
                  onClick={() => handlePanelCardClick(panel)}
                  style={{
                    outline:
                      customPanels.editPanelDialogSelectedPanel.id ===
                        panel.id && "solid black 2px",
                  }}
                >
                  <div className="panel-data make">
                    {panel.make}
                    {panel.isUserPanel && " ** "}
                  </div>
                  <div className="panel-data model">{panel.model}</div>
                  <div className="panel-data pixel-width">
                    {panel.pixelWidth}
                  </div>
                  <div className="panel-data pixel-height">
                    {panel.pixelHeight}
                  </div>
                  <div className="panel-data weight">{panel.weight}</div>
                  <div className="panel-data max-watt">{panel.maxWatt}</div>
                  <div className="panel-data average-watt">{panel.avgWatt}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
