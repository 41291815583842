import { useContext } from "react";
import { StoreContext } from "../../stores/store.context";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import FormMobile from "./FormMob";

import { useStyles } from "./ContactStyles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

function Contact() {
  const classes = useStyles();
  const { app } = useContext(StoreContext);

  return (
    <Box>
      <Header />
      <Grid
        container
        justifyContent="center"
        style={{
          minHeight: "100vh",
          backgroundColor: "",
        }}
      >
        <Paper
          className={classes.paperBackground}
          style={{ backgroundColor: app.color.white1 }}
        >
          <Box style={{ width: "100vw", height: 70 }} />

          <Grid
            container
            justifyContent="center"
            style={{ width: "100vw", height: "100%" }}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{
                width: "65%",
                height: "100%",
                backgroundColor: "",
                // marginLeft: 70,
              }}
            >
              {/* CONTACT DETAILS */}
              {/* CONTACT DETAILS */}
              {/* CONTACT DETAILS */}

              <Grid container justifyContent="center" style={{ width: "100%" }}>
                <Typography
                  align="center"
                  variant="h4"
                  style={{ fontWeight: 800 }}
                >
                  Contact Us
                </Typography>
              </Grid>
              {/* <Grid container justifyContent="center" style={{ width: "100%" }}>
                <Typography
                  align="center"
                  variant="h6"
                  style={{ fontWeight: 700, marginTop: 10 }}
                >
                  Phone Support
                </Typography>
              </Grid> */}
              {/* <Grid container justifyContent="center" style={{ width: "100%" }}>
                <Typography
                  align="center"
                  variant="h6"
                  style={{ fontWeight: 500 }}
                >
                  1-(833)-532-3533
                </Typography>
              </Grid> */}
              <Grid container justifyContent="center" style={{ width: "100%" }}>
                <Typography
                  align="center"
                  variant="h6"
                  style={{ fontWeight: 700, marginTop: 15 }}
                >
                  Support
                </Typography>
              </Grid>
              <Grid container justifyContent="center" style={{ width: "100%" }}>
                <Typography
                  align="center"
                  variant="h6"
                  style={{ fontWeight: 500 }}
                  //   button
                  key="Phone"
                  component="a"
                  href="mailto:support@leadled.io"
                >
                  support@leadled.io
                </Typography>
              </Grid>
              {/* <Grid container justifyContent="center" style={{ width: "100%" }}>
                <Typography
                  align="center"
                  variant="h6"
                  style={{ fontWeight: 700, marginTop: 15 }}
                >
                  Development Team
                </Typography>
              </Grid>
              <Grid container justifyContent="center" style={{ width: "100%" }}>
                <Typography
                  align="center"
                  variant="h6"
                  style={{ fontWeight: 500 }}
                  //   button
                  key="Email"
                  component="a"
                  href="mailto:devon@leadled.io"
                >
                  devon@leadled.io
                </Typography>
              </Grid> */}
              <Grid container justifyContent="center" style={{ width: "100%" }}>
                <Typography
                  align="center"
                  variant="h6"
                  style={{ fontWeight: 700, marginTop: 25 }}
                >
                  Mailing Address
                </Typography>
              </Grid>
              <Grid container justifyContent="center" style={{ width: "100%" }}>
                <Typography
                  align="center"
                  variant="h6"
                  style={{ fontWeight: 500 }}
                >
                  13110 NE 177th PL #332
                  <br />
                  Woodinville, WA, USA
                  <br />
                  98072
                </Typography>
              </Grid>

              {/* CONTACT FORM */}
              {/* CONTACT FORM */}
              {/* CONTACT FORM */}
              <FormMobile />
              <Box style={{ width: "100vw", height: 65 }} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Footer />
    </Box>
  );
}

export default Contact;
