import { useState, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { observer } from "mobx-react-lite";

import { deleteUserPanel } from "../../graphql/mutations";
import { StoreContext } from "../../stores/store.context";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

export const DeleteDialog = observer((props) => {
  const { customPanels } = useContext(StoreContext);
  const [loading, setIsLoading] = useState(false);

  const deletePanel = async () => {
    setIsLoading(true);
    try {
      let res = await API.graphql(
        graphqlOperation(deleteUserPanel, {
          input: { id: customPanels.editPanelDialogSelectedPanel.id },
        })
      );
    } catch (err) {
      setIsLoading(false);
    }
    setIsLoading(false);
    customPanels.setEditPanelDialogIsOpen(false);
    customPanels.setEditPanelDialogSelectedPanel({});
    customPanels.setDeleteDialogIsOpen(false);
  };

  return (
    <Grid
      justifyContent="center"
      alignItems="center"
      container
      style={{ width: "100%", height: "100%", backgroundColor: "white" }}
    >
      <Grid
        justifyContent="center"
        alignItems="center"
        container
        style={{ width: 250, height: 200, backgroundColor: "" }}
      >
        <Typography align="center">Are you sure?</Typography>
        <Typography align="center">
          Deleting this panel will affect all screens in all projects associated
          to this panel.
        </Typography>
        <Typography align="center">This action cannot be undone.</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid
          justifyContent="center"
          alignItems="center"
          container
          style={{ width: "100%", height: "100%", backgroundColor: "" }}
        >
          <Button
            variant="contained"
            color="secondary"
            style={{ width: 95, margin: 10, backgroudColor: "black" }}
            onClick={() => customPanels.setDeleteDialogIsOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            onClick={deletePanel}
            style={{ width: 95, margin: 10, color: "red" }}
          >
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              <Typography>Delete</Typography>
            )}
          </Button>
        </Grid>
      </Grid>
    </Grid>
    //   </Paper>
    // </Dialog>
  );
});
