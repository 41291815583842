import { useContext } from "react";
import { StoreContext } from "../../stores/store.context";
import { observer } from "mobx-react-lite";

import { useTheme } from "@material-ui/core/styles";

import AddIcon from "@material-ui/icons/Add";

export const HowDoesItWork = observer(() => {
  const { app } = useContext(StoreContext);
  const theme = useTheme();
  return (
    <div
      id="butWhyContainer"
      style={{
        marginTop: 50,
        background: app.color.grey.dark,
        // background: "red",
        marginInline: "auto",
        paddingTop: 30,
        borderRadius: 8,
        boxShadow: "0px 0px 50px #0000006b",
        maxWidth: 1000,
        width: "90%",
        " &:hover": {
          background: "white",
        },
      }}
    >
      <div
        id="butWhyHeader"
        style={{
          padding: 10,
          marginBottom: 30,
          borderColor: theme.palette.primary.main,
          borderWidth: 2,
          borderRightWidth: 1,
          borderRadius: 0,
          marginLeft: "auto",
          display: "flex",
        }}
      >
        <div
          style={{
            fontSize: 26,
            fontWeight: 900,
            fontFamily: "Chivo ",
            color: theme.palette.primary.main,
            marginInline: "auto",
          }}
        >
          How does it Work?
        </div>
      </div>
      <div
        id="butWhyBottom"
        style={{
          background: app.color.white.two,
          paddingBottom: 30,
          borderBottomRightRadius: 8,
          borderBottomLeftRadius: 8,
          borderStyle: "solid",
          borderTopWidth: 0,
          paddingInline: 15,
        }}
      >
        <div style={{ width: "100%" }}>
          <div
            align="center"
            style={{
              paddingTop: 40,
              //   paddingInline: 150,
              fontSize: 26,
              fontWeight: 300,
              fontFamily: "Chivo ",
              color: "#444",
            }}
          >
            TK-421 is a two part system:
          </div>
          <div
            align="center"
            style={{
              paddingTop: 40,
              paddingInline: 25,
              fontSize: 22,
              fontWeight: 400,
              fontFamily: "Chivo",
              color: "#444",
            }}
          >
            Browser based software running from a computer
          </div>
          <div
            style={{
              paddingTop: 20,
              justifyContent: "center",
              paddingInline: "auto",
              display: "flex",
            }}
          >
            <AddIcon style={{ fontSize: 30 }} />
          </div>
          <div
            align="center"
            style={{
              paddingTop: 20,
              paddingInline: 0,
              fontSize: 22,
              fontWeight: 400,
              fontFamily: "Chivo",
              color: "#444",
            }}
          >
            A mobile app running from a mobile device
          </div>
        </div>
      </div>
    </div>
  );
});
