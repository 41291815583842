import { useEffect, useContext } from "react";
import { observer } from "mobx-react-lite";

import { StoreContext } from "../../../stores/store.context";

export const StarredModules = () => {
  const { project } = useContext(StoreContext);
  const screenList = project.screenList;

  return (
    <div className="output-container">
      {screenList.map(
        (screen, i) => screen.panel && <Screen screen={screen} key={i} />
      )}
    </div>
  );
};

const Screen = observer((props) => {
  const { project, user } = useContext(StoreContext);

  const { screen } = props;
  const output = user.outputPageOutput;
  const panel = screen.panel;

  const screenWidth = panel.pixelWidth * screen.panelColumns;
  const screenHeight = panel.pixelHeight * screen.panelRows;
  let startX = screen.startX - output.startX;
  let startY = screen.startY - output.startY;

  const canvasId = `${screen.id}starredmodules`;

  useEffect(() => {
    const canvas = document.getElementById(canvasId);
    canvas.style.left = `${startX}px`;
    canvas.style.top = `${startY}px`;
    canvas.width = screenWidth;
    canvas.height = screenHeight;
    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "rgba(0, 0, 0, 0)";
    ctx.rect(0, 0, screenWidth, screenHeight);
    ctx.clearRect(0, 0, screenWidth, screenHeight);
    ctx.beginPath();

    let screenOffPressedModules = project.pressedModules.filter(
      (module) => module.ownerScreen?.id === screen.id && module.isStarred
    );

    const drawModules = () => {
      if (!project.isStarredOn) return;

      screenOffPressedModules.forEach((m) => {
        let x = (m.moduleX * panel.pixelWidth) / panel.moduleColumns;
        let y = (m.moduleY * panel.pixelHeight) / panel.moduleRows;

        ctx.fillStyle = "rgba(200,50,0,1)";
        ctx.fillRect(
          x,
          y,
          panel.pixelWidth / panel.moduleColumns,
          panel.pixelHeight / panel.moduleRows
        );

        ctx.fillStyle = "rgba(0,0,0,1)";
        ctx.fillRect(
          x + 1,
          y + 1,
          panel.pixelWidth / panel.moduleColumns - 2,
          panel.pixelHeight / panel.moduleRows - 2
        );

        let textString = `${m.panelX + 1}/${m.panelY + 1}`;

        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillStyle = "white";

        ctx.fillText(
          textString,
          x + panel.pixelWidth / panel.moduleColumns / 2,
          y + panel.pixelHeight / panel.moduleRows / 2
        );
      });
    };

    requestAnimationFrame(drawModules);
  }, [project.pressedModules, project.id]);

  return (
    <div
      style={{
        transformOrigin: `${startX}px ${startY}px`,
        transform: `rotate(${screen.rotation}deg)`,
      }}
    >
      <canvas id={canvasId} style={{ position: "absolute" }}></canvas>
    </div>
  );
});
