export const calcDims = (outputList) => {
  let projectDims = { minX: 100000, maxX: 0, minY: 100000, maxY: 0 };
  outputList.forEach((output) => {
    if (output.startX < projectDims.minX) {
      projectDims.minX = output.startX;
    }
    if (output.startY < projectDims.minY) {
      projectDims.minY = output.startY;
    }
    if (output.width + output.startX > projectDims.maxX) {
      projectDims.maxX = output.width + output.startX;
    }
    if (output.height + output.startY > projectDims.maxY) {
      projectDims.maxY = output.height + output.startY;
    }
  });

  return projectDims;
};
