import { useContext } from "react";
import { StoreContext } from "../../stores/store.context";
import { useTheme } from "@material-ui/core/styles";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Form from "./Form";

import { useStyles } from "./ContactStyles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

function Contact() {
  const { app } = useContext(StoreContext);
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Box style={{ width: "100%", overflow: "hidden" }}>
      <Header />
      <Grid
        container
        justifyContent="center"
        style={{
          height: "100vh",
          backgroundColor: "",
          minHeight: 500,
          // width: "100vw",
        }}
      >
        <Paper
          className={classes.paperBackground}
          style={{ backgroundColor: app.color.white1 }}
        >
          <Grid id="this" container style={{ height: "100%", width: "100%" }}>
            {/* CONTACT FORM */}
            {/* CONTACT FORM */}
            {/* CONTACT FORM */}
            <Grid
              container
              // justifyContent="flex-start"
              alignItems="center"
              style={{
                width: "60%",
                height: "100%",
                backgroundColor: "",
                marginLeft: 70,
              }}
            >
              <Form />
            </Grid>

            {/* <Divider orientation="vertical" flexItem /> */}

            {/* CONTACT DETAILS */}
            {/* CONTACT DETAILS */}
            {/* CONTACT DETAILS */}
            <Grid
              container
              alignItems="center"
              style={{
                width: "30%",
                height: "100%",
                backgroundColor: "",
                paddingLeft: 70,
                borderStyle: "solid",
                borderLeftWidth: 1,
                borderTopWidth: 0,
                borderRightWidth: 0,
                borderBottomWidth: 0,
              }}
            >
              <Grid item style={{ backgroundColor: "" }}>
                <Typography
                  variant="h3"
                  style={{ fontWeight: 700, fontFamily: "Chivo" }}
                  gutterBottom={true}
                >
                  Contact Us
                </Typography>
                {/* <Typography
                  variant="h6"
                  style={{ fontWeight: 700, marginTop: 40 }}
                  // gutterBottom={true}
                >
                  Phone Support
                </Typography>
                <Typography
                  variant="h6"
                  style={{ fontWeight: 500 }}
                  // gutterBottom={true}
                >
                  1-(833)-532-3533
                </Typography> */}
                <Typography
                  variant="h6"
                  style={{
                    fontWeight: 700,
                    fontFamily: "Chivo",
                    marginTop: 25,
                  }}
                // gutterBottom={true}
                >
                  Support
                </Typography>
                <Typography
                  variant="h6"
                  style={{ fontWeight: 500 }}
                  key="Email2"
                  component="a"
                  href="mailto:support@leadled.io"
                >
                  support@leadled.io
                </Typography>
                {/* <Typography
                  variant="h6"
                  style={{
                    fontWeight: 700,
                    fontFamily: "Chivo",
                    marginTop: 25,
                  }}
                  // gutterBottom={true}
                >
                  Business Inquiries & Contracting
                </Typography>
                <Typography
                  variant="h6"
                  style={{ fontWeight: 500 }}
                  key="Email"
                  component="a"
                  href="mailto:devon@leadled.io"
                >
                  devon@leadled.io
                </Typography> */}
                <Typography
                  variant="h6"
                  style={{
                    fontWeight: 700,
                    fontFamily: "Chivo",
                    marginTop: 25,
                  }}
                // gutterBottom={true}
                >
                  Mailing Address
                </Typography>
                <Typography
                  variant="h6"
                  style={{ fontWeight: 500 }}
                // gutterBottom={true}
                >
                  13110 NE 177th PL #332
                  <br />
                  Woodinville, WA, USA
                  <br />
                  98072
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Footer />
    </Box>
  );
}

export default Contact;
