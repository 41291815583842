import "./projectPage.css";

import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { observer } from "mobx-react-lite";
import useResizeObserver from "use-resize-observer";
import { Transition } from "react-transition-group";

import NewProjectDialog from "./components//NewProjectDialog/NewProjectDialog";
import CanvasPreview from "./components//CanvasPreview/CanvasPreview";
import DashboardToolbar from "../../components/Toolbar/Toolbar";

import useStyles from "./ProjectsPageStyles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import { StoreContext } from "../../stores/store.context";


const ProjectsPage = observer(() => {
  const { projects, dialogs } = useContext(StoreContext)

  const c = useStyles();
  const ref = useRef(null);
  const { width, height } = useResizeObserver({ ref });

  return (
    <div className={c.root}>
      <DashboardToolbar heading="Projects" />

      <Box className={c.navbar} />
      <main className={c.content} style={{ overflow: "hidden" }}>
        <Box className={c.toolbar} />

        <div className="project-card-container">
          {/* New Project Button */}
          {/* New Project Button */}
          {/* New Project Button */}
          {/* <Grid item sm={6} md={4} style={{ backgroundColor: "" }}> */}
          <div className="project-card">
            <Box
              style={{ cursor: "pointer" }}
              className={c.actionArea}
              onClick={() => dialogs.setNewProjectDialogIsOpen(true)}
            >
              <Box
                ref={ref}
                className={c.newProjectBox}
                style={{
                  height: width ? width / 1.77 + 1 : 0,
                  borderStyle: "solid",
                  borderWidth: 2,
                  borderBottomWidth: 0,
                }}
              >
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "",
                  }}
                >
                  <AddIcon className={c.addIcon} />
                </Grid>
              </Box>
              <Box className={c.gridInfo}>
                <Grid container justifyContent="space-around">
                  <Grid item>
                    <Typography
                      className={c.createProject}
                      variant="h6"
                      // fontFamily="Chivo"
                      style={{ paddingBottom: 11, paddingTop: 11 }}
                    >
                      Create New Project
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </div>

          {/* Project Buttons */}
          {/* Project Buttons */}
          {/* Project Buttons */}
          {projects.list.map((project, index) => (
            <ProjectCard projectProp={project} index={index} key={index} />
          ))}
        </div>
      </main>
      <NewProjectDialog />
    </div>
  );
});

export default ProjectsPage;

const ProjectCard = (props) => {
  const { project, app } = useContext(StoreContext)
  const { projectProp, index } = props;
  const c = useStyles();
  const initRender = useRef(false);

  function handProjectClick(p) {
    project.selectProject(p);
  }

  let allUpdatedAts = [];
  let mostRecentDate = new Date("2020-10-17T20:40:26.082Z");
  let arr1 = JSON.stringify(projectProp).split("updatedAt");
  arr1.shift();
  arr1.forEach((arr) => {
    let arr2 = arr.split("Z");
    allUpdatedAts.push(arr2[0].replace('":"', "") + "Z");
  });
  for (let i = 0; i < allUpdatedAts.length; i++) {
    let thisDate = new Date(allUpdatedAts[i]);
    if (thisDate > mostRecentDate) {
      mostRecentDate = thisDate;
    }
  }

  let time = moment(mostRecentDate).fromNow();

  const [inProp, setInProp] = useState(app.initProjectPageRender);

  const duration = 333;

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
  };

  const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };

  useEffect(() => {
    setTimeout(() => setInProp(true), (index * duration) / 3);
    app.setInitProjectPageRender(true);
  }, []);

  return (
    <Transition in={inProp} timeout={duration}>
      {(state) => (
        <div
          className="project-card"
          style={{ ...defaultStyle, ...transitionStyles[state] }}
        >
          <Box
            onClick={() => handProjectClick(projectProp)}
            component={Link}
            to={`/userOnMade/dashboard/${projectProp.id}`}
            style={{ backgroundColor: "pink" }}
          >
            <Box className={c.actionArea}>
              <CanvasPreview project={projectProp} />

              <Box className={c.gridInfo}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      alignItems="stretch"
                      style={{ backgroundColor: "", height: "100%" }}
                    >
                      <Typography
                        className={c.title}
                        variant="h5"
                        style={{ fontWeight: 500 }}
                      >
                        {projectProp.name.toUpperCase()}
                      </Typography>
                      <Typography variant="subtitle2">{time}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      alignItems="stretch"
                      style={{ backgroundColor: "", height: "100%" }}
                    >
                      <Typography
                        justify="space-between"
                        className={c.title}
                        variant="subtitle2"
                      >
                        {projectProp.outputs.items.length}{" "}
                        {projectProp.outputs.items.length === 1
                          ? "output"
                          : "outputs"}
                      </Typography>
                      <Typography
                        justify="space-between"
                        className={c.title}
                        variant="subtitle2"
                        align="right"
                      >
                        {projectProp.screens.items.length}{" "}
                        {projectProp.screens.items.length === 1
                          ? "screen"
                          : "screens"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </div>
      )}
    </Transition>
  );
};
