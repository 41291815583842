import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  paperBackground: {
    width: "100%",
    height: "100vh",
  },
  // toolbar: {
  //   width: "100vw",
  // },
  appBar: {
    backgroundColor: "black",
    elevation: 10,
    maxHeight: 65,
    paddingLeft: "3%",
    paddingRight: "3%",
    width: "100vw",
  },
  logo: {
    fontFamily: "Chivo",
    marginLeft: "35px",
    fontWeight: 600,
    color: "white",
    fontSize: 16,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  logoMobile: {
    fontFamily: "Chivo",
    fontWeight: 600,
    color: "white",
    // fontSize: 16,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  appBarButtons: {
    cursor: 'pointer',
    marginLeft: "35px",
    fontWeight: 600,
    color: "white",
    fontSize: 15,
    // marginTop: 6,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  coolSlogan: {
    fontWeight: 800,
    fontFamily: "IBM Plex Sans",
    fontSize: "110px",
  },
  we: {
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  divider: {
    background: "white",
    marginLeft: "40px",
  },
  footer: {
    backgroundColor: "black",
    width: "100%",
  },
  footerType: {
    fontWeight: 500,
    color: "white",
  },
  trademark: {
    color: "white",
  },
  button: {
    paddingTop: "12px",
    paddingBottom: "12px",
    borderRadius: 35,
  },
  buttonPaper: {
    borderRadius: 35,
  },
  footerColumnHeader: {
    paddingBottom: "20px",
  },
  footerColumnItem: {
    paddingBottom: "20px",
  },
}));
