import { useEffect, useState, useContext } from "react";
import { observer } from "mobx-react-lite";

import OutputInfo from "./OutputInfo";
import OutputEdit from "./OutputEdit";

import useStyles from "./EditSideBarStyle";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import { StoreContext } from "../../../../stores/store.context";

const OutputComponent = observer((props) => {
  const { project, app } = useContext(StoreContext);
  const output = project.selectedOutputEdit;
  const c = useStyles();
  const [editRequested, setEditRequested] = useState(false);

  useEffect(() => {
    setEditRequested(false);
  }, [JSON.stringify(project.selectedOutputEdit)]);

  return (
    <>
      {editRequested ? (
        <OutputEdit
          output={output}
          editRequested={editRequested}
          setEditRequested={setEditRequested}
        />
      ) : (
        <>
          <OutputInfo />

          <Grid item xs={12} style={{ paddingTop: 15 }}>
            <Grid container justifyContent="center" style={{ width: "100%" }}>
              <Button
                variant="contained"
                onClick={() => {
                  setEditRequested(!editRequested);
                }}
                autoFocus={false}
                className={c.editScreenButton}
                color="secondary"
              >
                Edit Output
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
});

export default OutputComponent;
