import { makeAutoObservable } from "mobx";

export class CustomPanelStore {
  newPanelDialogIsOpen = false;
  editPanelDialogIsOpen = false;
  editPanelDialogSelectedPanel = { make: "", model: "", isUserPanel: false };
  deleteDialogIsOpen = false;

  constructor() {
    makeAutoObservable(this);
  }

  setNewPanelDialogIsOpen(boolean) {
    this.newPanelDialogIsOpen = boolean;
  }
  setEditPanelDialogIsOpen(boolean) {
    this.editPanelDialogIsOpen = boolean;
  }
  setEditPanelDialogSelectedPanel(panel) {
    this.editPanelDialogSelectedPanel = panel;
  }
  setDeleteDialogIsOpen(boolean) {
    this.deleteDialogIsOpen = boolean;
  }
}
