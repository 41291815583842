import Amplify from "@aws-amplify/core";

export const apiKey = () => {
  Amplify.configure({
    aws_appsync_authenticationType: "API_KEY",
  });
};

export const pools = () => {
  Amplify.configure({
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  });
};
