import { withAuthenticator } from "@aws-amplify/ui-react";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Auth } from "aws-amplify";
import React from "react";
import useStyles from "./CommunityPageStyle";

function AccountPage() {
  const classes = useStyles();
  const owner = Auth.user.getUsername();


  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar variant="dense">
          <Typography variant="h6" noWrap>
            community
          </Typography>
        </Toolbar>
      </AppBar>

      <Box className={classes.navbar} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Typography paragraph>community page things</Typography>
      </main>
    </div>
  );
}

export default withAuthenticator(AccountPage);
