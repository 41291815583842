import { useContext } from "react";
import { observer } from "mobx-react-lite";


import { useStyles } from "./ScreenListStyle";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { StoreContext } from "../../../../stores/store.context";

const OutputList = observer(() => {
  const { project, app, dialogs } = useContext(StoreContext);

  const c = useStyles();

  const selectOutput = (output) => {
    project.selectOutputEdit(output);
    app.setSelectedEditValue(1);
  };

  const deleteDialog = (id) => {
    dialogs.setIdToDelete(id);
    dialogs.setDeleteDialogType('output')
    dialogs.setIsDeleteDialogOpen(true)
  };

  return (
    <Box className={c.bigBox}>
      <Grid
        container
        justifyContent="center"
        style={{ width: "100%", height: "100%" }}
      >
        {project.outputList.map((output, index) => (
          <Paper
            elevation={5}
            style={{
              width: "100%",
              cursor: "pointer",
              marginTop: 5,
              marginLeft: 10,
              marginRight: 10,
            }}
            key={index}
          >
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{
                width: "100%",
                backgroundColor: "#2B2F2F",
                borderStyle: "double",
                borderColor: output.color,
                borderRadius: 4,
                padding: 3,
                paddingLeft: 10,
                paddingRight: 10,
              }}
              onClick={() => selectOutput(project.outputList[index])}
            >
              <Typography
                noWrap
                style={{ maxWidth: "50%", color: "white", fontWeight: 500 }}
              >
                {output.name.toUpperCase()}
              </Typography>
              <IconButton edge="end" onClick={() => deleteDialog(output.id)}>
                <DeleteIcon fontSize="small" style={{ color: "white" }} />
              </IconButton>
            </Grid>
          </Paper>
        ))}
      </Grid>
    </Box>
  );
});

export default OutputList;
