import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  // ...theme,
  paperBackground: {
    width: "100%",
    justifyContent: "center",
  },
  toolbar: theme.mixins.denseToolbar,
  appBar: {
    backgroundColor: "black",
    elevation: 10,
  },
  appBarButtons: {
    marginLeft: "35px",
    fontWeight: 600,
    color: "white",
  },
  coolSlogan: {
    fontWeight: 800,
    fontFamily: "IBM Plex Sans",
    fontSize: "110px",
  },
  logo: {
    marginLeft: "35px",
    fontWeight: 600,
    color: "white",
    fontSize: 16,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  we: {
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  divider: {
    background: "white",
    marginLeft: "40px",
  },
  footer: {
    backgroundColor: "black",
    width: "100%",
  },
  footerType: {
    fontWeight: 500,
    color: "white",
  },
  trademark: {
    color: "white",
  },
  button: {
    paddingTop: "12px",
    paddingBottom: "12px",
    borderRadius: 35,
  },
  buttonPaper: {
    borderRadius: 35,
  },
  footerColumnHeader: {
    paddingBottom: "20px",
  },
  footerColumnItem: {
    paddingBottom: "20px",
  },
  formInput: {
    marginTop: 15,
    width: "90%",
  },
  formPaper: {
    margin: 20,
    marginTop: 30,
    width: "50%",
    height: 420,
    borderRadius: 8,
    minHeight: 390,
    minWidth: 450,
    borderWidth: 3,
    borderColor: "black",
    backgroundColor: "",
  },
  formPaperMob: {
    margin: 20,
    marginTop: 30,
    width: "50%",
    // height: 420,
    borderRadius: 8,
    // minHeight: 390,
    minWidth: 300,
    borderWidth: 3,
    borderColor: "black",
    backgroundColor: "",
  },
  formButton: {
    backgroundColor: "black",
    width: 180,
    marginTop: 25,
  },
}));
