import { useContext } from "react";

import { observer } from "mobx-react-lite";

import { StoreContext } from "../../../../stores/store.context";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";


const OutputInfo = observer(() => {
  const { project } = useContext(StoreContext);

  return (
    <>
      {/* Screen */}
      {/* Screen */}
      <Grid item xs={12} style={{ padding: 10 }}>
        <Typography
          noWrap
          variant="body1"
          align="center"
          style={{
            width: "100%",
            fontWeigth: 700,
            textDecoration: "underline",
          }}
        >
          Output Dimesions:
        </Typography>
      </Grid>

      <Grid item xs={12} style={{ paddingLeft: 15, paddingRight: 15 }}>
        <Typography
          noWrap
          variant="body1"
          align="center"
          style={{
            borderColor: project.selectedOutputEdit.color,
            borderWidth: 2,
            borderStyle: "solid",
          }}
        >
          {project.selectedOutputEdit.name ? project.selectedOutputEdit.name.toUpperCase() : null}
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        style={{
          marginTop: 5,
        }}
      >
        <Grid container justifyContent="space-around">
          <Grid item xs={6} style={{ paddingTop: 5 }}>
            <Typography align="center" variant="body2" style={{}}>
              width: {project.selectedOutputEdit.width ? project.selectedOutputEdit.width : null}
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ paddingTop: 5 }}>
            <Typography align="center" variant="body2">
              height: {project.selectedOutputEdit.height}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-around">
          <Grid item xs={6} style={{ paddingTop: 5 }}>
            <Typography align="center" variant="body2" style={{}}>
              start x: {project.selectedOutputEdit.startX}
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ paddingTop: 5 }}>
            <Typography align="center" variant="body2">
              start y: {project.selectedOutputEdit.startY}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-around">
          <Grid item xs={6} style={{ paddingTop: 5 }}>
            <Typography align="center" variant="body2" style={{}}>
              end x: {project.selectedOutputEdit.startX + project.selectedOutputEdit.width}
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ paddingTop: 5 }}>
            <Typography align="center" variant="body2">
              end y: {project.selectedOutputEdit.startY + project.selectedOutputEdit.height}
            </Typography>
          </Grid>
        </Grid>
        {/* <Grid item xs={12}>
          <Grid container justifyContent="space-around">
            <Grid item xs={12} style={{ paddingLeft: 0 }}>
              <Typography align="center" variant="body2">
                processed panels:
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ paddingLeft: 0 }}>
              <Typography align="center" variant="body2">
                processed pixels:
              </Typography>
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
    </>
  );
});

export default OutputInfo;
