import { useState, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { observer } from "mobx-react-lite";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SketchPicker } from "react-color";

import { createOwnerScreen } from "../../../../graphql/mutations";
import { defaultColors } from "../../../../utils/Colors";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from '@material-ui/core/FormHelperText';

import { StoreContext } from "../../../../stores/store.context";

const validationSchema = Yup.object({
  name: Yup.string().required("Required"),
  panelColumns: Yup.number().min(0).required("Required"),
  panelRows: Yup.number().min(0).required("Required"),
  startX: Yup.number().min(0).required("Required"),
  startY: Yup.number().min(0).required("Required"),
  rotation: Yup.number()
    .min(0, "Must be greater than 0")
    .max(360)
    .required("Required"),
});

const AddScreenForm = observer((props) => {
  const { project, user } = useContext(StoreContext);
  const { setOutputFormOpen } = props;
  const panels = user.panelTypes;
  const [isLoading, setIsLoading] = useState(false);
  const [colorModalOpen, setColorModalOpen] = useState(false);
  const [pos, setPos] = useState({ x: 0, y: 0 });
  let random = Math.floor(Math.random() * defaultColors.length);
  const [color, setColor] = useState(defaultColors[random]);
  const [zIndex, setZIndex] = useState(100);
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [modelDisable, setModelDisable] = useState(true);
  const [panelError, setPanelError] = useState(false);
  const [panelErrorText, setPanelErrorText] = useState("");

  const formik = useFormik({
    initialValues: {
      name: "",
      panelID: "",
      ownerScreenBackgroundContent: 1,
      panelRows: "",
      panelColumns: "",
      startX: "",
      startY: "",
      color: "",
      ownerScreenPanelId: "",
      ownerScreenProjectId: "",
      zIndex: zIndex,
      make: "",
      model: "",
      rotation: "",
      isUsingUserPanel: false,
    },
    validationSchema,
  });

  const panelMakeOptions = [
    ...new Set(
      panels.map((panel) => {
        return panel.make;
      })
    ),
  ];

  const sortedPanelMakeOptions = panelMakeOptions;

  const models = panels.filter((panel) => panel.make === make).sort();
  const sortedModels = models.sort((a, b) => a.model.localeCompare(b.model));

  const selectMake = (event) => {
    setMake(event.target.value);
    setModelDisable(false);
  };

  const selectModelHandleChange = (event) => {
    setModel(event.target.value);
  };

  const openColor = (x) => {
    setPos({
      x: x.clientX,
      y: x.clientY,
    });
    setColorModalOpen(true);
  };

  const closeColor = () => {
    setColorModalOpen(false);
  };

  const changeColor = (color, event) => {
    setColor(color.hex);
  };

  async function addScreen() {
    let ourColor = color;
    for (var i in formik.errors) {
      return;
    }

    if (!model.id) {
      setPanelErrorText("required");
      setPanelError(true);
      return
    }

    setIsLoading(true);
    try {
      const screen = {
        projectID: project.id,
        ownerScreenProjectId: project.id,
        projectScreensId: project.id,
        zIndex: zIndex,
        name: formik.values.name,
        panelID: model.id,
        panelColumns: parseInt(formik.values.panelColumns),
        panelRows: parseInt(formik.values.panelRows),
        startX: parseInt(formik.values.startX),
        startY: parseInt(formik.values.startY),
        color: ourColor,
        ownerScreenBackgroundContent: 1,
        ownerScreenPanelId: !model.isUserPanel ? model.id : null,
        ownerScreenUserPanelId: model.isUserPanel ? model.id : null,
        rotation: parseInt(formik.values.rotation),
        isUsingUserPanel: model.isUserPanel ? true : false,
      };
      await API.graphql(graphqlOperation(createOwnerScreen, { input: screen }));
      setZIndex(zIndex + 1);
      setMake("");
      setModel("");
    } catch (err) {
      console.error(err);
    }

    setIsLoading(false);
    setOutputFormOpen(false);
  }

  return (
    <Grid
      container
      style={{
        flex: 1,
        flexDirection: "row",
        backgroundColor: "",
      }}
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        container
        style={{ width: "100%", height: "70%", paddingTop: 0 }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <TextField
            id="name"
            name="name"
            size="small"
            label="Name"
            value={formik.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={"name" in formik.touched && "name" in formik.errors}
            style={{ width: "80%" }}
            helperText={
              formik.touched.name && formik.errors.name
                ? formik.errors.name
                : ""
            }
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl style={{ width: "80%" }} error={panelError}>
            <InputLabel id="model-label">Panel Make</InputLabel>
            <Select
              id="make"
              value={make}
              onChange={selectMake}
              style={{ width: "100%" }}
            >
              {sortedPanelMakeOptions.map((make, index) => (
                <MenuItem key={index ? index : index} value={make}>
                  {make}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{panelErrorText}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl style={{ width: "80%" }} error={panelError}>
            <InputLabel id="model-label">Panel Model</InputLabel>
            <Select
              disabled={modelDisable}
              style={{ width: "100%" }}
              labelId="model-label"
              id="model"
              value={model}
              onChange={selectModelHandleChange}
            >
              {sortedModels.map((model) => (
                <MenuItem key={model.id} value={model}>
                  {model.model}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{panelErrorText}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="panelColumns"
            name="panelColumns"
            size="small"
            label="Columns"
            value={formik.panelColumns}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              "panelColumns" in formik.touched &&
              "panelColumns" in formik.errors
            }
            style={{ width: "60%", marginTop: 5 }}
            helperText={
              formik.touched.panelColumns && formik.errors.panelColumns
                ? formik.errors.panelColumns
                : ""
            }
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="panelRows"
            name="panelRows"
            size="small"
            label="Rows"
            value={formik.panelRows}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              "panelRows" in formik.touched && "panelRows" in formik.errors
            }
            style={{ width: "60%", marginTop: 5 }}
            helperText={
              formik.touched.panelRows && formik.errors.panelRows
                ? formik.errors.panelRows
                : ""
            }
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="startX"
            name="startX"
            size="small"
            label="Start X"
            value={formik.startX}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={"startX" in formik.touched && "startX" in formik.errors}
            style={{ width: "60%", marginTop: 5 }}
            helperText={
              formik.touched.startX && formik.errors.startX
                ? formik.errors.startX
                : ""
            }
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="startY"
            name="startY"
            size="small"
            label="Start Y"
            value={formik.startY}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={"startY" in formik.touched && "startY" in formik.errors}
            style={{ width: "60%", marginTop: 5 }}
            helperText={
              formik.touched.startY && formik.errors.startY
                ? formik.errors.startY
                : ""
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="rotation"
            name="rotation"
            size="small"
            label="Rotation"
            value={formik.rotation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={"rotation" in formik.touched && "rotation" in formik.errors}
            style={{ width: "60%", marginTop: 5 }}
            helperText={
              formik.touched.rotation && formik.errors.rotation
                ? formik.errors.rotation
                : ""
            }
          />
        </Grid>

        <Button
          type="button"
          onClick={(e) => openColor(e)}
          style={{ backgroundColor: color, width: 95, marginTop: 10 }}
        >
          Color
        </Button>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={colorModalOpen}
          onClose={closeColor}
          closeAfterTransition
          style={{ left: pos.x, top: pos.y - 250 }}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <SketchPicker
            color={color}
            onChange={changeColor}
            presetColors={defaultColors}
          />
        </Modal>
      </Grid>
      <Grid item xs={6} style={{ marginTop: 10 }}>
        <Button
          onClick={() => setOutputFormOpen(false)}
          color="primary"
          variant="outlined"
          style={{ width: 95 }}
        >
          Cancel
        </Button>
      </Grid>
      <Grid item xs={6} style={{ marginTop: 10 }}>
        <Button
          onClick={addScreen}
          color="primary"
          variant="contained"
          disabled={isLoading}
          style={{ width: 95 }}
        >
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            <Typography>Submit</Typography>
          )}
        </Button>
      </Grid>
    </Grid>
  );
});

export default AddScreenForm;
