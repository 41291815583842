import { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import useResizeObserver from "use-resize-observer";

import { calcDims } from "../../../../Helpers/CalcProjectDims";

import { useStyles } from "./CanvasPreviewStyle";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

const CanvasPreview = observer((props) => {
  const { project } = props;
  const [screenList, setScreenList] = useState([]);
  const [outputList, setOutputList] = useState([]);
  const [canvasObj, setCanvasObj] = useState();
  const ref = useRef(null);
  const refObj = useResizeObserver({ ref });
  const widthOuter = refObj.width;
  const heightOuter = refObj.height;

  const ratio =
    canvasObj &&
    (100 * (canvasObj.maxY - canvasObj.minY)) / canvasObj.maxX - canvasObj.minX;
  const c = useStyles({ ratio: ratio });

  useEffect(() => {
    setScreenList(project.screens.items);
    setOutputList(project.outputs.items);
    setCanvasObj(calcDims(project.outputs.items));
  }, [JSON.stringify(project)]);

  const ScreenBox = (props) => {
    const { screen } = props;
    const panel = screen.panel ? screen.panel : screen.userPanel;
    const projectWidth = canvasObj.maxX - canvasObj.minX;
    const projectHeight = canvasObj.maxY - canvasObj.minY;
    const projectStartX = canvasObj.minX;
    const projectStartY = canvasObj.minY;
    let widtha = Math.floor(
      ((panel.pixelWidth * screen.panelColumns) / projectWidth) * 100
    );
    let heighta = Math.floor(
      ((panel.pixelHeight * screen.panelRows) / projectHeight) * 100
    );
    let startX = Math.floor(
      ((screen.startX - projectStartX) / projectWidth) * 100
    );
    let startY = Math.floor(
      ((screen.startY - projectStartY) / projectHeight) * 100
    );

    return (
      <div
        key={screen.id}
        className={c.screenBox}
        style={{
          backgroundColor: screen.color,
          top: `${startY}%`,
          left: `${startX}%`,
          width: `${widtha}%`,
          height: `${heighta}%`,
          position: "absolute",
          display: "flex",
          cursor: "pointer",
          transformOrigin: `0 0`,
          transform: `rotate(${screen.rotation}deg)`,
        }}
      ></div>
    );
  };

  const OutputBox = observer((props) => {
    const { output } = props;
    let projectWidth = canvasObj.maxX - canvasObj.minX;
    let projectHeight = canvasObj.maxY - canvasObj.minY;
    let projectStartX = canvasObj.minX;
    let projectStartY = canvasObj.minY;
    let width = Math.floor((output.width / projectWidth) * 100);
    let height = Math.floor((output.height / projectHeight) * 100);
    let startX = Math.floor(
      ((output.startX - projectStartX) / projectWidth) * 100
    );
    let startY = Math.floor(
      ((output.startY - projectStartY) / projectHeight) * 100
    );

    return (
      <Box
        component="button"
        type="button"
        key={output.id}
        border={3}
        borderColor={output.color}
        position="absolute"
        top={startY + "%"}
        left={startX + "%"}
        width={width + "%"}
        height={height + "%"}
        className={c.outputBox}
        display="flex"
        style={{ backgroundColor: "transparent", cursor: "pointer" }}
      ></Box>
    );
  });

  return (
    <div style={{ cursor: "pointer" }}>
      <Grid
        className={c.outerBoxStyle}
        ref={ref}
        style={{
          height: widthOuter ? widthOuter / 1.77 : 0,
          overflow: "hidden",
        }}
      >
        <div
          className={c.betweenOuterAndCanvas}
          style={{
            backgroundColor: "",
            maxWidth: heightOuter && ratio ? (heightOuter / ratio) * 80 : 0,
          }}
        >
          <div
            id="centeredCanvas"
            className={c.centeredCanvas}
            style={{
              paddingBottom: ratio + "%",
              backgroundColor: "",
            }}
          >
            {canvasObj &&
              screenList &&
              screenList.map(
                (screen, index) =>
                  (screen.panel || screen.userPanel) && (
                    <ScreenBox screen={screen} key={index} />
                  )
              )}
            {canvasObj &&
              screenList &&
              outputList.map((output, index) => (
                <OutputBox output={output} key={index} />
              ))}
          </div>
        </div>
      </Grid>
    </div>
  );
});

export default CanvasPreview;
