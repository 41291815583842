import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  sidebar: {
    width: drawerWidth,
    flexShrink: 0,
    bgColor: theme.palette.background.default,
    // paddingRight: 10,
    paddingLeft: 0,
    height: "100%",
  },
  drawerPaper: {
    width: drawerWidth,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
  },
  openDisplayTab: {
    borderRadius: 5,
    padding: "10px",
    // paddingLeft: "15x",
    // paddingRight: "15px",
    backgroundColor: "green",
    width: "95%",
  },

  editScreenButton: {
    borderRadius: 5,
    padding: "10px",
    paddingLeft: "15x",
    paddingRight: "15px",
    width: "95%",
  },
  textField: {
    marginTop: 9,
  },
  editButtons: {
    marginLeft: 15,
    marginRight: 15,
    margin: 10,
  },
}));

export default useStyles;
