import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  paperBackground: {
    // backgroundColor: '#E44211',
    // backgroundColor: theme.palette.ourYellowHell.main,
    // FFC31F FEC81D FED006 F6AE2D FDAD0D FFC857 ff9900
    width: "100%",
    // height: '100vh',
    // [theme.breakpoints.up("xs")]: {
    //   backgroundColor: '#c5283d'
    // },
    // [theme.breakpoints.up("sm")]: {
    //   backgroundColor: '#F6AE2D'
    // },
    // [theme.breakpoints.up("md")]: {
    //   backgroundColor: '#1C7C54'
    // },
    // [theme.breakpoints.up("lg")]: {
    //   backgroundColor: '#33658A'
    // },
    // [theme.breakpoints.up("xl")]: {
    //   backgroundColor: '#F26419'
    // },
  },
  toolbar: theme.mixins.denseToolbar,
  appBar: {
    backgroundColor: "black",
    elevation: 10,
  },
  appBarButtons: {
    marginLeft: "35px",
    fontWeight: 600,
    color: "white",
  },
  coolSlogan: {
    fontWeight: 800,
    fontFamily: "IBM Plex Sans",
    fontSize: "110px",
  },
  we: {
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  divider: {
    background: "white",
    marginLeft: "40px",
  },
  footer: {
    backgroundColor: "black",
    width: "100%",
  },
  footerType: {
    fontWeight: 500,
    color: "white",
  },
  trademark: {
    color: "white",
  },
  button: {
    paddingTop: "12px",
    paddingBottom: "12px",
    borderRadius: 35,
  },
  buttonPaper: {
    borderRadius: 35,
  },
  footerColumnHeader: {
    paddingBottom: "20px",
  },
  footerColumnItem: {
    paddingBottom: "20px",
  },
}));
