import { useContext } from "react";
import { StoreContext } from "../../stores/store.context";
import { observer } from "mobx-react-lite";

import { useStyles } from "./Tk421Styles";
import { useTheme } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

import "./styles.css";

export const ButWhy = observer(() => {
  const { app } = useContext(StoreContext);
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div
      className="but-why-container"
      style={{
        background: app.color.grey.dark,
      }}
    >
      <div
        style={{
          width: "100%",
          paddingInline: 30,
        }}
      >
        <div
          style={{
            background: "",
            marginBottom: 30,
            maxWidth: 700,
            marginInline: "auto",
          }}
        >
          <Typography
            style={{
              fontSize: 26,
              fontWeight: 900,
              fontFamily: "Chivo ",
              color: theme.palette.primary.main,
            }}
          >
            But why?
          </Typography>
        </div>
      </div>
      <div
        id="butWhyBottom"
        style={{
          background: "#FCFAF5",
          paddingBottom: 30,
          borderBottomRightRadius: 8,
          borderBottomLeftRadius: 8,
          borderStyle: "solid",
          borderTopWidth: 0,
          paddingInline: 30,
        }}
      >
        <div style={{ marginInline: "auto", maxWidth: 700 }}>
          <Typography
            className={classes.butWhyQuestion}
            style={{ paddingTop: 60 }}
          >
            Over time your system's panels, modules, and pixels malfunction and
            need replacment.
          </Typography>
          <Typography className={classes.butWhyAnswer}>
            As a diagnostic tool, TK-421 provides a clear and defined process to
            identify and repair issues.
          </Typography>
          <Typography className={classes.butWhyQuestion}>
            Malfunctioning components can take of time and energy to locate.
          </Typography>
          <Typography className={classes.butWhyAnswer}>
            By blacking out certain panels and modules, TK-421 isolates the
            problems in seconds
          </Typography>
          <Typography className={classes.butWhyQuestion}>
            Are your screens difficult to access?
          </Typography>
          <Typography className={classes.butWhyAnswer}>
            Minimizing time in the air is minimizing risk. TK-421 gives remote
            mobile access to pintpoint problems before you get there.
          </Typography>
          <Typography className={classes.butWhyQuestion}>
            Wait….this problem looks familiar. Did this happen before?
          </Typography>
          <Typography
            className={classes.butWhyAnswer}
            style={{ marginBottom: 50 }}
          >
            Reocurring issues could indicate a bigger problem with the
            equipment. TK-421 keeps a history of your previous activity.
          </Typography>
        </div>
      </div>
    </div>
  );
});
