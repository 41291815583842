import { useEffect, useState, useContext } from "react";
import { observer } from "mobx-react-lite";

import OutputComponent from "./OutputComponent";
import ScreenComponent from "./ScreenComponent";
import DisplayButton from "./DisplayButton";

import useStyles from "./EditSideBarStyle";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import "./styles.css";

import { StoreContext } from "../../../../stores/store.context";

const EditSideBar = observer(() => {

  const { project, app } = useContext(StoreContext);

  const classes = useStyles();

  return (
    <Box className={classes.sidebar} variant="permanent">
      <Grid container style={{ backgroundColor: "", height: "" }}>
        <Grid item xs={12} style={{ paddingTop: 0 }}>
          <Grid container justifyContent="center" style={{ width: "100%" }}>
            {project.outputList.map((output, index) => (
              <DisplayButton index={index} key={index} />
            ))}
          </Grid>
        </Grid>
      </Grid>

      {/* Project Info */}
      {/* Project Info */}
      <ProjectInfo />

      <Divider style={{ marginBottom: 0 }} />

      {/* Screen Section */}
      {/* Screen Section */}
      {app.selectedEditValue === 1 && <OutputComponent />}
      {app.selectedEditValue === 2 && <ScreenComponent />}
    </Box>
  );
});

export default EditSideBar;

const ProjectInfo = observer(() => {

  const { project, app } = useContext(StoreContext);

  const [totalPixels, setTotalPixels] = useState(0);
  const [totalPanels, setTotalPanels] = useState(0);
  var nf = new Intl.NumberFormat();

  useEffect(() => {
    try {
      let screenList = project.screenList;
      let totalPanels = 0;
      let totalPixels = 0;
      screenList.forEach((screen) => {
        const panel = screen.isUsingUserPanel ? screen.userPanel : screen.panel;
        let width = screen.panelColumns * panel.pixelWidth;
        let height = screen.panelRows * panel.pixelHeight;
        totalPixels = totalPixels + width * height;
        totalPanels = totalPanels + screen.panelColumns * screen.panelRows;
      });
      setTotalPixels(totalPixels);
      setTotalPanels(totalPanels);
    } catch (err) { }
  }, [project.screenList]);

  return (
    <Grid container style={{ backgroundColor: "", height: "" }}>
      <Grid item xs={12} style={{ padding: 10 }}>
        <Typography
          variant="body1"
          align="center"
          style={{
            width: "100%",
            fontWeigth: 700,
            textDecoration: "underline",
          }}
        >
          Project Dimesions:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="space-around">
          <Grid item xs={6} style={{ paddingLeft: 0 }}>
            <Typography align="center" variant="body2">
              width {project.width > 0 ? project.width : 0}
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ paddingLeft: 0 }}>
            <Typography align="center" variant="body2">
              height {project.height > 0 ? project.height : 0}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ paddingLeft: 0, paddingTop: 10, paddingBottom: 0 }}
          >
            <Typography align="center" variant="body2" style={{}}>
              total pixels: {nf.format(totalPixels)}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ paddingLeft: 0, paddingTop: 10, paddingBottom: 10 }}
          >
            <Typography align="center" variant="body2" style={{}}>
              total panels: {nf.format(totalPanels)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});
