import { makeAutoObservable } from "mobx";
import { Screen } from "./class.screen";
import { Output } from './class.output'
import { calcDims } from "../Helpers/CalcProjectDims";

export class ProjectStore {
  projectBackgroundContent = "id";
  name = "";
  id = "";
  isLabelOn = false;
  isGridOn = false;
  isStarred = false;
  isPlaying = false;
  screenList = [];
  outputList = [];
  selectedScreenEdit = {};
  selectedOutputEdit = {};
  width = 0
  height = 0
  startX = 0
  startY = 0
  dimsCalculated = false
  pressedPanels = []
  pressedModules = []
  starredModulesList = []

  constructor() {
    makeAutoObservable(this);
  }

  selectProject(project) {
    this.projectBackgroundContent = project.backgroundContent;
    this.name = project.name;
    this.id = project.id;
    this.isLabelOn = project.isLabelOn;
    this.isGridOn = project.isGridOn;
    this.isStarred = project.isStarred;
    this.isPlaying = project.isPlaying;
    this.setScreenList(project.screens.items);
    this.setOutputList(project.outputs.items);
  }

  clearProject() {
    this.projectBackgroundContent = "";
    this.name = "";
    this.id = "";
    this.isLabelOn = false;
    this.isGridOn = false;
    this.isStarred = false;
    this.isPlaying = false;
  }

  setProjectBackgroundContent(content) {
    this.projectBackgroundContent = content;
  }

  setScreenList(screenList) {
    this.screenList = [];
    screenList.forEach((screen) => this.addScreen(screen));

  }

  addScreen(_screen) {
    let screen = new Screen(_screen);
    this.screenList = [...this.screenList, screen];
  }

  removeScreen(id) {
    this.screenList = this.screenList.filter((screen) => screen.id !== id);
  }

  setOutputList(outputList) {
    this.outputList = [];
    outputList.forEach((output) => this.addOutput(output));
    this.calculateDimesions()
  }

  addOutput(_output) {
    let output = new Output(_output);
    this.outputList = [...this.outputList, output];
    this.calculateDimesions()
  }

  removeOutput(id) {
    this.outputList = this.outputList.filter((output) => output.id !== id);
    this.calculateDimesions()
  }

  selectScreenEdit(screen) {
    this.selectedScreenEdit = screen;
  }

  selectOutputEdit(output) {
    this.selectedOutputEdit = output;
  }

  calculateDimesions() {
    let dims = calcDims(this.outputList)
    this.width = (dims.maxX - dims.minX);
    this.height = (dims.maxY - dims.minY);
    this.startX = (dims.minX);
    this.startY = (dims.minY);
    this.dimsCalculated = (true);
  }

  setPressedPanels(list) {
    this.pressedPanels = list;
  }

  addPressedPanel(pressedPanel) {
    this.pressedPanels = [pressedPanel, ...this.pressedPanels];
  }

  removePressedPanel(id) {
    this.pressedPanels = this.pressedPanels.filter(
      (p) => p.id !== id
    );
  }

  setPressedModules(list) {
    this.pressedModules = list;
  }

  addPressedModule(pressedModule) {
    this.pressedModules = [pressedModule, ...this.pressedModules];
  }

  removePressedModule(id) {
    this.pressedModules = this.pressedModules.filter(
      (p) => p.id !== id
    );
  }

  setstarredModulesList(starredModulesList) {
    this.starredModulesList = starredModulesList;
  }

  addStarredModule(starredModule) {
    this.starredModulesList = [starredModule, ...this.starredModulesList];
  }

  removeStarredModule(id) {
    this.starredModulesList = this.starredModulesList.filter(
      (starredModule) => starredModule.id !== id
    );
  }
}