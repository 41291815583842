import { useContext } from "react";
import { observer } from "mobx-react-lite";

import { StoreContext } from "../../../../stores/store.context";

import Box from "@material-ui/core/Box";

export const IdColor = observer(() => {
  const { project } = useContext(StoreContext);

  return (
    <div>
      {project.screenList.map(
        (screen, index) =>
          screen.panel && <BackgroundColor screen={screen} key={index} />
      )}
    </div>
  );
});

function BackgroundColor(props) {
  const { user } = useContext(StoreContext);

  const screen = props.screen;
  const panel = screen.panel ? screen.panel : screen.userPanel;
  const output = user.outputPageOutput;
  let width = screen.panel.pixelWidth * screen.panelColumns;
  let height = screen.panel.pixelHeight * screen.panelRows;
  let startX = screen.startX - output.startX;
  let startY = screen.startY - output.startY;

  return (
    <div
      style={{
        transformOrigin: `${startX}px ${startY}px`,
        transform: `rotate(${screen.rotation}deg)`,
      }}
    >
      <Box
        key={screen.id}
        id={screen.id}
        position="absolute"
        top={startY}
        left={startX}
        width={width}
        height={height}
        style={{
          backgroundColor: screen.color,
        }}
      />
    </div>
  );
}
