import { makeAutoObservable } from "mobx";

export class DialogStore {

  isDeleteDialogOpen = false;
  deleteDialogType = 'output';
  idToDelete = '';

  newProjectDialogIsOpen = false;

  constructor() {
    makeAutoObservable(this);
  }

  setIsDeleteDialogOpen(boolean) {
    this.isDeleteDialogOpen = boolean;
  }

  setDeleteDialogType(type) {
    this.deleteDialogType = type;
  }

  setIdToDelete(id) {
    this.idToDelete = id;
  }

  setNewProjectDialogIsOpen(boolean) {
    this.newProjectDialogIsOpen = boolean;
  }


}