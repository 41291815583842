import { useEffect, useState, useContext } from "react";
import { observer } from "mobx-react-lite";

import { StoreContext } from "../../../../stores/store.context";

import ScreenInfo from "./ScreenInfo";
import ScreenEdit from "./ScreenEdit";

import useStyles from "./EditSideBarStyle";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

const ScreenComponent = observer(() => {
  const { project } = useContext(StoreContext);
  const screen = project.selectedScreenEdit
  const c = useStyles();
  const [editRequested, setEditRequested] = useState(false);

  useEffect(() => {
    setEditRequested(false);
  }, [screen]);

  return (

    <>
      {editRequested ? (
        <ScreenEdit
          editRequested={editRequested}
          setEditRequested={setEditRequested}
        />
      ) : (
        <>
          {screen.panel ? (
            <ScreenInfo
              editRequested={editRequested}
              setEditRequested={setEditRequested}
            />
          ) : (
            <div className="panel-error">
              The panel associated to this screen does not exist in the
              database, it is likely it was removed. The screen will not appear
              in this project until a new panel has been assigned. Panels can be
              assigned by clicking the the Edit Screen button below.
            </div>
          )}

          <Grid item xs={12} style={{ paddingTop: "15px" }}>
            <Grid container justifyContent="center" style={{ width: "100%" }}>
              <Button
                variant="contained"
                onClick={() => {
                  setEditRequested(!editRequested);
                }}
                autoFocus={false}
                className={c.editScreenButton}
                color="secondary"
              >
                Edit Screen
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
});

export default ScreenComponent;
