import { useContext } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";

import NewPanelDialog from "../../components/NewPanelDialog/NewPanelDialog.js";
import DashboardToolbar from "../../components/Toolbar/Toolbar";

import useStyles from "./UserManualStyles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import { StoreContext } from "../../stores/store.context";

function AccountPage() {
  const { user, app } = useContext(StoreContext);
  const classes = useStyles();
  const owner = user.username;
  const history = useHistory();

  async function signOut() {
    try {
      await Auth.signOut();
      history.push("/");
    } catch (error) {
      console.error(error);
    }
  }

  function panelOpen() {
    app.setNewPanelDialogIsOpen(true);
  }

  return (
    <div className={classes.root}>
      <DashboardToolbar heading="User Manual" />
      <Box className={classes.navbar} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Box
          style={{
            // backgroundColor: 'grey',
            height: "100%",
            flex: 1,
          }}
          alignItems="flex-end"
          display="flex"
          flexDirection="row-reverse"
        >
          <Button
            onClick={panelOpen}
            style={{
              width: "100px",
              height: "100px",
              align: "right",
            }}
          ></Button>
        </Box>
        <NewPanelDialog open={true} />
      </main>
    </div>
  );
}

export default AccountPage;
