import { useEffect, memo, useContext } from "react";
import { observer } from "mobx-react-lite";

import { StoreContext } from "../../stores/store.context";

import OutputPageFetchAndSub from "../../utils/OutputPageFetchAndSub";
import { ContentSwitch } from "./components/content";
import { Coordinates } from "./components/coordinates";
import { ScreenGrids } from "./components/screengrid";
import { PressedPanels } from "./components/pressed.panels.jsx";
import { PressedModules } from "./components/pressed.modules.jsx";
import { StarredModules } from "./components/starred.modules";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { CircularProgress } from "@material-ui/core";

const OutputPage = observer(() => {
  const { app, user } = useContext(StoreContext);

  return (
    <div
      style={{
        position: "absolute",
        width: user.outputPageOutput.width,
        height: user.outputPageOutput.height,
        overflow: "hidden",
      }}
    >
      <OutputPageFetchAndSub />
      {app.outputPageLoaderPost && user.outputPageOutput ? (
        <div>
          <Box zIndex="1" position="absolute">
            <ContentSwitch />
          </Box>
          <Box zIndex="2" position="absolute">
            <ScreenGrids />
          </Box>
          <Box zIndex="3" position="absolute">
            <Coordinates />
          </Box>
          <Box zIndex="4" position="absolute">
            <PressedPanels />
          </Box>
          <Box zIndex="5" position="absolute">
            <PressedModules />
          </Box>
          <Box zIndex="6" position="absolute">
            <StarredModules />
          </Box>
        </div>
      ) : (
        <LoadingScreen />
      )}
    </div>
  );
});

export default memo(OutputPage);

const LoadingScreen = () => {
  const { app } = useContext(StoreContext);

  useEffect(() => {
    if (app.outputPageLoader) {
      fadeOutEffect();
      setTimeout(() => app.setOutputPageLoaderPost(true), 500);
    }
  }, [app.outputPageLoader]);

  function fadeOutEffect() {
    var fadeTarget = document.getElementById("loader");
    var fadeEffect = setInterval(function () {
      if (!fadeTarget.style.opacity) {
        fadeTarget.style.opacity = 1;
      }
      if (fadeTarget.style.opacity > 0) {
        fadeTarget.style.opacity -= 0.1;
      } else {
        clearInterval(fadeEffect);
      }
    }, 22);
  }

  return (
    <Box zIndex="2000" id="loader">
      <Grid
        container
        alignItems="center"
        justifyContent="space-around"
        style={{
          height: "100vh",
          flexDirection: "column",
          display: "flex",
        }}
      >
        <CircularProgress size={30} />
      </Grid>
    </Box>
  );
};
