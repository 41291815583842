/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateProject = /* GraphQL */ `
  subscription OnCreateProject($owner: String!) {
    onCreateProject(owner: $owner) {
      id
      name
      projectBackgroundContent
      projectBackgroundContentPlay
      projectBackgroundContentGrid
      projectBackgroundContentLabels
      projectWidth
      projectHeight
      screens {
        items {
          id
          panelColumns
          panelRows
          panelID
          projectID
          startX
          startY
          rotation
          ownerScreenBackgroundContent
          name
          color
          zIndex
          isUsingUserPanel
          project {
            id
            name
            projectBackgroundContent
            projectBackgroundContentPlay
            projectBackgroundContentGrid
            projectBackgroundContentLabels
            projectWidth
            projectHeight
            screens {
              nextToken
            }
            creator {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            outputs {
              nextToken
            }
            isGridOn
            isLabelOn
            isStarredOn
            isPlaying
            createdAt
            updatedAt
            owner
          }
          panel {
            id
            make
            model
            pixelWidth
            pixelHeight
            moduleColumns
            moduleRows
            weight
            maxWatt
            averageWatt
            frameSubSpecies
            sizeSubSpecies
            createdAt
            updatedAt
            owner
          }
          userPanel {
            id
            make
            model
            pixelWidth
            pixelHeight
            moduleColumns
            moduleRows
            weight
            maxWatt
            averageWatt
            frameSubSpecies
            sizeSubSpecies
            createdAt
            updatedAt
            owner
          }
          pressedPanel {
            items {
              id
              x
              y
              projectID
              ownerScreenID
              index
              panelState
              isStarred
              isOn
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          pressedModule {
            items {
              id
              panelX
              panelY
              moduleX
              moduleY
              moduleState
              index
              ownerScreenID
              projectID
              isStarred
              isReplaced
              isOn
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      creator {
        id
        lastLogin
        username
        currentSubscription {
          items {
            id
            startDate
            endDate
            tier
            autoRenewal
            user {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            purchaseAmount
            currency
            userId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        subscriptions {
          items {
            id
            startDate
            endDate
            tier
            autoRenewal
            user {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            purchaseAmount
            currency
            userId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        availableProjects {
          items {
            id
            name
            projectBackgroundContent
            projectBackgroundContentPlay
            projectBackgroundContentGrid
            projectBackgroundContentLabels
            projectWidth
            projectHeight
            screens {
              nextToken
            }
            creator {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            outputs {
              nextToken
            }
            isGridOn
            isLabelOn
            isStarredOn
            isPlaying
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        email
        createdAt
        updatedAt
        owner
      }
      outputs {
        items {
          id
          name
          projectID
          width
          height
          startX
          startY
          color
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      isGridOn
      isLabelOn
      isStarredOn
      isPlaying
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateProject = /* GraphQL */ `
  subscription OnUpdateProject($owner: String!) {
    onUpdateProject(owner: $owner) {
      id
      name
      projectBackgroundContent
      projectBackgroundContentPlay
      projectBackgroundContentGrid
      projectBackgroundContentLabels
      projectWidth
      projectHeight
      screens {
        items {
          id
          panelColumns
          panelRows
          panelID
          projectID
          startX
          startY
          rotation
          ownerScreenBackgroundContent
          name
          color
          zIndex
          isUsingUserPanel
          project {
            id
            name
            projectBackgroundContent
            projectBackgroundContentPlay
            projectBackgroundContentGrid
            projectBackgroundContentLabels
            projectWidth
            projectHeight
            screens {
              nextToken
            }
            creator {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            outputs {
              nextToken
            }
            isGridOn
            isLabelOn
            isStarredOn
            isPlaying
            createdAt
            updatedAt
            owner
          }
          panel {
            id
            make
            model
            pixelWidth
            pixelHeight
            moduleColumns
            moduleRows
            weight
            maxWatt
            averageWatt
            frameSubSpecies
            sizeSubSpecies
            createdAt
            updatedAt
            owner
          }
          userPanel {
            id
            make
            model
            pixelWidth
            pixelHeight
            moduleColumns
            moduleRows
            weight
            maxWatt
            averageWatt
            frameSubSpecies
            sizeSubSpecies
            createdAt
            updatedAt
            owner
          }
          pressedPanel {
            items {
              id
              x
              y
              projectID
              ownerScreenID
              index
              panelState
              isStarred
              isOn
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          pressedModule {
            items {
              id
              panelX
              panelY
              moduleX
              moduleY
              moduleState
              index
              ownerScreenID
              projectID
              isStarred
              isReplaced
              isOn
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      creator {
        id
        lastLogin
        username
        currentSubscription {
          items {
            id
            startDate
            endDate
            tier
            autoRenewal
            user {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            purchaseAmount
            currency
            userId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        subscriptions {
          items {
            id
            startDate
            endDate
            tier
            autoRenewal
            user {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            purchaseAmount
            currency
            userId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        availableProjects {
          items {
            id
            name
            projectBackgroundContent
            projectBackgroundContentPlay
            projectBackgroundContentGrid
            projectBackgroundContentLabels
            projectWidth
            projectHeight
            screens {
              nextToken
            }
            creator {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            outputs {
              nextToken
            }
            isGridOn
            isLabelOn
            isStarredOn
            isPlaying
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        email
        createdAt
        updatedAt
        owner
      }
      outputs {
        items {
          id
          name
          projectID
          width
          height
          startX
          startY
          color
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      isGridOn
      isLabelOn
      isStarredOn
      isPlaying
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteProject = /* GraphQL */ `
  subscription OnDeleteProject($owner: String!) {
    onDeleteProject(owner: $owner) {
      id
      name
      projectBackgroundContent
      projectBackgroundContentPlay
      projectBackgroundContentGrid
      projectBackgroundContentLabels
      projectWidth
      projectHeight
      screens {
        items {
          id
          panelColumns
          panelRows
          panelID
          projectID
          startX
          startY
          rotation
          ownerScreenBackgroundContent
          name
          color
          zIndex
          isUsingUserPanel
          project {
            id
            name
            projectBackgroundContent
            projectBackgroundContentPlay
            projectBackgroundContentGrid
            projectBackgroundContentLabels
            projectWidth
            projectHeight
            screens {
              nextToken
            }
            creator {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            outputs {
              nextToken
            }
            isGridOn
            isLabelOn
            isStarredOn
            isPlaying
            createdAt
            updatedAt
            owner
          }
          panel {
            id
            make
            model
            pixelWidth
            pixelHeight
            moduleColumns
            moduleRows
            weight
            maxWatt
            averageWatt
            frameSubSpecies
            sizeSubSpecies
            createdAt
            updatedAt
            owner
          }
          userPanel {
            id
            make
            model
            pixelWidth
            pixelHeight
            moduleColumns
            moduleRows
            weight
            maxWatt
            averageWatt
            frameSubSpecies
            sizeSubSpecies
            createdAt
            updatedAt
            owner
          }
          pressedPanel {
            items {
              id
              x
              y
              projectID
              ownerScreenID
              index
              panelState
              isStarred
              isOn
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          pressedModule {
            items {
              id
              panelX
              panelY
              moduleX
              moduleY
              moduleState
              index
              ownerScreenID
              projectID
              isStarred
              isReplaced
              isOn
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      creator {
        id
        lastLogin
        username
        currentSubscription {
          items {
            id
            startDate
            endDate
            tier
            autoRenewal
            user {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            purchaseAmount
            currency
            userId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        subscriptions {
          items {
            id
            startDate
            endDate
            tier
            autoRenewal
            user {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            purchaseAmount
            currency
            userId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        availableProjects {
          items {
            id
            name
            projectBackgroundContent
            projectBackgroundContentPlay
            projectBackgroundContentGrid
            projectBackgroundContentLabels
            projectWidth
            projectHeight
            screens {
              nextToken
            }
            creator {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            outputs {
              nextToken
            }
            isGridOn
            isLabelOn
            isStarredOn
            isPlaying
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        email
        createdAt
        updatedAt
        owner
      }
      outputs {
        items {
          id
          name
          projectID
          width
          height
          startX
          startY
          color
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      isGridOn
      isLabelOn
      isStarredOn
      isPlaying
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateOutput = /* GraphQL */ `
  subscription OnCreateOutput($owner: String!) {
    onCreateOutput(owner: $owner) {
      id
      name
      projectID
      width
      height
      startX
      startY
      color
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateOutput = /* GraphQL */ `
  subscription OnUpdateOutput($owner: String!) {
    onUpdateOutput(owner: $owner) {
      id
      name
      projectID
      width
      height
      startX
      startY
      color
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteOutput = /* GraphQL */ `
  subscription OnDeleteOutput($owner: String!) {
    onDeleteOutput(owner: $owner) {
      id
      name
      projectID
      width
      height
      startX
      startY
      color
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateContentButton = /* GraphQL */ `
  subscription OnCreateContentButton {
    onCreateContentButton {
      id
      name
      primarycolor
      secondarycolor
      icon
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateContentButton = /* GraphQL */ `
  subscription OnUpdateContentButton {
    onUpdateContentButton {
      id
      name
      primarycolor
      secondarycolor
      icon
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteContentButton = /* GraphQL */ `
  subscription OnDeleteContentButton {
    onDeleteContentButton {
      id
      name
      primarycolor
      secondarycolor
      icon
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateOwnerScreen = /* GraphQL */ `
  subscription OnCreateOwnerScreen($owner: String!) {
    onCreateOwnerScreen(owner: $owner) {
      id
      panelColumns
      panelRows
      panelID
      projectID
      startX
      startY
      rotation
      ownerScreenBackgroundContent
      name
      color
      zIndex
      isUsingUserPanel
      project {
        id
        name
        projectBackgroundContent
        projectBackgroundContentPlay
        projectBackgroundContentGrid
        projectBackgroundContentLabels
        projectWidth
        projectHeight
        screens {
          items {
            id
            panelColumns
            panelRows
            panelID
            projectID
            startX
            startY
            rotation
            ownerScreenBackgroundContent
            name
            color
            zIndex
            isUsingUserPanel
            project {
              id
              name
              projectBackgroundContent
              projectBackgroundContentPlay
              projectBackgroundContentGrid
              projectBackgroundContentLabels
              projectWidth
              projectHeight
              isGridOn
              isLabelOn
              isStarredOn
              isPlaying
              createdAt
              updatedAt
              owner
            }
            panel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            userPanel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            pressedPanel {
              nextToken
            }
            pressedModule {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        creator {
          id
          lastLogin
          username
          currentSubscription {
            items {
              id
              startDate
              endDate
              tier
              autoRenewal
              purchaseAmount
              currency
              userId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscriptions {
            items {
              id
              startDate
              endDate
              tier
              autoRenewal
              purchaseAmount
              currency
              userId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          availableProjects {
            items {
              id
              name
              projectBackgroundContent
              projectBackgroundContentPlay
              projectBackgroundContentGrid
              projectBackgroundContentLabels
              projectWidth
              projectHeight
              isGridOn
              isLabelOn
              isStarredOn
              isPlaying
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          email
          createdAt
          updatedAt
          owner
        }
        outputs {
          items {
            id
            name
            projectID
            width
            height
            startX
            startY
            color
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        isGridOn
        isLabelOn
        isStarredOn
        isPlaying
        createdAt
        updatedAt
        owner
      }
      panel {
        id
        make
        model
        pixelWidth
        pixelHeight
        moduleColumns
        moduleRows
        weight
        maxWatt
        averageWatt
        frameSubSpecies
        sizeSubSpecies
        createdAt
        updatedAt
        owner
      }
      userPanel {
        id
        make
        model
        pixelWidth
        pixelHeight
        moduleColumns
        moduleRows
        weight
        maxWatt
        averageWatt
        frameSubSpecies
        sizeSubSpecies
        createdAt
        updatedAt
        owner
      }
      pressedPanel {
        items {
          id
          x
          y
          ownerScreen {
            id
            panelColumns
            panelRows
            panelID
            projectID
            startX
            startY
            rotation
            ownerScreenBackgroundContent
            name
            color
            zIndex
            isUsingUserPanel
            project {
              id
              name
              projectBackgroundContent
              projectBackgroundContentPlay
              projectBackgroundContentGrid
              projectBackgroundContentLabels
              projectWidth
              projectHeight
              isGridOn
              isLabelOn
              isStarredOn
              isPlaying
              createdAt
              updatedAt
              owner
            }
            panel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            userPanel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            pressedPanel {
              nextToken
            }
            pressedModule {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          projectID
          ownerScreenID
          index
          panelState
          isStarred
          isOn
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      pressedModule {
        items {
          id
          panelX
          panelY
          moduleX
          moduleY
          moduleState
          index
          ownerScreenID
          projectID
          ownerScreen {
            id
            panelColumns
            panelRows
            panelID
            projectID
            startX
            startY
            rotation
            ownerScreenBackgroundContent
            name
            color
            zIndex
            isUsingUserPanel
            project {
              id
              name
              projectBackgroundContent
              projectBackgroundContentPlay
              projectBackgroundContentGrid
              projectBackgroundContentLabels
              projectWidth
              projectHeight
              isGridOn
              isLabelOn
              isStarredOn
              isPlaying
              createdAt
              updatedAt
              owner
            }
            panel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            userPanel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            pressedPanel {
              nextToken
            }
            pressedModule {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          isStarred
          isReplaced
          isOn
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateOwnerScreen = /* GraphQL */ `
  subscription OnUpdateOwnerScreen($owner: String!) {
    onUpdateOwnerScreen(owner: $owner) {
      id
      panelColumns
      panelRows
      panelID
      projectID
      startX
      startY
      rotation
      ownerScreenBackgroundContent
      name
      color
      zIndex
      isUsingUserPanel
      project {
        id
        name
        projectBackgroundContent
        projectBackgroundContentPlay
        projectBackgroundContentGrid
        projectBackgroundContentLabels
        projectWidth
        projectHeight
        screens {
          items {
            id
            panelColumns
            panelRows
            panelID
            projectID
            startX
            startY
            rotation
            ownerScreenBackgroundContent
            name
            color
            zIndex
            isUsingUserPanel
            project {
              id
              name
              projectBackgroundContent
              projectBackgroundContentPlay
              projectBackgroundContentGrid
              projectBackgroundContentLabels
              projectWidth
              projectHeight
              isGridOn
              isLabelOn
              isStarredOn
              isPlaying
              createdAt
              updatedAt
              owner
            }
            panel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            userPanel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            pressedPanel {
              nextToken
            }
            pressedModule {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        creator {
          id
          lastLogin
          username
          currentSubscription {
            items {
              id
              startDate
              endDate
              tier
              autoRenewal
              purchaseAmount
              currency
              userId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscriptions {
            items {
              id
              startDate
              endDate
              tier
              autoRenewal
              purchaseAmount
              currency
              userId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          availableProjects {
            items {
              id
              name
              projectBackgroundContent
              projectBackgroundContentPlay
              projectBackgroundContentGrid
              projectBackgroundContentLabels
              projectWidth
              projectHeight
              isGridOn
              isLabelOn
              isStarredOn
              isPlaying
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          email
          createdAt
          updatedAt
          owner
        }
        outputs {
          items {
            id
            name
            projectID
            width
            height
            startX
            startY
            color
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        isGridOn
        isLabelOn
        isStarredOn
        isPlaying
        createdAt
        updatedAt
        owner
      }
      panel {
        id
        make
        model
        pixelWidth
        pixelHeight
        moduleColumns
        moduleRows
        weight
        maxWatt
        averageWatt
        frameSubSpecies
        sizeSubSpecies
        createdAt
        updatedAt
        owner
      }
      userPanel {
        id
        make
        model
        pixelWidth
        pixelHeight
        moduleColumns
        moduleRows
        weight
        maxWatt
        averageWatt
        frameSubSpecies
        sizeSubSpecies
        createdAt
        updatedAt
        owner
      }
      pressedPanel {
        items {
          id
          x
          y
          ownerScreen {
            id
            panelColumns
            panelRows
            panelID
            projectID
            startX
            startY
            rotation
            ownerScreenBackgroundContent
            name
            color
            zIndex
            isUsingUserPanel
            project {
              id
              name
              projectBackgroundContent
              projectBackgroundContentPlay
              projectBackgroundContentGrid
              projectBackgroundContentLabels
              projectWidth
              projectHeight
              isGridOn
              isLabelOn
              isStarredOn
              isPlaying
              createdAt
              updatedAt
              owner
            }
            panel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            userPanel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            pressedPanel {
              nextToken
            }
            pressedModule {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          projectID
          ownerScreenID
          index
          panelState
          isStarred
          isOn
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      pressedModule {
        items {
          id
          panelX
          panelY
          moduleX
          moduleY
          moduleState
          index
          ownerScreenID
          projectID
          ownerScreen {
            id
            panelColumns
            panelRows
            panelID
            projectID
            startX
            startY
            rotation
            ownerScreenBackgroundContent
            name
            color
            zIndex
            isUsingUserPanel
            project {
              id
              name
              projectBackgroundContent
              projectBackgroundContentPlay
              projectBackgroundContentGrid
              projectBackgroundContentLabels
              projectWidth
              projectHeight
              isGridOn
              isLabelOn
              isStarredOn
              isPlaying
              createdAt
              updatedAt
              owner
            }
            panel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            userPanel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            pressedPanel {
              nextToken
            }
            pressedModule {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          isStarred
          isReplaced
          isOn
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteOwnerScreen = /* GraphQL */ `
  subscription OnDeleteOwnerScreen($owner: String!) {
    onDeleteOwnerScreen(owner: $owner) {
      id
      panelColumns
      panelRows
      panelID
      projectID
      startX
      startY
      rotation
      ownerScreenBackgroundContent
      name
      color
      zIndex
      isUsingUserPanel
      project {
        id
        name
        projectBackgroundContent
        projectBackgroundContentPlay
        projectBackgroundContentGrid
        projectBackgroundContentLabels
        projectWidth
        projectHeight
        screens {
          items {
            id
            panelColumns
            panelRows
            panelID
            projectID
            startX
            startY
            rotation
            ownerScreenBackgroundContent
            name
            color
            zIndex
            isUsingUserPanel
            project {
              id
              name
              projectBackgroundContent
              projectBackgroundContentPlay
              projectBackgroundContentGrid
              projectBackgroundContentLabels
              projectWidth
              projectHeight
              isGridOn
              isLabelOn
              isStarredOn
              isPlaying
              createdAt
              updatedAt
              owner
            }
            panel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            userPanel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            pressedPanel {
              nextToken
            }
            pressedModule {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        creator {
          id
          lastLogin
          username
          currentSubscription {
            items {
              id
              startDate
              endDate
              tier
              autoRenewal
              purchaseAmount
              currency
              userId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscriptions {
            items {
              id
              startDate
              endDate
              tier
              autoRenewal
              purchaseAmount
              currency
              userId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          availableProjects {
            items {
              id
              name
              projectBackgroundContent
              projectBackgroundContentPlay
              projectBackgroundContentGrid
              projectBackgroundContentLabels
              projectWidth
              projectHeight
              isGridOn
              isLabelOn
              isStarredOn
              isPlaying
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          email
          createdAt
          updatedAt
          owner
        }
        outputs {
          items {
            id
            name
            projectID
            width
            height
            startX
            startY
            color
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        isGridOn
        isLabelOn
        isStarredOn
        isPlaying
        createdAt
        updatedAt
        owner
      }
      panel {
        id
        make
        model
        pixelWidth
        pixelHeight
        moduleColumns
        moduleRows
        weight
        maxWatt
        averageWatt
        frameSubSpecies
        sizeSubSpecies
        createdAt
        updatedAt
        owner
      }
      userPanel {
        id
        make
        model
        pixelWidth
        pixelHeight
        moduleColumns
        moduleRows
        weight
        maxWatt
        averageWatt
        frameSubSpecies
        sizeSubSpecies
        createdAt
        updatedAt
        owner
      }
      pressedPanel {
        items {
          id
          x
          y
          ownerScreen {
            id
            panelColumns
            panelRows
            panelID
            projectID
            startX
            startY
            rotation
            ownerScreenBackgroundContent
            name
            color
            zIndex
            isUsingUserPanel
            project {
              id
              name
              projectBackgroundContent
              projectBackgroundContentPlay
              projectBackgroundContentGrid
              projectBackgroundContentLabels
              projectWidth
              projectHeight
              isGridOn
              isLabelOn
              isStarredOn
              isPlaying
              createdAt
              updatedAt
              owner
            }
            panel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            userPanel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            pressedPanel {
              nextToken
            }
            pressedModule {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          projectID
          ownerScreenID
          index
          panelState
          isStarred
          isOn
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      pressedModule {
        items {
          id
          panelX
          panelY
          moduleX
          moduleY
          moduleState
          index
          ownerScreenID
          projectID
          ownerScreen {
            id
            panelColumns
            panelRows
            panelID
            projectID
            startX
            startY
            rotation
            ownerScreenBackgroundContent
            name
            color
            zIndex
            isUsingUserPanel
            project {
              id
              name
              projectBackgroundContent
              projectBackgroundContentPlay
              projectBackgroundContentGrid
              projectBackgroundContentLabels
              projectWidth
              projectHeight
              isGridOn
              isLabelOn
              isStarredOn
              isPlaying
              createdAt
              updatedAt
              owner
            }
            panel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            userPanel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            pressedPanel {
              nextToken
            }
            pressedModule {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          isStarred
          isReplaced
          isOn
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($owner: String!) {
    onCreateUser(owner: $owner) {
      id
      lastLogin
      username
      currentSubscription {
        items {
          id
          startDate
          endDate
          tier
          autoRenewal
          user {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          purchaseAmount
          currency
          userId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      subscriptions {
        items {
          id
          startDate
          endDate
          tier
          autoRenewal
          user {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          purchaseAmount
          currency
          userId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      availableProjects {
        items {
          id
          name
          projectBackgroundContent
          projectBackgroundContentPlay
          projectBackgroundContentGrid
          projectBackgroundContentLabels
          projectWidth
          projectHeight
          screens {
            items {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          creator {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          outputs {
            items {
              id
              name
              projectID
              width
              height
              startX
              startY
              color
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          isGridOn
          isLabelOn
          isStarredOn
          isPlaying
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      email
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($owner: String!) {
    onUpdateUser(owner: $owner) {
      id
      lastLogin
      username
      currentSubscription {
        items {
          id
          startDate
          endDate
          tier
          autoRenewal
          user {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          purchaseAmount
          currency
          userId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      subscriptions {
        items {
          id
          startDate
          endDate
          tier
          autoRenewal
          user {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          purchaseAmount
          currency
          userId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      availableProjects {
        items {
          id
          name
          projectBackgroundContent
          projectBackgroundContentPlay
          projectBackgroundContentGrid
          projectBackgroundContentLabels
          projectWidth
          projectHeight
          screens {
            items {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          creator {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          outputs {
            items {
              id
              name
              projectID
              width
              height
              startX
              startY
              color
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          isGridOn
          isLabelOn
          isStarredOn
          isPlaying
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      email
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($owner: String!) {
    onDeleteUser(owner: $owner) {
      id
      lastLogin
      username
      currentSubscription {
        items {
          id
          startDate
          endDate
          tier
          autoRenewal
          user {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          purchaseAmount
          currency
          userId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      subscriptions {
        items {
          id
          startDate
          endDate
          tier
          autoRenewal
          user {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          purchaseAmount
          currency
          userId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      availableProjects {
        items {
          id
          name
          projectBackgroundContent
          projectBackgroundContentPlay
          projectBackgroundContentGrid
          projectBackgroundContentLabels
          projectWidth
          projectHeight
          screens {
            items {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          creator {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          outputs {
            items {
              id
              name
              projectID
              width
              height
              startX
              startY
              color
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          isGridOn
          isLabelOn
          isStarredOn
          isPlaying
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      email
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateBetaTesters = /* GraphQL */ `
  subscription OnCreateBetaTesters($owner: String!) {
    onCreateBetaTesters(owner: $owner) {
      id
      email
      platform
      application
      registrationEmailSent
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateBetaTesters = /* GraphQL */ `
  subscription OnUpdateBetaTesters($owner: String!) {
    onUpdateBetaTesters(owner: $owner) {
      id
      email
      platform
      application
      registrationEmailSent
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteBetaTesters = /* GraphQL */ `
  subscription OnDeleteBetaTesters($owner: String!) {
    onDeleteBetaTesters(owner: $owner) {
      id
      email
      platform
      application
      registrationEmailSent
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateSubscriptionOrder = /* GraphQL */ `
  subscription OnCreateSubscriptionOrder($owner: String!) {
    onCreateSubscriptionOrder(owner: $owner) {
      id
      startDate
      endDate
      tier
      autoRenewal
      user {
        id
        lastLogin
        username
        currentSubscription {
          items {
            id
            startDate
            endDate
            tier
            autoRenewal
            user {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            purchaseAmount
            currency
            userId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        subscriptions {
          items {
            id
            startDate
            endDate
            tier
            autoRenewal
            user {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            purchaseAmount
            currency
            userId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        availableProjects {
          items {
            id
            name
            projectBackgroundContent
            projectBackgroundContentPlay
            projectBackgroundContentGrid
            projectBackgroundContentLabels
            projectWidth
            projectHeight
            screens {
              nextToken
            }
            creator {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            outputs {
              nextToken
            }
            isGridOn
            isLabelOn
            isStarredOn
            isPlaying
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        email
        createdAt
        updatedAt
        owner
      }
      purchaseAmount
      currency
      userId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateSubscriptionOrder = /* GraphQL */ `
  subscription OnUpdateSubscriptionOrder($owner: String!) {
    onUpdateSubscriptionOrder(owner: $owner) {
      id
      startDate
      endDate
      tier
      autoRenewal
      user {
        id
        lastLogin
        username
        currentSubscription {
          items {
            id
            startDate
            endDate
            tier
            autoRenewal
            user {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            purchaseAmount
            currency
            userId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        subscriptions {
          items {
            id
            startDate
            endDate
            tier
            autoRenewal
            user {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            purchaseAmount
            currency
            userId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        availableProjects {
          items {
            id
            name
            projectBackgroundContent
            projectBackgroundContentPlay
            projectBackgroundContentGrid
            projectBackgroundContentLabels
            projectWidth
            projectHeight
            screens {
              nextToken
            }
            creator {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            outputs {
              nextToken
            }
            isGridOn
            isLabelOn
            isStarredOn
            isPlaying
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        email
        createdAt
        updatedAt
        owner
      }
      purchaseAmount
      currency
      userId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteSubscriptionOrder = /* GraphQL */ `
  subscription OnDeleteSubscriptionOrder($owner: String!) {
    onDeleteSubscriptionOrder(owner: $owner) {
      id
      startDate
      endDate
      tier
      autoRenewal
      user {
        id
        lastLogin
        username
        currentSubscription {
          items {
            id
            startDate
            endDate
            tier
            autoRenewal
            user {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            purchaseAmount
            currency
            userId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        subscriptions {
          items {
            id
            startDate
            endDate
            tier
            autoRenewal
            user {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            purchaseAmount
            currency
            userId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        availableProjects {
          items {
            id
            name
            projectBackgroundContent
            projectBackgroundContentPlay
            projectBackgroundContentGrid
            projectBackgroundContentLabels
            projectWidth
            projectHeight
            screens {
              nextToken
            }
            creator {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            outputs {
              nextToken
            }
            isGridOn
            isLabelOn
            isStarredOn
            isPlaying
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        email
        createdAt
        updatedAt
        owner
      }
      purchaseAmount
      currency
      userId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreatePressedPanel = /* GraphQL */ `
  subscription OnCreatePressedPanel($owner: String!) {
    onCreatePressedPanel(owner: $owner) {
      id
      x
      y
      ownerScreen {
        id
        panelColumns
        panelRows
        panelID
        projectID
        startX
        startY
        rotation
        ownerScreenBackgroundContent
        name
        color
        zIndex
        isUsingUserPanel
        project {
          id
          name
          projectBackgroundContent
          projectBackgroundContentPlay
          projectBackgroundContentGrid
          projectBackgroundContentLabels
          projectWidth
          projectHeight
          screens {
            items {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          creator {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          outputs {
            items {
              id
              name
              projectID
              width
              height
              startX
              startY
              color
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          isGridOn
          isLabelOn
          isStarredOn
          isPlaying
          createdAt
          updatedAt
          owner
        }
        panel {
          id
          make
          model
          pixelWidth
          pixelHeight
          moduleColumns
          moduleRows
          weight
          maxWatt
          averageWatt
          frameSubSpecies
          sizeSubSpecies
          createdAt
          updatedAt
          owner
        }
        userPanel {
          id
          make
          model
          pixelWidth
          pixelHeight
          moduleColumns
          moduleRows
          weight
          maxWatt
          averageWatt
          frameSubSpecies
          sizeSubSpecies
          createdAt
          updatedAt
          owner
        }
        pressedPanel {
          items {
            id
            x
            y
            ownerScreen {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            projectID
            ownerScreenID
            index
            panelState
            isStarred
            isOn
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        pressedModule {
          items {
            id
            panelX
            panelY
            moduleX
            moduleY
            moduleState
            index
            ownerScreenID
            projectID
            ownerScreen {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            isStarred
            isReplaced
            isOn
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      projectID
      ownerScreenID
      index
      panelState
      isStarred
      isOn
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdatePressedPanel = /* GraphQL */ `
  subscription OnUpdatePressedPanel($owner: String!) {
    onUpdatePressedPanel(owner: $owner) {
      id
      x
      y
      ownerScreen {
        id
        panelColumns
        panelRows
        panelID
        projectID
        startX
        startY
        rotation
        ownerScreenBackgroundContent
        name
        color
        zIndex
        isUsingUserPanel
        project {
          id
          name
          projectBackgroundContent
          projectBackgroundContentPlay
          projectBackgroundContentGrid
          projectBackgroundContentLabels
          projectWidth
          projectHeight
          screens {
            items {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          creator {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          outputs {
            items {
              id
              name
              projectID
              width
              height
              startX
              startY
              color
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          isGridOn
          isLabelOn
          isStarredOn
          isPlaying
          createdAt
          updatedAt
          owner
        }
        panel {
          id
          make
          model
          pixelWidth
          pixelHeight
          moduleColumns
          moduleRows
          weight
          maxWatt
          averageWatt
          frameSubSpecies
          sizeSubSpecies
          createdAt
          updatedAt
          owner
        }
        userPanel {
          id
          make
          model
          pixelWidth
          pixelHeight
          moduleColumns
          moduleRows
          weight
          maxWatt
          averageWatt
          frameSubSpecies
          sizeSubSpecies
          createdAt
          updatedAt
          owner
        }
        pressedPanel {
          items {
            id
            x
            y
            ownerScreen {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            projectID
            ownerScreenID
            index
            panelState
            isStarred
            isOn
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        pressedModule {
          items {
            id
            panelX
            panelY
            moduleX
            moduleY
            moduleState
            index
            ownerScreenID
            projectID
            ownerScreen {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            isStarred
            isReplaced
            isOn
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      projectID
      ownerScreenID
      index
      panelState
      isStarred
      isOn
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeletePressedPanel = /* GraphQL */ `
  subscription OnDeletePressedPanel($owner: String!) {
    onDeletePressedPanel(owner: $owner) {
      id
      x
      y
      ownerScreen {
        id
        panelColumns
        panelRows
        panelID
        projectID
        startX
        startY
        rotation
        ownerScreenBackgroundContent
        name
        color
        zIndex
        isUsingUserPanel
        project {
          id
          name
          projectBackgroundContent
          projectBackgroundContentPlay
          projectBackgroundContentGrid
          projectBackgroundContentLabels
          projectWidth
          projectHeight
          screens {
            items {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          creator {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          outputs {
            items {
              id
              name
              projectID
              width
              height
              startX
              startY
              color
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          isGridOn
          isLabelOn
          isStarredOn
          isPlaying
          createdAt
          updatedAt
          owner
        }
        panel {
          id
          make
          model
          pixelWidth
          pixelHeight
          moduleColumns
          moduleRows
          weight
          maxWatt
          averageWatt
          frameSubSpecies
          sizeSubSpecies
          createdAt
          updatedAt
          owner
        }
        userPanel {
          id
          make
          model
          pixelWidth
          pixelHeight
          moduleColumns
          moduleRows
          weight
          maxWatt
          averageWatt
          frameSubSpecies
          sizeSubSpecies
          createdAt
          updatedAt
          owner
        }
        pressedPanel {
          items {
            id
            x
            y
            ownerScreen {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            projectID
            ownerScreenID
            index
            panelState
            isStarred
            isOn
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        pressedModule {
          items {
            id
            panelX
            panelY
            moduleX
            moduleY
            moduleState
            index
            ownerScreenID
            projectID
            ownerScreen {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            isStarred
            isReplaced
            isOn
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      projectID
      ownerScreenID
      index
      panelState
      isStarred
      isOn
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreatePressedModule = /* GraphQL */ `
  subscription OnCreatePressedModule($owner: String!) {
    onCreatePressedModule(owner: $owner) {
      id
      panelX
      panelY
      moduleX
      moduleY
      moduleState
      index
      ownerScreenID
      projectID
      ownerScreen {
        id
        panelColumns
        panelRows
        panelID
        projectID
        startX
        startY
        rotation
        ownerScreenBackgroundContent
        name
        color
        zIndex
        isUsingUserPanel
        project {
          id
          name
          projectBackgroundContent
          projectBackgroundContentPlay
          projectBackgroundContentGrid
          projectBackgroundContentLabels
          projectWidth
          projectHeight
          screens {
            items {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          creator {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          outputs {
            items {
              id
              name
              projectID
              width
              height
              startX
              startY
              color
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          isGridOn
          isLabelOn
          isStarredOn
          isPlaying
          createdAt
          updatedAt
          owner
        }
        panel {
          id
          make
          model
          pixelWidth
          pixelHeight
          moduleColumns
          moduleRows
          weight
          maxWatt
          averageWatt
          frameSubSpecies
          sizeSubSpecies
          createdAt
          updatedAt
          owner
        }
        userPanel {
          id
          make
          model
          pixelWidth
          pixelHeight
          moduleColumns
          moduleRows
          weight
          maxWatt
          averageWatt
          frameSubSpecies
          sizeSubSpecies
          createdAt
          updatedAt
          owner
        }
        pressedPanel {
          items {
            id
            x
            y
            ownerScreen {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            projectID
            ownerScreenID
            index
            panelState
            isStarred
            isOn
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        pressedModule {
          items {
            id
            panelX
            panelY
            moduleX
            moduleY
            moduleState
            index
            ownerScreenID
            projectID
            ownerScreen {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            isStarred
            isReplaced
            isOn
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      isStarred
      isReplaced
      isOn
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdatePressedModule = /* GraphQL */ `
  subscription OnUpdatePressedModule($owner: String!) {
    onUpdatePressedModule(owner: $owner) {
      id
      panelX
      panelY
      moduleX
      moduleY
      moduleState
      index
      ownerScreenID
      projectID
      ownerScreen {
        id
        panelColumns
        panelRows
        panelID
        projectID
        startX
        startY
        rotation
        ownerScreenBackgroundContent
        name
        color
        zIndex
        isUsingUserPanel
        project {
          id
          name
          projectBackgroundContent
          projectBackgroundContentPlay
          projectBackgroundContentGrid
          projectBackgroundContentLabels
          projectWidth
          projectHeight
          screens {
            items {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          creator {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          outputs {
            items {
              id
              name
              projectID
              width
              height
              startX
              startY
              color
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          isGridOn
          isLabelOn
          isStarredOn
          isPlaying
          createdAt
          updatedAt
          owner
        }
        panel {
          id
          make
          model
          pixelWidth
          pixelHeight
          moduleColumns
          moduleRows
          weight
          maxWatt
          averageWatt
          frameSubSpecies
          sizeSubSpecies
          createdAt
          updatedAt
          owner
        }
        userPanel {
          id
          make
          model
          pixelWidth
          pixelHeight
          moduleColumns
          moduleRows
          weight
          maxWatt
          averageWatt
          frameSubSpecies
          sizeSubSpecies
          createdAt
          updatedAt
          owner
        }
        pressedPanel {
          items {
            id
            x
            y
            ownerScreen {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            projectID
            ownerScreenID
            index
            panelState
            isStarred
            isOn
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        pressedModule {
          items {
            id
            panelX
            panelY
            moduleX
            moduleY
            moduleState
            index
            ownerScreenID
            projectID
            ownerScreen {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            isStarred
            isReplaced
            isOn
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      isStarred
      isReplaced
      isOn
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeletePressedModule = /* GraphQL */ `
  subscription OnDeletePressedModule($owner: String!) {
    onDeletePressedModule(owner: $owner) {
      id
      panelX
      panelY
      moduleX
      moduleY
      moduleState
      index
      ownerScreenID
      projectID
      ownerScreen {
        id
        panelColumns
        panelRows
        panelID
        projectID
        startX
        startY
        rotation
        ownerScreenBackgroundContent
        name
        color
        zIndex
        isUsingUserPanel
        project {
          id
          name
          projectBackgroundContent
          projectBackgroundContentPlay
          projectBackgroundContentGrid
          projectBackgroundContentLabels
          projectWidth
          projectHeight
          screens {
            items {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          creator {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          outputs {
            items {
              id
              name
              projectID
              width
              height
              startX
              startY
              color
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          isGridOn
          isLabelOn
          isStarredOn
          isPlaying
          createdAt
          updatedAt
          owner
        }
        panel {
          id
          make
          model
          pixelWidth
          pixelHeight
          moduleColumns
          moduleRows
          weight
          maxWatt
          averageWatt
          frameSubSpecies
          sizeSubSpecies
          createdAt
          updatedAt
          owner
        }
        userPanel {
          id
          make
          model
          pixelWidth
          pixelHeight
          moduleColumns
          moduleRows
          weight
          maxWatt
          averageWatt
          frameSubSpecies
          sizeSubSpecies
          createdAt
          updatedAt
          owner
        }
        pressedPanel {
          items {
            id
            x
            y
            ownerScreen {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            projectID
            ownerScreenID
            index
            panelState
            isStarred
            isOn
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        pressedModule {
          items {
            id
            panelX
            panelY
            moduleX
            moduleY
            moduleState
            index
            ownerScreenID
            projectID
            ownerScreen {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            isStarred
            isReplaced
            isOn
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      isStarred
      isReplaced
      isOn
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreatePanel = /* GraphQL */ `
  subscription OnCreatePanel {
    onCreatePanel {
      id
      make
      model
      pixelWidth
      pixelHeight
      moduleColumns
      moduleRows
      weight
      maxWatt
      averageWatt
      frameSubSpecies
      sizeSubSpecies
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdatePanel = /* GraphQL */ `
  subscription OnUpdatePanel {
    onUpdatePanel {
      id
      make
      model
      pixelWidth
      pixelHeight
      moduleColumns
      moduleRows
      weight
      maxWatt
      averageWatt
      frameSubSpecies
      sizeSubSpecies
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeletePanel = /* GraphQL */ `
  subscription OnDeletePanel {
    onDeletePanel {
      id
      make
      model
      pixelWidth
      pixelHeight
      moduleColumns
      moduleRows
      weight
      maxWatt
      averageWatt
      frameSubSpecies
      sizeSubSpecies
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateUserPanel = /* GraphQL */ `
  subscription OnCreateUserPanel($owner: String!) {
    onCreateUserPanel(owner: $owner) {
      id
      make
      model
      pixelWidth
      pixelHeight
      moduleColumns
      moduleRows
      weight
      maxWatt
      averageWatt
      frameSubSpecies
      sizeSubSpecies
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateUserPanel = /* GraphQL */ `
  subscription OnUpdateUserPanel($owner: String!) {
    onUpdateUserPanel(owner: $owner) {
      id
      make
      model
      pixelWidth
      pixelHeight
      moduleColumns
      moduleRows
      weight
      maxWatt
      averageWatt
      frameSubSpecies
      sizeSubSpecies
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteUserPanel = /* GraphQL */ `
  subscription OnDeleteUserPanel($owner: String!) {
    onDeleteUserPanel(owner: $owner) {
      id
      make
      model
      pixelWidth
      pixelHeight
      moduleColumns
      moduleRows
      weight
      maxWatt
      averageWatt
      frameSubSpecies
      sizeSubSpecies
      createdAt
      updatedAt
      owner
    }
  }
`;
