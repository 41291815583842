import { useState, useContext } from "react";

import { StoreContext } from "../../../../stores/store.context";

import Paper from "@material-ui/core/Paper";

const DisplayButton = (props) => {
  const { project } = useContext(StoreContext);
  const { index } = props;
  const output = project.outputList[index];
  const [color, setColor] = useState("#000");

  const mouseEnter = () => {
    setColor(output.color);
    output.setHighlight(true);
  };

  const mouseLeave = () => {
    setColor("#000");
    output.setHighlight(false);
  };

  return (
    <Paper
      elevation={4}
      style={{
        marginTop: 10,
        width: "100%",
        marginLeft: 10,
        marginRight: 10,
        borderRadius: 5,
        color: "white",
      }}
    >
      <button
        onClick={(event) => {
          event.preventDefault();
          window.open(`/output/${project.id}/${output.id}`);
        }}
        style={{
          borderColor: color,
          backgroundColor: "black",
          borderWidth: 3,
          borderStyle: "solid",
          cursor: "pointer",
          paddingTop: 15,
          paddingBottom: 15,
          width: "100%",
          borderRadius: 5,
          color: "white",
        }}
        autoFocus={false}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
      >
        OPEN DISPLAY {output.name.toUpperCase()}
      </button>
    </Paper>
  );
};

export default DisplayButton;
