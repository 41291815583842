import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  title: {
    paddingBottom: "20px",
    fontFamily: "Chivo",
    fontSize: 25,
  },
  fieldTitle: {
    paddingBottom: 0,
    fontFamily: "Chivo",
    fontWeight: 300,
  },
  noAccount: {
    fontSize: 14,
  },
  reset: {
    fontSize: 13,
    marginTop: 5,
    fontWeight: 600,
    // width: '100%',
    alignItems: "center",
    flexGrow: 1,
  },
}));
