// import { createSubscriptionLambda } from "../../graphql/mutations";

export const createSubscription = async (id, username, length) => {
  let start = new Date();
  let end = new Date();
  start.setSeconds(+start.getSeconds() + 0);
  end.setSeconds(+end.getSeconds() + length);
  start = start.toISOString();
  end = end.toISOString();
  const payload = {
    startDate: start,
    endDate: end,
    tier: 1,
    purchaseAmount: 1300,
    subscriptionOrderUserId: id,
    username: username,
  };
  // const res = await API.graphql(
  //   graphqlOperation(createSubscriptionLambda, { input: payload })
  // );
  // console.log(res);
};
