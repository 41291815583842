import { useState, useContext } from "react";
import { observer } from "mobx-react-lite";
import { API, graphqlOperation } from "aws-amplify";
import { pools, apiKey } from "../../utils/AuthType";
// import isEmail from "validator/lib/isEmail";

import { StoreContext } from "../../stores/store.context";
import { emailBetaRequestToSupport } from "../../graphql/mutations";

import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";

import "./style.css";

export const BetaTestingFormDialog = observer(() => {
  const { app } = useContext(StoreContext);

  const [isMac, setIsMac] = useState(false);
  const [isOsChosen, setIsOsChosen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emailAlreadyExists, setEmailAlreadyExits] = useState(false);
  const [resSuccess, setResSuccess] = useState(false);
  const [resFailed, setResFailed] = useState(false);
  const [formState, setFormState] = useState({
    email: "",
    application: "",
    isMac: isMac,
    emailError: false,
    applicationError: false,
  });

  const applicationList = [
    "Digital Billboard",
    "Studio / Sound Stage",
    "Stadium / Arena",
    "Trade Shows",
    "Live Events",
    "Other",
  ];

  const setInput = (key, value) => {
    setFormState({ ...formState, [key]: value });
  };

  const getPlatform = () => {
    if (isMac) {
      return "ios";
    } else {
      return "android";
    }
  };

  const createNewBetaTester = async () => {
    let platform = getPlatform();

    let payload = {
      email: formState.email,
      application: formState.application,
      platform: platform,
    };

    try {
      apiKey();
      const res = await API.graphql(
        graphqlOperation(emailBetaRequestToSupport, { input: payload })
      );
      pools();
      console.log(res);
      // if (res.data.emailBetaRequestToSupport === "SUCCESS") {
      //   return true;
      // } else {
      //   return false;
      // }
    } catch (error) {
      console.log(error);
    }
  };

  const submit = async () => {
    setInput("emailError", true);

    if (!formState.application) {
      setInput("applicationError", true);
      return;
    }

    setIsLoading(true);

    let res = await createNewBetaTester();

    if (res) {
      setResSuccess(true);
      setIsLoading(false);
    } else {
      setResFailed(true);
      setIsLoading(false);
    }
  };

  const setPlatform = (boolean) => {
    setIsMac(boolean);
    setIsOsChosen(true);
  };

  const cancel = () => {
    app.setBetaTestingDialogIsOpen(false);
    setTimeout(() => {
      setIsOsChosen(false);
      setIsOsChosen(false);
      setIsLoading(false);
      setResSuccess(false);
      setResFailed(false);
    }, 500);
  };

  return (
    <Dialog className="root" open={app.betaTestingDialogIsOpen}>
      <div className="root-container">
        {!isOsChosen ? (
          <ChooseOS setPlatform={setPlatform} />
        ) : (
          <>
            {isLoading ? (
              <div className="loading-container">
                <CircularProgress className="progress" />
              </div>
            ) : (
              <>
                {(resSuccess || resFailed) && (
                  <ResSuccess
                    resSuccess={resSuccess}
                    resFailed={resFailed}
                    cancel={cancel}
                  />
                )}
                {!resSuccess && (
                  <>
                    <Description isMac={isMac} />

                    <Form
                      setInput={setInput}
                      formState={formState}
                      applicationList={applicationList}
                      cancel={cancel}
                      submit={submit}
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </Dialog>
  );
});

const ResSuccess = (props) => {
  const { resSuccess, resFailed, cancel } = props;

  const close = () => {
    cancel();
  };

  return (
    <>
      <div className="res-success-container">
        {resSuccess ? (
          <>
            <div className="res-success-body">
              Your request has been received. We are working on adding you to
              our test group. You will receive and email shortly with a
              username, password, and link to the app in the app store.
            </div>
            <div className="button res-sucess-button" onClick={close}>
              CLOSE
            </div>
          </>
        ) : (
          <div className="res-success-body">
            It looks like that email has been used previously, try using a
            different email address.
          </div>
        )}
      </div>
    </>
  );
};

const Description = (props) => {
  const { isMac } = props;
  return (
    <>
      <div className="form-header">TK-421 Testing</div>
      {isMac ? (
        <>
          <div className="form-description">
            Thank you for your interest in TK-421. Submit the form below to be
            in included in our test group.
          </div>
          <div className="form-description">
            iOS testing requires an email address associated with an Apple
            account.
          </div>
        </>
      ) : (
        <>
          <div className="form-description">
            Thank you for your interest in TK-421. Upon submitting the below
            form you will receive an email containing credentials and a link to
            the mobile app.
          </div>
          <div className="form-description">
            Android testing requires a Google account email address.
          </div>
        </>
      )}
    </>
  );
};

const Form = (props) => {
  const { formState, setInput, applicationList, cancel, submit } = props;
  return (
    <div>
      <div className="form-field-title email">Email</div>
      <TextField
        label="Email"
        id="email-text-field"
        variant="outlined"
        fullWidth
        onChange={(event) => setInput("email", event.target.value)}
        value={formState.email}
        margin="dense"
      />
      <div className="form-field-title application">LED Application</div>
      <Select
        id="application-select"
        style={{ width: "100%", marginTop: 10 }}
        variant="outlined"
        size="small"
        margin="dense"
        value={formState.application}
        onChange={(event) => setInput("application", event.target.value)}
      >
        {applicationList.map((application, index) => (
          <MenuItem key={index} value={application}>
            {application}
          </MenuItem>
        ))}
      </Select>
      <div className="button submit" onClick={submit}>
        SUBMIT
      </div>
      <div className="button cancel" onClick={cancel}>
        CANCEL
      </div>
    </div>
  );
};

const ChooseOS = (props) => {
  const { setPlatform } = props;
  return (
    <div className="platform-container">
      <div className="platform-header">Mobile Platform </div>
      <div className="platform-description"></div>
      <div className="platform-button-container">
        <div className="platform-button" onClick={() => setPlatform(false)}>
          ANDROID
        </div>
        <div className="platform-button" onClick={() => setPlatform(true)}>
          iOS
        </div>
      </div>
    </div>
  );
};
