import { useEffect, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";


import {
  getProject,
  pressedPanelsbyProjectID,
  pressedModulesbyProjectID,
} from "../graphql/queries";

import {
  onCreatePressedPanel,
  onUpdatePressedPanel,
} from "../api/subscriptionsMin";

import {
  onCreateOwnerScreen,
  onDeleteOwnerScreen,
  onCreatePressedModule,
  onDeletePressedModule,
  onUpdatePressedModule,
  onUpdateOwnerScreen,
  onUpdateProject,
} from "../graphql/subscriptions";

import { StoreContext } from "../stores/store.context";

// When a user selects a new project it triggers a
// new subscription on the Screens Table, there is a
// check to see if there is a current user loggin in

const OutputPageFetchAndSub = observer((props) => {
  const { user, project, app } = useContext(StoreContext)
  const urlSplit = useLocation().pathname.split("/");
  const projectId = urlSplit[2];
  const outputId = urlSplit[3];
  const screenList = [];

  function batchFetchPanels() {
    if (screenList.length > 0) {
      screenList.forEach((screen) => queryPressedPanels(screen.id));
      screenList.forEach((screen) => fetchPressedModules(screen.id));
    }
  }

  useEffect(() => {
    if (user.isLoggedIn && user.isSubscribed) {
      const owner = user.username;

      fetchProject().then(batchFetchPanels);

      user.isLoggedIn && user.isSubscribed
        ? app.setOutputPageLoader(true)
        : console.log("");

      // Listeners for Project Changes

      const updateProjectListner = API.graphql(
        graphqlOperation(onUpdateProject, { owner: owner })
      ).subscribe({
        next: (projectData) => {
          const updatedProject = projectData.value.data.onUpdateProject;
          if (updatedProject.id === project.id) {
            project.selectProject(updatedProject);
          }
          project.setProjectBackgroundContent(updatedProject.projectBackgroundContent);
        },
        error: (err) => {
          console.error(err);
        },
      });

      // Listners for Screen Changes
      const createOwnerScreenListener = API.graphql(
        graphqlOperation(onCreateOwnerScreen, { owner: owner })
      ).subscribe({
        next: (screenData) => {
          const newScreen = screenData.value.data.onCreateOwnerScreen;
          if (newScreen.projectID === projectId) {
            project.addScreen(newScreen);
          }
        },
      });

      const updateOwnerScreenListener = API.graphql(
        graphqlOperation(onUpdateOwnerScreen, { owner: owner })
      ).subscribe({
        next: (screenData) => {
          const updatedScreen = screenData.value.data.onUpdateOwnerScreen;
          project.removeScreen(updatedScreen.id);
          project.addScreen(updatedScreen);
        },
      });

      const deleteOwnerScreenListener = API.graphql(
        graphqlOperation(onDeleteOwnerScreen, { owner: owner })
      ).subscribe({
        next: (screenData) => {
          const deleteOwnerScreen = screenData.value.data.onDeleteOwnerScreen;
          if (screenData.projectID === projectId) {
            project.removeScreen(deleteOwnerScreen.id);
          }
        },
      });

      const createPressedPanelListener = API.graphql(
        graphqlOperation(onCreatePressedPanel, { owner: owner })
      ).subscribe({
        next: (pressedPanelData) => {
          const newPressedPanel =
            pressedPanelData.value.data.onCreatePressedPanel;
          if (newPressedPanel.projectID === projectId) {
            project.addPressedPanel(newPressedPanel);
          }
        },
        error: (err) => {
          console.error(err);
        },
      });

      const updatePressedPanelListener = API.graphql(
        graphqlOperation(onUpdatePressedPanel, { owner: owner })
      ).subscribe({
        next: (pressedPanelData) => {
          const onUpdatePressedPanel =
            pressedPanelData.value.data.onUpdatePressedPanel;
          if (onUpdatePressedPanel.projectID === projectId) {
            project.removePressedPanel(onUpdatePressedPanel.id);
          }
          project.addPressedPanel(onUpdatePressedPanel);
        },
        error: (err) => {
          console.error(err);
        },
      });

      const createPressedModuleListener = API.graphql(
        graphqlOperation(onCreatePressedModule, { owner: owner })
      ).subscribe({
        next: (pressedModuleData) => {
          const newPressedModule =
            pressedModuleData.value.data.onCreatePressedModule;
          if (newPressedModule.projectID === project.id) {
            project.addPressedModule(newPressedModule);
          }
        },
        error: (err) => {
          console.error(err);
        },
      });

      const deletePressedModuleListener = API.graphql(
        graphqlOperation(onDeletePressedModule, { owner: owner })
      ).subscribe({
        next: (pressedModuleData) => {
          const deletePressedModule =
            pressedModuleData.value.data.onDeletePressedModule;
          project.removePressedModule(deletePressedModule.id);
        },
        error: (err) => {
          console.error(err);
        },
      });

      const updatePressedModuleListener = API.graphql(
        graphqlOperation(onUpdatePressedModule, { owner: owner })
      ).subscribe({
        next: (pressedModuleData) => {
          const onUpdatePressedModule =
            pressedModuleData.value.data.onUpdatePressedModule;
          if (onUpdatePressedModule.projectID === project.id) {
            project.removePressedModule(onUpdatePressedModule.id);
            project.addPressedModule(onUpdatePressedModule);
          }
        },
        error: (err) => {
          console.error(err);
        },

      });
      return () => {
        deleteOwnerScreenListener.unsubscribe();
        createOwnerScreenListener.unsubscribe();
        updateOwnerScreenListener.unsubscribe();
        createPressedPanelListener.unsubscribe();
        updatePressedPanelListener.unsubscribe();
        updateProjectListner.unsubscribe();
        createPressedModuleListener.unsubscribe();
        deletePressedModuleListener.unsubscribe();
        updatePressedModuleListener.unsubscribe();
      };
    } else return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.isLoggedIn, user.isSubscribed]);

  // function fetches project
  async function fetchProject() {
    try {
      const thisProject = projectId;
      const projectData = await API.graphql(
        graphqlOperation(getProject, { id: thisProject })
      );
      const resProject = projectData.data.getProject;
      const outputs = resProject.outputs.items;
      let output = outputs.find((x) => x.id === outputId);
      user.setOutputPageOutput(output);
      const screens = resProject.screens.items;
      screens.forEach((screen) => screenList.push(screen));
      project.selectProject(resProject);
      document.title = `${resProject.name}: ${output.name}`;
      project.setScreenList(resProject.screens.items);
      project.setScreenList(resProject.screens.items);
    } catch (err) {
      console.error(err);
    }
  }

  async function queryPressedPanels(thatownerScreenID) {
    try {
      const thisProject = projectId;
      const pressedPanelsData = await API.graphql(
        graphqlOperation(pressedPanelsbyProjectID, {
          projectID: thisProject,
          limit: 1000,
          filter: { ownerScreenID: { eq: thatownerScreenID } },
        })
      );
      const pressedPanels =
        pressedPanelsData.data.PressedPanelsbyProjectID.items;
      project.setPressedPanels(pressedPanels);
    } catch (err) {
      console.error(err);
    }
  }

  async function fetchPressedModules() {
    try {
      const thisProject = projectId;
      const pressedModulesData = await API.graphql(
        graphqlOperation(pressedModulesbyProjectID, {
          projectID: thisProject,
        })
      );
      const pressedModules =
        pressedModulesData.data.PressedModulesbyProjectID.items;
      project.setPressedModules(pressedModules);
    } catch (err) {
      console.error(err);
    }
  }
});

export default OutputPageFetchAndSub;
