import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 220;

export const useStyles = makeStyles({
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  outerBoxStyle: {
    backgroundColor: "darkgrey",
    position: "relative",
  },

  betweenOuterAndCanvas: {
    backgroundColor: "",
    padding: "0 %",
    boxSizing: "border-box",
    margin: "auto",
    top: "50%",
    transform: "translateY(-50%)",
    position: "relative",
  },
  centeredCanvas: {
    backgroundColor: "",
    borderRadius: 0,
    position: "relative",
    margin: "auto",
    top: "50%",
    transform: "translateY(00%)",
    // position: "absolute",
  },
  outputBox: {
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    position: "absolute",
    borderStyle: "double",
  },
  screenBox: {
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    position: "absolute",
  },
});
