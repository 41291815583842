import { useContext } from "react";
import { observer } from "mobx-react-lite";

import { StoreContext } from "../../../../stores/store.context";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

export const IdText = observer(() => {
  const { project, user } = useContext(StoreContext);

  function BackgroundColor(props) {
    const screen = props.screen;
    const panel = screen.panel ? screen.panel : screen.userPanel;
    const output = user.outputPageOutput;
    let width = screen.panel.pixelWidth * screen.panelColumns;
    let height = screen.panel.pixelHeight * screen.panelRows;
    let startX = screen.startX - output.startX;
    let startY = screen.startY - output.startY;

    return (
      <div
        style={{
          transformOrigin: `${startX}px ${startY}px`,
          transform: `rotate(${screen.rotation}deg)`,
        }}
      >
        <Box
          key={screen.id}
          id={screen.id}
          position="absolute"
          top={startY}
          left={startX}
          width={width}
          height={height}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              align="center"
              variant="h1"
              style={{
                fontSize: height / 7,
                fontWeight: 800,
                paddingBottom: 0,
              }}
            >
              {screen.name}
            </Typography>
            <Typography
              variant="h6"
              align="center"
              style={{
                fontSize: height / 30,
                fontWeight: 300,
                paddingBottom: 0,
              }}
            >
              {panel.make} {panel.model}
            </Typography>
            <Typography
              variant="h3"
              align="center"
              style={{ fontSize: height / 12, fontWeight: 300, paddingTop: 0 }}
            >
              {width} {"  x  "} {height}
            </Typography>
          </div>
        </Box>
      </div>
    );
  }

  return (
    <div>
      {project.screenList.map(
        (screen, index) =>
          screen.panel && <BackgroundColor screen={screen} key={index} />
      )}
    </div>
  );
});
