import { useEffect, useContext } from "react";
import { observer } from "mobx-react-lite";

import { StoreContext } from "../../../stores/store.context";

export const ScreenGrids = observer(() => {
  const { project } = useContext(StoreContext);
  const screenList = project.screenList;

  return (
    <div className="output-container">
      {screenList.map(
        (screen, i) => screen.panel && <Grid screen={screen} key={i} />
      )}
    </div>
  );
});

const Grid = (props) => {
  const { project, user } = useContext(StoreContext);
  const { screen } = props;
  const output = user.outputPageOutput;
  const screenWidth = screen.panel.pixelWidth * screen.panelColumns;
  const screenHeight = screen.panel.pixelHeight * screen.panelRows;
  let startX = screen.startX - output.startX;
  let startY = screen.startY - output.startY;

  let light = "rgba(0,0,0,0)";
  let dark = "rgba(0,0,0,.2)";
  let canvas;
  let ctx;

  const canvasId = `${screen.id}-screen-grid`;

  let panelArray = [];

  for (let i = 0; i < screen.panelRows; i++) {
    for (let j = 0; j < screen.panelColumns; j++) {
      let panel = {};
      if (i % 2 == 0) {
        if (j % 2 == 0) {
          panel.color = light;
        } else {
          panel.color = dark;
        }
      } else {
        if (j % 2 == 0) {
          panel.color = dark;
        } else {
          panel.color = light;
        }
      }
      panel.xpos = j * screen.panel.pixelWidth;
      panel.ypos = i * screen.panel.pixelHeight;
      panel.width = screen.panel.pixelWidth;
      panel.height = screen.panel.pixelHeight;
      panelArray.push(panel);
    }
  }

  const drawGrid = (isGridOn) => {
    canvas = document.getElementById(canvasId);
    ctx = canvas.getContext("2d");
    canvas.style.left = `${screen.startX - output.startX}px`;
    canvas.style.top = `${screen.startY - output.startY}px`;
    canvas.width = screenWidth;
    canvas.height = screenHeight;

    if (isGridOn) {
      panelArray.forEach((panel, i) => {
        ctx.fillStyle = isGridOn ? panel.color : light;
        ctx.fillRect(panel.xpos, panel.ypos, panel.width, panel.height);
      });
    } else {
      ctx.clearRect(0, 0, screenWidth, screenHeight);
    }
  };

  useEffect(() => {
    canvas = document.getElementById(canvasId);
    ctx = canvas.getContext("2d");
    canvas.style.left = `${screen.startX - output.startX}px`;
    canvas.style.top = `${screen.startY - output.startY}px`;
    canvas.width = screenWidth;
    canvas.height = screenHeight;
    ctx.rotate(Math.PI / 4);
  }, []);

  useEffect(() => {
    requestAnimationFrame(() => drawGrid(project.isGridOn));
  }, [project.isGridOn]);

  return (
    <div
      style={{
        position: "absolute",
        transformOrigin: `${startX}px ${startY}px`,
        transform: `rotate(${screen.rotation}deg)`,
      }}
    >
      <canvas
        id={canvasId}
        style={{
          position: "absolute",
        }}
      />
    </div>
  );
};
