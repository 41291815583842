/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const me = /* GraphQL */ `
  query Me {
    me {
      id
      lastLogin
      username
      currentSubscription {
        items {
          id
          startDate
          endDate
          tier
          autoRenewal
          user {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          purchaseAmount
          currency
          userId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      subscriptions {
        items {
          id
          startDate
          endDate
          tier
          autoRenewal
          user {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          purchaseAmount
          currency
          userId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      availableProjects {
        items {
          id
          name
          projectBackgroundContent
          projectBackgroundContentPlay
          projectBackgroundContentGrid
          projectBackgroundContentLabels
          projectWidth
          projectHeight
          screens {
            items {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          creator {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          outputs {
            items {
              id
              name
              projectID
              width
              height
              startX
              startY
              color
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          isGridOn
          isLabelOn
          isStarredOn
          isPlaying
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      email
      createdAt
      updatedAt
      owner
    }
  }
`;
export const echo = /* GraphQL */ `
  query Echo($msg: String) {
    echo(msg: $msg)
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      name
      projectBackgroundContent
      projectBackgroundContentPlay
      projectBackgroundContentGrid
      projectBackgroundContentLabels
      projectWidth
      projectHeight
      screens {
        items {
          id
          panelColumns
          panelRows
          panelID
          projectID
          startX
          startY
          rotation
          ownerScreenBackgroundContent
          name
          color
          zIndex
          isUsingUserPanel
          project {
            id
            name
            projectBackgroundContent
            projectBackgroundContentPlay
            projectBackgroundContentGrid
            projectBackgroundContentLabels
            projectWidth
            projectHeight
            screens {
              nextToken
            }
            creator {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            outputs {
              nextToken
            }
            isGridOn
            isLabelOn
            isStarredOn
            isPlaying
            createdAt
            updatedAt
            owner
          }
          panel {
            id
            make
            model
            pixelWidth
            pixelHeight
            moduleColumns
            moduleRows
            weight
            maxWatt
            averageWatt
            frameSubSpecies
            sizeSubSpecies
            createdAt
            updatedAt
            owner
          }
          userPanel {
            id
            make
            model
            pixelWidth
            pixelHeight
            moduleColumns
            moduleRows
            weight
            maxWatt
            averageWatt
            frameSubSpecies
            sizeSubSpecies
            createdAt
            updatedAt
            owner
          }
          pressedPanel {
            items {
              id
              x
              y
              projectID
              ownerScreenID
              index
              panelState
              isStarred
              isOn
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          pressedModule {
            items {
              id
              panelX
              panelY
              moduleX
              moduleY
              moduleState
              index
              ownerScreenID
              projectID
              isStarred
              isReplaced
              isOn
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      creator {
        id
        lastLogin
        username
        currentSubscription {
          items {
            id
            startDate
            endDate
            tier
            autoRenewal
            user {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            purchaseAmount
            currency
            userId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        subscriptions {
          items {
            id
            startDate
            endDate
            tier
            autoRenewal
            user {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            purchaseAmount
            currency
            userId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        availableProjects {
          items {
            id
            name
            projectBackgroundContent
            projectBackgroundContentPlay
            projectBackgroundContentGrid
            projectBackgroundContentLabels
            projectWidth
            projectHeight
            screens {
              nextToken
            }
            creator {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            outputs {
              nextToken
            }
            isGridOn
            isLabelOn
            isStarredOn
            isPlaying
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        email
        createdAt
        updatedAt
        owner
      }
      outputs {
        items {
          id
          name
          projectID
          width
          height
          startX
          startY
          color
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      isGridOn
      isLabelOn
      isStarredOn
      isPlaying
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        projectBackgroundContent
        projectBackgroundContentPlay
        projectBackgroundContentGrid
        projectBackgroundContentLabels
        projectWidth
        projectHeight
        screens {
          items {
            id
            panelColumns
            panelRows
            panelID
            projectID
            startX
            startY
            rotation
            ownerScreenBackgroundContent
            name
            color
            zIndex
            isUsingUserPanel
            project {
              id
              name
              projectBackgroundContent
              projectBackgroundContentPlay
              projectBackgroundContentGrid
              projectBackgroundContentLabels
              projectWidth
              projectHeight
              isGridOn
              isLabelOn
              isStarredOn
              isPlaying
              createdAt
              updatedAt
              owner
            }
            panel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            userPanel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            pressedPanel {
              nextToken
            }
            pressedModule {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        creator {
          id
          lastLogin
          username
          currentSubscription {
            items {
              id
              startDate
              endDate
              tier
              autoRenewal
              purchaseAmount
              currency
              userId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscriptions {
            items {
              id
              startDate
              endDate
              tier
              autoRenewal
              purchaseAmount
              currency
              userId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          availableProjects {
            items {
              id
              name
              projectBackgroundContent
              projectBackgroundContentPlay
              projectBackgroundContentGrid
              projectBackgroundContentLabels
              projectWidth
              projectHeight
              isGridOn
              isLabelOn
              isStarredOn
              isPlaying
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          email
          createdAt
          updatedAt
          owner
        }
        outputs {
          items {
            id
            name
            projectID
            width
            height
            startX
            startY
            color
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        isGridOn
        isLabelOn
        isStarredOn
        isPlaying
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getOutput = /* GraphQL */ `
  query GetOutput($id: ID!) {
    getOutput(id: $id) {
      id
      name
      projectID
      width
      height
      startX
      startY
      color
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listOutputs = /* GraphQL */ `
  query ListOutputs(
    $filter: ModelOutputFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOutputs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        projectID
        width
        height
        startX
        startY
        color
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getContentButton = /* GraphQL */ `
  query GetContentButton($id: ID!) {
    getContentButton(id: $id) {
      id
      name
      primarycolor
      secondarycolor
      icon
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listContentButtons = /* GraphQL */ `
  query ListContentButtons(
    $filter: ModelContentButtonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContentButtons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        primarycolor
        secondarycolor
        icon
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getOwnerScreen = /* GraphQL */ `
  query GetOwnerScreen($id: ID!) {
    getOwnerScreen(id: $id) {
      id
      panelColumns
      panelRows
      panelID
      projectID
      startX
      startY
      rotation
      ownerScreenBackgroundContent
      name
      color
      zIndex
      isUsingUserPanel
      project {
        id
        name
        projectBackgroundContent
        projectBackgroundContentPlay
        projectBackgroundContentGrid
        projectBackgroundContentLabels
        projectWidth
        projectHeight
        screens {
          items {
            id
            panelColumns
            panelRows
            panelID
            projectID
            startX
            startY
            rotation
            ownerScreenBackgroundContent
            name
            color
            zIndex
            isUsingUserPanel
            project {
              id
              name
              projectBackgroundContent
              projectBackgroundContentPlay
              projectBackgroundContentGrid
              projectBackgroundContentLabels
              projectWidth
              projectHeight
              isGridOn
              isLabelOn
              isStarredOn
              isPlaying
              createdAt
              updatedAt
              owner
            }
            panel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            userPanel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            pressedPanel {
              nextToken
            }
            pressedModule {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        creator {
          id
          lastLogin
          username
          currentSubscription {
            items {
              id
              startDate
              endDate
              tier
              autoRenewal
              purchaseAmount
              currency
              userId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscriptions {
            items {
              id
              startDate
              endDate
              tier
              autoRenewal
              purchaseAmount
              currency
              userId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          availableProjects {
            items {
              id
              name
              projectBackgroundContent
              projectBackgroundContentPlay
              projectBackgroundContentGrid
              projectBackgroundContentLabels
              projectWidth
              projectHeight
              isGridOn
              isLabelOn
              isStarredOn
              isPlaying
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          email
          createdAt
          updatedAt
          owner
        }
        outputs {
          items {
            id
            name
            projectID
            width
            height
            startX
            startY
            color
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        isGridOn
        isLabelOn
        isStarredOn
        isPlaying
        createdAt
        updatedAt
        owner
      }
      panel {
        id
        make
        model
        pixelWidth
        pixelHeight
        moduleColumns
        moduleRows
        weight
        maxWatt
        averageWatt
        frameSubSpecies
        sizeSubSpecies
        createdAt
        updatedAt
        owner
      }
      userPanel {
        id
        make
        model
        pixelWidth
        pixelHeight
        moduleColumns
        moduleRows
        weight
        maxWatt
        averageWatt
        frameSubSpecies
        sizeSubSpecies
        createdAt
        updatedAt
        owner
      }
      pressedPanel {
        items {
          id
          x
          y
          ownerScreen {
            id
            panelColumns
            panelRows
            panelID
            projectID
            startX
            startY
            rotation
            ownerScreenBackgroundContent
            name
            color
            zIndex
            isUsingUserPanel
            project {
              id
              name
              projectBackgroundContent
              projectBackgroundContentPlay
              projectBackgroundContentGrid
              projectBackgroundContentLabels
              projectWidth
              projectHeight
              isGridOn
              isLabelOn
              isStarredOn
              isPlaying
              createdAt
              updatedAt
              owner
            }
            panel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            userPanel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            pressedPanel {
              nextToken
            }
            pressedModule {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          projectID
          ownerScreenID
          index
          panelState
          isStarred
          isOn
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      pressedModule {
        items {
          id
          panelX
          panelY
          moduleX
          moduleY
          moduleState
          index
          ownerScreenID
          projectID
          ownerScreen {
            id
            panelColumns
            panelRows
            panelID
            projectID
            startX
            startY
            rotation
            ownerScreenBackgroundContent
            name
            color
            zIndex
            isUsingUserPanel
            project {
              id
              name
              projectBackgroundContent
              projectBackgroundContentPlay
              projectBackgroundContentGrid
              projectBackgroundContentLabels
              projectWidth
              projectHeight
              isGridOn
              isLabelOn
              isStarredOn
              isPlaying
              createdAt
              updatedAt
              owner
            }
            panel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            userPanel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            pressedPanel {
              nextToken
            }
            pressedModule {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          isStarred
          isReplaced
          isOn
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listOwnerScreens = /* GraphQL */ `
  query ListOwnerScreens(
    $filter: ModelOwnerScreenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOwnerScreens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        panelColumns
        panelRows
        panelID
        projectID
        startX
        startY
        rotation
        ownerScreenBackgroundContent
        name
        color
        zIndex
        isUsingUserPanel
        project {
          id
          name
          projectBackgroundContent
          projectBackgroundContentPlay
          projectBackgroundContentGrid
          projectBackgroundContentLabels
          projectWidth
          projectHeight
          screens {
            items {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          creator {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          outputs {
            items {
              id
              name
              projectID
              width
              height
              startX
              startY
              color
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          isGridOn
          isLabelOn
          isStarredOn
          isPlaying
          createdAt
          updatedAt
          owner
        }
        panel {
          id
          make
          model
          pixelWidth
          pixelHeight
          moduleColumns
          moduleRows
          weight
          maxWatt
          averageWatt
          frameSubSpecies
          sizeSubSpecies
          createdAt
          updatedAt
          owner
        }
        userPanel {
          id
          make
          model
          pixelWidth
          pixelHeight
          moduleColumns
          moduleRows
          weight
          maxWatt
          averageWatt
          frameSubSpecies
          sizeSubSpecies
          createdAt
          updatedAt
          owner
        }
        pressedPanel {
          items {
            id
            x
            y
            ownerScreen {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            projectID
            ownerScreenID
            index
            panelState
            isStarred
            isOn
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        pressedModule {
          items {
            id
            panelX
            panelY
            moduleX
            moduleY
            moduleState
            index
            ownerScreenID
            projectID
            ownerScreen {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            isStarred
            isReplaced
            isOn
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      lastLogin
      username
      currentSubscription {
        items {
          id
          startDate
          endDate
          tier
          autoRenewal
          user {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          purchaseAmount
          currency
          userId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      subscriptions {
        items {
          id
          startDate
          endDate
          tier
          autoRenewal
          user {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          purchaseAmount
          currency
          userId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      availableProjects {
        items {
          id
          name
          projectBackgroundContent
          projectBackgroundContentPlay
          projectBackgroundContentGrid
          projectBackgroundContentLabels
          projectWidth
          projectHeight
          screens {
            items {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          creator {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          outputs {
            items {
              id
              name
              projectID
              width
              height
              startX
              startY
              color
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          isGridOn
          isLabelOn
          isStarredOn
          isPlaying
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      email
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        lastLogin
        username
        currentSubscription {
          items {
            id
            startDate
            endDate
            tier
            autoRenewal
            user {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            purchaseAmount
            currency
            userId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        subscriptions {
          items {
            id
            startDate
            endDate
            tier
            autoRenewal
            user {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            purchaseAmount
            currency
            userId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        availableProjects {
          items {
            id
            name
            projectBackgroundContent
            projectBackgroundContentPlay
            projectBackgroundContentGrid
            projectBackgroundContentLabels
            projectWidth
            projectHeight
            screens {
              nextToken
            }
            creator {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            outputs {
              nextToken
            }
            isGridOn
            isLabelOn
            isStarredOn
            isPlaying
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        email
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getBetaTesters = /* GraphQL */ `
  query GetBetaTesters($id: ID!) {
    getBetaTesters(id: $id) {
      id
      email
      platform
      application
      registrationEmailSent
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listBetaTesters = /* GraphQL */ `
  query ListBetaTesters(
    $filter: ModelBetaTestersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBetaTesters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        platform
        application
        registrationEmailSent
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getSubscriptionOrder = /* GraphQL */ `
  query GetSubscriptionOrder($id: ID!) {
    getSubscriptionOrder(id: $id) {
      id
      startDate
      endDate
      tier
      autoRenewal
      user {
        id
        lastLogin
        username
        currentSubscription {
          items {
            id
            startDate
            endDate
            tier
            autoRenewal
            user {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            purchaseAmount
            currency
            userId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        subscriptions {
          items {
            id
            startDate
            endDate
            tier
            autoRenewal
            user {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            purchaseAmount
            currency
            userId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        availableProjects {
          items {
            id
            name
            projectBackgroundContent
            projectBackgroundContentPlay
            projectBackgroundContentGrid
            projectBackgroundContentLabels
            projectWidth
            projectHeight
            screens {
              nextToken
            }
            creator {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            outputs {
              nextToken
            }
            isGridOn
            isLabelOn
            isStarredOn
            isPlaying
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        email
        createdAt
        updatedAt
        owner
      }
      purchaseAmount
      currency
      userId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listSubscriptionOrders = /* GraphQL */ `
  query ListSubscriptionOrders(
    $filter: ModelSubscriptionOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscriptionOrders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        startDate
        endDate
        tier
        autoRenewal
        user {
          id
          lastLogin
          username
          currentSubscription {
            items {
              id
              startDate
              endDate
              tier
              autoRenewal
              purchaseAmount
              currency
              userId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscriptions {
            items {
              id
              startDate
              endDate
              tier
              autoRenewal
              purchaseAmount
              currency
              userId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          availableProjects {
            items {
              id
              name
              projectBackgroundContent
              projectBackgroundContentPlay
              projectBackgroundContentGrid
              projectBackgroundContentLabels
              projectWidth
              projectHeight
              isGridOn
              isLabelOn
              isStarredOn
              isPlaying
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          email
          createdAt
          updatedAt
          owner
        }
        purchaseAmount
        currency
        userId
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPressedPanel = /* GraphQL */ `
  query GetPressedPanel($id: ID!) {
    getPressedPanel(id: $id) {
      id
      x
      y
      ownerScreen {
        id
        panelColumns
        panelRows
        panelID
        projectID
        startX
        startY
        rotation
        ownerScreenBackgroundContent
        name
        color
        zIndex
        isUsingUserPanel
        project {
          id
          name
          projectBackgroundContent
          projectBackgroundContentPlay
          projectBackgroundContentGrid
          projectBackgroundContentLabels
          projectWidth
          projectHeight
          screens {
            items {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          creator {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          outputs {
            items {
              id
              name
              projectID
              width
              height
              startX
              startY
              color
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          isGridOn
          isLabelOn
          isStarredOn
          isPlaying
          createdAt
          updatedAt
          owner
        }
        panel {
          id
          make
          model
          pixelWidth
          pixelHeight
          moduleColumns
          moduleRows
          weight
          maxWatt
          averageWatt
          frameSubSpecies
          sizeSubSpecies
          createdAt
          updatedAt
          owner
        }
        userPanel {
          id
          make
          model
          pixelWidth
          pixelHeight
          moduleColumns
          moduleRows
          weight
          maxWatt
          averageWatt
          frameSubSpecies
          sizeSubSpecies
          createdAt
          updatedAt
          owner
        }
        pressedPanel {
          items {
            id
            x
            y
            ownerScreen {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            projectID
            ownerScreenID
            index
            panelState
            isStarred
            isOn
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        pressedModule {
          items {
            id
            panelX
            panelY
            moduleX
            moduleY
            moduleState
            index
            ownerScreenID
            projectID
            ownerScreen {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            isStarred
            isReplaced
            isOn
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      projectID
      ownerScreenID
      index
      panelState
      isStarred
      isOn
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listPressedPanels = /* GraphQL */ `
  query ListPressedPanels(
    $filter: ModelPressedPanelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPressedPanels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        x
        y
        ownerScreen {
          id
          panelColumns
          panelRows
          panelID
          projectID
          startX
          startY
          rotation
          ownerScreenBackgroundContent
          name
          color
          zIndex
          isUsingUserPanel
          project {
            id
            name
            projectBackgroundContent
            projectBackgroundContentPlay
            projectBackgroundContentGrid
            projectBackgroundContentLabels
            projectWidth
            projectHeight
            screens {
              nextToken
            }
            creator {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            outputs {
              nextToken
            }
            isGridOn
            isLabelOn
            isStarredOn
            isPlaying
            createdAt
            updatedAt
            owner
          }
          panel {
            id
            make
            model
            pixelWidth
            pixelHeight
            moduleColumns
            moduleRows
            weight
            maxWatt
            averageWatt
            frameSubSpecies
            sizeSubSpecies
            createdAt
            updatedAt
            owner
          }
          userPanel {
            id
            make
            model
            pixelWidth
            pixelHeight
            moduleColumns
            moduleRows
            weight
            maxWatt
            averageWatt
            frameSubSpecies
            sizeSubSpecies
            createdAt
            updatedAt
            owner
          }
          pressedPanel {
            items {
              id
              x
              y
              projectID
              ownerScreenID
              index
              panelState
              isStarred
              isOn
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          pressedModule {
            items {
              id
              panelX
              panelY
              moduleX
              moduleY
              moduleState
              index
              ownerScreenID
              projectID
              isStarred
              isReplaced
              isOn
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        projectID
        ownerScreenID
        index
        panelState
        isStarred
        isOn
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPressedModule = /* GraphQL */ `
  query GetPressedModule($id: ID!) {
    getPressedModule(id: $id) {
      id
      panelX
      panelY
      moduleX
      moduleY
      moduleState
      index
      ownerScreenID
      projectID
      ownerScreen {
        id
        panelColumns
        panelRows
        panelID
        projectID
        startX
        startY
        rotation
        ownerScreenBackgroundContent
        name
        color
        zIndex
        isUsingUserPanel
        project {
          id
          name
          projectBackgroundContent
          projectBackgroundContentPlay
          projectBackgroundContentGrid
          projectBackgroundContentLabels
          projectWidth
          projectHeight
          screens {
            items {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          creator {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          outputs {
            items {
              id
              name
              projectID
              width
              height
              startX
              startY
              color
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          isGridOn
          isLabelOn
          isStarredOn
          isPlaying
          createdAt
          updatedAt
          owner
        }
        panel {
          id
          make
          model
          pixelWidth
          pixelHeight
          moduleColumns
          moduleRows
          weight
          maxWatt
          averageWatt
          frameSubSpecies
          sizeSubSpecies
          createdAt
          updatedAt
          owner
        }
        userPanel {
          id
          make
          model
          pixelWidth
          pixelHeight
          moduleColumns
          moduleRows
          weight
          maxWatt
          averageWatt
          frameSubSpecies
          sizeSubSpecies
          createdAt
          updatedAt
          owner
        }
        pressedPanel {
          items {
            id
            x
            y
            ownerScreen {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            projectID
            ownerScreenID
            index
            panelState
            isStarred
            isOn
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        pressedModule {
          items {
            id
            panelX
            panelY
            moduleX
            moduleY
            moduleState
            index
            ownerScreenID
            projectID
            ownerScreen {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            isStarred
            isReplaced
            isOn
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      isStarred
      isReplaced
      isOn
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listPressedModules = /* GraphQL */ `
  query ListPressedModules(
    $filter: ModelPressedModuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPressedModules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        panelX
        panelY
        moduleX
        moduleY
        moduleState
        index
        ownerScreenID
        projectID
        ownerScreen {
          id
          panelColumns
          panelRows
          panelID
          projectID
          startX
          startY
          rotation
          ownerScreenBackgroundContent
          name
          color
          zIndex
          isUsingUserPanel
          project {
            id
            name
            projectBackgroundContent
            projectBackgroundContentPlay
            projectBackgroundContentGrid
            projectBackgroundContentLabels
            projectWidth
            projectHeight
            screens {
              nextToken
            }
            creator {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            outputs {
              nextToken
            }
            isGridOn
            isLabelOn
            isStarredOn
            isPlaying
            createdAt
            updatedAt
            owner
          }
          panel {
            id
            make
            model
            pixelWidth
            pixelHeight
            moduleColumns
            moduleRows
            weight
            maxWatt
            averageWatt
            frameSubSpecies
            sizeSubSpecies
            createdAt
            updatedAt
            owner
          }
          userPanel {
            id
            make
            model
            pixelWidth
            pixelHeight
            moduleColumns
            moduleRows
            weight
            maxWatt
            averageWatt
            frameSubSpecies
            sizeSubSpecies
            createdAt
            updatedAt
            owner
          }
          pressedPanel {
            items {
              id
              x
              y
              projectID
              ownerScreenID
              index
              panelState
              isStarred
              isOn
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          pressedModule {
            items {
              id
              panelX
              panelY
              moduleX
              moduleY
              moduleState
              index
              ownerScreenID
              projectID
              isStarred
              isReplaced
              isOn
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        isStarred
        isReplaced
        isOn
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPanel = /* GraphQL */ `
  query GetPanel($id: ID!) {
    getPanel(id: $id) {
      id
      make
      model
      pixelWidth
      pixelHeight
      moduleColumns
      moduleRows
      weight
      maxWatt
      averageWatt
      frameSubSpecies
      sizeSubSpecies
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listPanels = /* GraphQL */ `
  query ListPanels(
    $filter: ModelPanelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPanels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        make
        model
        pixelWidth
        pixelHeight
        moduleColumns
        moduleRows
        weight
        maxWatt
        averageWatt
        frameSubSpecies
        sizeSubSpecies
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getUserPanel = /* GraphQL */ `
  query GetUserPanel($id: ID!) {
    getUserPanel(id: $id) {
      id
      make
      model
      pixelWidth
      pixelHeight
      moduleColumns
      moduleRows
      weight
      maxWatt
      averageWatt
      frameSubSpecies
      sizeSubSpecies
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUserPanels = /* GraphQL */ `
  query ListUserPanels(
    $filter: ModelUserPanelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserPanels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        make
        model
        pixelWidth
        pixelHeight
        moduleColumns
        moduleRows
        weight
        maxWatt
        averageWatt
        frameSubSpecies
        sizeSubSpecies
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const outputsbyProjecID = /* GraphQL */ `
  query OutputsbyProjecID(
    $projectID: String
    $sortDirection: ModelSortDirection
    $filter: ModelOutputFilterInput
    $limit: Int
    $nextToken: String
  ) {
    OutputsbyProjecID(
      projectID: $projectID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        projectID
        width
        height
        startX
        startY
        color
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const screensbyProjectID = /* GraphQL */ `
  query ScreensbyProjectID(
    $projectID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelOwnerScreenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ScreensbyProjectID(
      projectID: $projectID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        panelColumns
        panelRows
        panelID
        projectID
        startX
        startY
        rotation
        ownerScreenBackgroundContent
        name
        color
        zIndex
        isUsingUserPanel
        project {
          id
          name
          projectBackgroundContent
          projectBackgroundContentPlay
          projectBackgroundContentGrid
          projectBackgroundContentLabels
          projectWidth
          projectHeight
          screens {
            items {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          creator {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          outputs {
            items {
              id
              name
              projectID
              width
              height
              startX
              startY
              color
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          isGridOn
          isLabelOn
          isStarredOn
          isPlaying
          createdAt
          updatedAt
          owner
        }
        panel {
          id
          make
          model
          pixelWidth
          pixelHeight
          moduleColumns
          moduleRows
          weight
          maxWatt
          averageWatt
          frameSubSpecies
          sizeSubSpecies
          createdAt
          updatedAt
          owner
        }
        userPanel {
          id
          make
          model
          pixelWidth
          pixelHeight
          moduleColumns
          moduleRows
          weight
          maxWatt
          averageWatt
          frameSubSpecies
          sizeSubSpecies
          createdAt
          updatedAt
          owner
        }
        pressedPanel {
          items {
            id
            x
            y
            ownerScreen {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            projectID
            ownerScreenID
            index
            panelState
            isStarred
            isOn
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        pressedModule {
          items {
            id
            panelX
            panelY
            moduleX
            moduleY
            moduleState
            index
            ownerScreenID
            projectID
            ownerScreen {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            isStarred
            isReplaced
            isOn
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const subrscriptionOrderbyUserID = /* GraphQL */ `
  query SubrscriptionOrderbyUserID(
    $userId: String
    $sortDirection: ModelSortDirection
    $filter: ModelSubscriptionOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    SubrscriptionOrderbyUserID(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        startDate
        endDate
        tier
        autoRenewal
        user {
          id
          lastLogin
          username
          currentSubscription {
            items {
              id
              startDate
              endDate
              tier
              autoRenewal
              purchaseAmount
              currency
              userId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscriptions {
            items {
              id
              startDate
              endDate
              tier
              autoRenewal
              purchaseAmount
              currency
              userId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          availableProjects {
            items {
              id
              name
              projectBackgroundContent
              projectBackgroundContentPlay
              projectBackgroundContentGrid
              projectBackgroundContentLabels
              projectWidth
              projectHeight
              isGridOn
              isLabelOn
              isStarredOn
              isPlaying
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          email
          createdAt
          updatedAt
          owner
        }
        purchaseAmount
        currency
        userId
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const pressedPanelsbyProjectID = /* GraphQL */ `
  query PressedPanelsbyProjectID(
    $projectID: String
    $sortDirection: ModelSortDirection
    $filter: ModelPressedPanelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PressedPanelsbyProjectID(
      projectID: $projectID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        x
        y
        ownerScreen {
          id
          panelColumns
          panelRows
          panelID
          projectID
          startX
          startY
          rotation
          ownerScreenBackgroundContent
          name
          color
          zIndex
          isUsingUserPanel
          project {
            id
            name
            projectBackgroundContent
            projectBackgroundContentPlay
            projectBackgroundContentGrid
            projectBackgroundContentLabels
            projectWidth
            projectHeight
            screens {
              nextToken
            }
            creator {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            outputs {
              nextToken
            }
            isGridOn
            isLabelOn
            isStarredOn
            isPlaying
            createdAt
            updatedAt
            owner
          }
          panel {
            id
            make
            model
            pixelWidth
            pixelHeight
            moduleColumns
            moduleRows
            weight
            maxWatt
            averageWatt
            frameSubSpecies
            sizeSubSpecies
            createdAt
            updatedAt
            owner
          }
          userPanel {
            id
            make
            model
            pixelWidth
            pixelHeight
            moduleColumns
            moduleRows
            weight
            maxWatt
            averageWatt
            frameSubSpecies
            sizeSubSpecies
            createdAt
            updatedAt
            owner
          }
          pressedPanel {
            items {
              id
              x
              y
              projectID
              ownerScreenID
              index
              panelState
              isStarred
              isOn
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          pressedModule {
            items {
              id
              panelX
              panelY
              moduleX
              moduleY
              moduleState
              index
              ownerScreenID
              projectID
              isStarred
              isReplaced
              isOn
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        projectID
        ownerScreenID
        index
        panelState
        isStarred
        isOn
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const pressedModulesbyProjectID = /* GraphQL */ `
  query PressedModulesbyProjectID(
    $projectID: String
    $sortDirection: ModelSortDirection
    $filter: ModelPressedModuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PressedModulesbyProjectID(
      projectID: $projectID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        panelX
        panelY
        moduleX
        moduleY
        moduleState
        index
        ownerScreenID
        projectID
        ownerScreen {
          id
          panelColumns
          panelRows
          panelID
          projectID
          startX
          startY
          rotation
          ownerScreenBackgroundContent
          name
          color
          zIndex
          isUsingUserPanel
          project {
            id
            name
            projectBackgroundContent
            projectBackgroundContentPlay
            projectBackgroundContentGrid
            projectBackgroundContentLabels
            projectWidth
            projectHeight
            screens {
              nextToken
            }
            creator {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            outputs {
              nextToken
            }
            isGridOn
            isLabelOn
            isStarredOn
            isPlaying
            createdAt
            updatedAt
            owner
          }
          panel {
            id
            make
            model
            pixelWidth
            pixelHeight
            moduleColumns
            moduleRows
            weight
            maxWatt
            averageWatt
            frameSubSpecies
            sizeSubSpecies
            createdAt
            updatedAt
            owner
          }
          userPanel {
            id
            make
            model
            pixelWidth
            pixelHeight
            moduleColumns
            moduleRows
            weight
            maxWatt
            averageWatt
            frameSubSpecies
            sizeSubSpecies
            createdAt
            updatedAt
            owner
          }
          pressedPanel {
            items {
              id
              x
              y
              projectID
              ownerScreenID
              index
              panelState
              isStarred
              isOn
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          pressedModule {
            items {
              id
              panelX
              panelY
              moduleX
              moduleY
              moduleState
              index
              ownerScreenID
              projectID
              isStarred
              isReplaced
              isOn
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        isStarred
        isReplaced
        isOn
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
