import { useEffect, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { observer } from "mobx-react-lite";


import { listOutputs } from "../graphql/queries";
import {
  onCreateOutput,
  onDeleteOutput,
  onUpdateOutput,
} from "../graphql/subscriptions";

import { StoreContext } from "../stores/store.context";

// When a user selects a new project it triggers a
// new subscription on the Screens Table, there is a
// check to see if there is a current user loggin in

const OutputsFetchAndSub = observer(() => {
  const { user, project, projects } = useContext(StoreContext);
  let owner = user.username;

  useEffect(() => {
    if (project.id) {
      fetchOutputs();

      const createOutputListener = API.graphql(
        graphqlOperation(onCreateOutput, { owner: owner })
      ).subscribe({
        next: (outputData) => {
          const newOutput = outputData.value.data.onCreateOutput;
          if (newOutput.projectID === project.id) {
            project.addOutput(newOutput);
            const thisProject = projects.list.find(
              (x) => x.id === newOutput.projectID
            );
            if (thisProject) thisProject.outputs.items.push(newOutput);
            project.selectOutputEdit(newOutput);
          }
        },
      });

      const updateOutputListener = API.graphql(
        graphqlOperation(onUpdateOutput, { owner: owner })
      ).subscribe({
        next: (outputData) => {
          const newOutput = outputData.value.data.onUpdateOutput;
          if (newOutput.projectID === project.id) {
            project.removeOutput(newOutput.id);
            project.addOutput(newOutput);

            // *** I think this has something to do with the list of
            // screens in the dashboard, I don't think this needs to change
            // on a screen update ****
            project.selectOutputEdit(newOutput);
          }
        },
        error: (error) => console.warn(error),
      });

      const deleteOutputListener = API.graphql(
        graphqlOperation(onDeleteOutput, { owner: owner })
      ).subscribe({
        next: (outputData) => {
          const deleteOwnerScreen = outputData.value.data.onDeleteOutput;
          project.removeOutput(deleteOwnerScreen.id);
        },
      });

      return () => {
        deleteOutputListener.unsubscribe();
        createOutputListener.unsubscribe();
        updateOutputListener.unsubscribe();
      };
    } else return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project.id]);

  // function calls graphQL to list User's screens
  async function fetchOutputs() {
    try {
      const thisProject = project.id;
      const res = await API.graphql(
        graphqlOperation(listOutputs, {
          filter: { projectID: { eq: thisProject } },
        })
      );
      const outputs = res.data.listOutputs.items;
      project.setOutputList(outputs);
      project.selectOutputEdit(outputs[0]);
    } catch (err) {
      console.error(err);
    }
  }
});

export default OutputsFetchAndSub;
