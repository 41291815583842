import { useEffect } from "react";
import { withRouter } from "react-router-dom";

const ScrollToTop = ({ children, location: { pathname } }) => {
  useEffect(() => {
    let t;
    clearTimeout(t);
    t = setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, 10);
  }, [pathname]);

  return children || null;
};

export default withRouter(ScrollToTop);
