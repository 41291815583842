import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useStyles } from "./FooterStyle";
import Tooltip from "@material-ui/core/Tooltip";

import "./svg.css";

function FooterWeb() {
  const classes = useStyles();
  const history = useHistory();

  const openInNewTabClockotron = () => {
    window.open("https://github.com/dlamon1/clockotron");
  };

  const openInNewTabAndroid = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.dlamon1.tk421dev"
    );
  };

  const openInNewTabIos = () => {
    window.open(
      "https://testflight.apple.com/join/QBbq81v4"
    );
  };

  const openInNewTabSignalDiagram = () => {
    window.open(
      "https://www.signaldiagram.com/"
    );
  };

  return (
    <Grid
      id="outer-grid"
      container
      alignItems="center"
      justifyContent="center"
      style={{
        flexDirection: "column",
        display: "flex",
      }}
    >
      <Paper className={classes.footer}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-around"
          style={{
            height: 500,
            backgroundColor: "",
          }}
        >
          <Grid item xs={10} style={{ backgroundColor: "", paddingTop: 20 }}>
            <Grid
              id="time"
              container
              justifyContent="space-between"
              alignItems="stretch"
              style={{
                backgroundColor: "",
                height: 400,
              }}
            >
              <Grid
                item
                style={{
                  backgroundColor: "",
                  marginLeft: 0,
                  width: "100%",
                  height: "50%",
                  // flex: 1,
                }}
              >
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                  alignItems="stretch"
                  style={{
                    backgroundColor: "",
                    height: "60%",
                  }}
                >
                  <Grid item id="electric" style={{ backgroundColor: "" }}>
                    <Grid container justifyContent="space-between">
                      <Grid
                        container
                        // justifyContent="space-between"
                        style={{ width: "80%" }}
                      >
                        {/* LEAD LED */}
                        {/* LEAD LED */}
                        {/* LEAD LED */}
                        <Grid
                          id="lead-led"
                          item
                          style={{
                            backgroundColor: "",
                            // minWidth: 230,
                            paddingTop: 35,
                            marginRight: 10,
                            paddingRight: "10%",
                          }}
                        >
                          <Typography
                            component={Link}
                            to="/"
                            color="secondary"
                            variant="h4"
                            style={{ marginLeft: 15, marginTop: 35 }}
                            className={classes.leadled}
                          >
                            LEAD LED
                          </Typography>
                        </Grid>

                        {/* COMPANY */}
                        {/* COMPANY */}
                        {/* COMPANY */}
                        <Grid item id="company" style={{ paddingRight: "10%" }}>
                          <Grid
                            container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="stretch"
                            style={{
                              backgroundColor: "",
                              height: "100%",
                              // paddingLeft: 70,
                              paddingTop: 35,
                            }}
                          >
                            <Grid item className={classes.footerColumnHeader}>
                              <Typography
                                className={classes.header}
                                variant="h6"
                              >
                                COMPANY
                              </Typography>
                            </Grid>
                            <Grid item className={classes.footerColumnItem}>
                              <Typography
                                component={Link}
                                to="/contact"
                                className={classes.trademark}
                                vairant="body1"
                              >
                                Contact
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* TOOLS */}
                        {/* TOOLS */}
                        {/* TOOLS */}
                        <Grid item id="tools" style={{ paddingRight: "10%" }}>
                          <Grid
                            container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="stretch"
                            style={{
                              backgroundColor: "",
                              height: "100%",
                              paddingTop: 35,
                            }}
                          >
                            <Grid item className={classes.footerColumnHeader}>
                              <Typography
                                className={classes.header}
                                variant="h6"
                              >
                                Tools
                              </Typography>
                            </Grid>
                            <Grid item className={classes.footerColumnItem}>
                              <Typography
                                component={Link}
                                to="/tk421"
                                className={classes.trademark}
                                vairant="body1"
                              >
                                TK-421
                              </Typography>
                            </Grid>
                            <Grid item className={classes.footerColumnItem}>
                              <div
                                onClick={openInNewTabClockotron}
                                className={classes.trademark}
                                vairant="body1"
                                style={{ cursor: "pointer" }}
                              >
                                Clockotron
                              </div>
                            </Grid>
                            <Grid item className={classes.footerColumnItem}>
                              <div
                                onClick={openInNewTabSignalDiagram}
                                className={classes.trademark}
                                vairant="body1"
                                style={{ cursor: "pointer" }}
                              >
                                Signal Diagram
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* LINKS */}
                        {/* LINKS */}
                        {/* LINKS */}
                        <Grid item>
                          <Grid
                            container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="stretch"
                            style={{
                              backgroundColor: "",
                              height: "100%",
                              paddingTop: "35px",
                            }}
                          >
                            <Grid item className={classes.footerColumnHeader}>
                              <Typography
                                className={classes.header}
                                variant="h6"
                              >
                                LINKS
                              </Typography>
                            </Grid>

                            <Grid item className={classes.footerColumnItem}>
                              <div
                                onClick={openInNewTabAndroid}
                                className={classes.trademark}
                                vairant="body1"
                                style={{ cursor: "pointer" }}
                              >
                                Android App
                              </div>
                            </Grid>
                            <Grid item className={classes.footerColumnItem}>
                              <div
                                onClick={openInNewTabIos}
                                className={classes.trademark}
                                vairant="body1"
                                style={{ cursor: "pointer" }}
                              >
                                iOS App
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* LOGO */}
                      {/* LOGO */}
                      {/* LOGO */}
                      <Grid
                        container
                        justifyContent="flex-end"
                        style={{
                          height: "15%",
                          width: "15%",
                          marginRight: 10,
                          marginTop: 35,
                          backgroundColor: "",
                          maxHeight: 125,
                          maxWidth: 125,
                        }}
                      >
                        <svg
                          version="1.1"
                          viewBox="100 100 900 900"
                          style={{ backgroundColor: "" }}
                        >
                          <a
                            onClick={() => {
                              history.push("");
                            }}
                          >
                            <path
                              // class="st1"
                              id="logo"
                              d="m468.29 472.77-44.82 134.46H556.2l44.82-134.46zM412.77 139.66l-44.82 134.47h132.73l44.82-134.47zM357.25 306.21l-44.82 134.47h132.73l44.82-134.47zM301.73 472.77l-44.82 134.46h132.73l44.82-134.46zM690.36 805.87l-44.82 134.47h132.73l44.82-134.47zM722.75 607.23l44.82-134.46H634.84l-44.82 134.46zM667.23 773.79l44.82-134.47H579.32L534.5 773.79zM523.8 805.87l-44.82 134.47h132.73l44.82-134.47z"
                            />
                          </a>
                        </svg>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                style={{
                  backgroundColor: "",
                  width: "100%",
                  margin: "40px",
                }}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="flex-end"
                >
                  <Grid item>
                    <Typography
                      className={classes.trademark}
                      variant="subtitle2"
                      component={Link}
                      to="/privacypolicy"
                    >
                      Privacy Policy
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      className={classes.trademark}
                      variant="subtitle2"
                      style={{
                        color: "white",
                      }}
                    >
                      © LEAD LED, LLC
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

export default FooterWeb;
