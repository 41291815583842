import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { StoreContext } from "../../stores/store.context";

import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import "./style.css";

const Public = observer(() => {
  const { app } = useContext(StoreContext);

  let history = useHistory();

  const navTk421 = () => {
    history.push("/tk421");
  };

  return (
    <Box>
      <Header />
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{
          height: "100vh",
          flexDirection: "column",
          display: "flex",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{
            height: "100vh",
            paddingTop: "50px",
            background: app.color.white1,
          }}
        >
          <Grid item xs={12} style={{ maxWidth: "60%" }}>
            <div className="slogan beginning">we who work</div>
            <div className="slogan middle"></div>
            <div className="slogan end">with screens</div>

            <Grid
              container
              alignItems="center"
              justifyContent="center"
              style={{ marginTop: "50px", backgroundColor: "" }}
            >
              <Grid item>
                <div className="button" onClick={navTk421}>
                  TK-421
                </div>
              </Grid>
            </Grid>
            <div className="tagline-container">
              <div className="tagline">interactive pixel maps</div>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </Box>
  );
});

export default Public;


