export const onCreatePressedPanel = /* GraphQL */ `
  subscription OnCreatePressedPanel($owner: String!) {
    onCreatePressedPanel(owner: $owner) {
      id
      x
      y
      ownerScreen {
        id
        panelColumns
        panelRows
        panelID
        projectID
        startX
        startY
        ownerScreenBackgroundContent
        name
        color
        rotation
        zIndex
        panel {
          id
          make
          model
          pixelWidth
          pixelHeight
          moduleColumns
          moduleRows
          weight
          maxWatt
          averageWatt
          frameSubSpecies
          sizeSubSpecies
          createdAt
          updatedAt
        }
        userPanel {
          id
          make
          model
          pixelWidth
          pixelHeight
          moduleColumns
          moduleRows
          weight
          maxWatt
          averageWatt
          frameSubSpecies
          sizeSubSpecies
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        owner
      }
      projectID
      ownerScreenID
      index
      panelState
      isStarred
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdatePressedPanel = /* GraphQL */ `
  subscription OnUpdatePressedPanel($owner: String!) {
    onUpdatePressedPanel(owner: $owner) {
      id
      x
      y
      ownerScreen {
        id
        panelColumns
        panelRows
        panelID
        projectID
        startX
        startY
        ownerScreenBackgroundContent
        name
        color
        zIndex
        rotation
        panel {
          id
          make
          model
          pixelWidth
          pixelHeight
          moduleColumns
          moduleRows
          weight
          maxWatt
          averageWatt
          frameSubSpecies
          sizeSubSpecies
          createdAt
          updatedAt
        }
        userPanel {
          id
          make
          model
          pixelWidth
          pixelHeight
          moduleColumns
          moduleRows
          weight
          maxWatt
          averageWatt
          frameSubSpecies
          sizeSubSpecies
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        owner
      }
      projectID
      ownerScreenID
      index
      panelState
      isStarred
      createdAt
      updatedAt
      owner
    }
  }
`;

export const onUpdateProject = /* GraphQL */ `
  subscription OnUpdateProject($owner: String!) {
    onUpdateProject(owner: $owner) {
      id
      name
      projectBackgroundContent
      projectBackgroundContentPlay
      projectBackgroundContentGrid
      projectBackgroundContentLabels
      projectWidth
      projectHeight
      screens {
        items {
          id
          panelColumns
          panelRows
          panelID
          projectID
          startX
          startY
          ownerScreenBackgroundContent
          name
          color
          rotation
          zIndex
          panel {
            id
            make
            model
            pixelWidth
            pixelHeight
            moduleColumns
            moduleRows
            weight
            maxWatt
            averageWatt
            frameSubSpecies
            sizeSubSpecies
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      creator {
        id
        username
        email
        createdAt
        updatedAt
        owner
      }
      outputs {
        items {
          id
          projectID
          width
          height
          startX
          startY

          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;

export const onUpdatePressedModuleForList = /* GraphQL */ `
  subscription OnUpdatePressedModule($owner: String!) {
    onUpdatePressedModule(owner: $owner) {
      id
      panelX
      panelY
      moduleX
      moduleY
      moduleState
      index
      ownerScreenID
      projectID
      ownerScreen {
        id
        panelColumns
        panelRows
        panelID
        projectID
        startX
        rotation
        startY
        ownerScreenBackgroundContent
        name
        color
        zIndex
        project {
          id
          name
          projectBackgroundContent
          projectBackgroundContentPlay
          projectBackgroundContentGrid
          projectBackgroundContentLabels
          projectWidth
          projectHeight
          outputs {
            items {
              id
              projectID
              width
              height
              startX
              startY

              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        panel {
          id
          make
          model
          pixelWidth
          pixelHeight
          moduleColumns
          moduleRows
          weight
          maxWatt
          averageWatt
          frameSubSpecies
          sizeSubSpecies
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        owner
      }
      isStarred
      isReplaced
      createdAt
      updatedAt
      owner
    }
  }
`;

export const onCreatePressedModuleCustom = /* GraphQL */ `
  subscription OnCreatePressedModule($owner: String!) {
    onCreatePressedModule(owner: $owner) {
      id
      panelX
      panelY
      moduleX
      moduleY
      moduleState
      index
      ownerScreenID
      projectID
      ownerScreen {
        id
        panelColumns
        panelRows
        panelID
        projectID
        startX
        startY
        rotation
        ownerScreenBackgroundContent
        name
        color
        zIndex
        panel {
          id
          make
          model
          pixelWidth
          pixelHeight
          moduleColumns
          moduleRows
          weight
          maxWatt
          averageWatt
          frameSubSpecies
          sizeSubSpecies
          createdAt
          updatedAt
        }

        createdAt
        updatedAt
        owner
      }
      isStarred
      isReplaced
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdatePressedModuleCustom = /* GraphQL */ `
  subscription OnUpdatePressedModule($owner: String!) {
    onUpdatePressedModule(owner: $owner) {
      id
      panelX
      panelY
      moduleX
      moduleY
      moduleState
      index
      ownerScreenID
      projectID
      ownerScreen {
        id
        panelColumns
        panelRows
        panelID
        projectID
        startX
        rotation
        startY
        ownerScreenBackgroundContent
        name
        color
        zIndex
        panel {
          id
          make
          model
          pixelWidth
          pixelHeight
          moduleColumns
          moduleRows
          weight
          maxWatt
          averageWatt
          frameSubSpecies
          sizeSubSpecies
          createdAt
          updatedAt
        }

        createdAt
        updatedAt
        owner
      }
      isStarred
      isReplaced
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeletePressedModuleCustom = /* GraphQL */ `
  subscription OnDeletePressedModule($owner: String!) {
    onDeletePressedModule(owner: $owner) {
      id
      panelX
      panelY
      moduleX
      moduleY
      moduleState
      index
      ownerScreenID
      projectID
      ownerScreen {
        id
      }
      isStarred
      isReplaced
      createdAt
      updatedAt
      owner
    }
  }
`;
