import { useState, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { observer } from "mobx-react-lite";

import TextField from "@material-ui/core/TextField";
import { CircularProgress } from "@material-ui/core";

import { createUserPanel } from "../../graphql/mutations";

import { StoreContext } from "../../stores/store.context";

import "./form-styles.css";

export const NewUserPanel = observer(() => {
  const { customPanels } = useContext(StoreContext);

  const initialFormState = {
    make: "",
    model: "",
    pixelWidth: "",
    pixelHeight: "",
    moduleRows: "",
    moduleColumns: "",
  };

  const [formState, setFormState] = useState(initialFormState);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    customPanels.setNewPanelDialogIsOpen(false);
  };

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value });
  }

  async function addScreen() {
    setIsLoading(true);
    try {
      if (
        !formState.make ||
        !formState.model ||
        !formState.pixelWidth ||
        !formState.pixelHeight ||
        !formState.moduleRows ||
        !formState.moduleColumns
      )
        return;
      const panel = { ...formState };
      let res = await API.graphql(
        graphqlOperation(createUserPanel, { input: panel })
      );
      setFormState(initialFormState);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
    handleClose();
  }

  return (
    <div
      className="modal-root"
      style={{ display: customPanels.newPanelDialogIsOpen ? "block" : "none" }}
    >
      <div className="form-container">
        <div className="form-inputs">
          <div className="form-input">
            <TextField
              autoFocus
              onChange={(event) => setInput("make", event.target.value)}
              value={formState.make}
              margin="dense"
              label="Make"
              variant="outlined"
              type="string"
              fullWidth
            />
          </div>
          <div className="form-input">
            <TextField
              className="form-input"
              onChange={(event) => setInput("model", event.target.value)}
              value={formState.model}
              label="Model"
              variant="outlined"
              margin="dense"
              type="string"
              fullWidth
            />
          </div>
          <div className="form-input">
            <TextField
              className="form-input"
              onChange={(event) => setInput("pixelWidth", event.target.value)}
              value={formState.pixelWidth}
              label="Pixels Wide"
              variant="outlined"
              margin="dense"
              type="number"
              fullWidth
            />
          </div>
          <div className="form-input">
            <TextField
              className="form-input"
              onChange={(event) => setInput("pixelHeight", event.target.value)}
              value={formState.pixelHeight}
              label="Pixels Tall"
              variant="outlined"
              margin="dense"
              type="number"
              fullWidth
            />
          </div>
          <div className="form-input">
            <TextField
              className="form-input"
              onChange={(event) =>
                setInput("moduleColumns", event.target.value)
              }
              value={formState.moduleColumns}
              label="Modules Wide"
              variant="outlined"
              type="number"
              margin="dense"
              fullWidth
            />
          </div>
          <div className="form-input">
            <TextField
              className="form-input"
              onChange={(event) => setInput("moduleRows", event.target.value)}
              value={formState.moduleRows}
              label="Modules Tall"
              variant="outlined"
              type="number"
              margin="dense"
              fullWidth
            />
          </div>
          <div className="form-input">
            <TextField
              className="form-input"
              onChange={(event) => setInput("weight", event.target.value)}
              value={formState.weight}
              label="Weight (kg)"
              variant="outlined"
              type="number"
              margin="dense"
              fullWidth
            />
          </div>
          <div className="form-input">
            <TextField
              className="form-input"
              onChange={(event) => setInput("maxWatt", event.target.value)}
              value={formState.maxWatt}
              label="Max Watt"
              variant="outlined"
              type="number"
              margin="dense"
              fullWidth
            />
          </div>
          <div className="form-input">
            <TextField
              className="form-input"
              onChange={(event) => setInput("averageWatt", event.target.value)}
              value={formState.averageWatt}
              label="Average Watt"
              variant="outlined"
              type="number"
              margin="dense"
              fullWidth
            />
          </div>

          <div className="button-container">
            <div
              className="delete-button cancel"
              onClick={() => customPanels.setNewPanelDialogIsOpen(false)}
              color="primary"
            >
              CANCEL
            </div>

            <div className="delete-button" onClick={addScreen} color="primary">
              {isLoading ? <CircularProgress size={18} /> : "SUBMIT"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
