import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";

import useStyles from "./NavBarStyle";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PhotoSizeSelectSmallIcon from "@material-ui/icons/PhotoSizeSelectSmall";
import BorderInnerIcon from "@material-ui/icons/BorderInner";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { StoreContext } from "../../stores/store.context";

export const NavBar = observer(() => {
  const { user } = useContext(StoreContext)

  const classes = useStyles();
  let location = useLocation();
  let pathname = location.pathname;

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <Box height="100%">
        <Grid container direction="column" justifyContent="space-between">
          <Grid item style={{ background: "" }}>
            <List style={{ background: "" }}>
              <Grid
                container
                justifyContent="center"
                style={{ width: "100%", marginBottom: 9, background: "" }}
              >
                <Typography
                  variant="h6"
                  style={{
                    fontFamily: "Chivo",
                    fontWeight: 600,
                  }}
                >
                  {user.username}
                </Typography>
              </Grid>

              <Divider />

              <ListItem
                button
                component={Link}
                to="/userOnMade/home/projects"
                selected={
                  "/userOnMade/home/projects" === pathname ? true : false
                }
                style={{ marginTop: 8, fontFamily: "Chivo" }}
              >
                <ListItemIcon>
                  <PhotoSizeSelectSmallIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Projects"
                  primaryTypographyProps={{
                    style: {
                      fontFamily: "Chivo",
                      fontWeight: 400,
                      color: "",
                    },
                  }}
                />
              </ListItem>

              <ListItem
                button
                component={Link}
                to="/userOnMade/home/panels"
                selected={"/userOnMade/home/panels" === pathname ? true : false}
                style={{ marginTop: 8, fontFamily: "Chivo" }}
              >
                <ListItemIcon>
                  <BorderInnerIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Panels"
                  primaryTypographyProps={{
                    style: {
                      fontFamily: "Chivo",
                      fontWeight: 400,
                      color: "",
                    },
                  }}
                />
              </ListItem>

              {/* <ListItem
                button
                component={Link}
                to="/userOnMade/home/usermanual"
                selected={
                  "/userOnMade/home/usermanual" === pathname ? true : false
                }
                style={{ marginTop: 8 }}
              >
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText
                  primary="User Manual"
                  primaryTypographyProps={{
                    style: {
                      fontFamily: "Chivo",
                      fontWeight: 700,
                      color: "",
                    },
                  }}
                />
              </ListItem> */}

              {/* <ListItem
                button
                component={Link}
                to="/userOnMade/home/settings"
                selected={
                  "/userOnMade/home/settings" === pathname ? true : false
                }
                style={{ marginTop: 8 }}
              >
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Settings"
                  primaryTypographyProps={{
                    style: {
                      fontFamily: "Chivo",
                      fontWeight: 700,
                      color: "",
                    },
                  }}
                />
              </ListItem> */}
              <Divider />
              {/* <Button
                onClick={panelOpen}
                style={{
                  width: "100px",
                  height: "100px",
                  align: "right",
                }}
              /> */}
            </List>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
});
