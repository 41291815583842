import { useEffect, useContext } from "react";
import { API, graphqlOperation, Auth } from "aws-amplify";

import {
  onCreateProject,
  onDeleteProject,
  onUpdateProject,
} from "../graphql/subscriptions";

import { observer } from "mobx-react-lite";

import { StoreContext } from "../stores/store.context";

const ProjectSubscriptions = observer(() => {
  const { user, project, projects } = useContext(StoreContext);
  const owner = user.username;

  // Creates a subscription on the Project table to
  // retrieve new fields upon creation and add them
  // to the local Gloabl Project list. The if statement
  // checks to see if there is a user currently signed in.

  useEffect(() => {
    const createProjectListener = API.graphql(
      graphqlOperation(onCreateProject, { owner: owner })
    ).subscribe({
      next: (projectData) => {
        const newProject = projectData.value.data.onCreateProject;
        projects.addProject(newProject);
        const projectID = projects.list[0];
        project.selectProject(projectID);
      },
      error: (err) => console.log(err),
    });

    const deleteProjectListener = API.graphql(
      graphqlOperation(onDeleteProject, { owner: owner })
    ).subscribe({
      next: (projectData) => {
        const deleteProject = projectData.value.data.onDeleteProject;
        projects.removeProject(deleteProject.id);
      },
      error: (err) => console.log(err),
    });

    return () => {
      deleteProjectListener.unsubscribe();
      createProjectListener.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [owner]);
});

export default ProjectSubscriptions;

function ProjectUpdateSubscription() {
  const { project } = useContext(StoreContext);
  const owner = Auth.user.getUsername();

  useEffect(() => {
    try {
      const updateProjectListener = API.graphql(
        graphqlOperation(onUpdateProject, { owner: owner })
      ).subscribe({
        next: (projectData) => {
          const updatedProject = projectData.value.data.onUpdateProject;
        },
        error: (err) => console.log(err),
      });

      return () => {
        updateProjectListener.unsubscribe();
      };
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project.id]);
}

export { ProjectUpdateSubscription };
