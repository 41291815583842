import { useEffect, useContext } from "react";
import { observer } from "mobx-react-lite";
import { useLocation, useHistory } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";

import ListSideBar from "./components/ListSideBar/ListSideBar";
import CanvasPreview from "./components/CanvasPreview/CanvasPreview";
import EditSideBar from "./components/EditSidebar/EditSideBar";

import { getProject } from "../../graphql/queries";

import useStyles from "./ProjectDashboardPageStyle";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { CircularProgress } from "@material-ui/core";

import { StoreContext } from "../../stores/store.context";

const ProjectDashboardPage = observer((props) => {
  const { project } = useContext(StoreContext);
  const classes = useStyles();
  const urlSplit = useLocation().pathname.split("/");
  const projectId = urlSplit[3];
  const history = useHistory();

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const res = await API.graphql(
          graphqlOperation(getProject, { id: projectId })
        );
        const resData = res.data.getProject;
        project.selectProject(resData);
      } catch (err) {
        console.log(err);
      }
    };
    if (!project.id) {
      fetchProject();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return project.id ? (
    <>
      <Box
        bgcolor="background.default"
        height="100vh"
        className={classes.backgroundBox}
      >
        <AppBar
          position="fixed"
          className={classes.appBar}
          color="transparent"
          elevation={1}
        >
          <Toolbar variant="dense">
            <Grid container alignItems="center">
              <Grid item xs={1}>
                <Button
                  onClick={() => history.push("/userOnMade/home/projects")}
                >
                  <ArrowBack />
                  Projects
                </Button>
              </Grid>
              <Grid item xs={10}>
                <Typography
                  variant="body1"
                  noWrap
                  className={classes.centerAppBar}
                >
                  project /{" "}
                  {project.name
                    ? project.name
                    : "loading..."}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography
                  variant="body2"
                  className={classes.rightAppBar}
                ></Typography>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>

        <div className={classes.toolbar} />

        <Grid
          container
          justifyContent="space-between"
          className={classes.canvasContainer}
          style={{ marginTop: 0, backgroundColor: "" }}
        >
          <Grid item style={{ backgroundColor: "" }}>
            <ListSideBar />
          </Grid>

          <Divider orientation="vertical" flexItem />

          <Grid item style={{ flex: 1, height: "100%", backgroundColor: "" }}>
            <Grid
              container
              alignItems="center"
              style={{
                marginTop: 10,
                width: "100%",
                height: "98%",
                maxHeight: "98%",
              }}
            >
              <CanvasPreview />
            </Grid>
          </Grid>

          <Divider orientation="vertical" flexItem />

          <Grid item style={{ backgroundColor: "", height: "100%" }}>
            <EditSideBar />
          </Grid>
        </Grid>
      </Box>
    </>
  ) : (
    <Grid
      container
      alignItems="center"
      justifyContent="space-around"
      style={{
        height: "100vh",
        flexDirection: "column",
        display: "flex",
      }}
    >
      <CircularProgress size={30} />
    </Grid>
  );
});

export default ProjectDashboardPage;
