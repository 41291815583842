import { useState, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { observer } from "mobx-react-lite";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SketchPicker } from "react-color";

import { createOutput } from "../../../../graphql/mutations";
import { defaultColors } from "../../../../utils/Colors";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { CircularProgress } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";

import { StoreContext } from "../../../../stores/store.context";

const validationSchema = Yup.object({
  name: Yup.string().required("Required"),
  width: Yup.number().min(0).required("Required"),
  height: Yup.number().min(0).required("Required"),
  startX: Yup.number().min(0).required("Required"),
  startY: Yup.number().min(0).required("Required"),
});

const NewProjectForm = observer((props) => {
  const { project } = useContext(StoreContext);
  const { setOutputFormOpen } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [colorModalOpen, setColorModalOpen] = useState(false);
  const [pos, setPos] = useState({ x: 0, y: 0 });
  let random = Math.floor(Math.random() * defaultColors.length);
  const [color, setColor] = useState(defaultColors[random]);
  const formik = useFormik({
    initialValues: {
      name: "",
      width: "",
      height: "",
      startX: "",
      startY: "",
      color: "#ffffff",
    },
    validationSchema,
  });

  const openColor = (x) => {
    setPos({
      x: x.clientX,
      y: x.clientY,
    });
    setColorModalOpen(true);
  };

  const closeColor = () => {
    setColorModalOpen(false);
  };

  const changeColor = (color, event) => {
    setColor(color.hex);
  };

  async function addOutput() {
    let ourColor = color;
    for (var i in formik.errors) {
      return;
    }
    setIsLoading(true);
    try {
      const name = formik.values.name;
      const projectID = project.id;
      const width = formik.values.width;
      const height = formik.values.height;
      const startX = formik.values.startX;
      const startY = formik.values.startY;
      const color = ourColor;
      const projectOutputsId = project.id;

      const output = {
        name,
        projectID,
        width,
        height,
        startX,
        startY,
        color,
        projectOutputsId,
      };
      let res = await API.graphql(
        graphqlOperation(createOutput, { input: output })
      );
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
    setOutputFormOpen(false);
  }

  return (
    <Grid
      container
      style={{
        flex: 1,
        flexDirection: "row",
        backgroundColor: "",
      }}
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        container
        style={{ width: "100%", height: "70%" }}
        justifyContent="center"
        alignItems="center"
      >
        <TextField
          //   autoFocus
          id="name"
          name="name"
          label="Name"
          value={formik.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={"name" in formik.touched && "name" in formik.errors}
          style={{ width: "70%", marginTop: 10 }}
          helperText={
            formik.touched.name && formik.errors.name ? formik.errors.name : ""
          }
          size="small"
        />
        <TextField
          id="width"
          name="width"
          size="small"
          label="Width"
          value={formik.width}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={"width" in formik.touched && "width" in formik.errors}
          style={{ width: "70%", marginTop: 10 }}
          helperText={
            formik.touched.width && formik.errors.width
              ? formik.errors.width
              : ""
          }
        />
        <TextField
          id="height"
          name="height"
          size="small"
          label="Height"
          value={formik.height}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={"height" in formik.touched && "height" in formik.errors}
          style={{ width: "70%", marginTop: 10 }}
          helperText={
            formik.touched.height && formik.errors.height
              ? formik.errors.height
              : ""
          }
        />
        <TextField
          id="startX"
          name="startX"
          size="small"
          label="Start X"
          value={formik.startX}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={"startX" in formik.touched && "startX" in formik.errors}
          style={{ width: "70%", marginTop: 10 }}
          helperText={
            formik.touched.startX && formik.errors.startX
              ? formik.errors.startX
              : ""
          }
        />
        <TextField
          id="startY"
          name="startY"
          size="small"
          label="Start Y"
          value={formik.startY}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={"startY" in formik.touched && "startY" in formik.errors}
          style={{ width: "70%", marginTop: 10 }}
          helperText={
            formik.touched.startY && formik.errors.startX
              ? formik.errors.startY
              : ""
          }
        />
        <Button
          type="button"
          onClick={(e) => openColor(e)}
          style={{ backgroundColor: color, width: 95, marginTop: 10 }}
        >
          Color
        </Button>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={colorModalOpen}
          onClose={closeColor}
          closeAfterTransition
          style={{ left: pos.x, top: pos.y }}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <SketchPicker
            color={color}
            onChange={changeColor}
            presetColors={defaultColors}
          />
        </Modal>
      </Grid>
      <Grid item xs={6} style={{ marginTop: 10 }}>
        <Button
          onClick={() => setOutputFormOpen(false)}
          color="primary"
          variant="outlined"
          style={{ width: 95 }}
        >
          Cancel
        </Button>
      </Grid>
      <Grid item xs={6} style={{ marginTop: 10 }}>
        <Button
          onClick={addOutput}
          color="primary"
          variant="contained"
          disabled={isLoading}
          style={{ width: 95 }}
        >
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            <Typography>Submit</Typography>
          )}
        </Button>
      </Grid>
    </Grid>
  );
});

export default NewProjectForm;
