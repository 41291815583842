import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
  backgroundStyle: {
    color: "blue",
  },
  marginStyle: {
    margin: "auto",
    padding: "auto",
  },
  boxStyle: {
    textAlign: "center",
    bgcolor: "primary.main",
    backgroundColor: "primary.main",
    // color: "black",
    maring: 0,
    padding: 15,
    height: "48vh",
  },
  gridItemStyle: {
    flexGrow: 1,
  },
  gridContainerStyle: {
    height: "50vh",
  },
  appBar: {
    elevation: 1,
    backgroundColor: "white",
  },
  centerAppBar: {
    textAlign: "center",
    color: "secondary.main",
  },
  rightAppBar: {
    textAlign: "right",
    color: "secondary.main",
  },
  leftAppBar: {
    textAlign: "left",
    color: "secondary.main",
  },
  pageContainer: {
    height: "85vh",
  },
  canvasContainer: {
    height: "94%",
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  toolbar: theme.mixins.denseToolbar,
  navbar: {
    // width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  backgroundBox: {
    backgroundColor: theme.palette.background.main,
  },
}));

export default useStyles;
