import { makeAutoObservable } from "mobx";

export class ProjectsStore {
  list = []

  constructor() {
    makeAutoObservable(this);
  }

  setList(projects) {
    this.list = [];
    projects.forEach((p) => this.addProject(p));
  }

  addProject(project) {
    this.list = [...this.list, project];
  }

  removeProject(id) {
    this.list = this.list.filter((p) => p.id !== id);
  }

}