import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 220;

export const useStyles = makeStyles({
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  background: {
    width: "98%",
    backgroundColor: "#2B2F2F",
    borderRadius: 6,
    height: "98%",
    position: "relative",
    overflow: "hidden",
    maxHeight: "100%",
  },
  betweenOuterAndCanvas: {
    // top: "50%",
    // transform: "translateY(-50%)",
    backgroundColor: "",
    top: "50%",
    transform: "translateY(-50%)",
    position: "relative",
    padding: "2%",
    boxSizing: "border-box",
    margin: "auto",
    maxHeight: "inherit",
  },
  centeredCanvas: {
    width: "98%",
    backgroundColor: "",
    borderRadius: 0,
    // borderStyle: "solid",
    // borderWidth: 2,
    // borderColor: "white",
    paddingBottom: (props) => props.ratio + "%",
    position: "relative",
    // overflow: "hidden",
    margin: "auto",
    // top: "50%",
    // transform: "translateY(-50%)",
    // maxWidth: 1000,
  },
  outputBox: {
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    position: "absolute",
    borderStyle: "double",
  },
  screenBox: {
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    position: "absolute",
  },
  innerBoxStyle: {
    direction: "column",
    alignItems: "center",
    justify: "center",
    height: "100%",
  },
  screenName: {
    // color:
    maxHeight: 100,
    zIndex: 100,
  },
  canvasOutline: {
    borderWidth: 1,
    margin: 10,
    borderColor: "lightgrey",
    backgroundColor: "blue",
    width: "95%",
    height: "95%",
    overflow: "hidden",
    // position: "absolute",
  },
  content: {
    width: "100%",
    height: "100%",
  },
});
