import { observer } from "mobx-react-lite";

export const HeaderText = observer(() => {
  return (
    <div className="header-container ">
      <div style={{ background: "", flex: 3 }}>
        <div className="header-new ">new!</div>
        <div className="header-body ">
          {/* With its intuitive and flexible design,{" "} */}
          <div
            className="header-body-middle"
            style={{
              fontFamily: "Chivo ",
              fontWeight: "700",
              display: "inline-block",
            }}
          >
            TK-421
          </div>{" "}
          a live and interactive pixel map
          {/* provides a new and defined process for the management of modular LED
          screens */}
        </div>
      </div>
    </div>
  );
});
