import { useEffect, useContext, useRef } from "react";
import { observer } from "mobx-react-lite";

import { StoreContext } from "../../../../stores/store.context";

import "./style.css";

let colors = [
  "#c0c0c0", //grey
  "#c0c000", //yellow
  "#00c000", //green
  "#00c0c0", //cyan
  "#c000c0", //magenta
  "#c00000", //red
  "#0000c0", //blue
  "#131313", //black
];

export const ColorBars = () => {
  const { project } = useContext(StoreContext);
  const screenList = project.screenList;

  return (
    <div className="output-container">
      {screenList.map(
        (screen, i) => screen.panel && <Screen screen={screen} key={i} />
      )}
    </div>
  );
};

const Screen = observer((props) => {
  const { project, user } = useContext(StoreContext);
  const { screen } = props;
  const output = user.outputPageOutput;

  const screenWidth = screen.panel.pixelWidth * screen.panelColumns;
  const screenHeight = screen.panel.pixelHeight * screen.panelRows;
  let startX = screen.startX - output.startX;
  let startY = screen.startY - output.startY;

  const barPosRef = useRef(0);
  const timeoutRef = useRef();
  const drawBars = useRef();

  useEffect(() => {
    const canvas = document.getElementById(screen.id);
    const ctx = canvas.getContext("2d");
    canvas.style.left = `${screen.startX - output.startX}px`;
    canvas.style.top = `${screen.startY - output.startY}px`;
    canvas.width = screenWidth;
    canvas.height = screenHeight;

    const barWidth = screen.panel.pixelWidth * 4;
    const numberOfBars =
      Math.floor(colors.length / Math.floor(screenWidth / barWidth)) *
      colors.length;

    let colorBars = [];

    for (let i = 0; i < numberOfBars; i++) {
      let bar = {};
      bar.color = colors[i % colors.length];
      bar.start = i * barWidth;
      colorBars.push(bar);
    }

    drawBars.current = () => {
      colorBars.forEach((bar, i) => {
        ctx.fillStyle = bar.color;
        let x = bar.start - barPosRef.current;

        // bump bar to end of the stack when it goes offscreen
        if (x < -barWidth) {
          x = x + numberOfBars * barWidth;
        }

        ctx.fillRect(x, 0, barWidth, screenHeight);
      });

      if (!project.isPlaying) {
        clearTimeout(timeoutRef.current);
        return;
      }

      barPosRef.current = barPosRef.current + 1;

      // restart the position when it reaches the width of the screen
      if (barPosRef.current == numberOfBars * barWidth) {
        barPosRef.current = 0;
      }

      timeoutRef.current = setTimeout(
        () => requestAnimationFrame(drawBars.current),
        16.666
      );
    };

    requestAnimationFrame(drawBars.current);

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [project.isPlaying]);

  return (
    <div
      style={{
        transformOrigin: `${startX}px ${startY}px`,
        transform: `rotate(${screen.rotation}deg)`,
      }}
    >
      <canvas id={screen.id} style={{ position: "absolute" }}></canvas>
    </div>
  );
});
