import "./svg.css";

import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

import { StreetIllustration } from "./images/StreeillustrationForNow";
import { HowWorkComputer } from "./how.work.computer. component";
import { HowWorkApp } from "./how.work.app.component";
import { HowDoesItWork } from "./howDoesItWork.component";
import { ButWhy } from "./but.why.component";
import { BetaTesting } from "./beta.testing.component";
import { HeaderText } from "./header.component";

function Tk421() {
  return (
    <div
      style={{
        background: "rgba(255,255,255,1)",
      }}
    >
      <Header />
      <div
        style={{
          background: "",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          zIndex: 1,
          position: "relative",
        }}
      >
        <div className="street-container">
          <StreetIllustration />
        </div>
      </div>

      <div
        className="everything-under-illustration"
        style={{
          width: "100%",
          zIndex: 2,
          position: "relative",
          pointerEvents: "none",
          cursor: "none",
        }}
      >
        <HeaderText />

        <ButWhy />

        <HowDoesItWork />

        <HowWorkComputer />

        <HowWorkApp />

        <BetaTesting />

        <div style={{ width: "100%", height: 50 }} />
      </div>
      <Footer />
    </div>
  );
}

export default Tk421;
