import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { useStyles } from "./FooterStyle";
import "./svg.css";

function FooterMobile() {
  const classes = useStyles();
  // const [color, setColor] = useState(initialColor);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      style={{
        // height: "1000px",
        flexDirection: "column",
        display: "flex",
      }}
    >
      <Paper className={classes.footer}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-around"
          style={{
            backgroundColor: "",
            // height: "1000px",
            paddingTop: 50,
          }}
        >
          <Grid item xs={10}>
            <Grid
              container
              justifyContent="center"
              alignItems="stretch"
              style={{
                backgroundColor: "",
                // height: "800px",
              }}
            >
              <Grid item>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                  alignItems="stretch"
                  style={{
                    // backgroundColor: 'grey',
                    height: "100%",
                  }}
                >
                  <Grid item>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        align="center"
                        // style={{ backgroundColor: 'grey' }}
                      >
                        <Grid item xs={12}>
                          <img
                            src="/images/landingPage/LeadLED_Brandmark_White_Small.jpg"
                            // public/images/landingPage/LeadLED_Brandmark_White_Small.jpg
                            alt="LED Wall"
                          />
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: 15 }}>
                          <Typography
                            component={Link}
                            to="/"
                            color="secondary"
                            variant="h3"
                            style={{ color: "#fff", marginTop: 15 }}
                            // className={classes.appBarButtons}
                          >
                            LEAD LED
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid
                          container
                          direction="column"
                          alignItems="center"
                          style={{
                            paddingTop: 20,
                            backgroundColor: "",
                            width: "100%",
                            // height: "100%",
                            // paddingLeft: "50px",
                            // paddingTop: "35px",
                            // marginTop: "50px",
                          }}
                        >
                          <Grid item xs={12} style={{}}>
                            {/* <Typography
                              className={classes.trademark}
                              variant="h6"
                            >
                              COMPANY
                            </Typography> */}
                          </Grid>
                          <Grid item xs={12} style={{ marginTop: 10 }}>
                            <Typography
                              component={Link}
                              to="/contact"
                              className={classes.trademark}
                              vairant="body1"
                            >
                              Contact Us
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    style={{
                      backgroundColor: "",
                      marginTop: 45,
                      paddingBottom: 40,
                    }}
                  >
                    <Grid
                      container
                      justifyContent="space-around"
                      // style={{ paddingLeft: '50px', paddingRight: '50px' }}
                    >
                      {/* <Typography
                        className={classes.trademark}
                        variant="subtitle2"
                        align="left"
                        component={Link}
                        to="/privacypolicy"
                      >
                        Privacy Policy
                      </Typography> */}
                      <Typography
                        className={classes.trademark}
                        variant="subtitle2"
                        align="left"
                      >
                        © LEAD LED, LLC
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

export default FooterMobile;
