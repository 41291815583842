import React from "react";
import { observer } from "mobx-react-lite";

export const ConnectOneOrMoreComputers = observer((props) => {
  const { w } = props;
  return (
    <>
      <svg
        id="comp1"
        x="0px"
        y="0px"
        width={w}
        // height="380px"
        viewBox="0 0 120 120"
      >
        {/* <g id="Background">
          <g id="background_00000024694554474634020660000005094688846225968289_">
            <circle fill="#FFFFFF" cx="60" cy="60" r="54" />
          </g>
        </g> */}
        {/* <g id="Border">
                      <g id="border_00000114769394786875173230000006204588161279045036_">
                        <path
                          fill="#F4AF23"
                          d="M60,6c29.823,0,54,24.177,54,54c0,29.823-24.177,54-54,54C30.177,114,6,89.823,6,60
                        C6,30.177,30.177,6,60,6 M60,4.5C29.397,4.5,4.5,29.397,4.5,60s24.897,55.5,55.5,55.5s55.5-24.897,55.5-55.5S90.603,4.5,60,4.5
                        L60,4.5z"
                        />
                      </g>
                    </g> */}
        <g id="Image">
          <path
            id="image_00000183211656345364625860000014318124521286226097_"
            fill="#8D8D8D"
            d="M70.957,43.573L70.957,43.573
                      L70.957,43.573L70.957,43.573z M64.735,94.437l0.001,0.002l0.01-0.001C64.742,94.438,64.739,94.438,64.735,94.437z
                      M67.079,42.756L67.079,42.756L67.079,42.756L67.079,42.756z M47.85,43.099v14.18c0,1-0.8,1.81-1.79,1.81H22.49
                      c-0.98,0-1.78-0.81-1.78-1.81v-14.18c0-1,0.8-1.81,1.78-1.81h23.57C47.05,41.289,47.85,42.099,47.85,43.099z M46.85,43.099
                      c0-0.45-0.35-0.81-0.79-0.81H22.49c-0.43,0-0.78,0.36-0.78,0.81v14.18c0,0.45,0.35,0.81,0.78,0.81h23.57
                      c0.44,0,0.79-0.36,0.79-0.81V43.099z M33.067,70.736l1.346-2.886l-8.34,0.002l-1.571,2.704c-0.075,0.13,0.018,0.292,0.168,0.292
                      h8.22C32.966,70.848,33.035,70.804,33.067,70.736z M23.099,62.679c-0.237,0-0.457,0.122-0.583,0.322l-2.039,3.248
                      c-0.165,0.262,0.024,0.604,0.334,0.604l19.883-0.005c0.258,0,0.495-0.144,0.613-0.374l1.656-3.226
                      c0.135-0.262-0.056-0.574-0.351-0.574L23.099,62.679z M100.88,34.539v9.46c0,0.22-0.1,0.43-0.28,0.57l-11.37,9.46
                      c0,0,0,0-0.01,0.01c-0.05,0.04-0.1,0.06-0.16,0.09c-0.02,0.01-0.04,0.02-0.06,0.03c-0.08,0.03-0.16,0.05-0.25,0.05
                      c-0.05,0-0.11-0.01-0.16-0.02l-14.61-3.2c-0.82,1.98-2.01,3.45-3.07,4.75c-1.36,1.64-2.42,2.95-2.42,4.76
                      c0,0.94,0.09,1.76,0.27,2.5l12.77-0.98c0.47-0.04,0.94,0.11,1.3,0.43s0.58,0.76,0.61,1.24l0.45,5.75
                      c4.35,0.38,8.18,1.13,9.56,4.54c1.35,3.36-0.95,7.05-6.17,9.88l4.72,6.12c0.01,0.02,0.01,0.03,0.02,0.05l0.02-0.01
                      c0.66,0.82,0.85,1.8,0.49,2.62c-0.28,0.65-0.85,1.08-1.5,1.13l-26.27,2.17c-0.04,0.01-0.08,0.01-0.11,0.01
                      c-0.5,0-0.95-0.27-1.19-0.7l-5.03-10.01c-0.26-0.61-0.21-1.24,0.09-1.75c0.3-0.48,0.82-0.79,1.42-0.84v0.01
                      c0.01-0.01,0.01-0.01,0.02-0.01l23.61-1.82c0.83-0.06,1.62,0.29,2.11,0.95l0.7,0.9c0.03-0.03,0.06-0.06,0.1-0.09
                      c1.7-0.91,7.13-4.2,5.58-8.05c-1.04-2.57-4.28-3.24-8.06-3.58l0.53,6.86c0.04,0.48-0.11,0.95-0.41,1.32
                      c-0.31,0.37-0.75,0.59-1.22,0.63l-23.51,1.81c-0.04,0-0.09,0.01-0.13,0.01c-0.92,0-1.71-0.73-1.78-1.68l-1.09-14.14
                      c-0.04-0.48,0.11-0.94,0.42-1.31c0.31-0.37,0.74-0.6,1.22-0.63l9.22-0.71c-0.16-0.79-0.26-1.65-0.26-2.62
                      c0-2.35,1.34-3.99,2.76-5.72c0.97-1.18,2.01-2.46,2.74-4.12l-10.39-2.27c-1.76,2.11-4.05,3.12-5.91,3.93
                      c-2.17,0.95-3.52,1.6-3.8,3.03c-0.18,0.92,0.22,1.8,0.68,2.83c0.57,1.26,1.22,2.69,0.84,4.48c-0.85,4.11-5.27,5.15-8.2,5.15
                      c-0.18,0-0.34-0.01-0.5-0.02l-2.15,5.23c-0.22,0.5-0.7,0.81-1.24,0.81l-26.37-0.16c-0.64,0-1.25-0.38-1.58-1
                      c-0.41-0.79-0.3-1.78,0.29-2.65l0.02,0.01c0.01-0.02,0.01-0.04,0.02-0.05l5.67-8.67c0.44-0.69,1.2-1.1,2.03-1.1h23.68
                      c0.01,0,0.01,0,0.02,0c0.6,0,1.14,0.26,1.48,0.72c0.34,0.48,0.44,1.11,0.25,1.69l-1.51,3.67c1.92-0.03,5.91-0.53,6.62-3.93
                      c0.28-1.31-0.22-2.41-0.74-3.57c-0.52-1.15-1.05-2.34-0.78-3.73c0.45-2.27,2.5-3.17,4.66-4.11c1.65-0.72,3.41-1.5,4.84-2.93
                      l-2.72-0.59c-0.34-0.08-0.59-0.38-0.59-0.73v-10.96c0-0.02,0.01-0.03,0.02-0.05c0-0.08,0.02-0.15,0.05-0.22
                      c0-0.03,0.01-0.05,0.02-0.07c0.05-0.09,0.11-0.17,0.18-0.23c0,0,0-0.01,0.01-0.01c0,0,0.01,0,0.02-0.01
                      c0.05-0.04,0.11-0.08,0.17-0.1l14.69-6c0.13-0.06,0.27-0.07,0.41-0.05l27.57,4.8c0.09,0.01,0.16,0.04,0.23,0.08h0.01
                      c0.01,0.01,0.01,0.02,0.02,0.02c0.1,0.06,0.18,0.14,0.25,0.24c0,0.01,0.01,0.02,0.01,0.03c0.04,0.07,0.08,0.14,0.09,0.23
                      c0.01,0.03,0,0.07,0.01,0.1C100.87,34.519,100.88,34.529,100.88,34.539z M46.01,61.839c0.06-0.14,0.04-0.3-0.04-0.43
                      c-0.09-0.13-0.23-0.2-0.39-0.2H21.9c-0.49,0-0.93,0.24-1.19,0.64l-5.67,8.67c-0.13,0.21-0.05,0.4-0.01,0.48
                      c0.04,0.07,0.16,0.25,0.42,0.25l8.22,0.04c0.22,0.35,0.59,0.56,1,0.56h8.22c0.38,0,0.73-0.19,0.96-0.5l7.4,0.05h0.01
                      c0.39,0,0.74-0.23,0.89-0.59L46.01,61.839z M84.89,82.389c-0.27-0.36-0.69-0.57-1.13-0.57c-0.03,0-0.07,0.01-0.11,0.01
                      l-23.61,1.82c-0.16,0.01-0.3,0.1-0.37,0.24c-0.08,0.13-0.08,0.29-0.01,0.42l4.53,8.65c0.18,0.35,0.54,0.55,0.95,0.52l7.37-0.61
                      c0.23,0.26,0.55,0.42,0.91,0.42h0.09l8.19-0.64c0.41-0.03,0.77-0.27,0.96-0.63l8.19-0.67c0.26-0.03,0.37-0.21,0.4-0.29
                      c0.04-0.07,0.1-0.27-0.05-0.47L84.89,82.389z M58.1,64.829c-0.2,0.01-0.39,0.11-0.53,0.27c-0.14,0.17-0.2,0.38-0.18,0.6
                      l1.08,14.13c0.04,0.45,0.42,0.78,0.85,0.75l23.5-1.81c0.21-0.01,0.39-0.11,0.53-0.27c0.14-0.17,0.2-0.38,0.19-0.6l-1.09-14.14
                      c-0.02-0.21-0.12-0.41-0.28-0.56c-0.14-0.12-0.32-0.19-0.51-0.19h-0.05L58.1,64.829z M88,42.189l-1.12-0.21l-28.27-5.34v9.45
                      l2.89,0.63c0.59-0.88,1.09-1.96,1.43-3.29l-0.74-0.16c-0.3-0.07-0.57-0.3-0.7-0.6c0,0-0.06-0.15-0.13-0.33
                      c-0.04,0.05-0.06,0.1-0.11,0.14c-0.12,0.09-0.26,0.14-0.42,0.14c-0.05,0-0.1,0-0.15-0.01c-0.38-0.09-0.67-0.45-0.67-0.86
                      c0-0.22,0.09-0.43,0.26-0.56c0.15-0.13,0.36-0.17,0.57-0.13c0.1,0.02,0.2,0.07,0.28,0.13c0.01-0.02,0.02-0.04,0.03-0.05
                      c0.09-0.15,0.28-0.3,0.66-0.21l4.33,0.9c0.11,0.03,0.39,0.08,0.53,0.36c0.05,0.08,0.07,0.17,0.08,0.25
                      c0.09-0.04,0.19-0.06,0.29-0.06c0.04,0,0.08,0,0.12,0.01c0.39,0.08,0.69,0.46,0.69,0.87c0,0.23-0.09,0.43-0.26,0.57
                      c-0.12,0.09-0.27,0.15-0.43,0.15c-0.05,0-0.11-0.01-0.16-0.02c-0.29-0.06-0.52-0.28-0.63-0.55c-0.05,0.1-0.09,0.17-0.09,0.17
                      c-0.13,0.25-0.39,0.4-0.67,0.4c-0.06,0-0.12,0-0.18-0.01l-1.03-0.23c-0.33,1.31-0.8,2.4-1.36,3.32l9.96,2.18
                      c0.29-1.03,0.46-2.19,0.45-3.55l-0.93-0.2c-0.31-0.06-0.59-0.3-0.73-0.61c0,0-0.08-0.19-0.17-0.4c-0.04,0.06-0.08,0.13-0.14,0.17
                      c-0.12,0.11-0.28,0.16-0.44,0.16c-0.05,0-0.11-0.01-0.16-0.02c-0.4-0.09-0.71-0.46-0.71-0.88c0-0.22,0.1-0.43,0.26-0.57
                      c0.17-0.13,0.39-0.18,0.6-0.13h0.01c0.12,0.02,0.23,0.09,0.34,0.17c0.01-0.03,0.01-0.07,0.04-0.1c0.13-0.2,0.36-0.27,0.67-0.2
                      l4.73,0.99c0.11,0.02,0.4,0.08,0.55,0.36c0.06,0.1,0.09,0.2,0.1,0.29c0.1-0.04,0.21-0.08,0.32-0.08c0.05,0,0.09,0.01,0.14,0.02
                      c0.41,0.09,0.73,0.48,0.73,0.89c0,0.23-0.1,0.44-0.27,0.58c-0.12,0.1-0.28,0.15-0.45,0.15c-0.06,0-0.11,0-0.17-0.01
                      c-0.33-0.07-0.59-0.34-0.68-0.66c-0.08,0.15-0.14,0.26-0.14,0.26c-0.14,0.26-0.41,0.41-0.7,0.41c-0.06,0-0.13-0.01-0.19-0.02
                      l-1.11-0.24c-0.01,1.33-0.19,2.5-0.48,3.54L88,52.519V42.189z M98.05,34.939l-25.41-4.42l-12.14,4.95l28.1,5.31L98.05,34.939z
                      M99.38,35.879l-5.46,3.38l-4.42,2.72v9.88l9.88-8.21V35.879z M79.997,88.776l-8.316,0.639l1.564,2.774
                      c0.035,0.061,0.1,0.099,0.169,0.099c0.005,0,0.01,0,0.015-0.001l8.196-0.632c0.15-0.012,0.23-0.181,0.145-0.304L79.997,88.776z
                      M85.454,86.748l-2.283-3.082c-0.13-0.176-0.336-0.279-0.553-0.279c-0.018,0-0.035,0.001-0.053,0.002l-19.455,1.494
                      c-0.294,0.023-0.46,0.348-0.305,0.599l1.899,3.089c0.126,0.205,0.349,0.328,0.586,0.328c0.018,0,0.035-0.001,0.053-0.002
                      l19.824-1.523C85.477,87.352,85.639,86.997,85.454,86.748z M82.05,42.569c-0.27-0.06-0.52,0-0.72,0.16
                      c-0.19,0.16-0.29,0.39-0.29,0.65c0,0.49,0.38,0.94,0.86,1.04c0.06,0.02,0.12,0.02,0.19,0.02c0.19,0,0.38-0.06,0.53-0.18
                      c0.19-0.16,0.29-0.39,0.29-0.65C82.91,43.119,82.53,42.659,82.05,42.569z M82.05,45.289c-0.26-0.05-0.52,0.01-0.72,0.17
                      c-0.19,0.15-0.29,0.39-0.29,0.64c0,0.49,0.37,0.95,0.86,1.05c0.06,0.01,0.13,0.02,0.19,0.02c0.2,0,0.38-0.06,0.52-0.18
                      c0.2-0.15,0.3-0.39,0.3-0.65C82.91,45.859,82.54,45.399,82.05,45.289z M85.06,43.169c-0.27-0.06-0.53,0-0.73,0.16
                      c-0.19,0.16-0.3,0.4-0.3,0.65c0,0.49,0.39,0.95,0.88,1.05c0.06,0.02,0.13,0.02,0.19,0.02c0.2,0,0.39-0.06,0.54-0.18
                      c0.19-0.16,0.3-0.39,0.3-0.65C85.94,43.729,85.55,43.259,85.06,43.169z M85.06,45.929c-0.27-0.06-0.53,0-0.73,0.16
                      c-0.19,0.15-0.3,0.39-0.3,0.65c0,0.48,0.39,0.95,0.88,1.05c0.06,0.02,0.13,0.03,0.2,0.03c0.19,0,0.38-0.07,0.53-0.19
                      c0.19-0.15,0.3-0.39,0.3-0.65C85.94,46.489,85.55,46.029,85.06,45.929z M84.93,48.939l-2.74-0.6c-0.3-0.07-0.6-0.01-0.82,0.17
                      c-0.21,0.17-0.33,0.44-0.33,0.73c0,0.56,0.43,1.09,0.99,1.22l2.73,0.62c0.08,0.02,0.16,0.03,0.24,0.03c0.22,0,0.43-0.07,0.6-0.2
                      c0.21-0.18,0.34-0.44,0.34-0.74C85.94,49.609,85.49,49.069,84.93,48.939z"
          />
        </g>
      </svg>
    </>
  );
});

export const CreateYourOutput = observer((props) => {
  const { w } = props;

  return (
    <>
      <svg
        id="comp2"
        x="0px"
        y="0px"
        width={w}
        // height="380px"
        viewBox="0 0 120 120"
      >
        {/* <g id="Background">
          <g id="background_00000101792432449272238630000012868805079863972774_">
            <circle fill="#FFFFFF" cx="60" cy="60" r="54" />
          </g>
        </g>
        <g id="Border">
          <g id="border_00000032615400177657019110000006854523325800994732_">
            <path
              fill="#F4AF23"
              d="M60,6c29.823,0,54,24.177,54,54c0,29.823-24.177,54-54,54S6,89.823,6,60C6,30.177,30.177,6,60,6 M60,4.5
                          C29.397,4.5,4.5,29.397,4.5,60s24.897,55.5,55.5,55.5s55.5-24.897,55.5-55.5S90.603,4.5,60,4.5L60,4.5z"
            />
          </g>
        </g> */}
        <g id="Image">
          <path
            id="image_00000066497990644798795560000016208017675357693877_"
            fill="#8D8D8D"
            d="M94.12,34.848H47.75V31.67
                        c0-0.828-0.672-1.5-1.5-1.5H26.946c-0.828,0-1.5,0.672-1.5,1.5v54c0,0.828,0.672,1.5,1.5,1.5H94.12c0.828,0,1.5-0.672,1.5-1.5
                        V36.348C95.62,35.519,94.948,34.848,94.12,34.848z M94.87,85.67c0,0.414-0.336,0.75-0.75,0.75H26.946
                        c-0.414,0-0.75-0.336-0.75-0.75v-54c0-0.414,0.336-0.75,0.75-0.75H46.25c0.414,0,0.75,0.336,0.75,0.75v3.928h47.12
                        c0.414,0,0.75,0.336,0.75,0.75V85.67z M27.696,32.42H45.5v3.178H27.696V32.42z M27.696,37.098H93.37v7.948H27.696V37.098z
                        M41.718,46.546H27.696V84.92h14.021V46.546z M37.323,65.158h-7.004c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h7.004
                        c0.414,0,0.75,0.336,0.75,0.75S37.737,65.158,37.323,65.158z M39.095,62.13h-8.776c-0.414,0-0.75-0.336-0.75-0.75
                        s0.336-0.75,0.75-0.75h8.776c0.414,0,0.75,0.336,0.75,0.75S39.509,62.13,39.095,62.13z M29.569,54.491
                        c0-0.414,0.336-0.75,0.75-0.75h7.004c0.414,0,0.75,0.336,0.75,0.75s-0.336,0.75-0.75,0.75h-7.004
                        C29.905,55.241,29.569,54.905,29.569,54.491z M39.095,52.213h-8.776c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h8.776
                        c0.414,0,0.75,0.336,0.75,0.75S39.509,52.213,39.095,52.213z M79.348,84.92H93.37V46.546H79.348V84.92z M82,50.713h8.776
                        c0.414,0,0.75,0.336,0.75,0.75s-0.336,0.75-0.75,0.75H82c-0.414,0-0.75-0.336-0.75-0.75S81.586,50.713,82,50.713z M82,53.741
                        h7.004c0.414,0,0.75,0.336,0.75,0.75s-0.336,0.75-0.75,0.75H82c-0.414,0-0.75-0.336-0.75-0.75S81.586,53.741,82,53.741z
                        M82,60.63h8.776c0.414,0,0.75,0.336,0.75,0.75s-0.336,0.75-0.75,0.75H82c-0.414,0-0.75-0.336-0.75-0.75S81.586,60.63,82,60.63z
                        M82,63.658h7.004c0.414,0,0.75,0.336,0.75,0.75s-0.336,0.75-0.75,0.75H82c-0.414,0-0.75-0.336-0.75-0.75
                        S81.586,63.658,82,63.658z M82,70.547h8.776c0.414,0,0.75,0.336,0.75,0.75s-0.336,0.75-0.75,0.75H82
                        c-0.414,0-0.75-0.336-0.75-0.75S81.586,70.547,82,70.547z M82,73.575h7.004c0.414,0,0.75,0.336,0.75,0.75s-0.336,0.75-0.75,0.75
                        H82c-0.414,0-0.75-0.336-0.75-0.75S81.586,73.575,82,73.575z M75.065,57.115v11.187H61.283V57.115H75.065z M46,57.115h13.783
                        v11.187H46V57.115z"
          />
        </g>
      </svg>
    </>
  );
});
export const RouteScreenDesign = observer((props) => {
  const { w } = props;
  return (
    <>
      <svg
        id="comp3"
        version="1.1"
        x="0px"
        y="0px"
        width={w}
        // height="380px"
        viewBox="0 0 120 120"
      >
        {/* <g id="Background">
          <g id="background">
            <circle fill="#FFFFFF" cx="60" cy="60" r="54" />
          </g>
        </g>
        <g id="Border">
          <g id="border">
            <path
              fill="#F4AF23"
              d="M60,6c29.823,0,54,24.177,54,54c0,29.823-24.177,54-54,54S6,89.823,6,60C6,30.177,30.177,6,60,6 M60,4.5
                          C29.397,4.5,4.5,29.397,4.5,60s24.897,55.5,55.5,55.5s55.5-24.897,55.5-55.5S90.603,4.5,60,4.5L60,4.5z"
            />
          </g>
        </g> */}
        <g id="Image">
          <path
            id="image_00000067917082382914482300000002182470363293326768_"
            fill="#8D8D8D"
            d="M60,39.723L60,39.723l0-0.772h0V39.723z
                        M60,29.191C60,29.191,60,29.191,60,29.191l0,0.871c0,0,0,0,0,0V29.191z M57.615,75.258v11.579c0,0.867-0.694,1.573-1.547,1.573
                        H36.816c-0.853,0-1.547-0.706-1.547-1.573V75.258c0-0.867,0.694-1.573,1.547-1.573h19.253
                        C56.921,73.685,57.615,74.391,57.615,75.258z M56.615,75.258c0-0.316-0.245-0.573-0.547-0.573H36.816
                        c-0.302,0-0.547,0.257-0.547,0.573v11.579c0,0.316,0.245,0.573,0.547,0.573h19.253c0.302,0,0.547-0.257,0.547-0.573V75.258z
                        M39.635,95.655l-1.177,2.025c-0.062,0.106,0.015,0.239,0.137,0.239h6.714c0.062,0,0.118-0.036,0.144-0.092l1.014-2.174
                        L39.635,95.655z M35.443,94.656l16.239-0.004c0.211,0,0.404-0.118,0.5-0.306l1.352-2.635c0.11-0.214-0.046-0.469-0.286-0.469
                        l-15.936,0.004c-0.193,0-0.373,0.099-0.476,0.263l-1.665,2.653C35.036,94.377,35.19,94.656,35.443,94.656z M62.385,86.837V75.258
                        c0-0.867,0.694-1.573,1.547-1.573h19.253c0.853,0,1.547,0.706,1.547,1.573v11.579c0,0.867-0.694,1.573-1.547,1.573H63.932
                        C63.079,88.41,62.385,87.704,62.385,86.837z M63.385,86.837c0,0.316,0.245,0.573,0.547,0.573h19.253
                        c0.302,0,0.547-0.257,0.547-0.573V75.258c0-0.316-0.245-0.573-0.547-0.573H63.932c-0.302,0-0.547,0.257-0.547,0.573V86.837z
                        M80.365,95.655l-6.833-0.002l1.014,2.174c0.026,0.056,0.082,0.092,0.144,0.092h6.714c0.123,0,0.199-0.133,0.137-0.239
                        L80.365,95.655z M84.83,94.163l-1.665-2.653c-0.103-0.164-0.283-0.263-0.476-0.263l-15.936-0.004
                        c-0.241,0-0.396,0.255-0.286,0.469l1.352,2.635c0.096,0.188,0.289,0.306,0.5,0.306l16.239,0.004
                        C84.81,94.656,84.964,94.377,84.83,94.163z M54.274,85.661H42.347V76.02h11.926V85.661z M54.024,80.165h-0.492l-0.947,2.85h1.439
                        V80.165z M54.024,78.685l-0.397,1.195h0.397V78.685z M54.024,77.03h-2.582l-0.947,2.85h2.832l0.278-0.836h-1.266l0.092-0.278
                        h1.266l0.211-0.636h-1.266l0.092-0.278H54l0.023-0.071V77.03z M47.779,81.448h3.756l0.426-1.283h-1.866l1.042-3.135h-1.89
                        L47.779,81.448z M54.024,83.3h-1.835l1.042-3.135h-1.12l-0.476,1.434h-0.77l-0.992,2.984h4.151V83.3z M69.749,79.049h0.299
                        l0.408-1.204h-0.298L69.749,79.049z M67.312,77.849h-0.3l-0.095,0.285h0.3L67.312,77.849z M69.415,80.975h-0.298l-0.409,1.204
                        h0.299L69.415,80.975z M65.726,76.02h11.926v9.642H65.726V76.02z M65.976,79.88h0.369l0.374-1.121h0.3l-0.374,1.121h1.266
                        l0.947-2.85h-2.832l-0.05,0.15V79.88z M68.116,80.165l-0.947,2.85h2.832l0.732-2.203l-0.216-0.647H68.116z M71.043,79.88
                        l0.731-2.201l-0.216-0.649h-2.4l-0.947,2.85H71.043z M65.976,80.165v1.729h1.266l0.21-0.631h-1.266l0.093-0.28h1.266l0.272-0.818
                        H65.976z M65.976,83.015h0.893l0.28-0.841h-1.173V83.015z M65.976,84.583h3.506l0.426-1.283h-3.932V84.583z M70.018,37.467
                        c0.411,0.069,0.616,0.105,1.026,0.179c0.884-1.326,1.344-1.98,2.297-3.25c-0.433-0.103-0.65-0.152-1.084-0.247
                        C71.326,35.449,70.878,36.117,70.018,37.467z M80.039,26.75c-0.493-0.184-0.74-0.273-1.236-0.448
                        c-1.111,1.106-1.649,1.676-2.689,2.858c0.474,0.145,0.711,0.219,1.183,0.374C78.359,28.384,78.907,27.829,80.039,26.75z
                        M20.507,30.191c0,0,16.956-12.522,39.493-12.522v5.327c-0.993,1.953-1.694,3.612-2.697,6.218
                        c1.087-0.031,1.878-0.04,2.697-0.024v0.871c-0.917-0.003-1.903,0.016-3.038,0.055c-1.345,3.621-1.912,5.494-2.829,9.096
                        c2.388-0.236,4.022-0.321,5.867-0.263v0.772c-2.112,0.003-4.369,0.161-7.063,0.5c0.886-3.959,1.459-6.041,2.864-10.053
                        c-1.726,0.095-2.589,0.175-4.305,0.4c-0.513,1.878-0.741,2.83-1.15,4.702c-1.098,0.167-1.65,0.26-2.75,0.47
                        c-0.689,3.879-0.936,5.764-1.163,9.354c5.503-1.089,9.461-1.504,13.569-1.394v2.664c-3.202,0-6.356,0.282-9.404,0.756
                        c0.176,1.428,0.957,2.507,1.778,3.64c1.185,1.634,2.382,3.308,1.793,5.983h2.83c0.026,0,0.046,0.016,0.071,0.021
                        c0.023,0.004,0.046-0.003,0.068,0.006c0.012,0.005,0.018,0.018,0.029,0.024c0.034,0.018,0.06,0.042,0.087,0.07
                        c0.025,0.025,0.048,0.047,0.065,0.078c0.006,0.011,0.019,0.017,0.024,0.029c0.011,0.025,0.004,0.052,0.009,0.079
                        c0.005,0.024,0.021,0.044,0.021,0.069v3.346c0,0.08-0.025,0.158-0.073,0.223l-4.606,6.248c-0.005,0.007-0.013,0.009-0.018,0.016
                        c-0.025,0.03-0.055,0.053-0.088,0.074c-0.013,0.008-0.024,0.019-0.038,0.026c-0.048,0.023-0.101,0.037-0.157,0.037H38.922
                        c-1.957,2.892-5.478,4.061-8.908,5.195c-3.653,1.208-7.103,2.349-8.474,5.451c-1.345,3.044-0.315,5.698,0.786,7.388
                        c2.297,3.526,7.221,6.297,11.321,6.412l0.953-1.457c0.375-0.588,1.023-0.943,1.73-0.943h19.342c0.006,0,0.011,0.002,0.017,0.002
                        v-0.047c0.539,0,1.02,0.237,1.318,0.65c0.308,0.427,0.391,0.987,0.222,1.499l-3.484,8.504c-0.204,0.46-0.647,0.746-1.139,0.746
                        l-21.54-0.124c-0.579,0-1.121-0.343-1.412-0.894c-0.364-0.69-0.271-1.554,0.247-2.31l0.05,0.034
                        c0.007-0.011,0.009-0.024,0.016-0.035l3.044-4.654c-4.316-0.354-9.136-3.174-11.523-6.838c-1.106-1.698-1.638-3.459-1.588-5.207
                        V80.45c0.031-1.05,0.272-2.095,0.725-3.119c1.551-3.509,5.376-4.773,9.075-5.997c3.14-1.038,6.148-2.034,7.977-4.246H34.91
                        c-0.207,0-0.375-0.168-0.375-0.375v-4.734c0-0.029,0.01-0.054,0.016-0.081c0.003-0.014,0.003-0.026,0.008-0.039
                        c0.022-0.065,0.061-0.119,0.112-0.163c0.004-0.004,0.005-0.01,0.01-0.014l6.305-4.859c0.066-0.051,0.146-0.078,0.229-0.078
                        h11.909c0.591-2.41-0.461-3.88-1.56-5.396c-0.858-1.184-1.74-2.404-1.955-4.061C33.406,50.05,20.507,58.104,20.507,58.104V30.191
                        z M30.807,97.703c-0.081,0.126-0.032,0.247-0.007,0.292c0.025,0.047,0.103,0.157,0.266,0.158l6.465,0.037
                        c0.02,0.05,0.034,0.101,0.061,0.148c0.206,0.358,0.591,0.581,1.004,0.581h6.714c0.449,0,0.861-0.263,1.051-0.67l0.004-0.008
                        l5.776,0.033h0.004c0.282,0,0.534-0.165,0.645-0.421l3.149-7.329c0.051-0.119,0.007-0.215-0.023-0.261
                        c-0.025-0.039-0.101-0.129-0.242-0.129H36.331c-0.364,0-0.696,0.182-0.891,0.486L30.807,97.703z M52.016,62.354h-16.73v3.984
                        h2.929c0.241-0.382,0.454-0.793,0.631-1.244h-0.59c-0.245,0-0.464-0.136-0.573-0.355c0,0-0.311-0.62-0.34-0.686
                        c-0.039-0.09-0.062-0.231,0.042-0.401c0.119-0.192,0.347-0.195,0.426-0.195h3.509c0.094,0.025,0.312,0.001,0.432,0.195
                        c0.105,0.17,0.082,0.312,0.043,0.4c-0.029,0.066-0.341,0.688-0.341,0.688c-0.108,0.218-0.328,0.354-0.573,0.354H39.9
                        c-0.151,0.441-0.323,0.86-0.526,1.244h12.641V62.354z M56.622,58.06l-3.856,4.068v3.444l3.856-5.23V58.06z M41.343,57.495
                        l-5.332,4.109h16.219l3.896-4.109H41.343z M47.335,21.549c-1.09,3.617-1.526,5.566-2.22,9.326
                        c4.378-1.075,6.584-1.399,11.015-1.614c0.387-1.066,0.59-1.596,1.014-2.643c-2.065,0.062-3.098,0.137-5.153,0.374
                        c0.113-0.359,0.17-0.538,0.286-0.894c2.084-0.228,3.131-0.297,5.224-0.347c0.332-0.791,0.503-1.183,0.856-1.958
                        c-2.157,0.021-3.236,0.077-5.385,0.281c0.126-0.35,0.19-0.524,0.32-0.871c2.178-0.193,3.271-0.243,5.455-0.25
                        c0.469-0.992,0.712-1.48,1.215-2.435C54.893,20.441,52.344,20.589,47.335,21.549z M36.653,38.598
                        c5.056-2.265,7.86-2.881,13.262-3.736c0.362-1.678,0.563-2.527,1.005-4.214c-2.857,0.412-4.298,0.702-7.134,1.463
                        c0.651-4.113,1.084-6.268,2.203-10.286c-3.333,0.731-4.986,1.25-8.234,2.441C36.871,30.074,36.611,33.003,36.653,38.598z
                        M98.512,85.668c-2.387,3.664-7.206,6.484-11.523,6.838l3.041,4.649c0.008,0.013,0.011,0.027,0.019,0.04l0.05-0.035
                        c0.519,0.756,0.61,1.619,0.247,2.31c-0.291,0.551-0.833,0.894-1.416,0.894l-21.532,0.124c-0.495,0-0.938-0.286-1.134-0.727
                        l-3.476-8.474c-0.187-0.561-0.104-1.121,0.204-1.548c0.299-0.413,0.779-0.65,1.318-0.65v0.047c0.006,0,0.011-0.002,0.017-0.002
                        H83.67c0.706,0,1.354,0.354,1.732,0.948l0.95,1.453c4.1-0.114,9.025-2.885,11.321-6.412c1.102-1.69,2.131-4.343,0.786-7.388
                        c-1.371-3.103-4.821-4.244-8.474-5.451c-3.431-1.134-6.952-2.303-8.909-5.195H67.609c-0.057,0-0.109-0.015-0.157-0.037
                        c-0.014-0.007-0.025-0.017-0.038-0.026c-0.033-0.021-0.063-0.044-0.088-0.074c-0.005-0.006-0.013-0.009-0.018-0.016l-4.606-6.248
                        c-0.048-0.064-0.073-0.143-0.073-0.223V57.12c0-0.025,0.016-0.045,0.021-0.069c0.005-0.026-0.002-0.053,0.009-0.079
                        c0.005-0.012,0.018-0.017,0.024-0.029c0.017-0.031,0.04-0.053,0.065-0.078c0.028-0.028,0.053-0.052,0.087-0.07
                        c0.011-0.006,0.017-0.019,0.029-0.024c0.022-0.009,0.045-0.001,0.068-0.006c0.024-0.005,0.045-0.021,0.071-0.021h2.83
                        c-0.588-2.675,0.609-4.349,1.793-5.983c0.822-1.133,1.602-2.212,1.778-3.64c-3.047-0.474-6.202-0.756-9.404-0.756v-2.664
                        c3.236,0.086,6.566,0.498,10.819,1.164c0.902-1.37,1.372-2.056,2.348-3.392c-5.311-1.129-8.943-1.756-13.167-1.751V38.95
                        c1.033,0.033,2.129,0.109,3.467,0.232c0.458-0.994,0.696-1.494,1.19-2.492c-1.747-0.194-2.624-0.253-4.378-0.298
                        c0.137-0.345,0.207-0.518,0.349-0.864c1.782,0.057,2.673,0.123,4.446,0.331c0.38-0.748,0.575-1.122,0.974-1.867
                        c-1.833-0.241-2.755-0.323-4.599-0.407c0.151-0.345,0.227-0.518,0.382-0.862c1.871,0.097,2.806,0.186,4.666,0.443
                        c0.53-0.962,0.803-1.442,1.367-2.388c-3.209-0.504-5.25-0.706-7.865-0.715v-0.871c0.673,0.013,1.365,0.043,2.258,0.093
                        c0.659-1.367,1.004-2.044,1.728-3.369c0.495,0.04,0.742,0.063,1.236,0.114c-0.749,1.31-1.108,1.98-1.792,3.334
                        c1.979,0.152,2.965,0.27,4.93,0.595c2.006-3.269,3.11-4.83,5.52-7.668c-4.971-1.281-7.51-1.561-12.577-1.739
                        c-0.502,0.907-0.923,1.697-1.304,2.446V17.67c22.536,0,39.493,12.522,39.493,12.522v27.913c0,0-12.899-8.054-29.102-10.817
                        c-0.215,1.657-1.098,2.877-1.956,4.061c-1.099,1.516-2.15,2.986-1.56,5.396h11.909c0.083,0,0.163,0.027,0.229,0.078l6.305,4.859
                        c0.004,0.003,0.005,0.01,0.009,0.013c0.052,0.043,0.091,0.098,0.113,0.163c0.004,0.013,0.005,0.025,0.008,0.038
                        c0.006,0.027,0.017,0.053,0.017,0.082v4.734c0,0.207-0.168,0.375-0.375,0.375h-2.768c1.829,2.211,4.838,3.207,7.977,4.246
                        c3.699,1.223,7.524,2.488,9.074,5.997C100.582,80.064,100.284,82.948,98.512,85.668z M69.523,30.16
                        c4.388,0.806,6.578,1.388,10.788,3.033c2.008-1.951,3.065-2.855,5.302-4.646c-0.04-0.848-0.065-1.274-0.126-2.128
                        c-4.023-1.773-6.096-2.59-10.293-3.775C72.725,25.422,71.592,26.945,69.523,30.16z M73.707,40.734
                        c1.704-2.274,2.61-3.37,4.528-5.422c-0.052-0.817-0.084-1.227-0.158-2.054c-3.561-1.216-5.394-1.651-9.065-2.29
                        c-2.029,3.248-2.942,4.981-4.559,8.316C68.173,39.701,70.054,39.904,73.707,40.734z M67.234,62.128l-3.856-4.068v2.282
                        l3.856,5.23V62.128z M67.771,61.604h16.219l-5.332-4.109H63.875L67.771,61.604z M84.715,66.338v-3.984h-16.73v3.984h12.641
                        c-0.203-0.384-0.375-0.803-0.526-1.244h-0.983c-0.244,0-0.464-0.136-0.573-0.355c0,0-0.312-0.621-0.34-0.686
                        c-0.039-0.088-0.063-0.229,0.042-0.401c0.121-0.194,0.37-0.188,0.43-0.195h1.754v0.569c0,0,0.001,0,0.001,0v-0.569h1.752
                        c0.097,0.011,0.312,0.001,0.432,0.194c0.106,0.173,0.082,0.314,0.043,0.403c-0.028,0.065-0.34,0.686-0.34,0.686
                        c-0.109,0.218-0.329,0.354-0.573,0.354h-0.59c0.176,0.451,0.39,0.862,0.631,1.244H84.715z M84.564,90.625
                        c-0.198-0.309-0.53-0.491-0.894-0.491H64.327c-0.142,0-0.217,0.09-0.242,0.129c-0.03,0.046-0.074,0.142-0.023,0.261l3.149,7.329
                        c0.111,0.258,0.377,0.431,0.648,0.421l5.776-0.033l0.004,0.008c0.189,0.407,0.602,0.67,1.051,0.67h6.714
                        c0.413,0,0.798-0.223,1.004-0.581c0.027-0.047,0.041-0.099,0.061-0.148l6.465-0.037c0.163-0.001,0.24-0.111,0.266-0.158
                        c0.024-0.045,0.073-0.166-0.011-0.297L84.564,90.625z M66.753,23.414c-0.515-0.063-0.772-0.092-1.288-0.144
                        c-0.196,0.324-0.293,0.487-0.486,0.816c0.509,0.048,0.764,0.075,1.272,0.134C66.45,23.896,66.551,23.735,66.753,23.414z"
          />
        </g>
      </svg>
    </>
  );
});
export const MatrixScreenMapped = observer((props) => {
  const { w } = props;
  return (
    <>
      <svg
        version="1.1"
        x="0px"
        y="0px"
        width={w}
        // height="380px"
        viewBox="0 0 120 120"
      >
        {/* <g id="Background">
          <g id="background_00000047048623136076840130000014891337494147221950_">
            <circle fill="#FFFFFF" cx="60" cy="60" r="54" />
          </g>
        </g>
        <g id="Border">
          <g id="border_00000067233272732903094580000014945897291010173336_">
            <path
              fill="#F4AF23"
              d="M60,6c29.823,0,54,24.177,54,54c0,29.823-24.177,54-54,54C30.177,114,6,89.823,6,60
					C6,30.177,30.177,6,60,6 M60,4.5C29.397,4.5,4.5,29.397,4.5,60s24.897,55.5,55.5,55.5s55.5-24.897,55.5-55.5S90.603,4.5,60,4.5
					L60,4.5z"
            />
          </g>
        </g> */}
        <g id="Image">
          <path
            id="image_00000040546836816375882910000013120883647421661836_"
            fill="#8D8D8D"
            d="M69.531,43.533H48.883
				c-2.663,0-4.83,2.167-4.83,4.83v48.224c0,2.663,2.167,4.83,4.83,4.83h20.648c2.663,0,4.83-2.167,4.83-4.83V48.362
				C74.361,45.699,72.194,43.533,69.531,43.533z M72.861,96.586c0,1.839-1.491,3.33-3.33,3.33H48.883c-1.839,0-3.33-1.491-3.33-3.33
				V48.362c0-1.839,1.491-3.33,3.33-3.33h20.648c1.839,0,3.33,1.491,3.33,3.33V96.586z M68.747,46.559h-2.039v0.213
				c0,0.87-0.706,1.576-1.576,1.576H53.282c-0.87,0-1.576-0.706-1.576-1.576v-0.213h-2.039c-1.441,0-2.609,1.168-2.609,2.609v46.613
				c0,1.441,1.168,2.609,2.609,2.609h19.08c1.441,0,2.609-1.168,2.609-2.609V49.168C71.356,47.727,70.188,46.559,68.747,46.559z
				 M62.176,54.488v5.953h-5.937v-5.953H62.176z M69.613,54.488v5.953h-5.937v-5.953H69.613z M69.613,67.894h-5.937v-5.954h5.937
				V67.894z M69.613,75.348h-5.937v-5.954h5.937V75.348z M69.613,82.801h-5.937v-5.953h5.937V82.801z M48.802,84.301h5.937v5.954
				h-5.937V84.301z M56.239,67.894v-5.954h5.937v5.954H56.239z M62.176,69.394v5.954h-5.937v-5.954H62.176z M62.176,76.848v5.953
				h-5.937v-5.953H62.176z M56.239,84.301h5.937v5.954h-5.937V84.301z M63.676,84.301h5.937v5.954h-5.937V84.301z M54.739,60.441
				h-5.937v-5.953h5.937V60.441z M54.739,61.941v5.954h-5.937v-5.954H54.739z M54.739,69.394v5.954h-5.937v-5.954H54.739z
				 M54.739,76.848v5.953h-5.937v-5.953H54.739z M70.363,83.551L70.363,83.551L70.363,83.551z M70.363,76.098L70.363,76.098
				L70.363,76.098z M70.363,68.644L70.363,68.644L70.363,68.644z M70.363,61.191L70.363,61.191L70.363,61.191z M62.926,53.738
				L62.926,53.738L62.926,53.738z M55.489,53.738L55.489,53.738L55.489,53.738z M66.005,97.354H52.41
				c-0.389,0-0.704-0.315-0.704-0.704s0.315-0.704,0.704-0.704h13.595c0.389,0,0.704,0.315,0.704,0.704S66.394,97.354,66.005,97.354
				z M65.996,36.574c0.519,0.646,0.417,1.589-0.229,2.109c-0.277,0.223-0.609,0.331-0.939,0.331c-0.435,0-0.865-0.188-1.161-0.549
				c-0.083-0.098-1.629-1.83-4.816-1.959c-3.209,0.13-4.754,1.883-4.819,1.961c-0.298,0.354-0.727,0.538-1.158,0.538
				c-0.334,0-0.671-0.111-0.948-0.339c-0.634-0.522-0.736-1.451-0.221-2.091c0.094-0.117,2.186-2.647,6.406-3.037
				c0.123-0.033,0.25-0.055,0.383-0.055c0.123,0,0.237,0.01,0.357,0.013c0.12-0.004,0.234-0.013,0.357-0.013
				c0.133,0,0.26,0.023,0.383,0.055C63.81,33.927,65.902,36.457,65.996,36.574z M58.85,27.83
				c-7.593,0.151-11.309,4.943-11.465,5.151c-0.295,0.392-0.747,0.599-1.203,0.599c-0.312,0-0.626-0.097-0.895-0.297
				c-0.662-0.494-0.802-1.427-0.311-2.091c0.185-0.25,4.487-5.896,13.175-6.333c0.11-0.026,0.223-0.042,0.341-0.042
				c0.123,0,0.236,0.012,0.357,0.014c0.121-0.002,0.234-0.014,0.357-0.014c0.118,0,0.231,0.017,0.341,0.042
				c8.688,0.437,12.989,6.083,13.175,6.333c0.493,0.666,0.352,1.605-0.314,2.098c-0.268,0.198-0.581,0.294-0.891,0.294
				c-0.458,0-0.909-0.208-1.204-0.604C70.15,32.762,66.435,27.981,58.85,27.83z M79.424,25.776c0.485,0.672,0.333,1.609-0.338,2.094
				c-0.266,0.191-0.573,0.284-0.876,0.284c-0.465,0-0.923-0.215-1.216-0.62c-0.261-0.358-6.164-8.209-18.142-8.368
				c-11.994,0.158-17.896,8.03-18.144,8.369c-0.294,0.403-0.751,0.617-1.215,0.617c-0.306,0-0.613-0.093-0.879-0.286
				c-0.669-0.486-0.82-1.42-0.336-2.09c0.276-0.383,6.77-9.119,19.871-9.581c0.111-0.026,0.226-0.044,0.345-0.044
				c0.123,0,0.235,0.013,0.357,0.015c0.122-0.001,0.234-0.015,0.357-0.015c0.119,0,0.234,0.017,0.345,0.044
				C72.654,16.658,79.148,25.394,79.424,25.776z M70.363,83.551L70.363,83.551 M70.363,76.098L70.363,76.098 M70.363,68.644
				L70.363,68.644 M70.363,61.191L70.363,61.191 M62.926,53.738L62.926,53.738 M55.489,53.738L55.489,53.738"
          />
        </g>
      </svg>
    </>
  );
});
export const RemotelyTogglePanels = observer((props) => {
  const { w } = props;
  return (
    <>
      <svg
        version="1.1"
        x="0px"
        y="0px"
        width={w}
        // height="380px"
        viewBox="0 0 120 120"
      >
        {/* <g id="Background">
          <g id="background_00000057145443291479876840000003517562929628635281_">
            <circle fill="#FFFFFF" cx="60" cy="60" r="54" />
          </g>
        </g>
        <g id="Border">
          <g id="border_00000079456252329601069370000007282278978988688548_">
            <path
              fill="#F4AF23"
              d="M60,6c29.823,0,54,24.177,54,54c0,29.823-24.177,54-54,54S6,89.823,6,60C6,30.177,30.177,6,60,6 M60,4.5
					C29.397,4.5,4.5,29.397,4.5,60s24.897,55.5,55.5,55.5s55.5-24.897,55.5-55.5S90.603,4.5,60,4.5L60,4.5z"
            />
          </g>
        </g> */}
        <g id="Image">
          <path
            id="image"
            fill="#8D8D8D"
            d="M50.788,101.935c-0.885,0-1.716-0.248-2.439-0.672c0.144-0.424,0.309-0.911,0.494-1.457
				c0.56,0.403,1.24,0.629,1.946,0.629c0.332,0,0.67-0.05,1.004-0.155l16.283-5.143c1.754-0.554,2.726-2.424,2.172-4.178
				L57.885,51.82c-0.449-1.42-1.76-2.328-3.174-2.328c-0.332,0-0.67,0.05-1.004,0.156L37.424,54.79
				c-1.754,0.554-2.726,2.424-2.172,4.178l9.997,31.651c-0.362,0.771-0.682,1.44-0.947,1.983L33.822,59.42
				c-0.802-2.539,0.612-5.258,3.151-6.06l16.283-5.143c0.473-0.149,0.963-0.225,1.456-0.225c2.116,0,3.967,1.357,4.605,3.376
				l12.361,39.139c0.389,1.23,0.275,2.538-0.32,3.683c-0.595,1.145-1.6,1.989-2.831,2.378l-16.283,5.143
				C51.77,101.859,51.28,101.935,50.788,101.935z M58.369,82.233l-2.591-8.203l7.047-2.226l5.991,18.97
				c0.434,1.374-0.328,2.839-1.702,3.273l-15.163,4.789c-0.939,0.297-1.912,0.026-2.578-0.608c1.61-4.784,3.978-11.875,4.924-14.71
				L58.369,82.233z M64.528,92.638L53.26,96.197c-0.322,0.102-0.501,0.445-0.399,0.768h0c0.102,0.322,0.445,0.501,0.768,0.399
				l11.268-3.559c0.322-0.102,0.501-0.445,0.399-0.768v0C65.194,92.715,64.85,92.537,64.528,92.638z M36.682,59.152l9.384,29.711
				c0.891-1.933,1.965-4.308,3.163-7.007l-1.653-5.234l8.203-2.591l-2.591-8.203l7.047-2.226l-3.414-10.81
				c-0.434-1.374-1.899-2.136-3.273-1.702l-1.366,0.432c0.248,0.824-0.209,1.697-1.033,1.957l-9.429,2.978
				c-0.823,0.26-1.699-0.192-1.969-1.009l-1.366,0.432C37.01,56.312,36.248,57.778,36.682,59.152z M54.167,110.2
				c2.132-1.313,4.785-2.948,7.188-3.356c0.139-0.024,0.284-0.043,0.43-0.062c0.549-0.072,1.067-0.14,1.264-0.456
				c0.182-0.291,0.222-0.577,0.123-0.874c-0.153-0.459-0.648-0.91-1.262-1.147c-0.795-0.308-3.012-0.7-4.235-0.317
				c-0.847,0.265-1.973,0.721-3.164,1.205c-2.194,0.89-4.277,1.729-5.268,1.671c-0.726-0.043-1.359-0.456-1.83-1.195
				c-0.603-0.945-0.861-2.377-0.565-3.127c0.235-0.594,3.771-11.156,6.729-20.02c0.115-0.345,0.143-0.717,0.08-1.077
				c-0.088-0.506-0.425-0.919-0.903-1.106c-0.477-0.188-1.006-0.114-1.414,0.198c-0.428,0.327-0.77,0.759-0.987,1.25
				c-5.756,12.996-6.241,13.256-6.474,13.381c-1.311,0.703-1.874-0.201-2.248-0.797c-0.445-0.71-0.796-1.271-2.173-0.899
				c-0.435,0.118-0.727,0.376-1.009,0.627c-0.274,0.243-0.558,0.495-0.942,0.564c-0.55,0.099-0.937,0.048-1.278,0.004
				c-0.396-0.05-0.739-0.095-1.387,0.079c-0.488,0.132-0.687,0.417-0.938,0.778c-0.255,0.367-0.545,0.782-1.137,1.006
				c-0.486,0.184-0.921,0.15-1.342,0.115c-0.444-0.034-0.861-0.068-1.382,0.16c-0.616,0.27-0.711,0.511-0.832,0.815
				c-0.113,0.287-0.269,0.68-0.806,0.931c-0.211,0.099-0.455,0.104-0.713,0.109c-0.319,0.006-0.68,0.014-0.996,0.191
				c-0.18,0.101-0.33,0.333-0.459,0.652c7.208,6.17,16.109,10.415,25.909,11.906c0.071-0.042,0.149-0.094,0.215-0.13
				C52.891,110.986,53.501,110.609,54.167,110.2z M100.554,38.487C83.889,14.949,61.677,12.733,34.951,19.353
				c0.445,5.074,1.508,13.287,2.463,22.806c23.184,0.565,38.18,6.881,55.657,22.317C95.922,54.316,97.848,47.532,100.554,38.487z
				 M79.41,45.109c-2.654-1.648-4.019-2.374-6.816-3.639c0.603-3.079,0.906-4.658,1.517-7.719c2.99,1.276,4.442,2.023,7.224,3.747
				C80.561,40.522,80.175,42.077,79.41,45.109z M87.986,42.384c-2.531-2.188-3.868-3.163-6.65-4.887
				c0.774-3.025,1.163-4.527,1.937-7.353c2.907,1.778,4.289,2.796,6.846,5.108C89.272,38.007,88.842,39.462,87.986,42.384z"
          />
        </g>
      </svg>
    </>
  );
});
export const CreateALog = observer((props) => {
  const { w } = props;
  return (
    <>
      {" "}
      <svg
        id="comp6"
        version="1.1"
        x="0px"
        y="0px"
        width={w}
        height="380px"
        viewBox="0 0 120 120"
      >
        {/* <g id="Background">
          <g id="background_00000058556602380009164740000010473558513969590694_">
            <circle fill="#FFFFFF" cx="60" cy="60" r="54" />
          </g>
        </g>
        <g id="Border">
          <g id="border_00000136386927252040261390000005535838882927011253_">
            <path
              fill="#F4AF23"
              d="M60,6c29.823,0,54,24.177,54,54c0,29.823-24.177,54-54,54S6,89.823,6,60C6,30.177,30.177,6,60,6 M60,4.5
C29.397,4.5,4.5,29.397,4.5,60s24.897,55.5,55.5,55.5s55.5-24.897,55.5-55.5S90.603,4.5,60,4.5L60,4.5z"
            />
          </g>
        </g> */}
        <g id="Image">
          <path
            id="image_00000016060830586663941920000012687503434127282102_"
            fill="#8D8D8D"
            d="M79,29.64h-8.955
c-0.279-0.186-0.567-0.378-0.875-0.582c-1.09-0.721-1.697-2.033-2.339-3.423c-1.095-2.369-2.458-5.29-6.832-5.482l0,0h-0.001l0,0
c-4.374,0.193-5.736,3.113-6.832,5.482c-0.642,1.39-1.249,2.703-2.339,3.423c-0.308,0.204-0.596,0.395-0.875,0.582H41
c-3.033,0-5.5,2.524-5.5,5.626v53.97c0,3.102,2.467,5.626,5.5,5.626h38c3.033,0,5.5-2.524,5.5-5.626v-53.97
C84.5,32.164,82.033,29.64,79,29.64z M83.5,89.236c0,2.555-2.015,4.626-4.5,4.626H41c-2.485,0-4.5-2.071-4.5-4.626v-53.97
c0-2.555,2.015-4.626,4.5-4.626h7.497c-1.9,1.351-2.901,2.336-3.482,3.788H40.5c-0.576,0-1.043,0.467-1.043,1.043v53.559
c0,0.576,0.467,1.043,1.043,1.043h39c0.576,0,1.043-0.467,1.043-1.043V35.472c0-0.576-0.467-1.043-1.043-1.043h-4.517
c-0.581-1.452-1.582-2.437-3.482-3.788H79c2.485,0,4.5,2.071,4.5,4.626V89.236z M54.529,26.265c1.1-2.38,2.063-4.445,5.47-4.614
l0,0c3.408,0.169,4.371,2.233,5.471,4.614c0.701,1.519,1.427,3.089,2.872,4.045c4.069,2.69,4.99,3.456,5.502,5.461
c0.134,0.522,0.027,1.074-0.284,1.476c-0.137,0.175-0.434,0.47-0.891,0.47H47.33c-0.457,0-0.754-0.294-0.891-0.47
c-0.312-0.402-0.418-0.954-0.284-1.476c0.512-2.005,1.433-2.771,5.502-5.461C53.102,29.354,53.828,27.784,54.529,26.265z
M56.078,57.981l-7.74,10.908c-0.138,0.194-0.358,0.311-0.597,0.316h-0.015c-0.232,0-0.452-0.107-0.594-0.292
c-1.213-1.572-2.344-3.222-3.361-4.902c-0.215-0.354-0.102-0.816,0.253-1.03c0.357-0.215,0.815-0.102,1.03,0.253
c0.815,1.347,1.705,2.672,2.653,3.953l7.147-10.073c0.237-0.337,0.707-0.418,1.045-0.178
C56.238,57.175,56.318,57.643,56.078,57.981z M56.078,75.051l-7.74,10.908c-0.138,0.194-0.358,0.311-0.597,0.316h-0.015
c-0.232,0-0.452-0.108-0.594-0.292c-1.214-1.575-2.345-3.224-3.361-4.902c-0.215-0.354-0.102-0.816,0.253-1.03
c0.357-0.216,0.815-0.101,1.03,0.253c0.814,1.345,1.704,2.67,2.653,3.952l7.147-10.073c0.237-0.337,0.707-0.418,1.045-0.178
C56.238,74.245,56.318,74.713,56.078,75.051z M56.078,43.837l-7.74,10.908c-0.138,0.194-0.358,0.311-0.597,0.316h-0.015
c-0.232,0-0.452-0.107-0.594-0.292c-1.213-1.572-2.344-3.222-3.361-4.902c-0.215-0.354-0.102-0.816,0.253-1.03
c0.357-0.215,0.815-0.102,1.03,0.253c0.815,1.347,1.705,2.672,2.653,3.953l7.147-10.073c0.237-0.337,0.707-0.418,1.045-0.178
C56.238,43.031,56.318,43.499,56.078,43.837z M62.583,78.294c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h12.819
c0.414,0,0.75,0.336,0.75,0.75s-0.336,0.75-0.75,0.75H62.583z M73.563,82.598c0,0.414-0.336,0.75-0.75,0.75h-10.23
c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h10.23C73.227,81.848,73.563,82.184,73.563,82.598z M61.833,65.528
c0-0.414,0.336-0.75,0.75-0.75h10.23c0.414,0,0.75,0.336,0.75,0.75s-0.336,0.75-0.75,0.75h-10.23
C62.168,66.278,61.833,65.942,61.833,65.528z M75.402,61.224H62.583c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h12.819
c0.414,0,0.75,0.336,0.75,0.75S75.816,61.224,75.402,61.224z M61.833,51.384c0-0.414,0.336-0.75,0.75-0.75h10.23
c0.414,0,0.75,0.336,0.75,0.75s-0.336,0.75-0.75,0.75h-10.23C62.168,52.134,61.833,51.798,61.833,51.384z M75.402,47.08H62.583
c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h12.819c0.414,0,0.75,0.336,0.75,0.75S75.816,47.08,75.402,47.08z
M57.9,26.537c0-1.16,0.94-2.1,2.1-2.1c1.16,0,2.1,0.94,2.1,2.1s-0.94,2.1-2.1,2.1C58.84,28.637,57.9,27.697,57.9,26.537z"
          />
        </g>
      </svg>
    </>
  );
});
