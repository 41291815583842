import { useContext } from "react";
import { observer } from "mobx-react-lite";

import { StoreContext } from "../../../../stores/store.context";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const ScreenInfo = observer(() => {
  const { project } = useContext(StoreContext);
  const screen = project.selectedScreenEdit;
  const panel = screen.panel
  const panelColumns = screen.panelColumns ? screen.panelColumns : null;
  const panelRows = screen.panelRows ? screen.panelRows : null;
  const pixelWidth = panel ? panel.pixelWidth : null;
  const pixelHeight = panel ? panel.pixelHeight : null;
  const screenWidth = screen.panelColumns
    ? panel.pixelWidth * screen.panelColumns
    : "-";
  const screenHeight = screen.panelRows
    ? panel.pixelHeight * screen.panelRows
    : "-";
  const panelMake = panel ? panel.make : null;
  const panelModel = panel ? panel.model : null;

  return (
    <>
      {/* Screen */}
      {/* Screen */}
      <Grid item xs={12} style={{ padding: 10 }}>
        <Typography
          variant="body1"
          align="center"
          style={{
            width: "100%",
            fontWeigth: 700,
            textDecoration: "underline",
          }}
        >
          Screen Dimesions:
        </Typography>
      </Grid>

      <Grid item xs={12} style={{ paddingLeft: 15, paddingRight: 15 }}>
        <Typography
          variant="body1"
          align="center"
          style={{
            backgroundColor: screen.color,
          }}
        >
          {screen.name ? screen.name.toUpperCase() : null}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          marginTop: 5,
        }}
      >
        <Grid container justifyContent="space-around">
          <Grid item xs={6} style={{ paddingTop: 5 }}>
            <Typography align="center" variant="body2" style={{}}>
              columns: {panelColumns}
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ paddingTop: 5 }}>
            <Typography align="center" variant="body2">
              rows: {panelRows}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="flex-start">
          <Grid item xs={6} style={{ paddingTop: 5 }}>
            <Typography align="center" variant="body2">
              start x: {screen.startX}
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ paddingTop: 5 }}>
            <Typography align="center" variant="body2">
              start y: {screen.startY}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="space-around">
          <Grid item xs={6} style={{ paddingTop: 5 }}>
            <Typography align="center" variant="body2">
              width: {screenWidth}
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ paddingTop: 5 }}>
            <Typography align="center" variant="body2">
              height: {screenHeight}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {/* Screen */}
      {/* Screen */}

      {/* Panel */}
      {/* Panel */}
      <Grid item xs={12} style={{ paddingTop: 15 }}>
        <Typography
          variant="body1"
          align="center"
          style={{
            width: "100%",
            fontWeigth: 700,
            textDecoration: "underline",
          }}
        >
          Panel Type:
        </Typography>
      </Grid>

      <Grid item xs={12} style={{ padding: 0 }}>
        <Typography variant="body1" align="center">
          {panelMake} - {panelModel}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="space-around">
          <Grid item xs={12} style={{ paddingLeft: 0 }}>
            <Typography align="center" variant="body2">
              pixels wide: {pixelWidth}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ paddingLeft: 0 }}>
            <Typography align="center" variant="body2">
              pixels tall: {pixelHeight}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
});

export default ScreenInfo;
