import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import "./svg.css";

import { useStyles } from "./HeaderStyle";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import HeaderWeb from "./HeaderWeb";
import HeaderMobile from "./HeaderMobile";

function Public() {
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("xs"));
  // const isSM = false;
  // const isSM = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  return (
    <Box>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          {isXS ? <HeaderMobile /> : <HeaderWeb />}
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Public;
