import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  error: {
    color: "red",
  },
  fieldTitle: {
    paddingBottom: 10,
    fontFamily: "Chivo",
    fontWeight: 300,
  },
}));
