import { useState, useCallback, useEffect, useContext } from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { StoreContext } from "../../stores/store.context";

import { useStyles } from "./SignUpStyle";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core//Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import Confirm from "./Confirm";

const SignUp = observer(() => {
  const { app } = useContext(StoreContext);

  let initialFormState = {
    username: "",
    password: "",
    email: "",
    passwordCheck: "",
  };

  const pwdText =
    "Password must contain uppercase, lowercase, number, and be atleast 8 characters long";
  const emailText =
    "Must be a valid email address, you will receive a confirmation code";

  const [formState, setFormState] = useState(initialFormState);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [buttonText, setButtonText] = useState("Create account");
  const [toBeSignedUp, setToBeSignedUp] = useState(true);
  const [usernameError, setUsernameError] = useState(false);
  const [usernameErrorText, setUsernameErrorText] = useState(
    "Username cannot be blank"
  );
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorText, setPasswordErrorText] = useState(pwdText);
  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState(emailText);
  const [isCreating, setIsCreating] = useState(false);

  const classes = useStyles();

  function setInput(key, value, key1, value1) {
    setFormState({ ...formState, [key]: value, [key1]: value1 });
  }

  // const history = useHistory();
  // const goToSignIn = () => history.push("/userOnMade/dashboard");

  async function signUp() {
    setButtonDisabled(true);
    setButtonText("working..");
    setIsCreating(true);

    setEmailError(false);
    setUsernameError(false);
    setPasswordError(false);
    setEmailErrorText(emailText);
    setUsernameErrorText("Username cannot be blank");
    setPasswordErrorText(pwdText);

    let username = formState.username;
    let password = formState.password;
    let email = formState.email;
    let passwordCheck = formState.passwordCheck;

    if (email === "") {
      setButtonDisabled(false);
      setButtonText("create account");
      setIsCreating(false);
      setEmailError(true);
      setEmailErrorText("Email cannot be empty");
      console.log("return");
      return;
    }

    if (email === "") {
      setButtonDisabled(false);
      setButtonText("create account");
      setIsCreating(false);
      usernameError(true);
      setUsernameErrorText("Username cannot be empty");
      return;
    }

    if (passwordCheck !== password) {
      setPasswordError(true);
      setPasswordErrorText("Passwords do not match");
      setButtonDisabled(false);
      setButtonText("create account");
      setIsCreating(false);
      return;
    }

    try {
      const { user } = await Auth.signUp({
        username,
        password,
        attributes: {
          email,
        },
      });
      setToBeSignedUp(false);
    } catch (error) {
      switch (error.code) {
        case undefined:
          let errorRes = error.toString();
          let errorResCut = errorRes.replace("AuthError: ", "");

          switch (errorResCut) {
            case "Username cannot be empty":
              setUsernameError(true);
              setUsernameErrorText(errorResCut);
              break;
            case "Password cannot be empty":
              setPasswordError(true);
              setPasswordErrorText(errorResCut);
              break;
            case "Email cannot be empty":
              setEmailError(true);
              setEmailErrorText(errorResCut);
              break;
            default:
              break;
          }
          break;

        case "InvalidParameterException":
          if (error.message === "Invalid email address format.") {
            setEmailError(true);
            setEmailErrorText(error.message);
          } else {
            let msgArray = error.message.split(" ");
            let type = msgArray[6];
            let typeEdit = type.slice(1, type.length - 1);
            switch (typeEdit) {
              case "password":
                setPasswordError(true);
                setPasswordErrorText(pwdText);
                break;
              default:
                break;
            }
          }
          break;

        case "InvalidPasswordException":
          setPasswordError(true);
          setPasswordErrorText(pwdText);
          break;

        case "UsernameExistsException":
          setUsernameError(true);
          setUsernameErrorText(error.message);
          break;

        default:
          break;
      }

      setButtonDisabled(false);
      setButtonText("create account");
      setIsCreating(false);
    }
  }

  const handleKey = useCallback(
    (e) => {
      if (e.code === "Enter") {
        signUp();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formState]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKey);

    return () => {
      document.removeEventListener("keydown", handleKey);
    };
  }, [handleKey]);

  return toBeSignedUp ? (
    <Box>
      <Header />
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{
          height: "100vh",
          // width: '25vw',
          flexDirection: "column",
          display: "flex",
          backgroundColor: app.color.white1,
          paddingTop: 65,
        }}
      >
        <Paper
          elevation={6}
          style={{
            backgroundColor: "white",
            padding: 43,
            paddingTop: 50,
          }}
        >
          {/* <form> */}
          <Grid container style={{ backgroundColor: "white", width: 400 }}>
            <Grid
              item
              xs={12}
              style={{ backgroundColor: "white", marginBottom: 15 }}
            >
              <Typography
                style={{ fontWeight: 800 }}
                className={classes.title}
                variant="h6"
              >
                Create a new account
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ backgroundColor: "white", paddingTop: 10 }}
            >
              <Typography className={classes.fieldTitle}>
                Email Address*
              </Typography>
              <TextField
                id="email"
                label="Email"
                variant="outlined"
                fullWidth
                style={{ width: "100%" }}
                onChange={(event) =>
                  parseInt(setInput("email", event.target.value))
                }
                value={formState.email}
                error={emailError}
                helperText={emailErrorText}
                margin="dense"
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ backgroundColor: "white", paddingTop: 20 }}
            >
              <Typography className={classes.fieldTitle}>Username *</Typography>
              <TextField
                id="username"
                label="Username"
                variant="outlined"
                fullWidth
                onChange={(event) =>
                  parseInt(setInput("username", event.target.value))
                }
                value={formState.username}
                error={usernameError}
                helperText={usernameErrorText}
                margin="dense"
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ backgroundColor: "white", paddingTop: 20 }}
            >
              <Typography className={classes.fieldTitle}>Password *</Typography>
              <TextField
                id="password"
                label="Password"
                variant="outlined"
                fullWidth
                type="password"
                onChange={(event) =>
                  parseInt(setInput("password", event.target.value))
                }
                value={formState.password}
                error={passwordError}
                helperText={passwordErrorText}
                margin="dense"
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ backgroundColor: "white", paddingTop: 20 }}
            >
              <Typography className={classes.fieldTitle}>
                Confirm Password *
              </Typography>
              <TextField
                id="password-confirm"
                label="Password Confirmation"
                variant="outlined"
                type="password"
                style={{ width: "100%" }}
                fullWidth
                onChange={(event) =>
                  parseInt(setInput("passwordCheck", event.target.value))
                }
                value={formState.passwordCheck}
                margin="dense"
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ backgroundColor: "white", paddingTop: 20 }}
            >
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography>Have an account?</Typography>
                  <Typography
                    align="left"
                    component={Link}
                    to="/userOnMade/home/projects"
                  >
                    Sign In
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    onClick={signUp}
                    disabled={buttonDisabled}
                    style={{
                      backgroundColor: "black",
                      color: "white",
                      padding: 20,
                      paddingTop: 10,
                      paddingBottom: 10,
                    }}
                  >
                    {isCreating ? (
                      <CircularProgress
                        size={24}
                        style={{ marginLeft: 55, marginRight: 55 }}
                      />
                    ) : (
                      <Typography>Create Account</Typography>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* </form> */}
        </Paper>
      </Grid>
      <Footer />
    </Box>
  ) : (
    <Confirm />
  );
});

export default SignUp;
