import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  paperBackground: {
    width: "100%",
  },
  button: {
    paddingTop: "12px",
    paddingBottom: "12px",
    borderRadius: 35,
    minWidth: 200,
    marginInline: 30,
    "&:hover": {
      color: "white",
      backgroundColor: theme.palette.primary.main,
      elevation: 20,
    },
  },
  blurb: {
    maxWidth: 400,
    fontSize: 16,
    fontWeight: 300,
    fontFamily: "Chivo ",
    color: "#444",
  },
  butWhyQuestion: {
    paddingTop: 60,
    // paddingInline: 125,
    // maxWidth: 500,
    fontSize: 18,
    fontWeight: 400,
    fontFamily: "Chivo ",
    color: "#222",
  },
  butWhyAnswer: {
    paddingTop: 5,
    // paddingInline: 125,
    fontSize: 18,
    fontWeight: 300,
    fontFamily: "Chivo ",
    color: "#222",
  },
}));
