import { useEffect, useState, useContext } from "react";
import { observer } from "mobx-react-lite";
import { Hub } from "aws-amplify";
import { Switch, Route, Redirect } from "react-router-dom";
import { NavBar } from "../NavBar/NavBar";
import ProjectDashboardPage from "../../pages/ProjectDashboardPage/ProjectDashboardPage";
import OutputPage from "../../pages/OutputPage/OutputPage";
import PublicPage from "../../pages/PublicPage/PublicPage";
import ProjectsPage from "../../pages/ProjectsPage/ProjectsPage";
import { PanelPage } from "../../pages/PanelsPage/panels.page";
import SettingsPage from "../../pages/SettingsPage/SettingsPage";
import CommunityPage from "../../pages/CommunityPage/CommunityPage";
import PrivacyPolicy from "../../pages/PrivacyPolicy/PrivacyPolicy";
import SignUp from "../../pages/AuthPages/SignUp";
import SignIn from "../../pages/AuthPages/SignIn";
import Contact from "../../pages/Contact/Contact";
import ScrollToTop from "../../hooks/ScrollToTop";
import About from "../../pages/About/About";
import UserManual from "../../pages/UserManual/UserManual";
import TK421Main from "../../pages/Tk421/product.page";
import CheckoutPage from "../../pages/Checkout/CheckoutPage";

import ProjectSubscriptions from "../../utils/ProjectSubscriptions";
import ScreenFetchAndSub from "../../utils/ScreenFetchAndSub";
import OutputsFetchAndSub from "../../utils/OutputsFetchAndSub";
import HomeAPI from "../../utils/HomeAPI";
import FetchPanels from "../../utils/FetchPanels";
import MarkedSubscriptions from "../../utils/MarkedSubscriptions";

import {
  checkDynamoForUser,
  checkForCurrentUser,
  querySubscriptions,
  isThisSubscriptionCurrent,
  updateLastLogin,
} from "./Functions/Auth";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { BetaTestingFormDialog } from "../../pages/BetaTestingForm/beta.tesing.form.dialog";
import { StoreContext } from "../../stores/store.context";

const Router = () => {
  return (
    <ScrollToTop>
      <BetaTestingFormDialog />
      <Switch>
        <Route exact path="/" component={PublicPage} />
        <Route exact path="/privacypolicy" component={PrivacyPolicy} />
        <Route exact path="/signup" component={SignUp} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/about" component={About} />
        <Route exact path="/tk421" component={TK421Main} />
        <Redirect from="/newproject" to="/userOnMade/home/projects" />
        <AuthStateComponent />
      </Switch>
    </ScrollToTop>
  );
};

const AuthStateComponent = observer(() => {
  const { user, project, projects, app } = useContext(StoreContext);
  app.setOutputPageLoader(false);
  const [isAuthLoading, setIsAuthLoading] = useState(true);

  const handleSubscriptionStatus = async () => {
    let subRes = await querySubscriptions();
    let isAtleastOneSubscription = subRes.some(isThisSubscriptionCurrent);
    isAtleastOneSubscription && user.setIsSubscribed(true);
  };

  const handleSignInEvent = async () => {
    let dynamoUserRes;
    let currentUserRes = await checkForCurrentUser();

    user.setUser(currentUserRes);

    if (currentUserRes) {
      dynamoUserRes = await checkDynamoForUser(currentUserRes);
      dynamoUserRes && user.setIsLoggedIn(true);
    }
    if (currentUserRes && dynamoUserRes) {
      updateLastLogin(currentUserRes.id);
      await handleSubscriptionStatus();
    }
  };

  useEffect(() => {
    const sessionAuth = async () => {
      setIsAuthLoading(true);

      await handleSignInEvent();

      app.setOutputPageLoader(true);
      setIsAuthLoading(false);

      Hub.listen("auth", async (event) => {
        switch (event.payload.event) {
          case "signIn":
            const newCurrentUser = {
              username: event.payload.data.username,
              id: event.payload.data.attributes.sub,
              email: event.payload.data.attributes.email,
            };
            user.setUser(newCurrentUser);
            user.setIsLoggedIn(true);
            await handleSubscriptionStatus();
            setIsAuthLoading(false);

            handleSignInEvent();

            break;
          case "signOut":
            user.setUser({});
            user.setIsLoggedIn(false);
            user.setIsSubscribed(false);
            project.clearProject({});
            projects.setList([])
            project.setOutputList([]);
            project.selectScreenEdit({});
            project.selectOutputEdit({});
            project.setScreenList([]);
            project.setPressedPanels([]);
            project.setPressedModules([]);
            app.setNewProjectDialogIsOpen(false);
            user.setPanelTypes([]);
            project.setstarredModulesList([]);
            app.setInitProjectPageRender(false);
            setIsAuthLoading(false);
            setIsAuthLoading(false);
            break;
          default:
            break;
        }
      });
    };

    sessionAuth();
  }, []);

  return user.isLoggedIn ? (
    <PrivateRoutes isAuthLoading={isAuthLoading} />
  ) : (
    <NoCurrentUser isAuthLoading={isAuthLoading} />
  );
});

const NoCurrentUser = (props) => {
  const { isAuthLoading } = props;
  return isAuthLoading ? <Loader /> : <SignIn />;
};

const PrivateRoutes = (props) => {
  const { isSubscribed, isAuthLoading } = props;
  return (
    <div>
      <FetchPanels />
      <div>
        <Switch>
          <>
            <HomeAPI />
            <ProjectSubscriptions />
            <ScreenFetchAndSub />
            <OutputsFetchAndSub />
            <MarkedSubscriptions />
            <Switch>
              <Route
                path="/userOnMade/dashboard"
                component={ProjectDashboardPage}
              />
              <Route path="/userOnMade/checkout" component={CheckoutPage} />
              <Route path="/userOnMade/home">
                <>
                  <NavBar />
                  <Switch>
                    <Route
                      exact
                      path="/userOnMade/home/settings"
                      component={SettingsPage}
                    />
                    <Route
                      exact
                      path="/userOnMade/home/projects"
                      component={ProjectsPage}
                    />
                    <Route
                      exact
                      path="/userOnMade/home/panels"
                      component={PanelPage}
                    />
                    <Route
                      exact
                      path="/userOnMade/home/usermanual"
                      component={UserManual}
                    />
                    <Route
                      exact
                      path="/userOnMade/home/community"
                      component={CommunityPage}
                    />
                  </Switch>
                </>
              </Route>
              <OutputRoute
                isSubscribed={isSubscribed}
                isAuthLoading={isAuthLoading}
              />
            </Switch>
          </>
        </Switch>
      </div>
    </div>
  );
};

const OutputRoute = observer((props) => {
  const { isAuthLoading } = props;

  return isAuthLoading ? (
    <Loader />
  ) : (
    [
      // user.isSubscribed ? (
      <Route path="/output" component={OutputPage} key={1} />,
      // ) : (
      //   <PleaseSubscribe />
      // ),
    ]
  );
});

const Loader = () => {
  return (
    <Box zIndex="2000" id="loader">
      <Grid
        container
        alignItems="center"
        justifyContent="space-around"
        style={{
          height: "100vh",
          flexDirection: "column",
          display: "flex",
        }}
      >
        <CircularProgress size={30} />
      </Grid>
    </Box>
  );
};

export default Router;
