import { useState, useContext } from "react";
import { observer } from "mobx-react-lite";

import ScreenList from "./ScreenList";
import AddOutputForm from "./AddOutputForm";
import OutputList from "./OutputList";
import AddScreenForm from "./AddScreenForm";
import DeleteDialog from "./DeleteDialog";

import useStyles from "./ListSideBarStyle";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Tooltip from '@material-ui/core/Tooltip';

import { StoreContext } from "../../../../stores/store.context";

const ListSideBar = observer(() => {
  const { user } = useContext(StoreContext);
  const classes = useStyles();
  const [outputFormOpen, setOutputFormOpen] = useState(false);
  const [screenFormOpen, setScreenFormOpen] = useState(false);

  return (
    <Box className={classes.sidebar} style={{ paddingLeft: 0, height: "100%" }}>
      <DeleteDialog />
      <Grid
        container
        justifyContent="space-around"
        align="center"
        style={{ backgroundColor: "", height: "100%" }}
      >
        <Grid item xs={12} style={{ minHeight: "50%", backgroundColor: "" }}>
          {outputFormOpen ? (
            <AddOutputForm
              outputFormOpen={outputFormOpen}
              setOutputFormOpen={setOutputFormOpen}
            />
          ) : (
            <>
              <Button
                variant="contained"
                onClick={() => setOutputFormOpen(true)}
                autoFocus={false}
                className={classes.newScreenButton}
                color="secondary"
              >
                add Output
              </Button>
              <OutputList
              />
            </>
          )}
        </Grid>
        <Grid item xs={12} style={{ backgroundColor: "", height: "50%" }}>
          <Divider style={{ marginBottom: 0, marginTop: 0 }} />
          {screenFormOpen ? (
            <AddScreenForm
              outputFormOpen={screenFormOpen}
              setOutputFormOpen={setScreenFormOpen}
            />
          ) : (
            <>
              {!user.panelTypes.length
                ?
                <Tooltip

                  title={<div style={{ fontSize: 14 }}>To create a screen, first add a panel type from the dashboard on the previous page.</div>}
                >
                  <Button
                    variant="contained"
                    autoFocus={false}
                    className={classes.newScreenButton}
                    color="red"
                  >
                    add Screen
                  </Button>
                </Tooltip>
                :
                <Button
                  variant="contained"
                  onClick={() => setScreenFormOpen(true)}
                  autoFocus={false}
                  className={classes.newScreenButton}
                  color="secondary"
                >
                  add Screen
                </Button>
              }
              <ScreenList
              />
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
});

export default ListSideBar;
