import { createContext } from "react";

import { App } from "./app.store";
import { CustomPanelStore } from "./custom.panel.store";
import { UserStore } from "./store.user";
import { ProjectStore } from "./store.project";
import { ProjectsStore } from "./store.projects";
import { DialogStore } from "./store.dialogs";

const app = new App();
const customPanels = new CustomPanelStore();
const user = new UserStore();
const project = new ProjectStore();
const projects = new ProjectsStore();
const dialogs = new DialogStore();

export const StoreContext = createContext({
  app,
  customPanels,
  user,
  project,
  projects,
  dialogs,
});
