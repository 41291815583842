import { API, graphqlOperation, Auth } from "aws-amplify";

import { getUser } from "../../../graphql/queries";
import { createUser, updateUser } from "../../../graphql/mutations";
import { listSubscriptionOrders } from "../../../api/queriesMin";

export const checkForCurrentUser = async () => {
  try {
    let currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
    let userObj = {
      username: currentAuthenticatedUser.username,
      id: currentAuthenticatedUser.attributes.sub,
      email: currentAuthenticatedUser.attributes.email,
    };
    return userObj;
  } catch (error) {
    return false;
  }
};

export const checkDynamoForUser = async (user) => {
  let userReturnData;
  try {
    const checkForUser = await API.graphql(
      graphqlOperation(getUser, { id: user.id })
    );
    userReturnData = checkForUser.data.getUser;

    if (userReturnData === null) {
      let res = await createNewDynamoUser(user);
      return res;
    }

    return userReturnData;
  } catch (err) {
    return err;
  }
};

export const updateLastLogin = (user) => {
  try {
    const now = new Date().toISOString();
    API.graphql(
      graphqlOperation(updateUser, { input: { id: user, lastLogin: now } })
    );
  } catch (error) {
    console.error(error);
  }
};

export const createNewDynamoUser = async (user) => {
  try {
    const userToCreate = {
      // subscriptionTier: 0,
      // user: AWSDateTime,
      username: user.username,
      id: user.id,
      email: user.email,
    };
    let res = await API.graphql(
      graphqlOperation(createUser, { input: userToCreate })
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const isThisSubscriptionCurrent = (subscription) => {
  let start = new Date(subscription.startDate);
  let end = new Date(subscription.endDate);
  let now = new Date();
  return end > now && start < now;
};

export const querySubscriptions = async () => {
  try {
    let res = await API.graphql(graphqlOperation(listSubscriptionOrders));
    let data = res.data.listSubscriptionOrders.items;

    return data;
  } catch (e) {
    console.error(e);
    return e;
  }
};
