import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  screenName: {
    color: "primary.main",
  },
  screenMakeModel: {
    color: "primary.main",
  },
  listBox: {
    padding: "0px",
    color: "theme.palette.secondary.main",
  },
  listItem: {
    textAlign: "left",
    paddingBottom: "10px",
    paddingTop: "10px",
    paddingLeft: "15px",
  },
  bigBox: {
    padding: "5px",
    margin: "0px",
  },
  deleteIcon: {
    textAlign: "right",
    fontSize: "small",
  },
});
