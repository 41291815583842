import React from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";

import useStyles from "./ToolbarStyles";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

const DashboardToolbar = (props) => {
  const { heading } = props;
  const classes = useStyles();
  const history = useHistory();

  async function signOut() {
    try {
      await Auth.signOut();
      history.push("/");
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar variant="dense">
        <Grid
          container
          justifyContent="space-between"
          style={{ width: "1005" }}
        >
          <Typography variant="h6" noWrap style={{ color: "black" }}>
            {heading}
          </Typography>
          <Button
            onClick={signOut}
            style={{
              color: "white",
              marginLeft: 10,
              marginRight: 10,
              backgroundColor: "black",
            }}
          >
            Sign Out
          </Button>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default DashboardToolbar;
