import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import { BrowserRouter } from "react-router-dom";

import ScrollToTop from "./hooks/ScrollToTop";
import Router from "./components/Router/Router";
// import "antd/dist/antd.css";

import { MuiThemeProvider } from "@material-ui/core/styles";
import { theme } from "./utils/Theme";
import CssBaseline from "@material-ui/core/CssBaseline";

Amplify.configure(awsExports);

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline>
      <BrowserRouter>
        <ScrollToTop />
        <Router />
      </BrowserRouter>
    </CssBaseline>
  </MuiThemeProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
