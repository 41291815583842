import { useEffect, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { observer } from "mobx-react-lite";


import { listOwnerScreens } from "../graphql/queries";
import {
  onCreateOwnerScreen,
  onDeleteOwnerScreen,
  onUpdateOwnerScreen,
} from "../graphql/subscriptions";

import { StoreContext } from "../stores/store.context";

// When a user selects a new project it triggers a
// new subscription on the Screens Table, there is a
// check to see if there is a current user loggin in

const ScreenFetchAndSub = observer(() => {
  const { user, project, projects } = useContext(StoreContext);
  let owner = user.username;

  useEffect(() => {
    if (project.id) {
      fetchScreens();

      const createOwnerScreenListener = API.graphql(
        graphqlOperation(onCreateOwnerScreen, { owner: owner })
      ).subscribe({
        next: (screenData) => {
          const newScreen = screenData.value.data.onCreateOwnerScreen;
          if (newScreen.projectID === project.id) {
            project.addScreen(newScreen);
            const thisProject = projects.list.find(
              (p) => p.id === newScreen.projectID
            );
            thisProject.screens.items.push(newScreen);
            project.selectScreenEdit(newScreen);
          }
        },
      });

      const updateOwnerScreenListener = API.graphql(
        graphqlOperation(onUpdateOwnerScreen, { owner: owner })
      ).subscribe({
        next: (screenData) => {
          const newScreen = screenData.value.data.onUpdateOwnerScreen;
          if (newScreen.projectID === project.id) {
            project.removeScreen(newScreen.id);
            project.addScreen(newScreen);

            // *** I think this has something to do with the list of
            // screens in the dashboard, I don't think this needs to change
            // on a screen update ****

            let index = project.screenList.length
            project.selectScreenEdit(project.screenList[index - 1]);
          }
        },
      });

      const deleteOwnerScreenListener = API.graphql(
        graphqlOperation(onDeleteOwnerScreen, { owner: owner })
      ).subscribe({
        next: (screenData) => {
          const deleteOwnerScreen = screenData.value.data.onDeleteOwnerScreen;
          project.removeScreen(deleteOwnerScreen.id);
          const thisProject = projects.list.find(
            (p) => p.id === deleteOwnerScreen.projectID
          );
          let oldScreenList = thisProject.screens.items;
          let newScreenList = oldScreenList.filter(
            (screen) => screen.id !== deleteOwnerScreen.id
          );
          thisProject.screens.items = newScreenList;
        },
      });

      return () => {
        deleteOwnerScreenListener.unsubscribe();
        createOwnerScreenListener.unsubscribe();
        updateOwnerScreenListener.unsubscribe();
      };
    } else return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project.id]);

  // function calls graphQL to list User's screens
  async function fetchScreens() {
    try {
      const thisProject = project.id;
      const screenData = await API.graphql(
        graphqlOperation(listOwnerScreens, {
          filter: { projectID: { eq: thisProject } },
        })
      );
      const screens = screenData.data.listOwnerScreens.items;
      project.setScreenList(screens);
      project.setScreenList(screens);
    } catch (err) {
      console.error(err);
    }
  }
});

export default ScreenFetchAndSub;
