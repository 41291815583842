import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
  root: {},
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },

  title: {
    flexGrow: 1,
    fontWeight: 300,
    color: "white",
  },
  createProject: {
    flexGrow: 1,
    fontWeight: 300,
    color: "orange",
  },
  outerCard: {
    padding: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
    backgroundColor: theme.palette.background.main,
  },

  outerBoxStyle: {
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
    borderColor: "black",
  },
  newProjectBox: {
    position: "relative",
    backgroundColor: "lightgrey",
  },
  gridInfo: {
    padding: 15,
    backgroundColor: "#36342f",
    color: "white",
    "$:hover": {
      backgroundColor: "white",
    },
  },
  projectListContainer: {
    paddingLeft: 15,
    paddingTop: 15,
    paddingBottom: 15,
    paddingRight: 15,
  },
  newProject: {
    fontWeight: 300,
  },
  toolbar: theme.mixins.denseToolbar,
  navbar: {},
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  addIcon: {
    fontSize: 120,
    color: "black",
    opacity: 0.5,
  },
  actionArea: {
    transition:
      "opacity 100ms ease-in, margin 100ms ease-in, padding 75ms ease-in",
    "&:hover": {
      opacity: 0.9,
      padding: 5,
      paddingBottom: 0,
    },
  },
  focusHighlight: {
    color: "red",
  },
}));

export default useStyles;
