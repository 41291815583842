import "./styles.css";
import { useContext } from "react";
import { StoreContext } from "../../stores/store.context";
import { observer } from "mobx-react-lite";

import {
  ConnectOneOrMoreComputers,
  CreateYourOutput,
  RouteScreenDesign,
} from "./images/Illustrations";

export const HowWorkComputer = observer(() => {
  const { app } = useContext(StoreContext);
  return (
    <div
      id="howDoesItWork-container-computer"
      className="howDoesItWorkContainer"
    >
      <div
        id="fixedTab-computer"
        className="fixedTab"
        style={{
          background: app.color.grey.dark,
        }}
      >
        From the Computer
      </div>

      {/* Container for Cards */}
      {/* Container for Cards */}
      <div
        className="containerForCards-computer"
        style={{
          background: app.color.grey.dark,
        }}
      >
        {/* Connect One or More Comps */}
        {/* Connect One or More Comps */}
        <div
          id=""
          className="howDoesItWork-card-body-computer"
          style={{
            background: app.color.white.two,
          }}
        >
          <div className="howDoesItWork-card-image-computer" style={{}}>
            <ConnectOneOrMoreComputers w={300} />
          </div>
          <div className="howDoesItWork-card-words-computer" style={{}}>
            Connect one or more computers to the screens' processors, mimicking
            the flow of your content delivery hardware.
          </div>
        </div>

        {/* Create Your Output */}
        {/* Create Your Output */}
        <div
          className="howDoesItWork-card-body-computer-reverse"
          style={{
            background: app.color.white.two,
          }}
        >
          <div className="howDoesItWork-card-image-computer" style={{}}>
            <CreateYourOutput w={300} />
          </div>
          <div className="howDoesItWork-card-words-computer-reverse" style={{}}>
            From the project dashboard create a canvas, adding outputs
            accordingly. Map each screen to its position in the canvas
          </div>
        </div>

        {/* Create Your Output */}
        {/* Create Your Output */}
        <div
          id=""
          className="howDoesItWork-card-body-computer"
          style={{
            background: app.color.white.two,
          }}
        >
          <div className="howDoesItWork-card-image-computer" style={{}}>
            <RouteScreenDesign w={300} />
          </div>
          <div className="howDoesItWork-card-words-computer" style={{}}>
            Route the screen design into the video signal chain through the
            disaply tab in the browser
          </div>
        </div>
      </div>
    </div>
  );
});
