import { useState, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";

import { observer } from "mobx-react-lite";

import { deleteOwnerScreen, deleteOutput } from "../../../../graphql/mutations";

import { StoreContext } from "../../../../stores/store.context";

import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";

const DeleteDialog = observer((props) => {
  const { dialogs, app } = useContext(StoreContext);
  const [loading, setIsLoading] = useState(false);

  const deleteObj = async (id) => {
    setIsLoading(true);
    try {
      const obj = { id: id };
      if (dialogs.deleteDialogType === "screen") {
        await API.graphql(graphqlOperation(deleteOwnerScreen, { input: obj }));
      }
      if (dialogs.deleteDialogType === "output") {
        await API.graphql(graphqlOperation(deleteOutput, { input: obj }));
      }
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
    app.setSelectedEditValue(0)
    dialogs.setIsDeleteDialogOpen(false);
    setIsLoading(false);
  };

  return (
    <Dialog open={dialogs.isDeleteDialogOpen}>
      <Paper style={{ width: 400, height: 300 }}>
        <Grid
          justifyContent="center"
          alignItems="center"
          container
          style={{ width: "100%", height: "100%", backgroundColor: "" }}
        >
          <Grid
            justifyContent="center"
            alignItems="center"
            container
            style={{ width: 250, height: 200, backgroundColor: "" }}
          >
            <Typography align="center">
              Are you sure you want to delete this {dialogs.deleteDialogType}?
            </Typography>
            <Typography align="center">
              This action cannot be undone.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid
              justifyContent="center"
              alignItems="center"
              container
              style={{ width: "100%", height: "100%", backgroundColor: "" }}
            >
              <Button
                variant="contained"
                color="secondary"
                style={{ width: 95, margin: 10, backgroudColor: "black" }}
                onClick={() => dialogs.setIsDeleteDialogOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                onClick={() => deleteObj(dialogs.idToDelete)}
                style={{ width: 95, margin: 10, color: "red" }}
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  <Typography>Delete</Typography>
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Dialog>
  );
});

export default DeleteDialog;
