import { Link } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import "./svg.css";

import { useStyles } from "./HeaderStyle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

function HeaderMobile() {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ backgroundColor: "", height: "100%", width: "100%" }}
    >
      {/* <Box
        component={Link}
        to="/"
        style={{
          maxHeight: 56,
        }}
      >
        <img
          src="/images/SVG/LeadLED_Brandmark_White-min.svg"
          alt="LED Wall"
          style={{ height: 45, color: "orange" }}
        />
      </Box> */}
      <Typography
        component={Link}
        to="/"
        color="secondary"
        variant="h6"
        style={{
          fontSize: 22,
          backgroundColor: "",
        }}
        className={classes.logoMobile}
      >
        LEAD LED
      </Typography>
    </Grid>
  );
}

export default HeaderMobile;
