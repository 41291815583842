import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  paperBackground: {
    width: "100%",
    // height: "100vh",
  },
  toolbar: theme.mixins.denseToolbar,
  appBar: {
    backgroundColor: "black",
    elevation: 10,
  },
  appBarButtons: {
    marginLeft: "35px",
    fontWeight: 600,
    color: "white",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  leadled: {
    marginLeft: 35,
    fontWeight: 700,
    fontFamily: "Chivo",
    color: "white",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  coolSlogan: {
    fontWeight: 800,
    fontFamily: "IBM Plex Sans",
    fontSize: "110px",
  },
  we: {
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  divider: {
    background: "white",
    marginLeft: "40px",
  },
  footer: {
    backgroundColor: "black",
    width: "100%",
  },
  footerType: {
    fontWeight: 500,
    color: "white",
  },
  trademark: {
    color: "white",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  header: {
    color: "white",
    fontWeight: 700,
    fontSize: 15,
  },
  link: {
    color: "white",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  button: {
    paddingTop: "12px",
    paddingBottom: "12px",
    borderRadius: 35,
  },
  buttonPaper: {
    borderRadius: 35,
  },
  footerColumnHeader: {
    paddingBottom: "20px",
  },
  footerColumnItem: {
    paddingBottom: "20px",
  },
}));
