import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  sidebar: {
    width: drawerWidth,
    // flexShrink: 0,
    bgColor: theme.palette.background.default,
    paddingLeft: "15px",
  },
  newScreenButton: {
    textAlign: "center",
    margin: "auto",
    // paddingRight: "5px",
    marginTop: 10,
    width: "95%",
    borderRadius: 5,
    padding: "10px",
  },
  openDisplayTab: {
    marginTop: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
  },
  marked: {
    fontSize: 22,
    fontStyle: "Chivo",
    // fontWeight: 800,
    paddingBottom: 10,
  },
}));

export default useStyles;
