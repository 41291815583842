import { useState, useEffect, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { observer } from "mobx-react-lite";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

import { StoreContext } from "../../stores/store.context";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#fff",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "20px",
      "::placeholder": {
        color: "#fff",
      },
      "::focus": {
        color: "#fff",
      },
    },
    invalid: {
      color: "#fff",
      iconColor: "#fa755a",
    },
  },
};

const CheckoutForm = observer(() => {
  const { user } = useContext(StoreContext)
  const [orderDetails, setOrderDetails] = useState({
    startDate: null,
    endDate: null,
    tier: null,
    purchaseAmount: 0,
    token: null,
    username: user.username,
    subscriptionOrderUserId: user.id,
  });
  const [error, setError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (orderDetails.token) {
      checkout(orderDetails);
      //clearCart();
      //history.push("/");
    }
  }, [orderDetails]);

  // Handle real-time validation errors from the card Element.
  const handleChange = (event) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };

  // Handle form submission.
  const handleSubmit = async (event) => {
    event.preventDefault();
    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      // Inform the user if there was an error.
      setError(result.error.message);
    } else {
      setError(null);
      // Send the token to your server.
      const token = result.token;
      setOrderDetails({ ...orderDetails, token: token.id });
    }
  };

  const checkout = async (orderDetails) => {
    try {
      // await API.graphql(
      //   graphqlOperation(checkoutAndSubscribe, { input: orderDetails })
      // );
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Grid
        item
        alignItems="center"
        justifyContent="center"
        style={{
          width: 400,
          height: 100,
          backgroundColor: "",
          paddingTop: 15,
          padding: 15,
          borderRadius: 5,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
      >
        <Typography
          align="center"
          variant="h2"
          style={{ fontFamily: "IBM Plex Sans" }}
        >
          Checkout
        </Typography>
      </Grid>

      <Divider />

      <Grid
        item
        alignItems="center"
        justifyContent="center"
        style={{
          width: 400,
          height: 100,
          backgroundColor: "#030303",
          paddingTop: 15,
          padding: 15,
          borderRadius: 5,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          marginTop: 15,
        }}
      >
        <CardElement
          id="stripe-element"
          options={CARD_ELEMENT_OPTIONS}
          onChange={handleChange}
        />
        <Button
          variant="contained"
          onClick={handleSubmit}
          style={{ marginTop: 15, width: "100%" }}
        >
          Submit Payment
        </Button>
      </Grid>
    </>
  );
});

export default CheckoutForm;
