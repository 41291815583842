import { useEffect, useContext } from "react";
import { observer } from "mobx-react-lite";

import { StoreContext } from "../../../stores/store.context";

export const Coordinates = observer(() => {
  const { project } = useContext(StoreContext);

  return (
    <div>
      {project.screenList.map(
        (screen, index) =>
          screen.panel && <Screen screen={screen} key={index} />
      )}
    </div>
  );
});

const Screen = observer((props) => {
  const { project, user } = useContext(StoreContext);
  const { screen } = props;
  const output = user.outputPageOutput;
  const panel = screen.panel ? screen.panel : screen.userPanel;

  const screenWidth = screen.panel.pixelWidth * screen.panelColumns;
  const screenHeight = screen.panel.pixelHeight * screen.panelRows;
  let startX = screen.startX - output.startX;
  let startY = screen.startY - output.startY;

  const canvasId = `${screen.id}coordinates`;

  useEffect(() => {
    const canvas = document.getElementById(canvasId);
    const ctx = canvas.getContext("2d");

    canvas.style.left = `${startX}px`;
    canvas.style.top = `${startY}px`;
    canvas.width = screenWidth;
    canvas.height = screenHeight;

    ctx.fillStyle = "rgba(0, 0, 0, 0)";
    ctx.rect(0, 0, screenWidth, screenHeight);
    ctx.clearRect(0, 0, screenWidth, screenHeight);
    ctx.beginPath();

    const drawCoordinates = () => {
      if (!project.isLabelOn) return;

      let panelArray = [];

      for (let i = 0; i < screen.panelRows; i++) {
        for (let j = 0; j < screen.panelColumns; j++) {
          let _panel = {};
          _panel.id = `${j}/${i}`;
          _panel.x = j + 1;
          _panel.y = i + 1;
          _panel.xpos = panel.pixelWidth * j;
          _panel.ypos = panel.pixelHeight * i;
          panelArray.push(_panel);
        }
      }

      panelArray.forEach((p) => {
        let textString = `${p.x}-${p.y}`;

        ctx.font = "50px Helvetica";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillStyle = "white";
        ctx.strokeStyle = "black";
        ctx.lineWidth = 2;

        // ctx.font = '80px Sans-serif';
        // ctx.strokeStyle = 'black';
        // ctx.lineWidth = 8;
        // ctx.strokeText(text, x, y);
        // ctx.fillStyle = 'white';
        // ctx.fillText(text, x, y);

        ctx.fillText(
          textString,
          p.xpos + panel.pixelWidth / 2,
          p.ypos + panel.pixelHeight / 2,
          panel.pixelWidth
        );
        ctx.strokeText(
          textString,
          p.xpos + panel.pixelWidth / 2,
          p.ypos + panel.pixelHeight / 2,
          panel.pixelWidth
        );
      });
    };

    requestAnimationFrame(drawCoordinates);
  }, [project.isLabelOn]);

  return (
    <div
      style={{
        transformOrigin: `${startX}px ${startY}px`,
        transform: `rotate(${screen.rotation}deg)`,
      }}
    >
      <canvas id={canvasId} style={{ position: "absolute" }}></canvas>
    </div>
  );
});
