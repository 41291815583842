import { makeAutoObservable } from "mobx";

export class App {
  betaTestingDialogIsOpen = false;
  newPanelDialogIsOpen = false
  selectedEditValue = 0
  isOutputEditMode = false;
  isScreenEditMode = false;
  outputPageLoader = false
  outputPageLoaderPost = false
  initProjectPageRender = false
  color = {
    white1: "#faf8ef",
    white: {
      one: "#faf8ef",
      two: "#FCFAF5",
    },
    grey: {
      dark: "#202020",
      medium: "#303030",
      light: "#d4d3d5",
      lightest: "#f4f3f5",
    },
  }

  constructor() {
    makeAutoObservable(this);
  }

  setBetaTestingDialogIsOpen(boolean) {
    this.betaTestingDialogIsOpen = boolean;
  }

  setIsOutputEditMode(boolean) {
    this.isOutputEditMode = boolean;
  }

  setIsScreenEditMode(boolean) {
    this.isScreenEditMode = boolean;
  }

  setSelectedEditValue(value) {
    this.selectedEditValue = value;
  }

  setNewProjectDialogIsOpen(boolean) {
    this.newProjectDialogIsOpen = boolean;
  }

  setNewPanelDialogIsOpen(boolean) {
    this.newPanelDialogIsOpen = boolean;
  }

  setOutputPageLoader(boolean) {
    this.outputPageLoader = boolean;
  }

  setOutputPageLoaderPost(boolean) {
    this.outputPageLoaderPost = boolean;
  }

  setInitProjectPageRender(boolean) {
    this.initProjectPageRender = boolean;
  }

}
