export const pressedModulesbyProjectIdCustom = /* GraphQL */ `
  query PressedModulesbyProjectId(
    $projectID: String
    $sortDirection: ModelSortDirection
    $filter: ModelPressedModuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PressedModulesbyProjectID(
      projectID: $projectID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        panelX
        panelY
        moduleX
        moduleY
        moduleState
        index
        ownerScreenID
        projectID
        ownerScreen {
          id
          panelColumns
          panelRows
          panelID
          projectID
          startX
          startY
          ownerScreenBackgroundContent
          name
          color
          zIndex
          createdAt
          updatedAt
          owner
          panel {
            id
            make
            model
            pixelWidth
            pixelHeight
            moduleColumns
            moduleRows
            weight
            maxWatt
            averageWatt
            frameSubSpecies
            sizeSubSpecies
            createdAt
            updatedAt
          }
        }
        isStarred
        isReplaced
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;

export const listSubscriptionOrders = /* GraphQL */ `
  query ListSubscriptionOrders(
    $filter: ModelSubscriptionOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscriptionOrders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        startDate
        endDate
        tier
        autoRenewal
        user {
          id
          username
        }
        purchaseAmount
        userId
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        projectBackgroundContent
        projectBackgroundContentPlay
        projectBackgroundContentGrid
        projectBackgroundContentLabels
        projectWidth
        projectHeight
        screens {
          items {
            id
            panelColumns
            panelRows
            panelID
            projectID
            startX
            startY
            ownerScreenBackgroundContent
            name
            color
            zIndex
            project {
              id
              name
              projectBackgroundContent
              projectBackgroundContentPlay
              projectBackgroundContentGrid
              projectBackgroundContentLabels
              projectWidth
              projectHeight

              createdAt
              updatedAt
              owner
            }
            panel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies

              createdAt
              updatedAt
              owner
            }
            pressedPanel {
              nextToken
              startedAt
            }
            pressedModule {
              nextToken
              startedAt
            }

            createdAt
            updatedAt
            owner
          }
          nextToken
          startedAt
        }
        creator {
          id
          username
          currentSubscription {
            items {
              id
              startDate
              endDate
              tier
              autoRenewal
              purchaseAmount
              currency
              userId

              createdAt
              updatedAt
              owner
            }
            nextToken
            startedAt
          }
          subscriptions {
            items {
              id
              startDate
              endDate
              tier
              autoRenewal
              purchaseAmount
              currency
              userId

              createdAt
              updatedAt
              owner
            }
            nextToken
            startedAt
          }
          availableProjects {
            items {
              id
              name
              projectBackgroundContent
              projectBackgroundContentPlay
              projectBackgroundContentGrid
              projectBackgroundContentLabels
              projectWidth
              projectHeight

              createdAt
              updatedAt
              owner
            }
            nextToken
            startedAt
          }
          email

          createdAt
          updatedAt
          owner
        }
        outputs {
          items {
            id
            projectID
            width
            height
            startX
            startY
            number

            createdAt
            updatedAt
            owner
          }
          nextToken
          startedAt
        }

        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
