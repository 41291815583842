import { useEffect, useState, useCallback, useContext } from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";

import { StoreContext } from "../../stores/store.context";

import { useStyles } from "./SignInStyle";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core//Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import Confirm from "./Confirm.js";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

function SignIn() {
  const classes = useStyles();
  const { app } = useContext(StoreContext);

  let initialState = {
    username: "",
    password: "",
    passwordHidden: "",
  };

  const [notLoading, setNotLoading] = useState(true);
  const [passwordError, setPasswordError] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [formState, setFormState] = useState(initialState);
  const [isConfirmed, setIsConfirmed] = useState(true);

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value });
  }

  async function signIn() {
    setNotLoading(false);
    setButtonDisabled(true);
    let username = formState.username;
    let password = formState.password;
    try {
      const user = await Auth.signIn(username, password);
    } catch (error) {
      console.log(error);
      if (error.code === "UserNotConfirmedException") {
        setIsConfirmed(false);
      }
      setNotLoading(true);
      setInput("password", "");
      setPasswordError(error.message);
      setButtonDisabled(false);
    }
  }

  const handleKey = useCallback(
    (e) => {
      if (e.code === "Enter") {
        signIn();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formState]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKey);

    return () => {
      document.removeEventListener("keydown", handleKey);
    };
  }, [handleKey]);

  return (
    <div>
      {isConfirmed ? (
        <>
          <Header />
          <Grid
            container
            alignItems="center"
            justifyContent="space-around"
            style={{
              height: "100vh",
              flexDirection: "column",
              display: "flex",
              backgroundColor: app.color.white1,
            }}
          >
            <Paper
              elevation={6}
              style={{ backgroundColor: "white", padding: 43, paddingTop: 50 }}
            >
              <Grid container style={{ backgroundColor: "white", width: 400 }}>
                <Grid
                  item
                  xs={12}
                  style={{ backgroundColor: "white", marginBottom: 5 }}
                >
                  <Typography
                    style={{ fontWeight: 800 }}
                    className={classes.title}
                    variant="h6"
                  >
                    Sign In
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ backgroundColor: "white", paddingTop: 10 }}
                >
                  <Typography className={classes.fieldTitle}>
                    Username
                  </Typography>
                  <TextField
                    id="username"
                    label="Username"
                    variant="outlined"
                    fullWidth
                    onChange={(event) =>
                      parseInt(setInput("username", event.target.value))
                    }
                    value={formState.username}
                    margin="dense"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ backgroundColor: "white", paddingTop: 20 }}
                >
                  <Typography className={classes.fieldTitle}>
                    Password
                  </Typography>
                  <TextField
                    id="password"
                    label="Password"
                    variant="outlined"
                    type="password"
                    fullWidth
                    onChange={(event) =>
                      parseInt(setInput("password", event.target.value))
                    }
                    value={formState.password}
                    margin="dense"
                  />
                  {/* <Typography align="right" className={classes.reset}>
                      Forgot Password
                    </Typography> */}
                  <Typography align="left" style={{ color: "red" }}>
                    {passwordError}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ backgroundColor: "white", paddingTop: 25 }}
                >
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Typography className={classes.noAccount}>
                        Don't have an account?
                      </Typography>
                      <Typography
                        className={classes.noAccount}
                        component={Link}
                        to="/signUp"
                      >
                        Sign Up Here
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={signIn}
                        disabled={buttonDisabled}
                        style={{
                          backgroundColor: "black",
                          color: "white",
                          padding: 20,
                          paddingTop: 10,
                          paddingBottom: 10,
                        }}
                      >
                        {notLoading ? (
                          <Typography>Sign In</Typography>
                        ) : (
                          <CircularProgress
                            size={24}
                            style={{ marginLeft: 20, marginRight: 20 }}
                          />
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Footer />
        </>
      ) : (
        <Confirm />
      )}
    </div>
  );
}

export default SignIn;
