import { useTheme } from "@material-ui/core/styles";
import ContactWeb from "./ContactWeb";
import ContactMobile from "./ContactMobile";

import useMediaQuery from "@material-ui/core/useMediaQuery";

function Contact() {
  const theme = useTheme();
  const isWeb = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <>
      {isMobile && <ContactMobile />}
      {isWeb && <ContactWeb />}
    </>
  );
}

export default Contact;
