import React from "react";
import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";

import Grid from "@material-ui/core/Grid";

const Checkout = () => {
  const stripePromise = loadStripe(
    "pk_test_51JeJ9eBFjZ4XMqPG8jRWBkPdIeZ7RIPAFw8icScqdaGL9qR2pv9tX70Tnfi7UJpBypK0FUZQtFz0dgkOePouORs5009iwh42Yi"
  );

  return (
    <></>
    // <Grid
    //   id="outer"
    //   container
    //   direction="column"
    //   alignContent="center"
    //   style={{ width: "100vw", height: "100vh", backgroundColor: "" }}
    // >
    //   <Elements stripe={stripePromise}>
    //     <CheckoutForm />
    //   </Elements>
    // </Grid>
  );
};

export default Checkout;
