import { useContext } from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { StoreContext } from "../../stores/store.context";

import { useStyles } from "./Tk421Styles";
import { useTheme } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";

import "./styles.css";

export const BetaTesting = observer(() => {
  const { app } = useContext(StoreContext);
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div
      id="butWhyContainer"
      style={{
        width: "90%",
        background: app.color.grey.dark,
        marginInline: "auto",
        paddingTop: 30,
        borderRadius: 8,
        boxShadow: "2px 2px 3px #696969",
        maxWidth: 1000,
        borderBottomWidth: 2,
        marginTop: 50,
      }}
    >
      <div
        style={{
          width: "100%",
          paddingInline: 30,
        }}
      >
        <div
          style={{
            background: "",
            marginBottom: 30,
            maxWidth: 700,
            marginInline: "auto",
          }}
        >
          <div
            style={{
              fontSize: 26,
              fontWeight: 900,
              fontFamily: "Chivo ",
              color: theme.palette.primary.main,
              alignText: "center",
            }}
          >
            Beta Testing
          </div>
        </div>
      </div>
      <div
        id="butWhyBottom"
        style={{
          background: "#FCFAF5",
          borderBottomRightRadius: 8,
          borderBottomLeftRadius: 8,
          borderStyle: "solid",
          borderTopWidth: 0,
          paddingInline: 30,
        }}
      >
        <div style={{ marginInline: "auto", maxWidth: 700 }}>
          <Typography
            className={classes.butWhyQuestion}
            style={{ paddingBlock: 60 }}
          >
            Tk-421 is currently in testing. If you are interested in becoming a
            tester, please reach out to us!
          </Typography>
        </div>
      </div>
      <Grid
        container
        justifyContent="center"
        style={{
          width: "100%",
          paddingTop: 30,
          pointerEvents: "auto",
          cursor: "auto",
        }}
        spacing={0}
      >
        <Button
          variant="contained"
          className={classes.button}
          style={{ marginBottom: 30 }}
          color="primary"
          size="large"
          onClick={() => {
            app.setBetaTestingDialogIsOpen(true);
          }}
        >
          beta testing
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          style={{ marginBottom: 30 }}
          color="primary"
          size="large"
          component={Link}
          to="/contact"
        >
          contact us
        </Button>
      </Grid>
    </div>
  );
});
