import { createTheme } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";
import blue from "@material-ui/core/colors/blue";

export const theme = createTheme({
  mixins: {
    denseToolbar: {
      minHeight: 48,
    },
  },
  palette: {
    primary: {
      main: "#FDAD0D",
    },
    secondary: {
      main: "#000000",
    },
    info: {
      main: blue[300],
      secondary: grey[200],
    },
    music: {
      main: green[600],
    },
    ourYellowHell: {
      main: "#FDAD0D",
    },
    background: {
      main: grey[200],
    },
  },
  typography: {
    fontFamily: [
      "IBM Plex Sans",
      "IBM Plex Mono",
      "Comfortaa",
      "Nunito Sans",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      "Chivo",
    ].join(","),
  },
});
