import { useState, useContext, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { observer } from "mobx-react-lite";

import TextField from "@material-ui/core/TextField";
import { CircularProgress } from "@material-ui/core";

import { updateUserPanel } from "../../graphql/mutations";
import { StoreContext } from "../../stores/store.context";

import "./form-styles.css";
import { DeleteDialog } from "./delete.dialog";

const initialFormState = {
  make: "",
  model: "",
  pixelWidth: 1,
  pixelHeight: 1,
  moduleRows: 1,
  moduleColumns: 1,
  weight: 1,
  maxWatt: 10,
  averageWatt: 10,
};

export const EditPanelDialog = observer(() => {
  const { customPanels } = useContext(StoreContext);
  const [isLoading, setIsLoading] = useState(false);
  const [formState, setFormState] = useState(initialFormState);

  useEffect(() => {
    let panel = customPanels.editPanelDialogSelectedPanel;
    if (panel.pixelWidth) {
      let panelToEdit = {
        make: panel.make,
        model: panel.model,
        pixelWidth: panel.pixelWidth,
        pixelHeight: panel.pixelHeight,
        moduleRows: panel.moduleRows,
        moduleColumns: panel.moduleColumns,
        weight: panel.weight,
        maxWatt: panel.maxWatt,
        averageWatt: panel.averageWatt,
      };
      setFormState(panelToEdit);
    }
  }, [customPanels.editPanelDialogSelectedPanel]);

  const handleClose = () => {
    customPanels.setEditPanelDialogIsOpen(false);
  };

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value });
  }

  async function updateScreen() {
    setIsLoading(true);
    try {
      if (
        !formState.make ||
        !formState.model ||
        !formState.pixelWidth ||
        !formState.pixelHeight ||
        !formState.moduleRows ||
        !formState.moduleColumns
      ) {
        return;
      }
      const newPanel = {
        ...formState,
        id: customPanels.editPanelDialogSelectedPanel.id,
      };
      let res = await API.graphql(
        graphqlOperation(updateUserPanel, { input: newPanel })
      );
      setFormState(initialFormState);
    } catch (err) {
      console.error(err);
    }
    handleClose();
    setIsLoading(false);
  }

  return (
    <div
      className="modal-root"
      style={{ display: customPanels.editPanelDialogIsOpen ? "block" : "none" }}
    >
      <div className="form-container">
        <div className="form-inputs">
          {customPanels.deleteDialogIsOpen ? (
            <DeleteDialog />
          ) : (
            <>
              <div className="form-input">
                <TextField
                  autoFocus
                  onChange={(event) => setInput("make", event.target.value)}
                  value={formState.make}
                  margin="dense"
                  label="Make"
                  variant="outlined"
                  type="string"
                  fullWidth
                />
              </div>
              <div className="form-input">
                <TextField
                  className="form-input"
                  onChange={(event) => setInput("model", event.target.value)}
                  value={formState.model}
                  label="Model"
                  variant="outlined"
                  margin="dense"
                  type="string"
                  fullWidth
                />
              </div>
              <div className="form-input">
                <TextField
                  className="form-input"
                  onChange={(event) =>
                    setInput("pixelWidth", event.target.value)
                  }
                  value={formState.pixelWidth || undefined}
                  label="Pixels Wide"
                  variant="outlined"
                  margin="dense"
                  type="number"
                  fullWidth
                />
              </div>
              <div className="form-input">
                <TextField
                  className="form-input"
                  onChange={(event) =>
                    setInput("pixelHeight", event.target.value)
                  }
                  value={formState.pixelHeight || undefined}
                  label="Pixels Tall"
                  variant="outlined"
                  margin="dense"
                  type="number"
                  fullWidth
                />
              </div>
              <div className="form-input">
                <TextField
                  className="form-input"
                  onChange={(event) =>
                    setInput("moduleColumns", event.target.value)
                  }
                  value={formState.moduleColumns || undefined}
                  label="Modules Wide"
                  variant="outlined"
                  type="number"
                  margin="dense"
                  fullWidth
                />
              </div>
              <div className="form-input">
                <TextField
                  className="form-input"
                  onChange={(event) =>
                    setInput("moduleRows", event.target.value)
                  }
                  value={formState.moduleRows || undefined}
                  label="Modules Tall"
                  variant="outlined"
                  type="number"
                  margin="dense"
                  fullWidth
                />
              </div>
              <div className="form-input">
                <TextField
                  className="form-input"
                  onChange={(event) => setInput("weight", event.target.value)}
                  value={formState.weight || undefined}
                  label="Weight (kg)"
                  variant="outlined"
                  type="number"
                  margin="dense"
                  fullWidth
                />
              </div>
              <div className="form-input">
                <TextField
                  className="form-input"
                  onChange={(event) => setInput("maxWatt", event.target.value)}
                  value={formState.maxWatt || undefined}
                  label="Max Watt"
                  variant="outlined"
                  type="number"
                  margin="dense"
                  fullWidth
                />
              </div>
              <div className="form-input">
                <TextField
                  className="form-input"
                  onChange={(event) =>
                    setInput("averageWatt", event.target.value)
                  }
                  value={formState.averageWatt || undefined}
                  label="Average Watt"
                  variant="outlined"
                  type="number"
                  margin="dense"
                  fullWidth
                />
              </div>

              <div className="button-container">
                <div
                  className="delete-button cancel"
                  onClick={() => customPanels.setEditPanelDialogIsOpen(false)}
                  color="primary"
                >
                  CANCEL
                </div>
                <div
                  className="delete-button delete"
                  onClick={() => customPanels.setDeleteDialogIsOpen(true)}
                >
                  DELETE
                </div>
                <div
                  className="delete-button"
                  onClick={updateScreen}
                  color="primary"
                >
                  {isLoading ? <CircularProgress size={18} /> : "SUBMIT"}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
});
