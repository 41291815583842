import { useContext } from "react";
import { StoreContext } from "../../stores/store.context";
import { observer } from "mobx-react-lite";

import { useStyles } from "./Tk421Styles";

import {
  MatrixScreenMapped,
  RemotelyTogglePanels,
  CreateALog,
} from "./images/Illustrations";

import "./styles.css";

export const HowWorkApp = observer(() => {
  const { app } = useContext(StoreContext);
  return (
    <div id="howDoesItWork-container-app" className="howDoesItWorkContainer">
      {/* Container for Cards */}
      {/* Container for Cards */}
      <div
        className="containerForCards-app"
        style={{
          background: app.color.grey.dark,
          //   padding: 4,
        }}
      >
        {/* Connect One or More Comps */}
        {/* Connect One or More Comps */}
        <div
          id=""
          className="howDoesItWork-card-body-computer-reverse"
          style={{
            background: app.color.white.two,
          }}
        >
          <div className="howDoesItWork-card-image-computer" style={{}}>
            <MatrixScreenMapped w={300} />
          </div>
          <div className="howDoesItWork-card-words-computer-reverse" style={{}}>
            A matrix of each LED screen is mapped to the mobile app. Each screen
            is selectable, presenting a grid of panels and modules.
          </div>
        </div>

        {/* Create Your Output */}
        {/* Create Your Output */}
        <div
          id=""
          className="howDoesItWork-card-body-computer"
          style={{
            background: app.color.white.two,
          }}
        >
          <div className="howDoesItWork-card-image-computer" style={{}}>
            <RemotelyTogglePanels w={300} />
          </div>
          <div className="howDoesItWork-card-words-computer" style={{}}>
            Panels and modules become buttons, allowing the user to toggle on
            and off by sending black content to the screen. Adjust overall
            screen test patterns, view each panel's grid ID, mark modules and
            panels in red that is clearly seen on the screen.
          </div>
        </div>

        {/* Create Your Output */}
        {/* Create Your Output */}
        <div
          id=""
          className="howDoesItWork-card-body-computer-reverse"
          style={{
            background: app.color.white.two,
          }}
        >
          <div className="howDoesItWork-card-image-computer" style={{}}>
            <CreateALog w={300} />
          </div>
          <div className="howDoesItWork-card-words-computer-reverse" style={{}}>
            Create a log to track the state of your screen. Before getting to
            height, the technician will have a list indicating exactly what
            parts are needed to make the repair, saving travel time. This log
            persists over time, tracking potential hotspots on the screen that
            may indicate hidden issues.
          </div>
        </div>
      </div>
      <div
        id="fixedTab-app"
        className="fixedTab"
        style={{
          background: app.color.grey.dark,
        }}
      >
        From the App
      </div>
    </div>
  );
});
