import { useContext } from "react";
import { observer } from "mobx-react-lite";

import DashboardToolbar from "../../components/Toolbar/Toolbar";
import { createSubscription } from "./CreateSubscription";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import { StoreContext } from "../../stores/store.context";

const AccountPage = observer(() => {
  const { user } = useContext(StoreContext);
  let username = user.username;
  let id = user.id;

  const createShort = () => {
    createSubscription(id, username, 3600);
  };
  const createLong = () => {
    createSubscription(id, username, 15);
  };

  return (
    <Grid
      id="outer"
      container
      style={{ width: "100vw", height: "100vh", backgroundColor: "yellow" }}
    >
      <DashboardToolbar heading="Settings" />
      <Grid
        id="inner"
        container
        justifyContent="center"
        alignItems="center"
        style={{
          marginLeft: 220,
          width: `calc(100% - 220px)`,
          height: "100vh",
          backgroundColor: "",
        }}
      >
        <Button onClick={createShort} variant="contained">
          short subscription
        </Button>
        <Button onClick={createLong} variant="contained">
          long subscription
        </Button>
      </Grid>
    </Grid>
  );
});

export default AccountPage;
