import { useEffect, useContext } from "react";
import { observer } from "mobx-react-lite";

import { StoreContext } from "../../../stores/store.context";

export const PressedPanels = () => {
  const { project } = useContext(StoreContext);
  const screenList = project.screenList;

  return (
    <div className="output-container">
      {screenList.map(
        (screen, i) => screen.panel && <Screen screen={screen} key={i} />
      )}
    </div>
  );
};

const Screen = observer((props) => {
  const { project, user } = useContext(StoreContext);

  const { screen } = props;
  const output = user.outputPageOutput;
  const panel = screen.panel;

  const screenWidth = panel.pixelWidth * screen.panelColumns;
  const screenHeight = panel.pixelHeight * screen.panelRows;
  let startX = screen.startX - output.startX;
  let startY = screen.startY - output.startY;

  const canvasId = `${screen.id}pressedpanels`;

  useEffect(() => {
    const canvas = document.getElementById(canvasId);
    canvas.style.left = `${startX}px`;
    canvas.style.top = `${startY}px`;
    canvas.width = screenWidth;
    canvas.height = screenHeight;
    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "rgba(0, 0, 0, 0)";
    ctx.fillRect(0, 0, screenWidth, screenHeight);
    ctx.clearRect(0, 0, screenWidth, screenHeight);
    ctx.beginPath();

    let screenOffPressedPanels = project.pressedPanels.filter(
      (panel) =>
        panel.ownerScreen?.id === screen.id && panel.panelState === "panelOff"
    );

    const drawPanels = () => {
      screenOffPressedPanels.forEach((p) => {
        let x = p.x * panel.pixelWidth;
        let y = p.y * panel.pixelHeight;
        ctx.fillStyle = "rgba(0,0,0,1)";
        ctx.fillRect(x, y, panel.pixelWidth, panel.pixelHeight);
      });
    };

    requestAnimationFrame(drawPanels);
  }, [project.pressedPanels]);

  return (
    <div
      style={{
        transformOrigin: `${startX}px ${startY}px`,
        transform: `rotate(${screen.rotation}deg)`,
      }}
    >
      <canvas id={canvasId} style={{ position: "absolute" }}></canvas>
    </div>
  );
});
