import { memo, useEffect, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";

import { listPanels, listUserPanels } from "../graphql/queries";
import {
  onCreatePanel,
  onCreateUserPanel,
  onUpdateUserPanel,
  onDeleteUserPanel,
} from "../graphql/subscriptions";

import { StoreContext } from "../stores/store.context";

function FetchPanels(props) {
  const { user } = useContext(StoreContext);
  const owner = user.username;

  useEffect(() => {
    fetchPanels();
    fetchUserPanels();
  }, []);

  async function fetchPanels() {
    try {
      const panelList = await API.graphql(graphqlOperation(listPanels));
      const panelsReturned = panelList.data.listPanels.items;
      panelsReturned.forEach((panel) => (panel.isUserPanel = false));
      user.addPanelTypes(panelsReturned);
    } catch (err) {
      console.error(err);
    }
  }

  const fetchUserPanels = async () => {
    try {
      const panelList = await API.graphql(
        graphqlOperation(listUserPanels, { owner: owner })
      );
      const panelsReturned = panelList.data.listUserPanels.items;
      panelsReturned.forEach((panel) => (panel.isUserPanel = true));
      user.addPanelTypes(panelsReturned);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const createPanelListener = API.graphql(
      graphqlOperation(onCreatePanel, { owner: owner })
    ).subscribe({
      next: (panelData) => {
        const newPanel = panelData.value.data.onCreatePanel;
        user.addSinglePanelType(newPanel);
      },
      error: (err) => {
        console.error(err);
      },
    });

    const createUserPanelListener = API.graphql(
      graphqlOperation(onCreateUserPanel, { owner: owner })
    ).subscribe({
      next: (panelData) => {
        const newPanel = panelData.value.data.onCreateUserPanel;
        newPanel.isUserPanel = true;
        user.addSinglePanelType(newPanel);
      },
      error: (err) => {
        console.error(err);
      },
    });

    const updateUserPanelListener = API.graphql(
      graphqlOperation(onUpdateUserPanel, { owner: owner })
    ).subscribe({
      next: (panelData) => {
        const newPanel = panelData.value.data.onUpdateUserPanel;
        newPanel.isUserPanel = true;
        user.updatePanelType(newPanel);
      },
      error: (err) => {
        console.error(err);
      },
    });

    const deleteUserPanelListener = API.graphql(
      graphqlOperation(onDeleteUserPanel, { owner: owner })
    ).subscribe({
      next: (panelData) => {
        const deletedPanel = panelData.value.data.onDeleteUserPanel;
        user.removePanelType(deletedPanel);
      },
    });

    return () => {
      createPanelListener.unsubscribe();
      createUserPanelListener.unsubscribe();
      updateUserPanelListener.unsubscribe();
      deleteUserPanelListener.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

export default memo(FetchPanels);
