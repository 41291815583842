import { useState } from "react";
import { sendEmail } from "../../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import { pools, apiKey } from "../../utils/AuthType";

import { useStyles } from "./ContactStyles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";

function Form() {
  const c = useStyles();
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const [isSubmiting, setIsSubmitting] = useState(false);
  const [msgError, setMsgError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailSuccess, setEmailSuccess] = useState(false);

  const submit = async () => {
    setMsgError(false);
    setEmailError(false);
    setIsSubmitting(true);
    if (!msg) {
      setMsgError(true);
    }
    if (!email) {
      setEmailError(true);
    }
    if (msgError || emailError) {
      setIsSubmitting(false);
      return;
    }

    const payload = {
      msg: msg,
      email: email,
      responseRequested: true,
      subject: "Contact Form",
    };

    try {
      apiKey();
      let resp = await API.graphql(
        graphqlOperation(sendEmail, { input: payload })
      );
      resp.data.sendEmail === "SUCCESS" && setEmailSuccess(true);
    } catch (err) {
      setIsSubmitting(false);
    }
    pools();
    setEmail("");
    setMsg("");
    setIsSubmitting(false);
  };

  return (
    <Grid
      container
      style={{
        // flex: 1,
        backgroundColor: "",
        minWidth: 0,
      }}
    >
      <Grid item xs={12} style={{ backgroundColor: "" }}>
        <Grid container justifyContent="center" style={{ minWidth: 0 }}>
          <Paper className={c.formPaperMob} elevation={9}>
            <Box
              style={{
                borderWidth: 20,
                borderColor: "",
                paddingTop: 17,
                paddingBottom: 20,
              }}
            >
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{ height: "100%", backgroundColor: "" }}
              >
                {emailSuccess ? (
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: "100%", backgroundColor: "" }}
                  >
                    <Typography variant="h6" align="center">
                      Thank you for your feedback.
                    </Typography>
                  </Grid>
                ) : (
                  <>
                    <Paper
                      elevation={1}
                      className={c.formInput}
                      // style={{ height: 210 }}
                    >
                      <TextField
                        id="outlined-multiline-flexible"
                        label="Message"
                        value={msg}
                        onChange={(e) => setMsg(e.target.value)}
                        variant="outlined"
                        size="small"
                        multiline={true}
                        rows={10}
                        style={{ width: "100%" }}
                        error={msgError}
                        // helperText="Must include a message."
                      />
                    </Paper>

                    <Paper
                      elevation={1}
                      className={c.formInput}
                      style={{ marginTop: 40 }}
                    >
                      <TextField
                        id="outlined-multiline-flexible"
                        label="Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        variant="outlined"
                        size="small"
                        style={{ width: "100%" }}
                        error={emailError}
                        // helperText="Must include email address."
                      />
                    </Paper>

                    <Button
                      onClick={submit}
                      variant="contained"
                      color="secondary"
                      className={c.formButton}
                    >
                      {isSubmiting ? (
                        <CircularProgress
                          size={20}
                          style={{ marginLeft: 20, marginRight: 20 }}
                        />
                      ) : (
                        <Typography>Submit</Typography>
                      )}
                    </Button>
                  </>
                )}
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Form;
