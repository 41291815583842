import { useEffect, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";


import { pressedModulesbyProjectID } from "../graphql/queries";
import { onCreatePressedModule } from "../graphql/subscriptions";
import { onUpdatePressedModuleForList } from "../api/subscriptionsMin";

import { observer } from "mobx-react-lite";

import { StoreContext } from "../stores/store.context";

const ScreenFetchAndSub = observer(() => {
  const { user, project } = useContext(StoreContext);
  let owner = user.username;

  useEffect(() => {
    if (project.id) {
      fetchPressedModules();

      const createMarkedPanelListener = API.graphql(
        graphqlOperation(onCreatePressedModule, { owner: owner })
      ).subscribe({
        next: (moduleData) => {
          if (moduleData.projectID === project.id) {
            const newModule = moduleData.value.data.onCreatePressedModule;
            newModule.isStarred
              ? project.addStarredModule(newModule)
              : console.log("");
          }
        },
      });

      const updateMarkedPanelListener = API.graphql(
        graphqlOperation(onUpdatePressedModuleForList, { owner: owner })
      ).subscribe({
        next: (moduleData) => {
          const respMod = moduleData.value.data.onUpdatePressedModule;
          if (respMod.projectID === project.id) {
            const isStarred = respMod.isStarred;
            isStarred
              ? project.addStarredModule(respMod)
              : project.removeStarredModule(respMod.id);
          }
        },
      });

      return () => {
        updateMarkedPanelListener.unsubscribe();
        createMarkedPanelListener.unsubscribe();
      };
    } else return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project.id]);

  // function to list all pressed modules for all screens
  // in this project

  async function fetchPressedModules() {
    try {
      const thisProject = project.id;
      const resp = await API.graphql(
        graphqlOperation(pressedModulesbyProjectID, {
          projectID: thisProject,
          filter: { isStarred: { eq: true } },
        })
      );
      const modulesList = resp.data.PressedModulesbyProjectID.items;
      project.setstarredModulesList(modulesList);
    } catch (err) {
      console.error(err);
    }
  }
});

export default ScreenFetchAndSub;
