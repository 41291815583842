import { useContext } from "react";
import { observer } from "mobx-react-lite";
import useResizeObserver from "use-resize-observer";

import { StoreContext } from "../../../../stores/store.context";

import { useStyles } from "./CanvasPreviewStyle";
import { removeAllHightlight } from "../../../../Helpers/RemoveAllHighlight";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import "./canvasPreview.css";

const CanvasPreview = observer(() => {
  const { project } = useContext(StoreContext);
  const ratio = (100 * project.height) / project.width;
  const c = useStyles({ ratio: ratio });
  let refObj = useResizeObserver();


  return (
    <>
      <Grid
        container
        style={{ width: "100%", height: "100%", backgroundColor: "" }}
        justifyContent="center"
      >
        <div
          id="background"
          className={c.background}
          ref={refObj.ref}
          onClick={(e) => {
            removeAllHightlight(project.screenList);
          }}
        >
          {project.dimsCalculated && refObj.height ? (
            <div
              className={c.betweenOuterAndCanvas}
              style={{
                maxWidth:
                  ((refObj.height * project.width) / project.height) * 0.97,
                backgroundColor: "",
              }}
            >
              <div
                id="centeredCanvas"
                className={c.centeredCanvas}
                style={{ backgroundColor: "" }}
              >
                {project.outputList.map((output, index) => (
                  <OutputBox output={output} key={index} index={index} />
                ))}
                {project.screenList.map(
                  (screen, index) =>
                    screen.panel && (
                      <ScreenBox
                        screen={screen}
                        key={index}
                        index={index}
                      />
                    )
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </Grid>
    </>
  );
});

export default CanvasPreview;


const OutputBox = (props) => {
  const { project } = useContext(StoreContext);
  const { index } = props;
  let output = project.outputList[index];
  const ratio = (100 * project.height) / project.width;
  const c = useStyles({ ratio: ratio });
  let width = Math.floor((output.width / project.width) * 100);
  let height = Math.floor((output.height / project.height) * 100);
  let startX = Math.floor(
    ((output.startX - project.startX) / project.width) * 100
  );
  let startY = Math.floor(
    ((output.startY - project.startY) / project.height) * 100
  );

  return (
    <Box
      component="button"
      type="button"
      key={output.id}
      borderColor={output.color}
      position="absolute"
      top={startY + "%"}
      left={startX + "%"}
      width={width + "%"}
      height={height + "%"}
      className={c.outputBox}
      display="flex"
      style={{
        backgroundColor: "transparent",
        borderWidth: output.highlighted ? 10 : 3,
      }}
    />
  );
};


const ScreenBox = (props) => {
  const { index } = props;
  const { project, app } = useContext(StoreContext);
  const screen = project.screenList[index];
  const ratio = (100 * project.height) / project.width;
  const c = useStyles({ ratio: ratio });
  const panel = screen.panel;
  let projectWidth = project.width;
  let projectHeight = project.height;
  let width = Math.floor(
    ((panel.pixelWidth * screen.panelColumns) / projectWidth) * 100
  );
  let height = Math.floor(
    ((panel.pixelHeight * screen.panelRows) / projectHeight) * 100
  );
  let startX = Math.floor(
    ((screen.startX - project.startX) / projectWidth) * 100
  );
  let startY = Math.floor(
    ((screen.startY - project.startY) / projectHeight) * 100
  );

  const setScreen = () => {
    const screen = project.screenList[index];
    project.selectScreenEdit(screen);
    app.setSelectedEditValue(2);
  };

  return (
    <div
      onClick={setScreen}
      style={{
        backgroundColor: screen.color,
        top: `${startY}%`,
        left: `${startX}%`,
        width: `${width}%`,
        height: `${height}%`,
        position: "absolute",
        display: "flex",
        zIndex: screen.highlighted ? 10 : 0,
        cursor: "pointer",
        transformOrigin: `0 0`,
        transform: `rotate(${screen.rotation}deg)`,
      }}
    >
      <Grid
        container
        alignItems="stretch"
        style={{ width: "100%", height: "100%", padding: 5 }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="flex-start"
          style={{ width: "100%", pointerEvents: "none" }}
        >
          <Typography noWrap>
            ({screen.startX}/{screen.startY})
          </Typography>

          <Typography noWrap>
            ({screen.startX + screen.panelColumns * panel.pixelWidth}/
            {screen.startY})
          </Typography>
        </Grid>
        <Grid container justifyContent="center" alignItems="flex-end">
          <Typography
            noWrap
            className={c.screenName}
            variant="h3"
            style={{
              backgroundColor: screen.color,
              fontWeigth: 900,
              fontSize: "300%",
            }}
          >
            {screen.name.toUpperCase()}
          </Typography>
        </Grid>

        <Grid container justifyContent="center" alignItems="flex-start">
          <Typography
            className={c.screenName}
            variant="h5"
            style={{
              backgroundColor: screen.color,
              fontWeigth: 900,
              fontSize: "100%",
            }}
            noWrap
          >
            {panel.make} {panel.model}
          </Typography>
        </Grid>

        <Grid
          container
          justifyContent="space-between"
          alignItems="flex-end"
          style={{ width: "100%" }}
        >
          <Typography noWrap>
            ({screen.startX}/
            {screen.startY + screen.panelRows * panel.pixelHeight})
          </Typography>
          <Typography noWrap>
            ({screen.startX + screen.panelColumns * panel.pixelWidth}/
            {screen.startY + screen.panelRows * panel.pixelHeight})
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};
