import { useEffect, useContext } from "react";

import { StoreContext } from "../../../../stores/store.context";

import "./style.css";

let colors = [
  "rgba(0,0,0,.93)",
  "rgba(0,0,0,.85)",
  "rgba(0,0,0,.77)",
  "rgba(0,0,0,.69)",
  "rgba(0,0,0,.61)",
  "rgba(0,0,0,.53)",
  "rgba(0,0,0,.45)",
  "rgba(0,0,0,.37)",
  "rgba(0,0,0,.29)",
  "rgba(0,0,0,.21)",
  "rgba(0,0,0,.13)",
  "rgba(0,0,0,.05)",
];

export const TwelveStep = () => {
  const { project } = useContext(StoreContext);

  return (
    <div className="output-container">
      {project.screenList.map(
        (screen, i) => screen.panel && <Screen screen={screen} key={i} />
      )}
    </div>
  );
};

const Screen = (props) => {
  const { user } = useContext(StoreContext);

  const { screen } = props;
  const output = user.outputPageOutput;

  const screenWidth = screen.panel.pixelWidth * screen.panelColumns;
  const screenHeight = screen.panel.pixelHeight * screen.panelRows;
  let startX = screen.startX - output.startX;
  let startY = screen.startY - output.startY;

  const canvasId = `${screen.id}twelvestep`;

  useEffect(() => {
    const canvas = document.getElementById(canvasId);
    const ctx = canvas.getContext("2d");
    canvas.style.left = `${screen.startX - output.startX}px`;
    canvas.style.top = `${screen.startY - output.startY}px`;
    canvas.width = screenWidth;
    canvas.height = screenHeight;

    const barWidth = Math.floor(screenWidth / colors.length);

    let leftToRight = [];
    let rightToLeft = [];

    for (let i = 0; i < colors.length; i++) {
      let leftToRightBar = {};
      leftToRightBar.color = colors[i];
      leftToRightBar.start = i * barWidth;
      leftToRight.push(leftToRightBar);

      let rightToLeftBar = {};
      rightToLeftBar.color = colors[colors.length - i - 1];
      rightToLeftBar.start = i * barWidth;
      rightToLeft.push(rightToLeftBar);
    }

    const drawBars = () => {
      leftToRight.forEach((bar, i) => {
        ctx.fillStyle = bar.color;
        ctx.fillRect(bar.start, 0, barWidth, screenHeight / 2);
      });

      rightToLeft.forEach((bar, i) => {
        ctx.fillStyle = bar.color;
        ctx.fillRect(bar.start, screenHeight / 2, barWidth, screenHeight / 2);
      });
    };

    requestAnimationFrame(drawBars);
  }, []);

  return (
    <div
      style={{
        transformOrigin: `${startX}px ${startY}px`,
        transform: `rotate(${screen.rotation}deg)`,
      }}
    >
      <canvas id={canvasId} style={{ position: "absolute" }}></canvas>
    </div>
  );
};
