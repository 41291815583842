/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSubscriptionLambda = /* GraphQL */ `
  mutation CreateSubscriptionLambda($input: subscriptionInput!) {
    createSubscriptionLambda(input: $input) {
      statusCode
      purchaseRes {
        success
        purchaseId
      }
      subscriptionId
    }
  }
`;
export const checkoutAndSubscribe = /* GraphQL */ `
  mutation CheckoutAndSubscribe($input: subscriptionInput!) {
    checkoutAndSubscribe(input: $input) {
      statusCode
      purchaseRes {
        success
        purchaseId
      }
      subscriptionId
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      name
      projectBackgroundContent
      projectBackgroundContentPlay
      projectBackgroundContentGrid
      projectBackgroundContentLabels
      projectWidth
      projectHeight
      screens {
        items {
          id
          panelColumns
          panelRows
          panelID
          projectID
          startX
          startY
          rotation
          ownerScreenBackgroundContent
          name
          color
          zIndex
          isUsingUserPanel
          project {
            id
            name
            projectBackgroundContent
            projectBackgroundContentPlay
            projectBackgroundContentGrid
            projectBackgroundContentLabels
            projectWidth
            projectHeight
            screens {
              nextToken
            }
            creator {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            outputs {
              nextToken
            }
            isGridOn
            isLabelOn
            isStarredOn
            isPlaying
            createdAt
            updatedAt
            owner
          }
          panel {
            id
            make
            model
            pixelWidth
            pixelHeight
            moduleColumns
            moduleRows
            weight
            maxWatt
            averageWatt
            frameSubSpecies
            sizeSubSpecies
            createdAt
            updatedAt
            owner
          }
          userPanel {
            id
            make
            model
            pixelWidth
            pixelHeight
            moduleColumns
            moduleRows
            weight
            maxWatt
            averageWatt
            frameSubSpecies
            sizeSubSpecies
            createdAt
            updatedAt
            owner
          }
          pressedPanel {
            items {
              id
              x
              y
              projectID
              ownerScreenID
              index
              panelState
              isStarred
              isOn
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          pressedModule {
            items {
              id
              panelX
              panelY
              moduleX
              moduleY
              moduleState
              index
              ownerScreenID
              projectID
              isStarred
              isReplaced
              isOn
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      creator {
        id
        lastLogin
        username
        currentSubscription {
          items {
            id
            startDate
            endDate
            tier
            autoRenewal
            user {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            purchaseAmount
            currency
            userId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        subscriptions {
          items {
            id
            startDate
            endDate
            tier
            autoRenewal
            user {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            purchaseAmount
            currency
            userId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        availableProjects {
          items {
            id
            name
            projectBackgroundContent
            projectBackgroundContentPlay
            projectBackgroundContentGrid
            projectBackgroundContentLabels
            projectWidth
            projectHeight
            screens {
              nextToken
            }
            creator {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            outputs {
              nextToken
            }
            isGridOn
            isLabelOn
            isStarredOn
            isPlaying
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        email
        createdAt
        updatedAt
        owner
      }
      outputs {
        items {
          id
          name
          projectID
          width
          height
          startX
          startY
          color
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      isGridOn
      isLabelOn
      isStarredOn
      isPlaying
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      name
      projectBackgroundContent
      projectBackgroundContentPlay
      projectBackgroundContentGrid
      projectBackgroundContentLabels
      projectWidth
      projectHeight
      screens {
        items {
          id
          panelColumns
          panelRows
          panelID
          projectID
          startX
          startY
          rotation
          ownerScreenBackgroundContent
          name
          color
          zIndex
          isUsingUserPanel
          project {
            id
            name
            projectBackgroundContent
            projectBackgroundContentPlay
            projectBackgroundContentGrid
            projectBackgroundContentLabels
            projectWidth
            projectHeight
            screens {
              nextToken
            }
            creator {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            outputs {
              nextToken
            }
            isGridOn
            isLabelOn
            isStarredOn
            isPlaying
            createdAt
            updatedAt
            owner
          }
          panel {
            id
            make
            model
            pixelWidth
            pixelHeight
            moduleColumns
            moduleRows
            weight
            maxWatt
            averageWatt
            frameSubSpecies
            sizeSubSpecies
            createdAt
            updatedAt
            owner
          }
          userPanel {
            id
            make
            model
            pixelWidth
            pixelHeight
            moduleColumns
            moduleRows
            weight
            maxWatt
            averageWatt
            frameSubSpecies
            sizeSubSpecies
            createdAt
            updatedAt
            owner
          }
          pressedPanel {
            items {
              id
              x
              y
              projectID
              ownerScreenID
              index
              panelState
              isStarred
              isOn
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          pressedModule {
            items {
              id
              panelX
              panelY
              moduleX
              moduleY
              moduleState
              index
              ownerScreenID
              projectID
              isStarred
              isReplaced
              isOn
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      creator {
        id
        lastLogin
        username
        currentSubscription {
          items {
            id
            startDate
            endDate
            tier
            autoRenewal
            user {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            purchaseAmount
            currency
            userId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        subscriptions {
          items {
            id
            startDate
            endDate
            tier
            autoRenewal
            user {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            purchaseAmount
            currency
            userId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        availableProjects {
          items {
            id
            name
            projectBackgroundContent
            projectBackgroundContentPlay
            projectBackgroundContentGrid
            projectBackgroundContentLabels
            projectWidth
            projectHeight
            screens {
              nextToken
            }
            creator {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            outputs {
              nextToken
            }
            isGridOn
            isLabelOn
            isStarredOn
            isPlaying
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        email
        createdAt
        updatedAt
        owner
      }
      outputs {
        items {
          id
          name
          projectID
          width
          height
          startX
          startY
          color
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      isGridOn
      isLabelOn
      isStarredOn
      isPlaying
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      name
      projectBackgroundContent
      projectBackgroundContentPlay
      projectBackgroundContentGrid
      projectBackgroundContentLabels
      projectWidth
      projectHeight
      screens {
        items {
          id
          panelColumns
          panelRows
          panelID
          projectID
          startX
          startY
          rotation
          ownerScreenBackgroundContent
          name
          color
          zIndex
          isUsingUserPanel
          project {
            id
            name
            projectBackgroundContent
            projectBackgroundContentPlay
            projectBackgroundContentGrid
            projectBackgroundContentLabels
            projectWidth
            projectHeight
            screens {
              nextToken
            }
            creator {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            outputs {
              nextToken
            }
            isGridOn
            isLabelOn
            isStarredOn
            isPlaying
            createdAt
            updatedAt
            owner
          }
          panel {
            id
            make
            model
            pixelWidth
            pixelHeight
            moduleColumns
            moduleRows
            weight
            maxWatt
            averageWatt
            frameSubSpecies
            sizeSubSpecies
            createdAt
            updatedAt
            owner
          }
          userPanel {
            id
            make
            model
            pixelWidth
            pixelHeight
            moduleColumns
            moduleRows
            weight
            maxWatt
            averageWatt
            frameSubSpecies
            sizeSubSpecies
            createdAt
            updatedAt
            owner
          }
          pressedPanel {
            items {
              id
              x
              y
              projectID
              ownerScreenID
              index
              panelState
              isStarred
              isOn
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          pressedModule {
            items {
              id
              panelX
              panelY
              moduleX
              moduleY
              moduleState
              index
              ownerScreenID
              projectID
              isStarred
              isReplaced
              isOn
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      creator {
        id
        lastLogin
        username
        currentSubscription {
          items {
            id
            startDate
            endDate
            tier
            autoRenewal
            user {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            purchaseAmount
            currency
            userId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        subscriptions {
          items {
            id
            startDate
            endDate
            tier
            autoRenewal
            user {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            purchaseAmount
            currency
            userId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        availableProjects {
          items {
            id
            name
            projectBackgroundContent
            projectBackgroundContentPlay
            projectBackgroundContentGrid
            projectBackgroundContentLabels
            projectWidth
            projectHeight
            screens {
              nextToken
            }
            creator {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            outputs {
              nextToken
            }
            isGridOn
            isLabelOn
            isStarredOn
            isPlaying
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        email
        createdAt
        updatedAt
        owner
      }
      outputs {
        items {
          id
          name
          projectID
          width
          height
          startX
          startY
          color
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      isGridOn
      isLabelOn
      isStarredOn
      isPlaying
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createOutput = /* GraphQL */ `
  mutation CreateOutput(
    $input: CreateOutputInput!
    $condition: ModelOutputConditionInput
  ) {
    createOutput(input: $input, condition: $condition) {
      id
      name
      projectID
      width
      height
      startX
      startY
      color
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateOutput = /* GraphQL */ `
  mutation UpdateOutput(
    $input: UpdateOutputInput!
    $condition: ModelOutputConditionInput
  ) {
    updateOutput(input: $input, condition: $condition) {
      id
      name
      projectID
      width
      height
      startX
      startY
      color
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteOutput = /* GraphQL */ `
  mutation DeleteOutput(
    $input: DeleteOutputInput!
    $condition: ModelOutputConditionInput
  ) {
    deleteOutput(input: $input, condition: $condition) {
      id
      name
      projectID
      width
      height
      startX
      startY
      color
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createContentButton = /* GraphQL */ `
  mutation CreateContentButton(
    $input: CreateContentButtonInput!
    $condition: ModelContentButtonConditionInput
  ) {
    createContentButton(input: $input, condition: $condition) {
      id
      name
      primarycolor
      secondarycolor
      icon
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateContentButton = /* GraphQL */ `
  mutation UpdateContentButton(
    $input: UpdateContentButtonInput!
    $condition: ModelContentButtonConditionInput
  ) {
    updateContentButton(input: $input, condition: $condition) {
      id
      name
      primarycolor
      secondarycolor
      icon
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteContentButton = /* GraphQL */ `
  mutation DeleteContentButton(
    $input: DeleteContentButtonInput!
    $condition: ModelContentButtonConditionInput
  ) {
    deleteContentButton(input: $input, condition: $condition) {
      id
      name
      primarycolor
      secondarycolor
      icon
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createOwnerScreen = /* GraphQL */ `
  mutation CreateOwnerScreen(
    $input: CreateOwnerScreenInput!
    $condition: ModelOwnerScreenConditionInput
  ) {
    createOwnerScreen(input: $input, condition: $condition) {
      id
      panelColumns
      panelRows
      panelID
      projectID
      startX
      startY
      rotation
      ownerScreenBackgroundContent
      name
      color
      zIndex
      isUsingUserPanel
      project {
        id
        name
        projectBackgroundContent
        projectBackgroundContentPlay
        projectBackgroundContentGrid
        projectBackgroundContentLabels
        projectWidth
        projectHeight
        screens {
          items {
            id
            panelColumns
            panelRows
            panelID
            projectID
            startX
            startY
            rotation
            ownerScreenBackgroundContent
            name
            color
            zIndex
            isUsingUserPanel
            project {
              id
              name
              projectBackgroundContent
              projectBackgroundContentPlay
              projectBackgroundContentGrid
              projectBackgroundContentLabels
              projectWidth
              projectHeight
              isGridOn
              isLabelOn
              isStarredOn
              isPlaying
              createdAt
              updatedAt
              owner
            }
            panel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            userPanel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            pressedPanel {
              nextToken
            }
            pressedModule {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        creator {
          id
          lastLogin
          username
          currentSubscription {
            items {
              id
              startDate
              endDate
              tier
              autoRenewal
              purchaseAmount
              currency
              userId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscriptions {
            items {
              id
              startDate
              endDate
              tier
              autoRenewal
              purchaseAmount
              currency
              userId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          availableProjects {
            items {
              id
              name
              projectBackgroundContent
              projectBackgroundContentPlay
              projectBackgroundContentGrid
              projectBackgroundContentLabels
              projectWidth
              projectHeight
              isGridOn
              isLabelOn
              isStarredOn
              isPlaying
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          email
          createdAt
          updatedAt
          owner
        }
        outputs {
          items {
            id
            name
            projectID
            width
            height
            startX
            startY
            color
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        isGridOn
        isLabelOn
        isStarredOn
        isPlaying
        createdAt
        updatedAt
        owner
      }
      panel {
        id
        make
        model
        pixelWidth
        pixelHeight
        moduleColumns
        moduleRows
        weight
        maxWatt
        averageWatt
        frameSubSpecies
        sizeSubSpecies
        createdAt
        updatedAt
        owner
      }
      userPanel {
        id
        make
        model
        pixelWidth
        pixelHeight
        moduleColumns
        moduleRows
        weight
        maxWatt
        averageWatt
        frameSubSpecies
        sizeSubSpecies
        createdAt
        updatedAt
        owner
      }
      pressedPanel {
        items {
          id
          x
          y
          ownerScreen {
            id
            panelColumns
            panelRows
            panelID
            projectID
            startX
            startY
            rotation
            ownerScreenBackgroundContent
            name
            color
            zIndex
            isUsingUserPanel
            project {
              id
              name
              projectBackgroundContent
              projectBackgroundContentPlay
              projectBackgroundContentGrid
              projectBackgroundContentLabels
              projectWidth
              projectHeight
              isGridOn
              isLabelOn
              isStarredOn
              isPlaying
              createdAt
              updatedAt
              owner
            }
            panel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            userPanel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            pressedPanel {
              nextToken
            }
            pressedModule {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          projectID
          ownerScreenID
          index
          panelState
          isStarred
          isOn
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      pressedModule {
        items {
          id
          panelX
          panelY
          moduleX
          moduleY
          moduleState
          index
          ownerScreenID
          projectID
          ownerScreen {
            id
            panelColumns
            panelRows
            panelID
            projectID
            startX
            startY
            rotation
            ownerScreenBackgroundContent
            name
            color
            zIndex
            isUsingUserPanel
            project {
              id
              name
              projectBackgroundContent
              projectBackgroundContentPlay
              projectBackgroundContentGrid
              projectBackgroundContentLabels
              projectWidth
              projectHeight
              isGridOn
              isLabelOn
              isStarredOn
              isPlaying
              createdAt
              updatedAt
              owner
            }
            panel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            userPanel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            pressedPanel {
              nextToken
            }
            pressedModule {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          isStarred
          isReplaced
          isOn
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateOwnerScreen = /* GraphQL */ `
  mutation UpdateOwnerScreen(
    $input: UpdateOwnerScreenInput!
    $condition: ModelOwnerScreenConditionInput
  ) {
    updateOwnerScreen(input: $input, condition: $condition) {
      id
      panelColumns
      panelRows
      panelID
      projectID
      startX
      startY
      rotation
      ownerScreenBackgroundContent
      name
      color
      zIndex
      isUsingUserPanel
      project {
        id
        name
        projectBackgroundContent
        projectBackgroundContentPlay
        projectBackgroundContentGrid
        projectBackgroundContentLabels
        projectWidth
        projectHeight
        screens {
          items {
            id
            panelColumns
            panelRows
            panelID
            projectID
            startX
            startY
            rotation
            ownerScreenBackgroundContent
            name
            color
            zIndex
            isUsingUserPanel
            project {
              id
              name
              projectBackgroundContent
              projectBackgroundContentPlay
              projectBackgroundContentGrid
              projectBackgroundContentLabels
              projectWidth
              projectHeight
              isGridOn
              isLabelOn
              isStarredOn
              isPlaying
              createdAt
              updatedAt
              owner
            }
            panel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            userPanel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            pressedPanel {
              nextToken
            }
            pressedModule {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        creator {
          id
          lastLogin
          username
          currentSubscription {
            items {
              id
              startDate
              endDate
              tier
              autoRenewal
              purchaseAmount
              currency
              userId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscriptions {
            items {
              id
              startDate
              endDate
              tier
              autoRenewal
              purchaseAmount
              currency
              userId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          availableProjects {
            items {
              id
              name
              projectBackgroundContent
              projectBackgroundContentPlay
              projectBackgroundContentGrid
              projectBackgroundContentLabels
              projectWidth
              projectHeight
              isGridOn
              isLabelOn
              isStarredOn
              isPlaying
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          email
          createdAt
          updatedAt
          owner
        }
        outputs {
          items {
            id
            name
            projectID
            width
            height
            startX
            startY
            color
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        isGridOn
        isLabelOn
        isStarredOn
        isPlaying
        createdAt
        updatedAt
        owner
      }
      panel {
        id
        make
        model
        pixelWidth
        pixelHeight
        moduleColumns
        moduleRows
        weight
        maxWatt
        averageWatt
        frameSubSpecies
        sizeSubSpecies
        createdAt
        updatedAt
        owner
      }
      userPanel {
        id
        make
        model
        pixelWidth
        pixelHeight
        moduleColumns
        moduleRows
        weight
        maxWatt
        averageWatt
        frameSubSpecies
        sizeSubSpecies
        createdAt
        updatedAt
        owner
      }
      pressedPanel {
        items {
          id
          x
          y
          ownerScreen {
            id
            panelColumns
            panelRows
            panelID
            projectID
            startX
            startY
            rotation
            ownerScreenBackgroundContent
            name
            color
            zIndex
            isUsingUserPanel
            project {
              id
              name
              projectBackgroundContent
              projectBackgroundContentPlay
              projectBackgroundContentGrid
              projectBackgroundContentLabels
              projectWidth
              projectHeight
              isGridOn
              isLabelOn
              isStarredOn
              isPlaying
              createdAt
              updatedAt
              owner
            }
            panel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            userPanel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            pressedPanel {
              nextToken
            }
            pressedModule {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          projectID
          ownerScreenID
          index
          panelState
          isStarred
          isOn
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      pressedModule {
        items {
          id
          panelX
          panelY
          moduleX
          moduleY
          moduleState
          index
          ownerScreenID
          projectID
          ownerScreen {
            id
            panelColumns
            panelRows
            panelID
            projectID
            startX
            startY
            rotation
            ownerScreenBackgroundContent
            name
            color
            zIndex
            isUsingUserPanel
            project {
              id
              name
              projectBackgroundContent
              projectBackgroundContentPlay
              projectBackgroundContentGrid
              projectBackgroundContentLabels
              projectWidth
              projectHeight
              isGridOn
              isLabelOn
              isStarredOn
              isPlaying
              createdAt
              updatedAt
              owner
            }
            panel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            userPanel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            pressedPanel {
              nextToken
            }
            pressedModule {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          isStarred
          isReplaced
          isOn
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteOwnerScreen = /* GraphQL */ `
  mutation DeleteOwnerScreen(
    $input: DeleteOwnerScreenInput!
    $condition: ModelOwnerScreenConditionInput
  ) {
    deleteOwnerScreen(input: $input, condition: $condition) {
      id
      panelColumns
      panelRows
      panelID
      projectID
      startX
      startY
      rotation
      ownerScreenBackgroundContent
      name
      color
      zIndex
      isUsingUserPanel
      project {
        id
        name
        projectBackgroundContent
        projectBackgroundContentPlay
        projectBackgroundContentGrid
        projectBackgroundContentLabels
        projectWidth
        projectHeight
        screens {
          items {
            id
            panelColumns
            panelRows
            panelID
            projectID
            startX
            startY
            rotation
            ownerScreenBackgroundContent
            name
            color
            zIndex
            isUsingUserPanel
            project {
              id
              name
              projectBackgroundContent
              projectBackgroundContentPlay
              projectBackgroundContentGrid
              projectBackgroundContentLabels
              projectWidth
              projectHeight
              isGridOn
              isLabelOn
              isStarredOn
              isPlaying
              createdAt
              updatedAt
              owner
            }
            panel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            userPanel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            pressedPanel {
              nextToken
            }
            pressedModule {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        creator {
          id
          lastLogin
          username
          currentSubscription {
            items {
              id
              startDate
              endDate
              tier
              autoRenewal
              purchaseAmount
              currency
              userId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscriptions {
            items {
              id
              startDate
              endDate
              tier
              autoRenewal
              purchaseAmount
              currency
              userId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          availableProjects {
            items {
              id
              name
              projectBackgroundContent
              projectBackgroundContentPlay
              projectBackgroundContentGrid
              projectBackgroundContentLabels
              projectWidth
              projectHeight
              isGridOn
              isLabelOn
              isStarredOn
              isPlaying
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          email
          createdAt
          updatedAt
          owner
        }
        outputs {
          items {
            id
            name
            projectID
            width
            height
            startX
            startY
            color
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        isGridOn
        isLabelOn
        isStarredOn
        isPlaying
        createdAt
        updatedAt
        owner
      }
      panel {
        id
        make
        model
        pixelWidth
        pixelHeight
        moduleColumns
        moduleRows
        weight
        maxWatt
        averageWatt
        frameSubSpecies
        sizeSubSpecies
        createdAt
        updatedAt
        owner
      }
      userPanel {
        id
        make
        model
        pixelWidth
        pixelHeight
        moduleColumns
        moduleRows
        weight
        maxWatt
        averageWatt
        frameSubSpecies
        sizeSubSpecies
        createdAt
        updatedAt
        owner
      }
      pressedPanel {
        items {
          id
          x
          y
          ownerScreen {
            id
            panelColumns
            panelRows
            panelID
            projectID
            startX
            startY
            rotation
            ownerScreenBackgroundContent
            name
            color
            zIndex
            isUsingUserPanel
            project {
              id
              name
              projectBackgroundContent
              projectBackgroundContentPlay
              projectBackgroundContentGrid
              projectBackgroundContentLabels
              projectWidth
              projectHeight
              isGridOn
              isLabelOn
              isStarredOn
              isPlaying
              createdAt
              updatedAt
              owner
            }
            panel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            userPanel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            pressedPanel {
              nextToken
            }
            pressedModule {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          projectID
          ownerScreenID
          index
          panelState
          isStarred
          isOn
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      pressedModule {
        items {
          id
          panelX
          panelY
          moduleX
          moduleY
          moduleState
          index
          ownerScreenID
          projectID
          ownerScreen {
            id
            panelColumns
            panelRows
            panelID
            projectID
            startX
            startY
            rotation
            ownerScreenBackgroundContent
            name
            color
            zIndex
            isUsingUserPanel
            project {
              id
              name
              projectBackgroundContent
              projectBackgroundContentPlay
              projectBackgroundContentGrid
              projectBackgroundContentLabels
              projectWidth
              projectHeight
              isGridOn
              isLabelOn
              isStarredOn
              isPlaying
              createdAt
              updatedAt
              owner
            }
            panel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            userPanel {
              id
              make
              model
              pixelWidth
              pixelHeight
              moduleColumns
              moduleRows
              weight
              maxWatt
              averageWatt
              frameSubSpecies
              sizeSubSpecies
              createdAt
              updatedAt
              owner
            }
            pressedPanel {
              nextToken
            }
            pressedModule {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          isStarred
          isReplaced
          isOn
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      lastLogin
      username
      currentSubscription {
        items {
          id
          startDate
          endDate
          tier
          autoRenewal
          user {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          purchaseAmount
          currency
          userId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      subscriptions {
        items {
          id
          startDate
          endDate
          tier
          autoRenewal
          user {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          purchaseAmount
          currency
          userId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      availableProjects {
        items {
          id
          name
          projectBackgroundContent
          projectBackgroundContentPlay
          projectBackgroundContentGrid
          projectBackgroundContentLabels
          projectWidth
          projectHeight
          screens {
            items {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          creator {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          outputs {
            items {
              id
              name
              projectID
              width
              height
              startX
              startY
              color
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          isGridOn
          isLabelOn
          isStarredOn
          isPlaying
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      email
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      lastLogin
      username
      currentSubscription {
        items {
          id
          startDate
          endDate
          tier
          autoRenewal
          user {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          purchaseAmount
          currency
          userId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      subscriptions {
        items {
          id
          startDate
          endDate
          tier
          autoRenewal
          user {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          purchaseAmount
          currency
          userId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      availableProjects {
        items {
          id
          name
          projectBackgroundContent
          projectBackgroundContentPlay
          projectBackgroundContentGrid
          projectBackgroundContentLabels
          projectWidth
          projectHeight
          screens {
            items {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          creator {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          outputs {
            items {
              id
              name
              projectID
              width
              height
              startX
              startY
              color
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          isGridOn
          isLabelOn
          isStarredOn
          isPlaying
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      email
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      lastLogin
      username
      currentSubscription {
        items {
          id
          startDate
          endDate
          tier
          autoRenewal
          user {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          purchaseAmount
          currency
          userId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      subscriptions {
        items {
          id
          startDate
          endDate
          tier
          autoRenewal
          user {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          purchaseAmount
          currency
          userId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      availableProjects {
        items {
          id
          name
          projectBackgroundContent
          projectBackgroundContentPlay
          projectBackgroundContentGrid
          projectBackgroundContentLabels
          projectWidth
          projectHeight
          screens {
            items {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          creator {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          outputs {
            items {
              id
              name
              projectID
              width
              height
              startX
              startY
              color
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          isGridOn
          isLabelOn
          isStarredOn
          isPlaying
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      email
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createBetaTesters = /* GraphQL */ `
  mutation CreateBetaTesters(
    $input: CreateBetaTestersInput!
    $condition: ModelBetaTestersConditionInput
  ) {
    createBetaTesters(input: $input, condition: $condition) {
      id
      email
      platform
      application
      registrationEmailSent
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateBetaTesters = /* GraphQL */ `
  mutation UpdateBetaTesters(
    $input: UpdateBetaTestersInput!
    $condition: ModelBetaTestersConditionInput
  ) {
    updateBetaTesters(input: $input, condition: $condition) {
      id
      email
      platform
      application
      registrationEmailSent
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteBetaTesters = /* GraphQL */ `
  mutation DeleteBetaTesters(
    $input: DeleteBetaTestersInput!
    $condition: ModelBetaTestersConditionInput
  ) {
    deleteBetaTesters(input: $input, condition: $condition) {
      id
      email
      platform
      application
      registrationEmailSent
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createSubscriptionOrder = /* GraphQL */ `
  mutation CreateSubscriptionOrder(
    $input: CreateSubscriptionOrderInput!
    $condition: ModelSubscriptionOrderConditionInput
  ) {
    createSubscriptionOrder(input: $input, condition: $condition) {
      id
      startDate
      endDate
      tier
      autoRenewal
      user {
        id
        lastLogin
        username
        currentSubscription {
          items {
            id
            startDate
            endDate
            tier
            autoRenewal
            user {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            purchaseAmount
            currency
            userId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        subscriptions {
          items {
            id
            startDate
            endDate
            tier
            autoRenewal
            user {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            purchaseAmount
            currency
            userId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        availableProjects {
          items {
            id
            name
            projectBackgroundContent
            projectBackgroundContentPlay
            projectBackgroundContentGrid
            projectBackgroundContentLabels
            projectWidth
            projectHeight
            screens {
              nextToken
            }
            creator {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            outputs {
              nextToken
            }
            isGridOn
            isLabelOn
            isStarredOn
            isPlaying
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        email
        createdAt
        updatedAt
        owner
      }
      purchaseAmount
      currency
      userId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateSubscriptionOrder = /* GraphQL */ `
  mutation UpdateSubscriptionOrder(
    $input: UpdateSubscriptionOrderInput!
    $condition: ModelSubscriptionOrderConditionInput
  ) {
    updateSubscriptionOrder(input: $input, condition: $condition) {
      id
      startDate
      endDate
      tier
      autoRenewal
      user {
        id
        lastLogin
        username
        currentSubscription {
          items {
            id
            startDate
            endDate
            tier
            autoRenewal
            user {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            purchaseAmount
            currency
            userId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        subscriptions {
          items {
            id
            startDate
            endDate
            tier
            autoRenewal
            user {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            purchaseAmount
            currency
            userId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        availableProjects {
          items {
            id
            name
            projectBackgroundContent
            projectBackgroundContentPlay
            projectBackgroundContentGrid
            projectBackgroundContentLabels
            projectWidth
            projectHeight
            screens {
              nextToken
            }
            creator {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            outputs {
              nextToken
            }
            isGridOn
            isLabelOn
            isStarredOn
            isPlaying
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        email
        createdAt
        updatedAt
        owner
      }
      purchaseAmount
      currency
      userId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteSubscriptionOrder = /* GraphQL */ `
  mutation DeleteSubscriptionOrder(
    $input: DeleteSubscriptionOrderInput!
    $condition: ModelSubscriptionOrderConditionInput
  ) {
    deleteSubscriptionOrder(input: $input, condition: $condition) {
      id
      startDate
      endDate
      tier
      autoRenewal
      user {
        id
        lastLogin
        username
        currentSubscription {
          items {
            id
            startDate
            endDate
            tier
            autoRenewal
            user {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            purchaseAmount
            currency
            userId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        subscriptions {
          items {
            id
            startDate
            endDate
            tier
            autoRenewal
            user {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            purchaseAmount
            currency
            userId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        availableProjects {
          items {
            id
            name
            projectBackgroundContent
            projectBackgroundContentPlay
            projectBackgroundContentGrid
            projectBackgroundContentLabels
            projectWidth
            projectHeight
            screens {
              nextToken
            }
            creator {
              id
              lastLogin
              username
              email
              createdAt
              updatedAt
              owner
            }
            outputs {
              nextToken
            }
            isGridOn
            isLabelOn
            isStarredOn
            isPlaying
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        email
        createdAt
        updatedAt
        owner
      }
      purchaseAmount
      currency
      userId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPressedPanel = /* GraphQL */ `
  mutation CreatePressedPanel(
    $input: CreatePressedPanelInput!
    $condition: ModelPressedPanelConditionInput
  ) {
    createPressedPanel(input: $input, condition: $condition) {
      id
      x
      y
      ownerScreen {
        id
        panelColumns
        panelRows
        panelID
        projectID
        startX
        startY
        rotation
        ownerScreenBackgroundContent
        name
        color
        zIndex
        isUsingUserPanel
        project {
          id
          name
          projectBackgroundContent
          projectBackgroundContentPlay
          projectBackgroundContentGrid
          projectBackgroundContentLabels
          projectWidth
          projectHeight
          screens {
            items {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          creator {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          outputs {
            items {
              id
              name
              projectID
              width
              height
              startX
              startY
              color
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          isGridOn
          isLabelOn
          isStarredOn
          isPlaying
          createdAt
          updatedAt
          owner
        }
        panel {
          id
          make
          model
          pixelWidth
          pixelHeight
          moduleColumns
          moduleRows
          weight
          maxWatt
          averageWatt
          frameSubSpecies
          sizeSubSpecies
          createdAt
          updatedAt
          owner
        }
        userPanel {
          id
          make
          model
          pixelWidth
          pixelHeight
          moduleColumns
          moduleRows
          weight
          maxWatt
          averageWatt
          frameSubSpecies
          sizeSubSpecies
          createdAt
          updatedAt
          owner
        }
        pressedPanel {
          items {
            id
            x
            y
            ownerScreen {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            projectID
            ownerScreenID
            index
            panelState
            isStarred
            isOn
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        pressedModule {
          items {
            id
            panelX
            panelY
            moduleX
            moduleY
            moduleState
            index
            ownerScreenID
            projectID
            ownerScreen {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            isStarred
            isReplaced
            isOn
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      projectID
      ownerScreenID
      index
      panelState
      isStarred
      isOn
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePressedPanel = /* GraphQL */ `
  mutation UpdatePressedPanel(
    $input: UpdatePressedPanelInput!
    $condition: ModelPressedPanelConditionInput
  ) {
    updatePressedPanel(input: $input, condition: $condition) {
      id
      x
      y
      ownerScreen {
        id
        panelColumns
        panelRows
        panelID
        projectID
        startX
        startY
        rotation
        ownerScreenBackgroundContent
        name
        color
        zIndex
        isUsingUserPanel
        project {
          id
          name
          projectBackgroundContent
          projectBackgroundContentPlay
          projectBackgroundContentGrid
          projectBackgroundContentLabels
          projectWidth
          projectHeight
          screens {
            items {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          creator {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          outputs {
            items {
              id
              name
              projectID
              width
              height
              startX
              startY
              color
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          isGridOn
          isLabelOn
          isStarredOn
          isPlaying
          createdAt
          updatedAt
          owner
        }
        panel {
          id
          make
          model
          pixelWidth
          pixelHeight
          moduleColumns
          moduleRows
          weight
          maxWatt
          averageWatt
          frameSubSpecies
          sizeSubSpecies
          createdAt
          updatedAt
          owner
        }
        userPanel {
          id
          make
          model
          pixelWidth
          pixelHeight
          moduleColumns
          moduleRows
          weight
          maxWatt
          averageWatt
          frameSubSpecies
          sizeSubSpecies
          createdAt
          updatedAt
          owner
        }
        pressedPanel {
          items {
            id
            x
            y
            ownerScreen {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            projectID
            ownerScreenID
            index
            panelState
            isStarred
            isOn
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        pressedModule {
          items {
            id
            panelX
            panelY
            moduleX
            moduleY
            moduleState
            index
            ownerScreenID
            projectID
            ownerScreen {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            isStarred
            isReplaced
            isOn
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      projectID
      ownerScreenID
      index
      panelState
      isStarred
      isOn
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePressedPanel = /* GraphQL */ `
  mutation DeletePressedPanel(
    $input: DeletePressedPanelInput!
    $condition: ModelPressedPanelConditionInput
  ) {
    deletePressedPanel(input: $input, condition: $condition) {
      id
      x
      y
      ownerScreen {
        id
        panelColumns
        panelRows
        panelID
        projectID
        startX
        startY
        rotation
        ownerScreenBackgroundContent
        name
        color
        zIndex
        isUsingUserPanel
        project {
          id
          name
          projectBackgroundContent
          projectBackgroundContentPlay
          projectBackgroundContentGrid
          projectBackgroundContentLabels
          projectWidth
          projectHeight
          screens {
            items {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          creator {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          outputs {
            items {
              id
              name
              projectID
              width
              height
              startX
              startY
              color
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          isGridOn
          isLabelOn
          isStarredOn
          isPlaying
          createdAt
          updatedAt
          owner
        }
        panel {
          id
          make
          model
          pixelWidth
          pixelHeight
          moduleColumns
          moduleRows
          weight
          maxWatt
          averageWatt
          frameSubSpecies
          sizeSubSpecies
          createdAt
          updatedAt
          owner
        }
        userPanel {
          id
          make
          model
          pixelWidth
          pixelHeight
          moduleColumns
          moduleRows
          weight
          maxWatt
          averageWatt
          frameSubSpecies
          sizeSubSpecies
          createdAt
          updatedAt
          owner
        }
        pressedPanel {
          items {
            id
            x
            y
            ownerScreen {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            projectID
            ownerScreenID
            index
            panelState
            isStarred
            isOn
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        pressedModule {
          items {
            id
            panelX
            panelY
            moduleX
            moduleY
            moduleState
            index
            ownerScreenID
            projectID
            ownerScreen {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            isStarred
            isReplaced
            isOn
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      projectID
      ownerScreenID
      index
      panelState
      isStarred
      isOn
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPressedModule = /* GraphQL */ `
  mutation CreatePressedModule(
    $input: CreatePressedModuleInput!
    $condition: ModelPressedModuleConditionInput
  ) {
    createPressedModule(input: $input, condition: $condition) {
      id
      panelX
      panelY
      moduleX
      moduleY
      moduleState
      index
      ownerScreenID
      projectID
      ownerScreen {
        id
        panelColumns
        panelRows
        panelID
        projectID
        startX
        startY
        rotation
        ownerScreenBackgroundContent
        name
        color
        zIndex
        isUsingUserPanel
        project {
          id
          name
          projectBackgroundContent
          projectBackgroundContentPlay
          projectBackgroundContentGrid
          projectBackgroundContentLabels
          projectWidth
          projectHeight
          screens {
            items {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          creator {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          outputs {
            items {
              id
              name
              projectID
              width
              height
              startX
              startY
              color
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          isGridOn
          isLabelOn
          isStarredOn
          isPlaying
          createdAt
          updatedAt
          owner
        }
        panel {
          id
          make
          model
          pixelWidth
          pixelHeight
          moduleColumns
          moduleRows
          weight
          maxWatt
          averageWatt
          frameSubSpecies
          sizeSubSpecies
          createdAt
          updatedAt
          owner
        }
        userPanel {
          id
          make
          model
          pixelWidth
          pixelHeight
          moduleColumns
          moduleRows
          weight
          maxWatt
          averageWatt
          frameSubSpecies
          sizeSubSpecies
          createdAt
          updatedAt
          owner
        }
        pressedPanel {
          items {
            id
            x
            y
            ownerScreen {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            projectID
            ownerScreenID
            index
            panelState
            isStarred
            isOn
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        pressedModule {
          items {
            id
            panelX
            panelY
            moduleX
            moduleY
            moduleState
            index
            ownerScreenID
            projectID
            ownerScreen {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            isStarred
            isReplaced
            isOn
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      isStarred
      isReplaced
      isOn
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePressedModule = /* GraphQL */ `
  mutation UpdatePressedModule(
    $input: UpdatePressedModuleInput!
    $condition: ModelPressedModuleConditionInput
  ) {
    updatePressedModule(input: $input, condition: $condition) {
      id
      panelX
      panelY
      moduleX
      moduleY
      moduleState
      index
      ownerScreenID
      projectID
      ownerScreen {
        id
        panelColumns
        panelRows
        panelID
        projectID
        startX
        startY
        rotation
        ownerScreenBackgroundContent
        name
        color
        zIndex
        isUsingUserPanel
        project {
          id
          name
          projectBackgroundContent
          projectBackgroundContentPlay
          projectBackgroundContentGrid
          projectBackgroundContentLabels
          projectWidth
          projectHeight
          screens {
            items {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          creator {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          outputs {
            items {
              id
              name
              projectID
              width
              height
              startX
              startY
              color
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          isGridOn
          isLabelOn
          isStarredOn
          isPlaying
          createdAt
          updatedAt
          owner
        }
        panel {
          id
          make
          model
          pixelWidth
          pixelHeight
          moduleColumns
          moduleRows
          weight
          maxWatt
          averageWatt
          frameSubSpecies
          sizeSubSpecies
          createdAt
          updatedAt
          owner
        }
        userPanel {
          id
          make
          model
          pixelWidth
          pixelHeight
          moduleColumns
          moduleRows
          weight
          maxWatt
          averageWatt
          frameSubSpecies
          sizeSubSpecies
          createdAt
          updatedAt
          owner
        }
        pressedPanel {
          items {
            id
            x
            y
            ownerScreen {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            projectID
            ownerScreenID
            index
            panelState
            isStarred
            isOn
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        pressedModule {
          items {
            id
            panelX
            panelY
            moduleX
            moduleY
            moduleState
            index
            ownerScreenID
            projectID
            ownerScreen {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            isStarred
            isReplaced
            isOn
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      isStarred
      isReplaced
      isOn
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePressedModule = /* GraphQL */ `
  mutation DeletePressedModule(
    $input: DeletePressedModuleInput!
    $condition: ModelPressedModuleConditionInput
  ) {
    deletePressedModule(input: $input, condition: $condition) {
      id
      panelX
      panelY
      moduleX
      moduleY
      moduleState
      index
      ownerScreenID
      projectID
      ownerScreen {
        id
        panelColumns
        panelRows
        panelID
        projectID
        startX
        startY
        rotation
        ownerScreenBackgroundContent
        name
        color
        zIndex
        isUsingUserPanel
        project {
          id
          name
          projectBackgroundContent
          projectBackgroundContentPlay
          projectBackgroundContentGrid
          projectBackgroundContentLabels
          projectWidth
          projectHeight
          screens {
            items {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          creator {
            id
            lastLogin
            username
            currentSubscription {
              nextToken
            }
            subscriptions {
              nextToken
            }
            availableProjects {
              nextToken
            }
            email
            createdAt
            updatedAt
            owner
          }
          outputs {
            items {
              id
              name
              projectID
              width
              height
              startX
              startY
              color
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          isGridOn
          isLabelOn
          isStarredOn
          isPlaying
          createdAt
          updatedAt
          owner
        }
        panel {
          id
          make
          model
          pixelWidth
          pixelHeight
          moduleColumns
          moduleRows
          weight
          maxWatt
          averageWatt
          frameSubSpecies
          sizeSubSpecies
          createdAt
          updatedAt
          owner
        }
        userPanel {
          id
          make
          model
          pixelWidth
          pixelHeight
          moduleColumns
          moduleRows
          weight
          maxWatt
          averageWatt
          frameSubSpecies
          sizeSubSpecies
          createdAt
          updatedAt
          owner
        }
        pressedPanel {
          items {
            id
            x
            y
            ownerScreen {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            projectID
            ownerScreenID
            index
            panelState
            isStarred
            isOn
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        pressedModule {
          items {
            id
            panelX
            panelY
            moduleX
            moduleY
            moduleState
            index
            ownerScreenID
            projectID
            ownerScreen {
              id
              panelColumns
              panelRows
              panelID
              projectID
              startX
              startY
              rotation
              ownerScreenBackgroundContent
              name
              color
              zIndex
              isUsingUserPanel
              createdAt
              updatedAt
              owner
            }
            isStarred
            isReplaced
            isOn
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      isStarred
      isReplaced
      isOn
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPanel = /* GraphQL */ `
  mutation CreatePanel(
    $input: CreatePanelInput!
    $condition: ModelPanelConditionInput
  ) {
    createPanel(input: $input, condition: $condition) {
      id
      make
      model
      pixelWidth
      pixelHeight
      moduleColumns
      moduleRows
      weight
      maxWatt
      averageWatt
      frameSubSpecies
      sizeSubSpecies
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePanel = /* GraphQL */ `
  mutation UpdatePanel(
    $input: UpdatePanelInput!
    $condition: ModelPanelConditionInput
  ) {
    updatePanel(input: $input, condition: $condition) {
      id
      make
      model
      pixelWidth
      pixelHeight
      moduleColumns
      moduleRows
      weight
      maxWatt
      averageWatt
      frameSubSpecies
      sizeSubSpecies
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePanel = /* GraphQL */ `
  mutation DeletePanel(
    $input: DeletePanelInput!
    $condition: ModelPanelConditionInput
  ) {
    deletePanel(input: $input, condition: $condition) {
      id
      make
      model
      pixelWidth
      pixelHeight
      moduleColumns
      moduleRows
      weight
      maxWatt
      averageWatt
      frameSubSpecies
      sizeSubSpecies
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createUserPanel = /* GraphQL */ `
  mutation CreateUserPanel(
    $input: CreateUserPanelInput!
    $condition: ModelUserPanelConditionInput
  ) {
    createUserPanel(input: $input, condition: $condition) {
      id
      make
      model
      pixelWidth
      pixelHeight
      moduleColumns
      moduleRows
      weight
      maxWatt
      averageWatt
      frameSubSpecies
      sizeSubSpecies
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUserPanel = /* GraphQL */ `
  mutation UpdateUserPanel(
    $input: UpdateUserPanelInput!
    $condition: ModelUserPanelConditionInput
  ) {
    updateUserPanel(input: $input, condition: $condition) {
      id
      make
      model
      pixelWidth
      pixelHeight
      moduleColumns
      moduleRows
      weight
      maxWatt
      averageWatt
      frameSubSpecies
      sizeSubSpecies
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUserPanel = /* GraphQL */ `
  mutation DeleteUserPanel(
    $input: DeleteUserPanelInput!
    $condition: ModelUserPanelConditionInput
  ) {
    deleteUserPanel(input: $input, condition: $condition) {
      id
      make
      model
      pixelWidth
      pixelHeight
      moduleColumns
      moduleRows
      weight
      maxWatt
      averageWatt
      frameSubSpecies
      sizeSubSpecies
      createdAt
      updatedAt
      owner
    }
  }
`;
export const sendEmail = /* GraphQL */ `
  mutation SendEmail($input: emailInput!) {
    sendEmail(input: $input)
  }
`;
export const emailBetaRequestToSupport = /* GraphQL */ `
  mutation EmailBetaRequestToSupport($input: emailBetaTestRequestInput!) {
    emailBetaRequestToSupport(input: $input) {
      res
    }
  }
`;
