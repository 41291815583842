import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";
import "./svg.css";
import FooterMobile from "./FooterMobile";
import FooterWeb from "./FooterWeb";

function Footer() {
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("xs"));

  return <Box>{isXS ? <FooterMobile /> : <FooterWeb />}</Box>;
}

export default Footer;
