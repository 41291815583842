import { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";

import { IdColor } from "./Content/id.color";
import { Chroma } from "./Content/chroma.jsx";
import { Black } from "./Content/black";
import { White } from "./Content/white";
import { ColorBars } from "./Content/colorbars";
import { IdText } from "./Content/id.text";
import { CheckeredBars } from "./Content/checkeredbars";
import { TwelveStep } from "./Content/twelvestep";
import { Scroll } from "./Content/scroll";
import { StoreContext } from "../../../stores/store.context";

export const ContentSwitch = observer(() => {
  const { project } = useContext(StoreContext);

  const Content = () => {
    switch (project.projectBackgroundContent) {
      case "check":
        return <CheckeredBars />;
      case "white":
        return <White />;
      case "black":
        return <Black />;
      case "bars":
        return <ColorBars />;
      case "id":
        return (
          <div>
            <IdColor />
            <div style={{ zIndex: 5 }}>
              <IdText />
            </div>
          </div>
        );
      case "chroma":
        return <Chroma />;
      case "gradient":
        return <TwelveStep />;
      case "scroll":
        return <Scroll />;
      default:
        break;
    }
  };

  return (
    project.projectBackgroundContent && (
      <div style={{ zIndex: 2, position: "absolute" }}>
        <Content />
      </div>
    )
  );
});
