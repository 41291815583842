import React, { useEffect, useState } from "react";

import "../svg.css";

export const StreetIllustration = () => {
  const on = "#fff";

  const [scrollPosition, setScrollPosition] = useState(0);
  const [phoneButtonHasBeenClicked, setPhoneButtonHasBeenClicked] =
    useState(false);

  let scrollTranslate = `translate(${scrollPosition}, 0)`;

  useEffect(() => {
    const button = document.getElementById("pa1");
    const x = button.getBoundingClientRect();
    const tooltip = document.getElementById("tooltip");
    tooltip.style.height = `${x.height}px`;
    tooltip.style.width = `${x.width}px`;
    tooltip.style.left = `${x.left}px`;
    tooltip.style.right = `${x.right}px`;
    tooltip.style.top = `${x.top - x.width}px`;
  }, []);

  const handleClick = (child) => {
    if (!phoneButtonHasBeenClicked) {
      setPhoneButtonHasBeenClicked(true);
      document.getElementById("tooltip").style.opacity = "0";
    }
    const button = child.closest("g").getAttribute("id");
    const rect = child.closest("g").querySelector("rect");
    const text = child.closest("g").querySelector("text");
    const rectState = rect.getAttribute("fill");
    const panelId = "s" + button.substring(1);
    const panel = document.getElementById(panelId).children[0];
    const on = "#fff";
    const off = "#000";
    const offScreen = "#282828";

    const whipeOff = () => {
      text.setAttribute("fill", on);
      rect.setAttribute("fill", off);
      panel.setAttribute("fill", offScreen);
    };

    const whipeOn = () => {
      text.setAttribute("fill", off);
      rect.setAttribute("fill", on);
      panel.setAttribute("fill", on);
    };
    rectState === on ? whipeOff() : whipeOn();
  };

  useEffect(() => {
    let selectedBall = false;
    let selectedBallTouch = false;
    let ball = document.getElementById("ball");

    ball.addEventListener("mousedown", startDrag);
    ball.addEventListener("touchstart", startDragTouch);

    function startDrag() {
      selectedBall = true;
      ball.addEventListener("mousemove", drag);
      ball.addEventListener("mouseup", endDrag);
      document.addEventListener("mouseup", endDrag);
    }

    function startDragTouch() {
      selectedBallTouch = true;
      ball.addEventListener("touchmove", dragTouch);
      ball.addEventListener("touchend", endDragTouch);
      document.addEventListener("touchend", endDragTouch);
    }

    function drag(e) {
      e.preventDefault();
      let svg = document.querySelector("svg");
      let pt = svg.createSVGPoint();

      function cursorPoint(e) {
        pt.x = e.clientX;
        pt.y = e.clientY;
        return pt.matrixTransform(svg.getScreenCTM().inverse());
      }

      if (selectedBall) {
        let loc = cursorPoint(e);
        let scroll = (421 - loc.x) * 7.5;
        if (loc.x > 421 && loc.x < 595) {
          ball.setAttribute("cx", loc.x);
          requestAnimationFrame(() => setScrollPosition(scroll));
        }
      }
    }

    function dragTouch(e) {
      e.preventDefault();
      let svg = document.querySelector("svg");
      let pt = svg.createSVGPoint();

      function cursorPoint(e) {
        pt.x = e.touches[0].clientX;
        pt.y = e.touches[0].clientY;
        return pt.matrixTransform(svg.getScreenCTM().inverse());
      }

      if (selectedBallTouch) {
        let loc = cursorPoint(e);
        let scroll = (421 - loc.x) * 7.5;
        if (loc.x > 421 && loc.x < 595) {
          ball.setAttribute("cx", loc.x);
          requestAnimationFrame(() => setScrollPosition(scroll));
        }
      }
    }

    function endDrag(e) {
      selectedBall = false;
    }

    function endDragTouch(e) {
      selectedBallTouch = false;
    }

    return () => {
      ball.removeEventListener("mousedown", startDrag);
      ball.removeEventListener("mousemove", drag);
      ball.removeEventListener("mouseup", endDrag);
      document.removeEventListener("mouseleave", endDrag);
    };
  }, []);

  return (
    <>
      {!phoneButtonHasBeenClicked && (
        <div id="tooltip">
          <div className="tooltip-text">Click!</div>
          <div className="triangle" />
        </div>
      )}
      <svg version="1.1" viewBox="60 0 1920 1296.6" className="svg-container">
        <defs>
          <clipPath id="phoneClip" transform="translate(387 356)">
            <rect width="235" height="513" fill="none" />
          </clipPath>
          <clipPath id="clipPath" transform="translate(417.915 238.725)">
            <rect width="1920.612" height="1150" fill="none" />
          </clipPath>
        </defs>
        <g id="street">
          <path
            id="STREET"
            className="st0"
            d="M1128.1,1219.8c97.3-41.1,205.4-49.4,307.8-23.5l770.9,194.7V983.8l-298.4-37.2
      c-24.1-2.8-32.4-8.4-32.4-14.5v-17.9c-0.3-1-0.3-2,0-3.1c0,0,0,0,0,0c1.4-6,10.8-12.1,20.6-16.2l310.2-131.1v-81.5l-877.2,180.3
      c-29.1,6-59,7.2-88.5,3.6L-292.4,680v79.9l796.7,202.4c12.6,2.7,18.9,8.7,19.5,15.7c0,0.1,0,0.3,0,0.4c0,0.8,0,1.5-0.1,2.3
      c-0.1,5.4-0.4,17.3,0,22.7c1,12.6-16,28.3-47.5,34.4l-768.8,158.1v241.5h905.5L1128.1,1219.8z"
          />
        </g>
        <g id="landscape_illustration">
          <g id="street_markings">
            <g>
              <polygon
                fill="none"
                stroke="#999999"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
          780.81,896.477 745.324,902.256 448.659,849.836 482.457,845.96 		"
              />

              <polygon
                fill="none"
                stroke="#999999"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
          715.015,907.191 676.796,913.415 384.089,857.241 419.989,853.124 		"
              />

              <polygon
                fill="none"
                stroke="#999999"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
          644.108,918.739 602.827,925.461 315.404,865.117 353.607,860.736 		"
              />

              <polygon
                fill="none"
                stroke="#999999"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
          900.223,877.03 869.434,882.044 567.964,836.154 598.043,832.705 		"
              />

              <polygon
                fill="none"
                stroke="#999999"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
          1050.754,852.516 1025.403,856.645 722.37,818.447 747.953,815.513 		"
              />

              <polygon
                fill="none"
                stroke="#999999"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
          1003.745,860.172 976.754,864.567 673.66,824.033 700.623,820.941 		"
              />

              <polygon
                fill="none"
                stroke="#999999"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
          953.672,868.326 924.875,873.016 622.268,829.927 650.725,826.663 		"
              />

              <polygon
                fill="none"
                stroke="#999999"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
          567.467,931.22 522.741,938.503 242.198,873.513 282.934,868.841 		"
              />
            </g>
            <g>
              <polygon
                fill="none"
                stroke="#999999"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
          275.345,1213.116 248.088,1199.039 623.09,1103.137 653.338,1113.007 		"
              />

              <polygon
                fill="none"
                stroke="#999999"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
          226.578,1187.93 201.512,1174.984 570.866,1086.096 599.056,1095.295 		"
              />

              <polygon
                fill="none"
                stroke="#999999"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
          181.698,1164.751 158.57,1152.806 522.105,1070.186 548.441,1078.779 		"
              />

              <polygon
                fill="none"
                stroke="#999999"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
          387.968,1271.281 355.308,1254.414 740.768,1141.535 775.926,1153.007 		"
              />

              <polygon
                fill="none"
                stroke="#999999"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
          602.351,1382.002 558.097,1359.146 954.098,1211.144 999.121,1225.834 		"
              />

              <polygon
                fill="none"
                stroke="#999999"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
          523.375,1341.214 483.591,1320.667 877.079,1186.013 918.397,1199.494 		"
              />

              <polygon
                fill="none"
                stroke="#999999"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
          452.289,1304.501 416.331,1285.929 806.205,1162.887 844.257,1175.303 		"
              />

              <polygon
                fill="none"
                stroke="#999999"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
          140.258,1143.349 118.851,1132.293 476.475,1055.297 501.132,1063.343 		"
              />
            </g>
            <g>
              <polygon
                fill="none"
                stroke="#999999"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
          1678.728,844.84 1654.688,842.717 1866.614,788.32 1886.439,789.625 		"
              />

              <polygon
                fill="none"
                stroke="#999999"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
          1635.178,840.993 1611.816,838.929 1831.079,785.982 1850.471,787.258 		"
              />

              <polygon
                fill="none"
                stroke="#999999"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
          1592.851,837.254 1570.138,835.247 1796.315,783.694 1815.288,784.942 		"
              />

              <polygon
                fill="none"
                stroke="#999999"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
          1770.653,852.961 1745.151,850.709 1940.847,793.206 1961.592,794.571 		"
              />

              <polygon
                fill="none"
                stroke="#999999"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
          1918.265,866.002 1890.328,863.534 2057.905,800.91 2080.145,802.373 		"
              />

              <polygon
                fill="none"
                stroke="#999999"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
          1867.547,861.521 1840.459,859.128 2017.979,798.282 2039.703,799.712 		"
              />

              <polygon
                fill="none"
                stroke="#999999"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
          1818.366,857.177 1792.089,854.855 1978.97,795.715 2000.196,797.112 		"
              />

              <polygon
                fill="none"
                stroke="#999999"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
          1551.697,833.618 1529.606,831.666 1762.295,781.455 1780.862,782.677 		"
              />
            </g>
            <g>
              <polygon
                fill="none"
                stroke="#999999"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
          2111.208,1127.05 2094.281,1145.794 1764.828,1086.137 1790.206,1071.22 		"
              />

              <polygon
                fill="none"
                stroke="#999999"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
          2079.404,1162.269 2060.065,1183.685 1714.11,1115.948 1742.681,1099.155 		"
              />

              <polygon
                fill="none"
                stroke="#999999"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
          2042.989,1202.594 2020.683,1227.295 1656.677,1149.707 1689.085,1130.658 		"
              />

              <polygon
                fill="none"
                stroke="#999999"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
          2164.562,1067.968 2151.31,1082.643 1851.103,1035.426 1871.471,1023.454 		"
              />

              <polygon
                fill="none"
                stroke="#999999"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
          2224.83,1001.229 2215.174,1011.922 1950.396,977.063 1965.662,968.089 		"
              />

              <polygon
                fill="none"
                stroke="#999999"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
          2206.773,1021.225 2196.101,1033.043 1920.439,994.671 1937.169,984.837 		"
              />

              <polygon
                fill="none"
                stroke="#999999"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
          2186.792,1043.351 2174.935,1056.482 1887.498,1014.033 1905.912,1003.209 		"
              />

              <polygon
                fill="none"
                stroke="#999999"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="
          2000.884,1249.22 1974.871,1278.026 1591.102,1188.251 1628.175,1166.46 		"
              />
            </g>
            <polyline
              fill="none"
              stroke="#999999"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              points="
      2248.238,700.435 1715.354,848.076 1699.556,846.68 2257.567,695.158 	"
            />
          </g>

          <g id="Layer_20">
            <polygon
              className="st11"
              points="1828.7,-89.5 2140.4,195.6 2140.4,664.2 1828.7,705.2 		"
            />
            <polygon
              className="st11"
              points="1570.7,-23.8 1828.7,-89.5 1828.7,461.3 1570.7,264.4 		"
            />
            <g>
              <g>
                <polygon
                  className="st11"
                  points="1624.1,64.9 1595.5,70.9 1595.5,25.4 1624.1,18.9 				"
                />
                <polygon
                  className="st11"
                  points="1679.9,53.2 1650,59.5 1650,13.1 1679.9,6.3 				"
                />
                <polygon
                  className="st11"
                  points="1738.1,41 1706.9,47.6 1706.9,0.1 1738.1,-7 				"
                />
                <polygon
                  className="st11"
                  points="1799.1,28.3 1766.4,35.1 1766.4,-13.4 1799.1,-20.8 				"
                />
                <polygon
                  className="st11"
                  points="1624.1,142.1 1595.5,147.2 1595.5,101.8 1624.1,96.1 				"
                />
                <polygon
                  className="st11"
                  points="1679.9,132.1 1650,137.5 1650,91 1679.9,85.2 				"
                />
                <polygon
                  className="st11"
                  points="1738.1,121.7 1706.9,127.3 1706.9,79.9 1738.1,73.7 				"
                />
                <polygon
                  className="st11"
                  points="1799.1,110.9 1766.4,116.7 1766.4,68.2 1799.1,61.7 				"
                />
                <polygon
                  className="st11"
                  points="1624.1,218.3 1595.5,222.5 1595.5,177.1 1624.1,172.3 				"
                />
                <polygon
                  className="st11"
                  points="1679.9,210 1650,214.4 1650,168 1679.9,163.1 				"
                />
                <polygon
                  className="st11"
                  points="1738.1,201.4 1706.9,206 1706.9,158.6 1738.1,153.4 				"
                />
                <polygon
                  className="st11"
                  points="1799.1,192.4 1766.4,197.2 1766.4,148.7 1799.1,143.2 				"
                />
                <polygon
                  className="st11"
                  points="1624.1,303.2 1595.5,306.5 1595.5,261.1 1624.1,257.3 				"
                />
                <polygon
                  className="st11"
                  points="1679.9,296.8 1650,300.3 1650,253.8 1679.9,249.9 				"
                />
                <polygon
                  className="st11"
                  points="1738.1,290.2 1706.9,293.8 1706.9,246.3 1738.1,242.2 				"
                />
                <polygon
                  className="st11"
                  points="1799.1,283.2 1766.4,287 1766.4,238.4 1799.1,234.1 				"
                />
                <polygon
                  className="st11"
                  points="1624.1,378.2 1595.5,380.6 1595.5,335.1 1624.1,332.2 				"
                />
                <polygon
                  className="st11"
                  points="1679.9,373.4 1650,376 1650,329.6 1679.9,326.5 				"
                />
                <polygon
                  className="st11"
                  points="1738.1,368.5 1706.9,371.2 1706.9,323.7 1738.1,320.5 				"
                />
                <polygon
                  className="st11"
                  points="1799.1,363.4 1766.4,366.1 1766.4,317.6 1799.1,314.2 				"
                />
              </g>
              <polygon
                className="st11"
                points="1875.7,58.2 1851.5,39.8 1851.6,-8.6 1875.7,11.4 			"
              />
              <polygon
                className="st11"
                points="1917,89.8 1895.7,73.5 1895.8,28.1 1917.1,45.8 			"
              />
              <polygon
                className="st11"
                points="1953.7,117.8 1934.8,103.4 1934.9,60.6 1953.8,76.4 			"
              />
              <polygon
                className="st11"
                points="1986.6,142.9 1969.6,129.9 1969.7,89.5 1986.7,103.6 			"
              />
              <polygon
                className="st11"
                points="1875.6,136.7 1851.5,121 1851.5,72.7 1875.6,90 			"
              />
              <polygon
                className="st11"
                points="1916.9,163.6 1895.6,149.7 1895.7,104.4 1917,119.7 			"
              />
              <polygon
                className="st11"
                points="1953.6,187.4 1934.7,175.1 1934.7,132.4 1953.7,146 			"
              />
              <polygon
                className="st11"
                points="1986.4,208.8 1969.4,197.7 1969.5,157.4 1986.5,169.6 			"
              />
              <polygon
                className="st11"
                points="1875.6,213.9 1851.5,200.9 1851.5,152.7 1875.6,167.3 			"
              />
              <polygon
                className="st11"
                points="1916.8,236.2 1895.5,224.7 1895.6,179.5 1916.9,192.4 			"
              />
              <polygon
                className="st11"
                points="1953.4,255.9 1934.5,245.7 1934.6,203.2 1953.5,214.6 			"
              />
              <polygon
                className="st11"
                points="1986.2,273.6 1969.3,264.5 1969.4,224.2 1986.3,234.5 			"
              />
              <polygon
                className="st11"
                points="1875.5,299.7 1851.4,289.7 1851.5,241.7 1875.5,253.3 			"
              />
              <polygon
                className="st11"
                points="1916.7,316.9 1895.5,308 1895.5,263 1916.8,273.2 			"
              />
              <polygon
                className="st11"
                points="1953.3,332.1 1934.4,324.3 1934.5,281.8 1953.4,290.9 			"
              />
              <polygon
                className="st11"
                points="1986,345.8 1969.1,338.7 1969.2,298.6 1986.1,306.8 			"
              />
              <polygon
                className="st11"
                points="1875.4,375.3 1851.4,367.9 1851.4,320 1875.5,329 			"
              />
              <polygon
                className="st11"
                points="1916.6,387.9 1895.4,381.4 1895.4,336.4 1916.6,344.4 			"
              />
              <polygon
                className="st11"
                points="1953.1,399.2 1934.3,393.4 1934.3,351 1953.2,358.1 			"
              />
              <polygon
                className="st11"
                points="1985.8,409.2 1968.9,404 1969,364 1985.9,370.3 			"
              />
            </g>
            <polygon
              className="st11"
              points="1828.7,219.7 1828.7,479.3 1933.9,497 1933.9,273.1 		"
            />
            <line
              className="st11"
              x1="2139.4"
              y1="515.2"
              x2="1829"
              y2="452.7"
            />
            <g>
              <polygon
                className="st12"
                points="1933.9,497 1828.7,478.5 1828.7,219.7 1933.9,273.7 			"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="223.7"
                x2="1933.9"
                y2="277.2"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="229.8"
                x2="1933.9"
                y2="282.4"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="235.9"
                x2="1933.9"
                y2="287.7"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="242.1"
                x2="1933.9"
                y2="293"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="248.2"
                x2="1933.9"
                y2="298.3"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="254.3"
                x2="1933.9"
                y2="303.5"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="260.4"
                x2="1933.9"
                y2="308.8"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="266.5"
                x2="1933.9"
                y2="314.1"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="272.6"
                x2="1933.9"
                y2="319.4"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="278.7"
                x2="1933.9"
                y2="324.6"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="284.9"
                x2="1933.9"
                y2="329.9"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="291"
                x2="1933.9"
                y2="335.2"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="297.1"
                x2="1933.9"
                y2="340.5"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="303.2"
                x2="1933.9"
                y2="345.8"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="309.3"
                x2="1933.9"
                y2="351"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="315.4"
                x2="1933.9"
                y2="356.3"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="321.6"
                x2="1933.9"
                y2="361.6"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="327.7"
                x2="1933.9"
                y2="366.9"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="333.8"
                x2="1933.9"
                y2="372.1"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="339.9"
                x2="1933.9"
                y2="377.4"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="346"
                x2="1933.9"
                y2="382.7"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="352.1"
                x2="1933.9"
                y2="388"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="358.3"
                x2="1933.9"
                y2="393.2"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="364.4"
                x2="1933.9"
                y2="398.5"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="370.5"
                x2="1933.9"
                y2="403.8"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="376.6"
                x2="1933.9"
                y2="409.1"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="382.7"
                x2="1933.9"
                y2="414.4"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="388.8"
                x2="1933.9"
                y2="419.6"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="394.9"
                x2="1933.9"
                y2="424.9"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="401.1"
                x2="1933.9"
                y2="430.2"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="407.2"
                x2="1933.9"
                y2="435.5"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="425.5"
                x2="1933.9"
                y2="451.3"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="437.8"
                x2="1933.9"
                y2="461.8"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="450"
                x2="1933.9"
                y2="472.4"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="462.2"
                x2="1933.9"
                y2="482.9"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="413.3"
                x2="1933.9"
                y2="440.7"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="419.4"
                x2="1933.9"
                y2="446"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="431.6"
                x2="1933.9"
                y2="456.6"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="443.9"
                x2="1933.9"
                y2="467.1"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="456.1"
                x2="1933.9"
                y2="477.7"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="468.3"
                x2="1933.9"
                y2="488.2"
              />
              <line
                className="st13"
                x1="1828.7"
                y1="474.5"
                x2="1933.9"
                y2="493.5"
              />
            </g>
            <polygon
              className="st14"
              points="1685.5,313 1685.5,724 1828.7,705.2 1828.7,359.3 		"
            />
            <polygon
              className="st15"
              points="1837.1,605.7 1837.1,569.7 1685.5,562.5 1685.5,606.3 		"
            />
            <polygon
              className="st15"
              points="1837.1,462.9 1837.1,505.8 1685.5,484.4 1685.5,434.2 		"
            />
            <polygon
              className="st15"
              points="1727.9,643.7 1727.2,719.2 1799.9,709 1799.9,640.4 		"
            />
            <line
              className="st11"
              x1="1765.9"
              y1="713.7"
              x2="1765.9"
              y2="642.2"
            />
            <g>
              <polygon
                className="st16"
                points="1909.1,694.5 1863.6,700.4 1863.6,529.1 1909.1,534 			"
              />
              <polygon
                className="st16"
                points="1963.1,687.4 1924.9,692.4 1924.9,535.8 1963.1,539.9 			"
              />
              <polygon
                className="st16"
                points="2009.1,681.4 1976.5,685.7 1976.5,541.3 2009.1,544.9 			"
              />
              <polygon
                className="st16"
                points="2048.7,676.2 2020.6,679.9 2020.6,546.1 2048.7,549.2 			"
              />
            </g>
            <line
              className="st16"
              x1="1828.7"
              y1="557"
              x2="1685.5"
              y2="547.9"
            />
            <line
              className="st11"
              x1="2140.4"
              y1="541.3"
              x2="1837.1"
              y2="497"
            />
          </g>
          <g id="Layer_18">
            <polygon
              className="st15"
              points="1483.1,-70.1 1483.1,150.4 1509.3,159.5 1509.3,-77.5 		"
            />
            <polyline
              className="st15"
              points="511.8,707.5 512.3,42.4 731.7,101.9 731.7,194.5 901.1,232 901.1,671.6 682.8,719.7 		"
            />
            <g>
              <polygon
                className="st17"
                points="415.8,-169 415.8,80.6 223.8,157.5 222.8,-237.7 			"
              />
              <polygon
                className="st12"
                points="400.5,191 245.4,161.1 245.4,-167.9 400.5,-116.2 			"
              />
              <line
                className="st13"
                x1="245.4"
                y1="-162.9"
                x2="400.5"
                y2="-111.5"
              />
              <line
                className="st13"
                x1="245.4"
                y1="-155.1"
                x2="400.5"
                y2="-104.3"
              />
              <line
                className="st13"
                x1="245.4"
                y1="-147.3"
                x2="400.5"
                y2="-97"
              />
              <line
                className="st13"
                x1="245.4"
                y1="-139.6"
                x2="400.5"
                y2="-89.7"
              />
              <line
                className="st13"
                x1="245.4"
                y1="-131.8"
                x2="400.5"
                y2="-82.5"
              />
              <line
                className="st13"
                x1="245.4"
                y1="-124"
                x2="400.5"
                y2="-75.2"
              />
              <line
                className="st13"
                x1="245.4"
                y1="-116.2"
                x2="400.5"
                y2="-68"
              />
              <line
                className="st13"
                x1="245.4"
                y1="-108.5"
                x2="400.5"
                y2="-60.7"
              />
              <line
                className="st13"
                x1="245.4"
                y1="-100.7"
                x2="400.5"
                y2="-53.4"
              />
              <line
                className="st13"
                x1="245.4"
                y1="-92.9"
                x2="400.5"
                y2="-46.2"
              />
              <line
                className="st13"
                x1="245.4"
                y1="-85.1"
                x2="400.5"
                y2="-38.9"
              />
              <line
                className="st13"
                x1="245.4"
                y1="-77.3"
                x2="400.5"
                y2="-31.7"
              />
              <line
                className="st13"
                x1="245.4"
                y1="-69.6"
                x2="400.5"
                y2="-24.4"
              />
              <line
                className="st13"
                x1="245.4"
                y1="-61.8"
                x2="400.5"
                y2="-17.1"
              />
              <line className="st13" x1="245.4" y1="-54" x2="400.5" y2="-9.9" />
              <line
                className="st13"
                x1="245.4"
                y1="-46.2"
                x2="400.5"
                y2="-2.6"
              />
              <line
                className="st13"
                x1="245.4"
                y1="-38.5"
                x2="400.5"
                y2="4.6"
              />
              <line
                className="st13"
                x1="245.4"
                y1="-30.7"
                x2="400.5"
                y2="11.9"
              />
              <line
                className="st13"
                x1="245.4"
                y1="-22.9"
                x2="400.5"
                y2="19.2"
              />
              <line
                className="st13"
                x1="245.4"
                y1="-15.1"
                x2="400.5"
                y2="26.4"
              />
              <line
                className="st13"
                x1="245.4"
                y1="-7.4"
                x2="400.5"
                y2="33.7"
              />
              <line className="st13" x1="245.4" y1="0.4" x2="400.5" y2="40.9" />
              <line className="st13" x1="245.4" y1="8.2" x2="400.5" y2="48.2" />
              <line className="st13" x1="245.4" y1="16" x2="400.5" y2="55.5" />
              <line
                className="st13"
                x1="245.4"
                y1="23.7"
                x2="400.5"
                y2="62.7"
              />
              <line className="st13" x1="245.4" y1="31.5" x2="400.5" y2="70" />
              <line
                className="st13"
                x1="245.4"
                y1="39.3"
                x2="400.5"
                y2="77.2"
              />
              <line
                className="st13"
                x1="245.4"
                y1="47.1"
                x2="400.5"
                y2="84.5"
              />
              <line
                className="st13"
                x1="245.4"
                y1="54.8"
                x2="400.5"
                y2="91.8"
              />
              <line className="st13" x1="245.4" y1="62.6" x2="400.5" y2="99" />
              <line
                className="st13"
                x1="245.4"
                y1="70.4"
                x2="400.5"
                y2="106.3"
              />
              <line
                className="st13"
                x1="245.4"
                y1="93.7"
                x2="400.5"
                y2="128.1"
              />
              <line
                className="st13"
                x1="245.4"
                y1="109.3"
                x2="400.5"
                y2="142.6"
              />
              <line
                className="st13"
                x1="245.4"
                y1="124.8"
                x2="400.5"
                y2="157.1"
              />
              <line
                className="st13"
                x1="245.4"
                y1="140.4"
                x2="400.5"
                y2="171.6"
              />
              <line
                className="st13"
                x1="245.4"
                y1="78.2"
                x2="400.5"
                y2="113.5"
              />
              <line
                className="st13"
                x1="245.4"
                y1="85.9"
                x2="400.5"
                y2="120.8"
              />
              <line
                className="st13"
                x1="245.4"
                y1="101.5"
                x2="400.5"
                y2="135.3"
              />
              <line
                className="st13"
                x1="245.4"
                y1="117.1"
                x2="400.5"
                y2="149.8"
              />
              <line
                className="st13"
                x1="245.4"
                y1="132.6"
                x2="400.5"
                y2="164.4"
              />
              <line
                className="st13"
                x1="245.4"
                y1="148.2"
                x2="400.5"
                y2="178.9"
              />
              <line
                className="st13"
                x1="245.4"
                y1="155.9"
                x2="400.5"
                y2="186.2"
              />
            </g>
            <polygon
              className="st15"
              points="223.8,157.5 512.3,42.4 511.8,707.5 -368,641.5 -368,174.3 222.8,-237.7 		"
            />
            <polygon
              className="st15"
              points="1442.2,-198.6 1442.2,276.8 1210.5,276.8 1210.5,-119.1 		"
            />
            <polygon
              className="st15"
              points="1570.7,-108.5 1570.7,257.8 1509.3,236.2 1509.3,-77.5 1483.1,-94 1483.1,236.2 1442.2,276.8 
          1442.2,-198.6 1483.5,-169.6 1483.1,-114.5 1509.3,-97.5 1509.3,-151.6 		"
            />
            <polygon
              className="st15"
              points="1483.3,-143.4 1483.1,-114.5 1509.3,-97.5 1509.3,-151.6 		"
            />
            <polygon
              className="st15"
              points="1483.1,-94 1483.1,-70.1 1509.3,-77.5 		"
            />
            <g>
              <g>
                <g>
                  <polygon
                    className="st18"
                    points="690.8,353.5 557.2,335.8 557,195.6 690.8,222.4 					"
                  />
                  <polygon
                    className="st18"
                    points="832.2,372.1 718,357 717.9,227.8 832.1,250.7 					"
                  />
                  <polygon
                    className="st18"
                    points="954.1,388.2 855.5,375.2 855.4,255.3 954.1,275.1 					"
                  />
                </g>
                <g>
                  <polygon
                    className="st18"
                    points="691,583.9 557.4,582.3 557.2,377.1 690.9,392 					"
                  />
                  <polygon
                    className="st18"
                    points="832.2,585.6 718.1,584.3 718,395.1 832.2,407.8 					"
                  />
                  <polygon
                    className="st18"
                    points="954.1,587.1 855.5,585.9 855.5,410.4 954.1,421.5 					"
                  />
                </g>
              </g>
              <polygon
                className="st19"
                points="557,97.5 557.2,155.9 691.3,185.6 691.3,131.2 			"
              />
            </g>
            <line
              className="st15"
              x1="511.8"
              y1="707.5"
              x2="682.8"
              y2="698.6"
            />
            <g>
              <g>
                <line
                  className="st20"
                  x1="572.1"
                  y1="624.3"
                  x2="572"
                  y2="704.3"
                />
                <line
                  className="st20"
                  x1="586.4"
                  y1="624.2"
                  x2="586.3"
                  y2="703.6"
                />
                <line
                  className="st20"
                  x1="600.5"
                  y1="624.1"
                  x2="600.4"
                  y2="702.9"
                />
                <line
                  className="st20"
                  x1="614.4"
                  y1="623.9"
                  x2="614.3"
                  y2="702.2"
                />
                <line
                  className="st20"
                  x1="628.1"
                  y1="623.8"
                  x2="628"
                  y2="701.6"
                />
                <line
                  className="st20"
                  x1="655"
                  y1="623.6"
                  x2="654.9"
                  y2="700.3"
                />
                <line
                  className="st20"
                  x1="641.6"
                  y1="623.7"
                  x2="641.5"
                  y2="700.9"
                />
                <line
                  className="st20"
                  x1="668.1"
                  y1="623.4"
                  x2="668"
                  y2="699.6"
                />
              </g>
              <polygon
                className="st21"
                points="802.3,622.2 557.2,624.4 557.1,705 802.3,693.2 			"
              />
            </g>
          </g>
          <g id="boldest">
            <path
              className="st22"
              d="M523.8,980.8c-1.3,12.1-18,26.2-47.5,31.9l-905,175.1V725.3l933,237c12.6,2.7,18.9,8.7,19.5,15.7
          c0,0.1,0,0.3,0,0.4C524,979.2,523.9,980,523.8,980.8z"
            />
            <path
              className="st22"
              d="M476.4,1037.9l-905,186.1v-36.2l905-175.1c29.4-5.7,46.2-19.8,47.5-31.9c-0.1,5.4-0.4,17.3,0,22.7
          C524.9,1016,507.9,1031.8,476.4,1037.9z"
            />
            <g>
              <polyline
                className="st23"
                points="682.8,719.7 682.8,256.9 1353.9,109.1 1353.9,767.7 			"
              />
              <polyline
                className="st23"
                points="1480.2,751.1 1685.5,724 1685.5,242.4 1353.9,109.1 1353.9,767.7 1395.5,762.2 			"
              />
              <g>
                <g>
                  <line
                    className="st1"
                    x1="699"
                    y1="442.3"
                    x2="699"
                    y2="515.7"
                  />
                  <line
                    className="st1"
                    x1="724"
                    y1="439.7"
                    x2="724"
                    y2="514.3"
                  />
                  <line
                    className="st1"
                    x1="749.7"
                    y1="437.1"
                    x2="749.7"
                    y2="512.8"
                  />
                  <line
                    className="st1"
                    x1="690.9"
                    y1="443.1"
                    x2="690.9"
                    y2="516.2"
                  />
                  <line
                    className="st1"
                    x1="707.3"
                    y1="441.5"
                    x2="707.3"
                    y2="515.2"
                  />
                  <line
                    className="st1"
                    x1="732.5"
                    y1="438.9"
                    x2="732.5"
                    y2="513.8"
                  />
                  <line
                    className="st1"
                    x1="758.5"
                    y1="436.2"
                    x2="758.5"
                    y2="512.3"
                  />
                  <line
                    className="st1"
                    x1="715.6"
                    y1="440.6"
                    x2="715.6"
                    y2="514.8"
                  />
                  <line
                    className="st1"
                    x1="741.1"
                    y1="438"
                    x2="741.1"
                    y2="513.3"
                  />
                  <line
                    className="st1"
                    x1="767.3"
                    y1="435.3"
                    x2="767.3"
                    y2="511.8"
                  />
                  <line
                    className="st1"
                    x1="776.3"
                    y1="434.4"
                    x2="776.3"
                    y2="511.3"
                  />
                  <line
                    className="st1"
                    x1="785.3"
                    y1="433.5"
                    x2="785.3"
                    y2="510.7"
                  />
                  <line
                    className="st1"
                    x1="803.7"
                    y1="431.6"
                    x2="803.7"
                    y2="509.7"
                  />
                  <line
                    className="st1"
                    x1="832"
                    y1="428.7"
                    x2="832"
                    y2="508.1"
                  />
                  <line
                    className="st1"
                    x1="861.2"
                    y1="425.7"
                    x2="861.2"
                    y2="506.4"
                  />
                  <line
                    className="st1"
                    x1="794.4"
                    y1="432.5"
                    x2="794.4"
                    y2="510.2"
                  />
                  <line
                    className="st1"
                    x1="813"
                    y1="430.6"
                    x2="813"
                    y2="509.2"
                  />
                  <line
                    className="st1"
                    x1="841.6"
                    y1="427.7"
                    x2="841.6"
                    y2="507.5"
                  />
                  <line
                    className="st1"
                    x1="871.2"
                    y1="424.6"
                    x2="871.2"
                    y2="505.8"
                  />
                  <line
                    className="st1"
                    x1="822.4"
                    y1="429.6"
                    x2="822.4"
                    y2="508.6"
                  />
                  <line
                    className="st1"
                    x1="851.4"
                    y1="426.7"
                    x2="851.4"
                    y2="506.9"
                  />
                  <line
                    className="st1"
                    x1="881.2"
                    y1="423.6"
                    x2="881.2"
                    y2="505.2"
                  />
                  <line
                    className="st1"
                    x1="891.4"
                    y1="422.6"
                    x2="891.4"
                    y2="504.6"
                  />
                  <line
                    className="st1"
                    x1="901.7"
                    y1="421.5"
                    x2="901.7"
                    y2="504"
                  />
                  <line
                    className="st1"
                    x1="922.7"
                    y1="419.3"
                    x2="922.7"
                    y2="502.8"
                  />
                  <line
                    className="st1"
                    x1="955.1"
                    y1="416"
                    x2="955.1"
                    y2="501"
                  />
                  <line
                    className="st1"
                    x1="988.6"
                    y1="412.6"
                    x2="988.6"
                    y2="499"
                  />
                  <line
                    className="st1"
                    x1="912.2"
                    y1="420.4"
                    x2="912.2"
                    y2="503.4"
                  />
                  <line
                    className="st1"
                    x1="933.4"
                    y1="418.2"
                    x2="933.4"
                    y2="502.2"
                  />
                  <line
                    className="st1"
                    x1="966.1"
                    y1="414.9"
                    x2="966.1"
                    y2="500.3"
                  />
                  <line
                    className="st1"
                    x1="1000"
                    y1="411.4"
                    x2="1000"
                    y2="498.4"
                  />
                  <line
                    className="st1"
                    x1="944.1"
                    y1="417.1"
                    x2="944.1"
                    y2="501.6"
                  />
                  <line
                    className="st1"
                    x1="977.3"
                    y1="413.7"
                    x2="977.3"
                    y2="499.7"
                  />
                  <line
                    className="st1"
                    x1="1011.6"
                    y1="410.2"
                    x2="1011.6"
                    y2="497.7"
                  />
                  <line
                    className="st1"
                    x1="1023.3"
                    y1="409"
                    x2="1023.3"
                    y2="497"
                  />
                  <line
                    className="st1"
                    x1="1035.1"
                    y1="407.8"
                    x2="1035.1"
                    y2="496.4"
                  />
                  <line
                    className="st1"
                    x1="1059.3"
                    y1="405.3"
                    x2="1059.3"
                    y2="495"
                  />
                  <line
                    className="st1"
                    x1="1135.4"
                    y1="397.5"
                    x2="1135.4"
                    y2="490.6"
                  />
                  <line
                    className="st1"
                    x1="1217.6"
                    y1="389.1"
                    x2="1217.6"
                    y2="485.8"
                  />
                  <line
                    className="st1"
                    x1="1047.1"
                    y1="406.6"
                    x2="1047.1"
                    y2="495.7"
                  />
                  <line
                    className="st1"
                    x1="1084"
                    y1="402.8"
                    x2="1084"
                    y2="493.5"
                  />
                  <line
                    className="st1"
                    x1="1162.1"
                    y1="394.8"
                    x2="1162.1"
                    y2="489"
                  />
                  <line
                    className="st1"
                    x1="1246.4"
                    y1="386.1"
                    x2="1246.4"
                    y2="484.2"
                  />
                  <line
                    className="st1"
                    x1="1122.3"
                    y1="398.8"
                    x2="1122.3"
                    y2="491.3"
                  />
                  <line
                    className="st1"
                    x1="1175.7"
                    y1="393.4"
                    x2="1175.7"
                    y2="488.3"
                  />
                  <line
                    className="st1"
                    x1="1276.1"
                    y1="383"
                    x2="1276.1"
                    y2="482.5"
                  />
                  <line
                    className="st1"
                    x1="1337.9"
                    y1="376.7"
                    x2="1337.9"
                    y2="478.9"
                  />
                  <line
                    className="st1"
                    x1="1071.6"
                    y1="404.1"
                    x2="1071.6"
                    y2="494.3"
                  />
                  <line
                    className="st1"
                    x1="1109.4"
                    y1="400.2"
                    x2="1109.4"
                    y2="492.1"
                  />
                  <line
                    className="st1"
                    x1="1203.4"
                    y1="390.5"
                    x2="1203.4"
                    y2="486.7"
                  />
                  <line
                    className="st1"
                    x1="1291.2"
                    y1="381.5"
                    x2="1291.2"
                    y2="481.6"
                  />
                  <line
                    className="st1"
                    x1="1096.6"
                    y1="401.5"
                    x2="1096.6"
                    y2="492.8"
                  />
                  <line
                    className="st1"
                    x1="1148.6"
                    y1="396.1"
                    x2="1148.6"
                    y2="489.8"
                  />
                  <line
                    className="st1"
                    x1="1231.9"
                    y1="387.6"
                    x2="1231.9"
                    y2="485"
                  />
                  <line
                    className="st1"
                    x1="1306.6"
                    y1="379.9"
                    x2="1306.6"
                    y2="480.7"
                  />
                  <line
                    className="st1"
                    x1="1189.5"
                    y1="391.9"
                    x2="1189.5"
                    y2="487.5"
                  />
                  <line
                    className="st1"
                    x1="1261.2"
                    y1="384.6"
                    x2="1261.2"
                    y2="483.3"
                  />
                  <line
                    className="st1"
                    x1="1322.1"
                    y1="378.3"
                    x2="1322.1"
                    y2="479.8"
                  />
                </g>
                <g>
                  <line
                    className="st1"
                    x1="1384.8"
                    y1="380.8"
                    x2="1384.8"
                    y2="481.1"
                  />
                  <line
                    className="st1"
                    x1="1353.9"
                    y1="375.1"
                    x2="1353.9"
                    y2="478"
                  />
                  <line
                    className="st1"
                    x1="1399.6"
                    y1="383.6"
                    x2="1399.6"
                    y2="482.6"
                  />
                  <line
                    className="st1"
                    x1="1369.5"
                    y1="378"
                    x2="1369.5"
                    y2="479.5"
                  />
                  <line
                    className="st1"
                    x1="1414.1"
                    y1="386.3"
                    x2="1414.1"
                    y2="484"
                  />
                  <line
                    className="st1"
                    x1="1428.2"
                    y1="388.9"
                    x2="1428.2"
                    y2="485.4"
                  />
                  <line
                    className="st1"
                    x1="1442"
                    y1="391.5"
                    x2="1442"
                    y2="486.8"
                  />
                  <line
                    className="st1"
                    x1="1468.5"
                    y1="396.4"
                    x2="1468.5"
                    y2="489.4"
                  />
                  <line
                    className="st1"
                    x1="1540.9"
                    y1="409.9"
                    x2="1540.9"
                    y2="496.7"
                  />
                  <line
                    className="st1"
                    x1="1604.2"
                    y1="421.7"
                    x2="1604.2"
                    y2="503"
                  />
                  <line
                    className="st1"
                    x1="1455.4"
                    y1="394"
                    x2="1455.4"
                    y2="488.1"
                  />
                  <line
                    className="st1"
                    x1="1493.8"
                    y1="401.1"
                    x2="1493.8"
                    y2="492"
                  />
                  <line
                    className="st1"
                    x1="1562.9"
                    y1="414"
                    x2="1562.9"
                    y2="498.9"
                  />
                  <line
                    className="st1"
                    x1="1623.6"
                    y1="425.3"
                    x2="1623.6"
                    y2="505"
                  />
                  <line
                    className="st1"
                    x1="1529.5"
                    y1="407.8"
                    x2="1529.5"
                    y2="495.5"
                  />
                  <line
                    className="st1"
                    x1="1573.6"
                    y1="416"
                    x2="1573.6"
                    y2="500"
                  />
                  <line
                    className="st1"
                    x1="1642.2"
                    y1="428.8"
                    x2="1642.2"
                    y2="506.8"
                  />
                  <line
                    className="st1"
                    x1="1677.2"
                    y1="435.3"
                    x2="1677.2"
                    y2="510.3"
                  />
                  <line
                    className="st1"
                    x1="1481.3"
                    y1="398.8"
                    x2="1481.3"
                    y2="490.7"
                  />
                  <line
                    className="st1"
                    x1="1517.9"
                    y1="405.6"
                    x2="1517.9"
                    y2="494.4"
                  />
                  <line
                    className="st1"
                    x1="1594.2"
                    y1="419.9"
                    x2="1594.2"
                    y2="502"
                  />
                  <line
                    className="st1"
                    x1="1651.2"
                    y1="430.5"
                    x2="1651.2"
                    y2="507.7"
                  />
                  <line
                    className="st1"
                    x1="1506"
                    y1="403.4"
                    x2="1506"
                    y2="493.2"
                  />
                  <line
                    className="st1"
                    x1="1552.1"
                    y1="412"
                    x2="1552.1"
                    y2="497.8"
                  />
                  <line
                    className="st1"
                    x1="1614"
                    y1="423.6"
                    x2="1614"
                    y2="504"
                  />
                  <line
                    className="st1"
                    x1="1660"
                    y1="432.1"
                    x2="1660"
                    y2="508.6"
                  />
                  <line className="st1" x1="1584" y1="418" x2="1584" y2="501" />
                  <line
                    className="st1"
                    x1="1633"
                    y1="427.1"
                    x2="1633"
                    y2="505.9"
                  />
                  <line
                    className="st1"
                    x1="1668.7"
                    y1="433.7"
                    x2="1668.7"
                    y2="509.5"
                  />
                </g>
              </g>
              <polygon
                className="st23"
                points="1367.1,478 1353.9,545.5 682.8,564.5 657,518.1 			"
              />
              <polygon
                className="st23"
                points="1685.5,562.5 1353.9,545.5 1367.1,478 1711.3,513.8 			"
              />
              <polyline
                className="st24"
                points="682.8,516.7 682.8,444 1353.9,375.1 1353.9,478 			"
              />
              <polyline
                className="st24"
                points="1353.9,375.1 1685.5,436.9 1685.5,511.1 			"
              />
            </g>
            {/* <path
                  className="st25"
                  d="M415.8,1520.9l712.3-301.1c97.3-41.1,205.4-49.4,307.8-23.5l981.4,247.9L415.8,1520.9z"
                /> */}
            <path
              className="st22"
              d="M2257.6,742.3v224.2l-349.5-39.9c-21.9-2.5-30.7-7.2-32.1-12.4c-0.3-1-0.3-2,0-3.1c0,0,0,0,0,0
          c1.4-6,10.8-12.1,20.6-16.2L2257.6,742.3z"
            />
            <path
              className="st22"
              d="M2257.6,966.6v23.6l-349.2-43.5c-24.1-2.8-32.4-8.4-32.4-14.5v-17.9c1.4,5.2,10.2,9.9,32.1,12.4
          L2257.6,966.6z"
            />
            <line
              className="st26"
              x1="682.8"
              y1="719.7"
              x2="1210.6"
              y2="757.4"
            />
            <path
              className="st26"
              d="M2286.2,644.9v17.5l-945.5,183c-35.7,6.9-72.1,8.3-108.2,4.2L-410.2,662.1v-20.6l0.2,0l921.8,65.9"
            />
            <line
              className="st26"
              x1="1310.1"
              y1="764.6"
              x2="1353.9"
              y2="767.7"
            />
            <path
              className="st22"
              d="M2286.2,662.4v3.5l-956.6,196.6c-29.1,6-59,7.2-88.5,3.6L-416.2,665v-3.5l5.9-2.7v3.4l1642.7,187.5
          c36.1,4.1,72.6,2.7,108.2-4.2L2286.2,662.4z"
            />
            <path className="st2" d="M-432.5,683.6" />
            <path className="st2" d="M523.9,841.9" />
            <path className="st2" d="M-432.5,683.6" />
            <path className="st2" d="M539.3,838.6" />
            <polyline
              className="st22"
              points="-315.9,1398.2 686.3,1123.8 707.5,1130.7 -303.2,1413.8 		"
            />
            <g className="st3">
              <polygon
                className="st27"
                points="23.9,942.8 23.9,-394.2 -410.2,-394.2 -410.2,1000 			"
              />
              <polygon
                className="st27"
                points="98.5,-94 21.6,1.4 -410.2,-100.4 -410.2,-237.2 			"
              />
            </g>
            <path
              className="st28"
              d="M1590.1,184l-59.4-24.4l-66.6-27.4l-75.2-30.9l-0.2,0.2c-0.8-0.3-2.1-0.8-3.7-1.4
          c-22.6-8.1-46.9-10-70.5-5.4c-13.5,2.6-27.1,5.3-33.1,6.6l-87.8,20.2l-81.1,18.6l-75.2,17.3l-69.8,16l-65,14.9l-60.7,14
          l-56.8,13.1l-53.3,12.2v57.4v57.5v57.6v57.7l53.5-4.9l57.1-5.2l61-5.6l65.3-6l70.2-6.4l75.6-6.9l81.6-7.4l88.4-8.1l0,0.2l29.9-2.9
          c23.3-2.3,46.8-1.7,69.9,1.6l4,0.6l75.6,12.4l66.9,11l59.7,9.8l53.5,8.8v-60.9v-60.8v-60.7v-60.6L1590.1,184z"
            />
          </g>
          <g id="details">
            <g>
              <polyline
                className="st17"
                points="1395.5,762.2 1395.5,683.7 1480.2,678.1 1480.2,751.1 			"
              />
              <g>
                <g>
                  <path
                    className="st29"
                    d="M1579.5,673v45.9l-66.8,7.7v-49.3L1579.5,673 M1581,671.4l-69.8,4.5v52.4l69.8-8V671.4L1581,671.4z"
                  />
                </g>
                <g>
                  <path
                    className="st29"
                    d="M1663.2,667.6v41.7l-54.9,6.3v-44.5L1663.2,667.6 M1664.7,666l-57.9,3.7v47.6l57.9-6.7V666L1664.7,666z"
                  />
                </g>
              </g>
              <g>
                <path
                  className="st29"
                  d="M1397,575.2l81.7,2.1v70l-81.7,3.2V575.2 M1395.5,573.7v78.5l84.7-3.3v-72.9L1395.5,573.7L1395.5,573.7z"
                />
              </g>
              <g>
                <path
                  className="st29"
                  d="M1512.8,578.2l66.5,1.7v63.4l-66.5,2.6V578.2 M1511.3,576.7v70.9l69.5-2.7v-66.3L1511.3,576.7
                  L1511.3,576.7z"
                />
              </g>
              <g>
                <path
                  className="st29"
                  d="M1608.1,580.7l55.1,1.4v57.9l-55.1,2.2V580.7 M1606.6,579.2v64.6l58.1-2.3v-60.8L1606.6,579.2
                  L1606.6,579.2z"
                />
              </g>
              <g>
                <line
                  className="st30"
                  x1="1424.6"
                  y1="574.4"
                  x2="1424.6"
                  y2="651"
                />
                <line
                  className="st30"
                  x1="1453.9"
                  y1="575.2"
                  x2="1453.9"
                  y2="649.8"
                />
              </g>
              <g>
                <line
                  className="st30"
                  x1="1535"
                  y1="577.3"
                  x2="1535"
                  y2="646.6"
                />
                <line
                  className="st30"
                  x1="1559.1"
                  y1="578"
                  x2="1559.1"
                  y2="645.7"
                />
              </g>
              <g>
                <line
                  className="st30"
                  x1="1626.4"
                  y1="579.7"
                  x2="1626.4"
                  y2="643"
                />
                <line
                  className="st30"
                  x1="1646.5"
                  y1="580.3"
                  x2="1646.5"
                  y2="642.2"
                />
              </g>
            </g>
            <g>
              <g>
                <polyline
                  className="st23"
                  points="1210.6,757.5 1210.6,681.4 1310.1,684.9 1310.1,764.6 				"
                />
                <g>
                  <path
                    className="st29"
                    d="M1308.6,574.7v76.6l-96.5-2v-73.1L1308.6,574.7 M1310.1,573.2l-99.5,1.5v76.1l99.5,2V573.2L1310.1,573.2z
                      "
                  />
                </g>
                <g>
                  <line
                    className="st30"
                    x1="1244.1"
                    y1="574.2"
                    x2="1244.1"
                    y2="651.5"
                  />
                  <line
                    className="st30"
                    x1="1278.6"
                    y1="573.7"
                    x2="1278.6"
                    y2="652.2"
                  />
                </g>
              </g>
              <g>
                <g>
                  <g>
                    <path
                      className="st29"
                      d="M704.1,665.3l52.2,1.8v40.3l-52.2-3.3V665.3 M702.6,663.7v41.7l55.2,3.5v-43.3L702.6,663.7L702.6,663.7z
                          "
                    />
                  </g>
                  <g>
                    <path
                      className="st29"
                      d="M783,667.9l58.7,2v42.8L783,709V667.9 M781.5,666.4v44l61.7,3.9v-45.8L781.5,666.4L781.5,666.4z"
                    />
                  </g>
                  <g>
                    <path
                      className="st29"
                      d="M871.4,670.9l66.5,2.2v45.7l-66.5-4.2V670.9 M869.9,669.4V716l69.5,4.4v-48.7L869.9,669.4L869.9,669.4z"
                    />
                  </g>
                  <g>
                    <path
                      className="st29"
                      d="M970.9,674.3l75.8,2.6v48.9l-75.8-4.8V674.3 M969.4,672.7v49.6l78.8,5v-51.9L969.4,672.7L969.4,672.7z"
                    />
                  </g>
                  <g>
                    <path
                      className="st29"
                      d="M1084,678.1l87.2,2.9v52.6l-87.2-5.5V678.1 M1082.5,676.5v53l90.2,5.7v-55.6L1082.5,676.5L1082.5,676.5z
                          "
                    />
                  </g>
                </g>
                <g>
                  <path
                    className="st29"
                    d="M758,583.3v56.8l-53.8-1.1v-54.9L758,583.3 M759.5,581.7l-56.8,0.9v57.8l56.8,1.2V581.7L759.5,581.7z"
                  />
                </g>
                <g>
                  <path
                    className="st29"
                    d="M845,581.9v59.9l-59.8-1.2v-57.8L845,581.9 M846.5,580.4l-62.8,1v60.7l62.8,1.3V580.4L846.5,580.4z"
                  />
                </g>
                <g>
                  <path
                    className="st29"
                    d="M941.7,580.4v63.4l-66.8-1.4v-61L941.7,580.4 M943.2,578.9l-69.8,1.1v64l69.8,1.4V578.9L943.2,578.9z"
                  />
                </g>
                <g>
                  <path
                    className="st29"
                    d="M1049.7,578.7V646l-75-1.5v-64.6L1049.7,578.7 M1051.2,577.2l-78,1.2V646l78,1.6V577.2L1051.2,577.2z"
                  />
                </g>
                <g>
                  <path
                    className="st29"
                    d="M1171.1,576.8v71.7l-84.8-1.7v-68.6L1171.1,576.8 M1172.6,575.3l-87.8,1.4v71.6l87.8,1.8V575.3
                      L1172.6,575.3z"
                  />
                </g>
                <g>
                  <line
                    className="st30"
                    x1="720.7"
                    y1="582.3"
                    x2="720.7"
                    y2="640.8"
                  />
                  <line
                    className="st30"
                    x1="740.5"
                    y1="582"
                    x2="740.5"
                    y2="641.2"
                  />
                </g>
                <g>
                  <line
                    className="st30"
                    x1="803.7"
                    y1="581"
                    x2="803.7"
                    y2="642.5"
                  />
                  <line
                    className="st30"
                    x1="825.5"
                    y1="580.7"
                    x2="825.5"
                    y2="643"
                  />
                </g>
                <g>
                  <line
                    className="st30"
                    x1="895.7"
                    y1="579.6"
                    x2="895.7"
                    y2="644.4"
                  />
                  <line
                    className="st30"
                    x1="919.9"
                    y1="579.2"
                    x2="919.9"
                    y2="644.9"
                  />
                </g>
                <g>
                  <line
                    className="st30"
                    x1="998"
                    y1="578"
                    x2="998"
                    y2="646.5"
                  />
                  <line
                    className="st30"
                    x1="1025.1"
                    y1="577.6"
                    x2="1025.1"
                    y2="647"
                  />
                </g>
                <g>
                  <line
                    className="st30"
                    x1="1112.7"
                    y1="576.2"
                    x2="1112.7"
                    y2="648.8"
                  />
                  <line
                    className="st30"
                    x1="1143.1"
                    y1="575.8"
                    x2="1143.1"
                    y2="649.4"
                  />
                </g>
              </g>
            </g>
            <line
              className="st31"
              x1="1210.6"
              y1="757.5"
              x2="1310.1"
              y2="746.7"
            />
            <line className="st31" x1="1480.2" y1="751.1" x2="1396" y2="746" />
            <g className="st3">
              <g className="st9">
                <polygon
                  className="st18"
                  points="106.5,275.9 38.6,298.2 39,258.4 106.8,233.3 				"
                />
                <polygon
                  className="st18"
                  points="203.9,244 122.8,270.6 123.1,227.3 204.2,197.3 				"
                />
                <polygon
                  className="st18"
                  points="322.2,205.3 223.6,237.6 223.8,190.1 322.3,153.7 				"
                />
                <polygon
                  className="st18"
                  points="468.7,157.4 346.2,197.5 346.4,144.8 468.7,99.6 				"
                />
              </g>
              <g className="st9">
                <polygon
                  className="st18"
                  points="105.6,408.2 37.6,421.5 38.4,326.2 106.3,306 				"
                />
                <polygon
                  className="st18"
                  points="203.2,389 121.9,405 122.6,301.1 203.8,277 				"
                />
                <polygon
                  className="st18"
                  points="321.7,365.7 222.8,385.1 223.4,271.1 322.1,241.7 				"
                />
                <polygon
                  className="st18"
                  points="468.7,336.8 345.8,361 346.2,234.6 468.7,198.1 				"
                />
              </g>
              <g className="st9">
                <polygon
                  className="st18"
                  points="104.3,589.1 36.1,590.3 37.3,449.7 105.4,438.4 				"
                />
                <polygon
                  className="st18"
                  points="202.1,587.4 120.6,588.8 121.7,435.6 203,422.1 				"
                />
                <polygon
                  className="st18"
                  points="321.1,585.4 221.8,587.1 222.7,418.8 321.6,402.3 				"
                />
                <polygon
                  className="st18"
                  points="468.7,582.9 345.3,585 345.8,398.3 468.7,377.8 				"
                />
              </g>
              <g className="st9">
                <g>
                  <line
                    className="st18"
                    x1="43.5"
                    y1="618.7"
                    x2="43"
                    y2="673.9"
                  />
                  <line
                    className="st18"
                    x1="51.1"
                    y1="618.8"
                    x2="50.6"
                    y2="674.5"
                  />
                  <line
                    className="st18"
                    x1="58.8"
                    y1="618.9"
                    x2="58.3"
                    y2="675"
                  />
                  <line
                    className="st18"
                    x1="66.6"
                    y1="619"
                    x2="66.2"
                    y2="675.6"
                  />
                  <line
                    className="st18"
                    x1="74.6"
                    y1="619.1"
                    x2="74.1"
                    y2="676.1"
                  />
                  <line
                    className="st18"
                    x1="90.9"
                    y1="619.3"
                    x2="90.5"
                    y2="677.3"
                  />
                  <line
                    className="st18"
                    x1="116.4"
                    y1="619.7"
                    x2="116"
                    y2="679.1"
                  />
                  <line
                    className="st18"
                    x1="82.7"
                    y1="619.2"
                    x2="82.2"
                    y2="676.7"
                  />
                  <line
                    className="st18"
                    x1="99.3"
                    y1="619.4"
                    x2="98.8"
                    y2="677.9"
                  />
                  <line
                    className="st18"
                    x1="107.8"
                    y1="619.5"
                    x2="107.3"
                    y2="678.5"
                  />
                  <line
                    className="st18"
                    x1="125.2"
                    y1="619.8"
                    x2="124.8"
                    y2="679.8"
                  />
                  <line
                    className="st18"
                    x1="134.2"
                    y1="619.9"
                    x2="133.8"
                    y2="680.4"
                  />
                  <line
                    className="st18"
                    x1="143.3"
                    y1="620"
                    x2="142.9"
                    y2="681.1"
                  />
                  <line
                    className="st18"
                    x1="152.6"
                    y1="620.1"
                    x2="152.2"
                    y2="681.7"
                  />
                  <line
                    className="st18"
                    x1="162"
                    y1="620.3"
                    x2="161.6"
                    y2="682.4"
                  />
                  <line
                    className="st18"
                    x1="171.6"
                    y1="620.4"
                    x2="171.3"
                    y2="683.1"
                  />
                  <line
                    className="st18"
                    x1="181.4"
                    y1="620.5"
                    x2="181.1"
                    y2="683.8"
                  />
                  <line
                    className="st18"
                    x1="191.4"
                    y1="620.6"
                    x2="191"
                    y2="684.5"
                  />
                </g>
                <polygon
                  className="st32"
                  points="201.8,620.8 35.8,618.6 35.3,673.4 201.5,685.3 				"
                />
              </g>
            </g>
            <g>
              <polygon
                className="st33"
                points="106.8,277.3 39.3,299.5 39.3,259.5 106.8,234.4 			"
              />
              <polygon
                className="st34"
                points="203.9,245.3 123.1,271.9 123.1,228.3 203.9,198.3 			"
              />
              <polygon
                className="st18"
                points="321.9,206.5 223.4,238.9 223.4,191 321.9,154.3 			"
              />
              <polygon
                className="st18"
                points="468.7,158.2 346,198.6 346,145.3 468.7,99.7 			"
              />
              <polygon
                className="st33"
                points="106.8,409.8 39.3,423 39.3,327.6 106.8,307.4 			"
              />
              <polygon
                className="st34"
                points="203.9,390.9 123,406.7 123.1,302.6 203.9,278.4 			"
              />
              <polygon
                className="st18"
                points="321.9,367.8 223.4,387 223.4,272.6 321.9,243.2 			"
              />
              <polygon
                className="st18"
                points="468.7,339.1 346,363.1 346,236 468.7,199.4 			"
              />
              <polygon
                className="st33"
                points="106.8,590.1 39.3,591 39.3,451.1 106.8,440 			"
              />
              <polygon
                className="st34"
                points="203.8,588.7 123,589.9 123,437.3 203.9,424 			"
              />
              <polygon
                className="st18"
                points="321.9,587.1 223.4,588.5 223.4,420.7 321.9,404.5 			"
              />
              <polygon
                className="st18"
                points="468.7,585.1 346,586.8 346,400.5 468.7,380.3 			"
              />
              <g>
                <g>
                  <line
                    className="st33"
                    x1="46.8"
                    y1="619.3"
                    x2="46.8"
                    y2="673.9"
                  />
                  <line
                    className="st33"
                    x1="54.3"
                    y1="619.4"
                    x2="54.3"
                    y2="674.5"
                  />
                  <line
                    className="st33"
                    x1="62"
                    y1="619.5"
                    x2="62"
                    y2="675.1"
                  />
                  <line
                    className="st33"
                    x1="69.7"
                    y1="619.6"
                    x2="69.7"
                    y2="675.6"
                  />
                  <line
                    className="st33"
                    x1="77.6"
                    y1="619.8"
                    x2="77.6"
                    y2="676.2"
                  />
                  <line
                    className="st33"
                    x1="93.8"
                    y1="620"
                    x2="93.8"
                    y2="677.5"
                  />
                  <line
                    className="st33"
                    x1="119.1"
                    y1="620.4"
                    x2="119.1"
                    y2="679.4"
                  />
                  <line
                    className="st33"
                    x1="85.6"
                    y1="619.9"
                    x2="85.6"
                    y2="676.8"
                  />
                  <line
                    className="st33"
                    x1="102.1"
                    y1="620.2"
                    x2="102.1"
                    y2="678.1"
                  />
                  <line
                    className="st33"
                    x1="110.5"
                    y1="620.3"
                    x2="110.5"
                    y2="678.7"
                  />
                  <line
                    className="st33"
                    x1="127.8"
                    y1="620.6"
                    x2="127.8"
                    y2="680"
                  />
                  <line
                    className="st33"
                    x1="136.7"
                    y1="620.7"
                    x2="136.7"
                    y2="680.7"
                  />
                  <line
                    className="st33"
                    x1="145.7"
                    y1="620.9"
                    x2="145.7"
                    y2="681.4"
                  />
                  <line
                    className="st33"
                    x1="154.9"
                    y1="621"
                    x2="154.9"
                    y2="682.1"
                  />
                  <line
                    className="st33"
                    x1="164.3"
                    y1="621.2"
                    x2="164.3"
                    y2="682.8"
                  />
                  <line
                    className="st33"
                    x1="173.8"
                    y1="621.4"
                    x2="173.8"
                    y2="683.5"
                  />
                  <line
                    className="st33"
                    x1="183.5"
                    y1="621.5"
                    x2="183.5"
                    y2="684.2"
                  />
                  <line
                    className="st33"
                    x1="193.4"
                    y1="621.7"
                    x2="193.4"
                    y2="684.9"
                  />
                </g>
                <polygon
                  className="st35"
                  points="203.7,621.9 39.2,619.1 39.2,673.4 203.7,685.7 				"
                />
              </g>
              <polygon
                className="st33"
                points="-43.8,326.9 -92.9,343 -92.9,308.7 -43.8,290.4 			"
              />
              <polygon
                className="st33"
                points="25.2,304.2 -32.1,323 -32.1,286.1 25.2,264.8 			"
              />
              <polygon
                className="st33"
                points="106.9,204 39.3,231.2 39.3,191.2 106.9,161.1 			"
              />
              <polygon
                className="st33"
                points="-43.8,264.6 -92.9,284.4 -92.9,250.1 -43.7,228.2 			"
              />
              <polygon
                className="st33"
                points="25.2,236.9 -32.1,260 -32.1,223 25.2,197.5 			"
              />
              <polygon
                className="st33"
                points="106.9,130.7 39.4,162.8 39.4,-52.5 106.9,-100.6 			"
              />
              <polygon
                className="st33"
                points="-43.7,202.4 -92.9,225.8 -92.9,40 -43.7,5.6 			"
              />
              <polygon
                className="st33"
                points="25.2,169.6 -32.1,196.9 -32.1,-1.5 25.2,-42.7 			"
              />
              <polygon
                className="st33"
                points="-43.8,439.4 -93,449.1 -93,367.2 -43.8,352.5 			"
              />
              <polygon
                className="st33"
                points="25.2,426 -32.1,437.2 -32.1,349 25.2,331.9 			"
              />
              <polygon
                className="st33"
                points="-43.8,592.5 -93,593.2 -93,473.2 -43.8,465.1 			"
              />
              <polygon
                className="st33"
                points="25.2,591.6 -32.1,592.4 -32.1,463.1 25.2,453.7 			"
              />
              <g>
                <g>
                  <line
                    className="st33"
                    x1="-87.4"
                    y1="617.4"
                    x2="-87.5"
                    y2="664.2"
                  />
                  <line
                    className="st33"
                    x1="-81.9"
                    y1="617.5"
                    x2="-81.9"
                    y2="664.7"
                  />
                  <line
                    className="st33"
                    x1="-76.4"
                    y1="617.6"
                    x2="-76.4"
                    y2="665.1"
                  />
                  <line
                    className="st33"
                    x1="-70.7"
                    y1="617.7"
                    x2="-70.7"
                    y2="665.5"
                  />
                  <line
                    className="st33"
                    x1="-64.9"
                    y1="617.8"
                    x2="-65"
                    y2="666"
                  />
                  <line
                    className="st33"
                    x1="-53.2"
                    y1="618"
                    x2="-53.2"
                    y2="666.8"
                  />
                  <line
                    className="st33"
                    x1="-35"
                    y1="618.3"
                    x2="-35"
                    y2="668.2"
                  />
                  <line
                    className="st33"
                    x1="-59.1"
                    y1="617.9"
                    x2="-59.1"
                    y2="666.4"
                  />
                  <line
                    className="st33"
                    x1="-47.2"
                    y1="618.1"
                    x2="-47.2"
                    y2="667.3"
                  />
                  <line
                    className="st33"
                    x1="-41.1"
                    y1="618.2"
                    x2="-41.1"
                    y2="667.8"
                  />
                  <line
                    className="st33"
                    x1="-28.7"
                    y1="618.4"
                    x2="-28.7"
                    y2="668.7"
                  />
                  <line
                    className="st33"
                    x1="-22.4"
                    y1="618.5"
                    x2="-22.4"
                    y2="669.2"
                  />
                  <line
                    className="st33"
                    x1="-15.9"
                    y1="618.6"
                    x2="-15.9"
                    y2="669.7"
                  />
                  <line
                    className="st33"
                    x1="-9.4"
                    y1="618.7"
                    x2="-9.4"
                    y2="670.2"
                  />
                  <line
                    className="st33"
                    x1="-2.7"
                    y1="618.8"
                    x2="-2.7"
                    y2="670.7"
                  />
                  <line className="st33" x1="4" y1="618.9" x2="4" y2="671.2" />
                  <line
                    className="st33"
                    x1="10.9"
                    y1="619.1"
                    x2="10.9"
                    y2="671.7"
                  />
                  <line
                    className="st33"
                    x1="17.8"
                    y1="619.2"
                    x2="17.8"
                    y2="672.2"
                  />
                </g>
                <polygon
                  className="st35"
                  points="25.1,619.3 -93,617.3 -93,663.8 25.1,672.8 				"
                />
              </g>
              <g>
                <g>
                  <line
                    className="st33"
                    x1="234.4"
                    y1="621.8"
                    x2="234.4"
                    y2="687.4"
                  />
                  <line
                    className="st33"
                    x1="245.2"
                    y1="622"
                    x2="245.2"
                    y2="688.3"
                  />
                  <line
                    className="st33"
                    x1="256.3"
                    y1="622.2"
                    x2="256.3"
                    y2="689.1"
                  />
                  <line
                    className="st33"
                    x1="267.6"
                    y1="622.4"
                    x2="267.6"
                    y2="689.9"
                  />
                  <line
                    className="st33"
                    x1="279.1"
                    y1="622.6"
                    x2="279.1"
                    y2="690.8"
                  />
                  <line
                    className="st33"
                    x1="302.8"
                    y1="622.9"
                    x2="302.8"
                    y2="692.6"
                  />
                  <line
                    className="st33"
                    x1="340.2"
                    y1="623.6"
                    x2="340.2"
                    y2="695.3"
                  />
                  <line
                    className="st33"
                    x1="290.8"
                    y1="622.8"
                    x2="290.8"
                    y2="691.7"
                  />
                  <line
                    className="st33"
                    x1="315"
                    y1="623.1"
                    x2="315"
                    y2="693.5"
                  />
                  <line
                    className="st33"
                    x1="327.5"
                    y1="623.3"
                    x2="327.5"
                    y2="694.4"
                  />
                  <line
                    className="st33"
                    x1="353.3"
                    y1="623.8"
                    x2="353.2"
                    y2="696.3"
                  />
                  <line
                    className="st33"
                    x1="366.5"
                    y1="624"
                    x2="366.5"
                    y2="697.3"
                  />
                  <line
                    className="st33"
                    x1="380.1"
                    y1="624.2"
                    x2="380.1"
                    y2="698.3"
                  />
                  <line
                    className="st33"
                    x1="394"
                    y1="624.4"
                    x2="394"
                    y2="699.4"
                  />
                  <line
                    className="st33"
                    x1="408.2"
                    y1="624.6"
                    x2="408.2"
                    y2="700.4"
                  />
                  <line
                    className="st33"
                    x1="422.7"
                    y1="624.9"
                    x2="422.7"
                    y2="701.5"
                  />
                  <line
                    className="st33"
                    x1="437.5"
                    y1="625.1"
                    x2="437.5"
                    y2="702.6"
                  />
                  <line
                    className="st33"
                    x1="452.7"
                    y1="625.4"
                    x2="452.7"
                    y2="703.7"
                  />
                </g>
                <polygon
                  className="st35"
                  points="468.7,625.6 223.4,621.7 223.4,686.6 468.7,704.9 				"
                />
              </g>
              <polygon
                className="st34"
                points="123,-111 123.1,123.1 203.9,85.1 202.6,-166.7 			"
              />
              <polygon
                className="st34"
                points="123,153.7 123,196.9 203.9,165.1 203.6,118.2 			"
              />
            </g>
          </g>
        </g>
        <g id="hand_x26_phone">
          <path
            className="st36"
            d="M352.6,479.4c14.5-2.8,25,9.4,28.4,20.7c3.4,11.4,13.6,110.3-47.7,133c2.3,0,10.4-4.4,10.4-4.4
      s-0.3,26.7-24.5,79.8c-1.8,5.1,6.6,31.8,15.7,37.9c0.8,0-6.1-9-6.1-9s44-29.7,101.6-30.4s184.2-179.6,184.2-179.6
      s30.3-28.8,55.3-28.8c25,0,28,18.9,28,25.8s9.1,40.9-78.8,78.1c-10.6,6.8,8.3,45.5,8.3,45.5s28.8-25.6,37.9-25.6s24.3,1.1,32.9,9.7
      c8.6,8.6,11.1,23.5,11.1,34.1s-25,46.2-85.6,68.2c-0.8,1.5,3,31.1,3,31.1s34.1-39.4,45.5-40.2c11.4-0.8,20.5-0.8,26.5,7.6
      c6.1,8.3,12.9,25.8,10.6,39.4c-2.3,13.6-28,44.7-33.3,47.7c-5.3,3-41.7,20.5-49.3,20.5c-7.6,0-12.1,35.6-0.8,40.2
      c11.4,4.5,65.9-48.5,75.8-27.3s9.1,67.5-15.2,84.9s-53.8,29.6-67.5,36.4c-13.6,6.8-35.6,15.2-65.2,46.2
      c-29.6,31.1-131.9,104.6-167.5,119.7c-35.6,15.2-67.4-11.4-67.4-11.4l4.9,3.5c12.2,8.7,27,13.1,42,12.5l3.1-0.1
      c0,0-23.5,18.3-37.9,32.7c-14.4,14.4-70.5,72-125.8,186.4c0.8,1.5-375.2,0-375.2,0s196.3-217.5,228.1-244.8
      C90,1092,172.6,982.1,193,969.2c7.6-4.5,6.8-11.4,6.8-17.4s3-200.1,9.1-218.3s46.2-92.5,50-106.1c3.8-13.6,11.4-30.3,16.7-42.4
      c5.3-12.1,34.9-33.3,39.4-44.7C319.6,528.9,334,482.9,352.6,479.4z"
          />
          <g>
            <path
              className="st37"
              d="M610.9,951H401.2c-19.9,0-36.1-16.2-36.1-36.1V339c0-19.9,16.2-36.1,36.1-36.1h209.7
          c19.9,0,36.1,16.2,36.1,36.1v576C647,934.9,630.9,951,610.9,951z"
            />
            <path
              className="st22"
              d="M635.7,345.6v561.7c0,17.2-13.9,31.1-31.1,31.1H407.5c-17.2,0-31.1-13.9-31.1-31.1V342.7
          c0-17.2,13.9-31.1,31.1-31.1h28.8v9.1c0,7.5,6.1,13.7,13.7,13.7h115.2c7.5,0,13.7-6.1,13.7-13.7v-9.1h22.8
          C622.8,311.6,635.7,322.4,635.7,345.6z"
            />
          </g>
          <circle className="st22" cx="542.5" cy="321.4" r="4.4" />
          <path
            className="st22"
            d="M525.3,324.5h-35.2c-1.8,0-3.2-1.4-3.2-3.2l0,0c0-1.8,1.4-3.2,3.2-3.2h35.2c1.8,0,3.2,1.4,3.2,3.2l0,0
      C528.5,323,527.1,324.5,525.3,324.5z"
          />
          <g>
            <g>
              <path
                className="st36"
                d="M659.9,802.7c-16.2-5.6-21.4-38.1-20.1-51.9c1.4-13.8,10.5-21.9,32.2-25.7c21.7-3.8,37.1,7.8,37.1,47
              c0,12.9-20,29.1-30.6,30.6C668,804.2,659.9,802.7,659.9,802.7z"
              />
              <path
                className="st36"
                d="M700.2,695.2c-5.5,5.4-16.5,13.6-29.3,15.1c-3.4,0.4-7,0.4-10.6-0.4c-16.9-3.4-20.8-20.8-20.8-42.8
              c0-17.6,5-29.7,11.3-38c5.8-3.9,11.2-6.9,14.3-6.9c1.8,0,3.9,0,6,0.2c8.8,0.5,19.9,2.6,26.8,9.5c2.6,2.6,4.6,5.7,6.2,9.2
              c1.6,3.4,2.7,7.2,3.5,10.9c0,0.1,0.1,0.3,0.1,0.4C711.9,669.7,707.2,688.5,700.2,695.2z"
              />
              <path
                className="st36"
                d="M680.9,917.5c-41.7-10-40.1-42.1-41.3-54.2c-1.2-12,4.9-26.1,13.6-28.8c0,0,40.9,6.8,47.7,17.6"
              />
              <path className="st36" d="M702.4,855c0,0-0.9-2.3-1.9-3.8" />
            </g>
            <g>
              <path
                className="st15"
                d="M660.4,736c0,0,16.7-3,19.2-3s16.7,4.5,19.7,17.2c3,12.6-1,27.8-19.5,30.8c0.8,0.5,0.8-17.2-3.3-27.3
              C672.5,743.6,660.4,736,660.4,736z"
              />
              <path
                className="st22"
                d="M660.4,640.5c0,0,24.8-14.1,36.4,13.1c9.2,21.6-4.5,33.3-13.1,37.4C684.2,691.1,677.6,651.1,660.4,640.5z"
              />
              <path
                className="st15"
                d="M672,872.5c0,0,7.6,5.1,12.6,5.1c5.1,0,8.2-8.6,6.1-17.8c-4-17.4-18-24.1-25.4-24.1c-2.1,0-5.1,0.9-5.1,0.9
              s8,0.4,10.4,11S673.6,868.5,672,872.5z"
              />
            </g>
          </g>
          <path className="st25" d="M199.2,961.3c0,0-7.3,41.1,22.3,62.7" />
        </g>
        <g id="gradient">
          <linearGradient
            id="Rectangle_5_00000177457832456072264350000017496135451371063987_"
            gradientUnits="userSpaceOnUse"
            x1="543.8737"
            y1="876.3724"
            x2="543.8737"
            y2="875.3614"
            gradientTransform="matrix(1920 0 0 -380 -1043258.8125 333933.9062)"
          >
            <stop offset="0" stopColor="#FFFFFF" stopOpacity="0" />
            <stop offset="0.856" stopColor="#FFFFFF" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>

          <rect
            id="Rectangle_5"
            x="-267.3"
            y="912.4"
            fill="url(#Rectangle_5_00000177457832456072264350000017496135451371063987_)"
            width="2492.1"
            height="384.2"
          />
        </g>
        <g id="ScreenGrid">
          <g id="sa1">
            <polygon
              fill="#fff"
              points="784.6,215.5 731.3,227.7 731.3,285.1 784.7,274.7 		"
            />
          </g>
          <g id="sa2">
            <polygon
              fill="#fff"
              points="784.7,274.7 731.3,285.1 731.3,342.6 784.8,334 		"
            />
          </g>
          <g id="sa3">
            <polygon
              fill="#fff"
              points="784.8,334 731.3,342.6 731.3,400.2 784.8,393.5 		"
            />
          </g>
          <g id="sa4">
            <polygon
              fill="#fff"
              points="784.8,393.5 731.3,400.2 731.3,457.9 784.9,453 		"
            />
          </g>
          <g id="sb1">
            <polygon
              fill="#fff"
              points="841.5,202.4 784.6,215.5 784.7,274.7 841.6,263.6 		"
            />
          </g>
          <g id="sb2">
            <polygon
              fill="#fff"
              points="841.6,263.6 784.7,274.7 784.8,334 841.7,324.9 		"
            />
          </g>
          <g id="sb3">
            <polygon
              fill="#fff"
              points="841.7,324.9 784.8,334 784.8,393.5 841.8,386.3 		"
            />
          </g>
          <g id="sb4">
            <polygon
              fill="#fff"
              points="841.8,386.3 784.8,393.5 784.9,453 841.9,447.8 		"
            />
          </g>
          <g id="sc1">
            <polygon
              fill="#fff"
              points="902.2,188.4 841.5,202.4 841.6,263.6 902.4,251.7 		"
            />
          </g>
          <g id="sc2">
            <polygon
              fill="#fff"
              points="902.4,251.7 841.6,263.6 841.7,324.9 902.6,315.1 		"
            />
          </g>
          <g id="sc3">
            <polygon
              fill="#fff"
              points="902.6,315.1 841.7,324.9 841.8,386.3 902.8,378.6 		"
            />
          </g>
          <g id="sc4">
            <polygon
              fill="#fff"
              points="902.8,378.6 841.8,386.3 841.9,447.8 902.9,442.3 		"
            />
          </g>
          <g id="sd1">
            <polygon
              fill="#fff"
              points="967.3,173.5 902.2,188.4 902.4,251.7 967.5,239 		"
            />
          </g>
          <g id="sd2">
            <polygon
              fill="#fff"
              points="967.5,239 902.4,251.7 902.6,315.1 967.8,304.6 		"
            />
          </g>
          <g id="sd3">
            <polygon
              fill="#fff"
              points="967.8,304.6 902.6,315.1 902.8,378.6 968,370.4 		"
            />
          </g>
          <g id="sd4">
            <polygon
              fill="#fff"
              points="968,370.4 902.8,378.6 902.9,442.3 968.3,436.3 		"
            />
          </g>
          <g id="se1">
            <polygon
              fill="#fff"
              points="1037.1,157.5 967.3,173.5 967.5,239 1037.4,225.3 		"
            />
          </g>
          <g id="se2">
            <polygon
              fill="#fff"
              points="1037.4,225.3 967.5,239 967.8,304.6 1037.8,293.4 		"
            />
          </g>
          <g id="se3">
            <polygon
              fill="#fff"
              points="1037.8,293.4 967.8,304.6 968,370.4 1038.1,361.6 		"
            />
          </g>
          <g id="se4">
            <polygon
              fill="#fff"
              points="1038.1,361.6 968,370.4 968.3,436.3 1038.5,429.9 		"
            />
          </g>
          <g id="sf1">
            <polygon
              fill="#fff"
              points="1112.3,140.2 1037.1,157.5 1037.4,225.3 1112.7,210.7 		"
            />
          </g>
          <g id="sf2">
            <polygon
              fill="#fff"
              points="1112.7,210.7 1037.4,225.3 1037.8,293.4 1113.1,281.3 		"
            />
          </g>
          <g id="sf3">
            <polygon
              fill="#fff"
              points="1113.1,281.3 1037.8,293.4 1038.1,361.6 1113.6,352 		"
            />
          </g>
          <g id="sf4">
            <polygon
              fill="#fff"
              points="1113.6,352 1038.1,361.6 1038.5,429.9 1114,423 		"
            />
          </g>
          <g id="sg1">
            <polygon
              fill="#fff"
              points="1193.4,121.6 1112.3,140.2 1112.7,210.7 1193.9,194.8 		"
            />
          </g>
          <g id="sg2">
            <polygon
              fill="#fff"
              points="1193.9,194.8 1112.7,210.7 1113.1,281.3 1194.5,268.2 		"
            />
          </g>
          <g id="sg3">
            <polygon
              fill="#fff"
              points="1194.5,268.2 1113.1,281.3 1113.6,352 1195,341.8 		"
            />
          </g>
          <g id="sg4">
            <polygon
              fill="#fff"
              points="1195,341.8 1113.6,352 1114,423 1195.6,415.5 		"
            />
          </g>
          <g id="sh1">
            <polygon
              fill="#fff"
              points="1281.2,101.4 1193.4,121.6 1193.9,194.8 1281.9,177.6 		"
            />
          </g>
          <g id="sh2">
            <polygon
              fill="#fff"
              points="1281.9,177.6 1193.9,194.8 1194.5,268.2 1282.6,254.1 		"
            />
          </g>
          <g id="sh3">
            <polygon
              fill="#fff"
              points="1282.6,254.1 1194.5,268.2 1195,341.8 1283.3,330.7 		"
            />
          </g>
          <g id="sh4">
            <polygon
              fill="#fff"
              points="1283.3,330.7 1195,341.8 1195.6,415.5 1284,407.5 		"
            />
          </g>
          <g id="si1">
            <path
              fill="#fff"
              d="M1388.5,101.6c-0.8-0.3-2.1-0.8-3.7-1.4c-22.6-8.1-46.9-10-70.5-5.4c-13.5,2.6-27.1,5.3-33.1,6.6l0.7,76.2
          l31.6-5.2c23.3-3.8,47.9-2.5,70.7,3.9l4.4,1.2V101.6z"
            />
          </g>
          <g id="si2">
            <path
              fill="#fff"
              d="M1388.5,177.6l-4.4-1.2c-22.7-6.4-47.4-7.7-70.7-3.9l-31.6,5.2l0.7,76.3l35.1-5c20.6-3,41.6-2,61.9,2.9
          l9,2.1V177.6z"
            />
          </g>
          <g id="si3">
            <path
              fill="#fff"
              d="M1388.5,253.9l-9-2.1c-20.2-5-41.2-6-61.9-2.9l-35.1,5l0.7,76.8l30.2-4.3c23.4-3.3,47.2-2.2,70.2,3.2
          l4.6,0.8L1388.5,253.9z"
            />
          </g>
          <g id="si4">
            <path
              fill="#fff"
              d="M1388.3,330.4l-4.6-1.2c-23-5.4-46.8-6-70.2-2.7l-30.2,4.3l0.7,77l29.9-2.9c23.3-2.3,46.8-1.7,69.9,1.6
          l4,0.6L1388.3,330.4z"
            />
          </g>
          <g id="sj1">
            <polygon
              fill="#fff"
              points="1464,132.3 1388.8,101.4 1388.5,177.6 1463.9,203.9 		"
            />
          </g>
          <g id="sj2">
            <polygon
              fill="#fff"
              points="1463.9,203.9 1388.5,177.6 1388.3,253.9 1463.7,275.6 		"
            />
          </g>
          <g id="sj3">
            <polygon
              fill="#fff"
              points="1463.7,275.6 1388.3,253.9 1388,330.4 1463.5,347.4 		"
            />
          </g>
          <g id="sj4">
            <polygon
              fill="#fff"
              points="1463.5,347.4 1388,330.4 1387.8,407 1463.4,419.4 		"
            />
          </g>
          <g id="sk1">
            <polygon
              fill="#fff"
              points="1530.7,159.6 1464,132.3 1463.9,203.9 1530.6,227.2 		"
            />
          </g>
          <g id="sk2">
            <polygon
              fill="#fff"
              points="1530.6,227.2 1463.9,203.9 1463.7,275.6 1530.5,294.8 		"
            />
          </g>
          <g id="sk3">
            <polygon
              fill="#fff"
              points="1530.5,294.8 1463.7,275.6 1463.5,347.4 1530.4,362.5 		"
            />
          </g>
          <g id="sk4">
            <polygon
              fill="#fff"
              points="1530.4,362.5 1463.5,347.4 1463.4,419.4 1530.3,430.4 		"
            />
          </g>
          <g id="sl1">
            <polygon
              fill="#fff"
              points="1590.1,184 1530.7,159.6 1530.6,227.2 1590.1,247.9 		"
            />
          </g>
          <g id="sl2">
            <polygon
              fill="#fff"
              points="1590.1,247.9 1530.6,227.2 1530.5,294.8 1590,311.9 		"
            />
          </g>
          <g id="sl3">
            <polygon
              fill="#fff"
              points="1590,311.9 1530.5,294.8 1530.4,362.5 1590,376 		"
            />
          </g>
          <g id="sl4">
            <polygon
              fill="#fff"
              points="1590,376 1530.4,362.5 1530.3,430.4 1589.9,440.2 		"
            />
          </g>
          <g id="sm1">
            <polygon
              fill="#fff"
              points="1643.4,205.9 1590.1,184 1590.1,247.9 1643.4,266.6 		"
            />
          </g>
          <g id="sm2">
            <polygon
              fill="#fff"
              points="1643.4,266.6 1590.1,247.9 1590,311.9 1643.4,327.3 		"
            />
          </g>
          <g id="sm3">
            <polygon
              fill="#fff"
              points="1643.4,327.3 1590,311.9 1590,376 1643.4,388.1 		"
            />
          </g>
          <g id="sm4">
            <polygon
              fill="#fff"
              points="1643.4,388.1 1590,376 1589.9,440.2 1643.4,449 		"
            />
          </g>
        </g>
        <g id="ScreenOutline">
          <path
            id="ScreenOutline_00000164477383374742346130000004520246707577756562_"
            className="st39"
            d="M1590.1,184l-59.4-24.4l-66.6-27.4
      l-75.2-30.9l-0.2,0.2c-0.8-0.3-2.1-0.8-3.7-1.4c-22.6-8.1-46.9-10-70.5-5.4c-13.5,2.6-27.1,5.3-33.1,6.6l-87.8,20.2l-81.1,18.6
      l-75.2,17.3l-69.8,16l-65,14.9l-60.7,14l-56.8,13.1l-53.3,12.2v57.4v57.5v57.6v57.7l53.5-4.9l57.1-5.2l61-5.6l65.3-6l70.2-6.4
      l75.6-6.9l81.6-7.4l88.4-8.1l0,0.2l29.9-2.9c23.3-2.3,46.8-1.7,69.9,1.6l4,0.6l75.6,12.4l66.9,11l59.7,9.8l53.5,8.8v-60.9v-60.8
      v-60.7v-60.6L1590.1,184z"
          />
        </g>

        {/* PHONE */}
        {/* PHONE */}
        {/* PHONE */}
        {/* PHONE */}
        {/* PHONE */}
        {/* PHONE */}
        {/* PHONE */}
        {/* PHONE */}
        <g clipPath="url(#phoneClip)">
          <g id="PhoneGrid" transform={scrollTranslate}>
            <g id="pa1" onClick={(e) => handleClick(e.target)}>
              <rect
                x="389.3"
                y="380.3"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
                // id="button-for-tooltip"
              />
              <text
                transform="matrix(1 0 0 1 427.5113 445.1488)"
                className="st40"
              >
                1/1
              </text>
            </g>
            <g id="pa2" onClick={(e) => handleClick(e.target)}>
              <rect
                x="389.3"
                y="496.9"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 427.5113 561.793)"
                className="st40 st41"
              >
                1/2
              </text>
            </g>
            <g id="pa3" onClick={(e) => handleClick(e.target)}>
              <rect
                x="389.3"
                y="613.6"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 427.5113 678.437)"
                className="st40 st41"
              >
                1/3
              </text>
            </g>
            <g id="pa4" onClick={(e) => handleClick(e.target)}>
              <rect
                x="389.3"
                y="730.2"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 427.5115 795.0801)"
                className="st40 st41"
              >
                1/4
              </text>
            </g>
            <g id="pb1" onClick={(e) => handleClick(e.target)}>
              <rect
                x="506"
                y="380.3"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 544.1555 445.1488)"
                className="st40 st41"
              >
                2/1
              </text>
            </g>
            <g id="pb2" onClick={(e) => handleClick(e.target)}>
              <rect
                x="506"
                y="496.9"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 544.1555 561.793)"
                className="st40 st41"
              >
                2/2
              </text>
            </g>
            <g id="pb3" onClick={(e) => handleClick(e.target)}>
              <rect
                x="506"
                y="613.6"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 544.1555 678.437)"
                className="st40 st41"
              >
                2/3
              </text>
            </g>
            <g id="pb4" onClick={(e) => handleClick(e.target)}>
              <rect
                x="506"
                y="730.2"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 544.1557 795.0801)"
                className="st40 st41"
              >
                2/4
              </text>
            </g>
            <g id="pc1" onClick={(e) => handleClick(e.target)}>
              <rect
                x="622.6"
                y="380.3"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 660.7996 445.1488)"
                className="st40 st41"
              >
                3/1
              </text>
            </g>
            <g id="pc2" onClick={(e) => handleClick(e.target)}>
              <rect
                x="622.6"
                y="496.9"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 660.7996 561.793)"
                className="st40 st41"
              >
                3/2
              </text>
            </g>
            <g id="pc3" onClick={(e) => handleClick(e.target)}>
              <rect
                x="622.6"
                y="613.6"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 660.7996 678.437)"
                className="st40 st41"
              >
                3/3
              </text>
            </g>
            <g id="pc4" onClick={(e) => handleClick(e.target)}>
              <rect
                x="622.6"
                y="730.2"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 660.7998 795.0801)"
                className="st40 st41"
              >
                3/4
              </text>
            </g>
            <g id="pd1" onClick={(e) => handleClick(e.target)}>
              <rect
                x="739.3"
                y="380.3"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 777.4435 445.1488)"
                className="st40 st41"
              >
                4/1
              </text>
            </g>
            <g id="pd2" onClick={(e) => handleClick(e.target)}>
              <rect
                x="739.3"
                y="496.9"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 777.4435 561.793)"
                className="st40 st41"
              >
                4/2
              </text>
            </g>
            <g id="pd3" onClick={(e) => handleClick(e.target)}>
              <rect
                x="739.3"
                y="613.6"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 777.4435 678.437)"
                className="st40 st41"
              >
                4/3
              </text>
            </g>
            <g id="pd4" onClick={(e) => handleClick(e.target)}>
              <rect
                x="739.3"
                y="730.2"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 777.4438 795.0801)"
                className="st40 st41"
              >
                4/4
              </text>
            </g>
            <g id="pe1" onClick={(e) => handleClick(e.target)}>
              <rect
                x="855.9"
                y="380.3"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 894.0878 445.1488)"
                className="st40 st41"
              >
                5/1
              </text>
            </g>
            <g id="pe2" onClick={(e) => handleClick(e.target)}>
              <rect
                x="855.9"
                y="496.9"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 894.0878 561.793)"
                className="st40 st41"
              >
                5/2
              </text>
            </g>
            <g id="pe3" onClick={(e) => handleClick(e.target)}>
              <rect
                x="855.9"
                y="613.6"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 894.0878 678.437)"
                className="st40 st41"
              >
                5/3
              </text>
            </g>
            <g id="pe4" onClick={(e) => handleClick(e.target)}>
              <rect
                x="855.9"
                y="730.2"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 894.088 795.0801)"
                className="st40 st41"
              >
                5/4
              </text>
            </g>
            <g id="pf1" onClick={(e) => handleClick(e.target)}>
              <rect
                x="972.5"
                y="380.3"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 1010.7317 445.1488)"
                className="st40 st41"
              >
                6/1
              </text>
            </g>
            <g id="pf2" onClick={(e) => handleClick(e.target)}>
              <rect
                x="972.5"
                y="496.9"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 1010.7317 561.793)"
                className="st40 st41"
              >
                6/2
              </text>
            </g>
            <g id="pf3" onClick={(e) => handleClick(e.target)}>
              <rect
                x="972.5"
                y="613.6"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 1010.7317 678.437)"
                className="st40 st41"
              >
                6/3
              </text>
            </g>
            <g id="pf4" onClick={(e) => handleClick(e.target)}>
              <rect
                x="972.5"
                y="730.2"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 1010.7319 795.0801)"
                className="st40 st41"
              >
                6/4
              </text>
            </g>
            <g id="pg1" onClick={(e) => handleClick(e.target)}>
              <rect
                x="1089.2"
                y="380.3"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 1127.3754 445.1488)"
                className="st40 st41"
              >
                7/1
              </text>
            </g>
            <g id="pg2" onClick={(e) => handleClick(e.target)}>
              <rect
                x="1089.2"
                y="496.9"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 1127.3754 561.793)"
                className="st40 st41"
              >
                7/2
              </text>
            </g>
            <g id="pg3" onClick={(e) => handleClick(e.target)}>
              <rect
                x="1089.2"
                y="613.6"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 1127.3754 678.437)"
                className="st40 st41"
              >
                7/3
              </text>
            </g>
            <g id="pg4" onClick={(e) => handleClick(e.target)}>
              <rect
                x="1089.2"
                y="730.2"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 1127.3755 795.0801)"
                className="st40 st41"
              >
                7/4
              </text>
            </g>
            <g id="ph1" onClick={(e) => handleClick(e.target)}>
              <rect
                x="1205.8"
                y="380.3"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 1244.0188 445.1488)"
                className="st40 st41"
              >
                8/1
              </text>
            </g>
            <g id="ph2" onClick={(e) => handleClick(e.target)}>
              <rect
                x="1205.8"
                y="496.9"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 1244.0188 561.793)"
                className="st40 st41"
              >
                8/2
              </text>
            </g>
            <g id="ph3" onClick={(e) => handleClick(e.target)}>
              <rect
                x="1205.8"
                y="613.6"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 1244.0188 678.437)"
                className="st40 st41"
              >
                8/3
              </text>
            </g>
            <g id="ph4" onClick={(e) => handleClick(e.target)}>
              <rect
                x="1205.8"
                y="730.2"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 1244.019 795.0801)"
                className="st40 st41"
              >
                8/4
              </text>
            </g>
            <g id="pi1" onClick={(e) => handleClick(e.target)}>
              <rect
                x="1322.5"
                y="380.3"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 1360.6622 445.1488)"
                className="st40 st41"
              >
                9/1
              </text>
            </g>
            <g id="pi2" onClick={(e) => handleClick(e.target)}>
              <rect
                x="1322.5"
                y="496.9"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 1360.6622 561.793)"
                className="st40 st41"
              >
                9/2
              </text>
            </g>
            <g id="pi3" onClick={(e) => handleClick(e.target)}>
              <rect
                x="1322.5"
                y="613.6"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 1360.6622 678.437)"
                className="st40 st41"
              >
                9/3
              </text>
            </g>
            <g id="pi4" onClick={(e) => handleClick(e.target)}>
              <rect
                x="1322.5"
                y="730.2"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 1360.6625 795.0801)"
                className="st40 st41"
              >
                9/4
              </text>
            </g>
            <g id="pj1" onClick={(e) => handleClick(e.target)}>
              <rect
                x="1439.1"
                y="380.3"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 1470.5417 445.1488)"
                className="st40 st41"
              >
                10/1
              </text>
            </g>
            <g id="pj2" onClick={(e) => handleClick(e.target)}>
              <rect
                x="1439.1"
                y="496.9"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 1470.5417 561.793)"
                className="st40 st41"
              >
                10/2
              </text>
            </g>
            <g id="pj3" onClick={(e) => handleClick(e.target)}>
              <rect
                x="1439.1"
                y="613.6"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 1470.5417 678.437)"
                className="st40 st41"
              >
                10/3
              </text>
            </g>
            <g id="pj4" onClick={(e) => handleClick(e.target)}>
              <rect
                x="1439.1"
                y="730.2"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 1470.542 795.0801)"
                className="st40 st41"
              >
                10/4
              </text>
            </g>
            <g id="pk1" onClick={(e) => handleClick(e.target)}>
              <rect
                x="1555.8"
                y="380.3"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 1587.1851 445.1488)"
                className="st40 st41"
              >
                11/1
              </text>
            </g>
            <g id="pk2" onClick={(e) => handleClick(e.target)}>
              <rect
                x="1555.8"
                y="496.9"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 1587.1851 561.793)"
                className="st40 st41"
              >
                11/2
              </text>
            </g>
            <g id="pk3" onClick={(e) => handleClick(e.target)}>
              <rect
                x="1555.8"
                y="613.6"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 1587.1851 678.437)"
                className="st40 st41"
              >
                11/3
              </text>
            </g>
            <g id="pk4" onClick={(e) => handleClick(e.target)}>
              <rect
                x="1555.8"
                y="730.2"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 1587.1853 795.0801)"
                className="st40 st41"
              >
                11/4
              </text>
            </g>
            <g id="pl1" onClick={(e) => handleClick(e.target)}>
              <rect
                x="1672.4"
                y="380.3"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 1703.8286 445.1488)"
                className="st40 st41"
              >
                12/1
              </text>
            </g>
            <g id="pl2" onClick={(e) => handleClick(e.target)}>
              <rect
                x="1672.4"
                y="496.9"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 1703.8286 561.793)"
                className="st40 st41"
              >
                12/2
              </text>
            </g>
            <g id="pl3" onClick={(e) => handleClick(e.target)}>
              <rect
                x="1672.4"
                y="613.6"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 1703.8286 678.437)"
                className="st40 st41"
              >
                12/3
              </text>
            </g>
            <g id="pl4" onClick={(e) => handleClick(e.target)}>
              <rect
                x="1672.4"
                y="730.2"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 1703.8289 795.0801)"
                className="st40 st41"
              >
                12/4
              </text>
            </g>
            <g id="pm1" onClick={(e) => handleClick(e.target)}>
              <rect
                x="1789"
                y="380.3"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 1820.4724 445.1488)"
                className="st40 st41"
              >
                13/1
              </text>
            </g>
            <g id="pm2" onClick={(e) => handleClick(e.target)}>
              <rect
                x="1789"
                y="496.9"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 1820.4724 561.793)"
                className="st40 st41"
              >
                13/2
              </text>
            </g>
            <g id="pm3" onClick={(e) => handleClick(e.target)}>
              <rect
                x="1789"
                y="613.6"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 1820.4724 678.437)"
                className="st40 st41"
              >
                13/3
              </text>
            </g>
            <g id="pm4" onClick={(e) => handleClick(e.target)}>
              <rect
                x="1789"
                y="730.2"
                // className="st28"
                width="116.6"
                height="116.6"
                fill={on}
              />
              <text
                transform="matrix(1 0 0 1 1820.4727 795.0801)"
                className="st40 st41"
              >
                13/4
              </text>
            </g>
            <rect
              id="phone_screen_bounding_box"
              x="387.1"
              y="356.4"
              className="st0"
              width="238.3"
              height="513.5"
              // fill="#000"
            />
          </g>
        </g>
        <g id="scrollbar">
          <path
            id="bar"
            className="st42"
            d="M597.2,901.7H414.9c-2.4,0-4.3-1.9-4.3-4.3l0,0c0-2.4,1.9-4.3,4.3-4.3h182.3c2.4,0,4.3,1.9,4.3,4.3
      l0,0C601.5,899.8,599.6,901.7,597.2,901.7z"
          />
          {/* <a href="" style={{ zIndex: 4 }}> */}
          {/* <div className="ball2"></div> */}
          <circle
            style={{ position: "absolute", cursor: "pointer" }}
            fill="#222"
            position="absolute"
            id="ball"
            // className="st43"
            cx="421"
            cy="897.4"
            r="25.4"
            transform="translate(0, 0)"
          />
          {/* </a> */}
        </g>
      </svg>
    </>
  );
};
