import { useEffect, useContext, useRef } from "react";
import { observer } from "mobx-react-lite";

import { StoreContext } from "../../../../stores/store.context";

import "./style.css";

let colors = [
  "#c0c0c0", //grey
  "#c0c000", //yellow
  "#00c000", //green
  "#00c0c0", //cyan
  "#c000c0", //magenta
  "#c00000", //red
  "#0000c0", //blue
  "#131313", //black
];

export const CheckeredBars = () => {
  const { project } = useContext(StoreContext);
  const screenList = project.screenList;

  return (
    <div className="output-container">
      {screenList.map(
        (screen, i) => screen.panel && <Screen screen={screen} key={i} />
      )}
    </div>
  );
};

const Screen = observer((props) => {
  const { project, user } = useContext(StoreContext);
  const { screen } = props;
  const output = user.outputPageOutput;

  const screenWidth = screen.panel.pixelWidth * screen.panelColumns;
  const screenHeight = screen.panel.pixelHeight * screen.panelRows;
  let startX = screen.startX - output.startX;
  let startY = screen.startY - output.startY;

  const posRef = useRef(0);
  const timeoutRef = useRef();
  const drawChecks = useRef();

  const canvasId = `${screen.id}checkeredbars`;

  useEffect(() => {
    const canvas = document.getElementById(canvasId);
    const ctx = canvas.getContext("2d");
    canvas.style.left = `${screen.startX - output.startX}px`;
    canvas.style.top = `${screen.startY - output.startY}px`;
    canvas.width = screenWidth;
    canvas.height = screenHeight;

    drawChecks.current = () => {
      let panelArray = [];

      for (let i = 0; i < screen.panelRows; i++) {
        for (let j = 0; j < screen.panelColumns; j++) {
          let colorIndex = (i % screen.panelRows) + (j % screen.panelColumns);
          let x = (colorIndex + posRef.current) % colors.length;
          let panel = {};
          panel.xpos = j * screen.panel.pixelWidth;
          panel.ypos = i * screen.panel.pixelHeight;
          panel.width = screen.panel.pixelWidth;
          panel.height = screen.panel.pixelHeight;
          panel.color = colors[x];
          panelArray.push(panel);
        }
      }

      panelArray.forEach((panel, i) => {
        ctx.fillStyle = panel.color;
        ctx.fillRect(panel.xpos, panel.ypos, panel.width, panel.height);
      });

      if (!project.isPlaying) {
        clearTimeout(timeoutRef.current);
        return;
      }

      posRef.current = posRef.current + 1;

      if (!project.isPlaying) {
        return;
      }

      timeoutRef.current = setTimeout(
        () => requestAnimationFrame(drawChecks.current),
        1000
      );
    };

    requestAnimationFrame(drawChecks.current);

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [project.isPlaying]);

  return (
    <div
      style={{
        transformOrigin: `${startX}px ${startY}px`,
        transform: `rotate(${screen.rotation}deg)`,
      }}
    >
      <canvas id={canvasId} style={{ position: "absolute" }}></canvas>
    </div>
  );
});
