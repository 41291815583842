import { useState, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { observer } from "mobx-react-lite";
import { SketchPicker } from "react-color";

import { StoreContext } from "../../../../stores/store.context";
import { updateOutput } from "../../../../graphql/mutations";
import { defaultColors } from "../../../../utils/Colors";

import useStyles from "./EditSideBarStyle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import Modal from "@material-ui/core/Modal";

const OutputEdit = observer((props) => {
  const { project } = useContext(StoreContext);
  const { output, setEditRequested } = props;
  const c = useStyles();
  const [loading, setLoading] = useState(false);
  const [_name, setName] = useState(output.name);
  const [_width, setWidth] = useState(output.width);
  const [_height, setHeight] = useState(output.height);
  const [_startX, setStartX] = useState(output.startX);
  const [_startY, setStartY] = useState(output.startY);
  const [colorModalOpen, setColorModalOpen] = useState(false);
  const [pos, setPos] = useState({ x: 0, y: 0 });
  const [_color, setColor] = useState(output.color);

  const submit = async () => {
    reqUpdateOutput();
  };

  const cancel = () => {
    setEditRequested();
  };

  const reqUpdateOutput = async () => {
    setLoading(true);

    try {
      const newOutput = {
        name: _name,
        id: project.selectedOutputEdit.id,
        width: _width,
        height: _height,
        startX: _startX,
        startY: _startY,
        color: _color,
      };
      let resp = await API.graphql(
        graphqlOperation(updateOutput, { input: newOutput })
      );
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  const openColor = (x) => {
    setPos({
      x: x.clientX - 150,
      y: x.clientY,
    });
    setColorModalOpen(true);
  };

  const closeColor = () => {
    setColorModalOpen(false);
  };

  const changeColor = (color, event) => {
    setColor(color.hex);
  };

  return (
    <>
      {/* output */}
      {/* output */}
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: 10, width: "100%" }}
      >
        <Typography
          align="center"
          style={{
            backgroundColor: project.selectedOutputEdit.color,
            marginLeft: 15,
            marginRight: 15,
            width: "80%",
          }}
        >
          {output.name}
        </Typography>
        <Grid item style={{ marginTop: 10 }}>
          <TextField
            id="outlined-multiline-flexible"
            label="Name"
            value={_name}
            onChange={(e) => setName(e.target.value)}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item style={{ marginTop: 10 }}>
          <TextField
            id="outlined-multiline-flexible"
            label="Width"
            value={_width}
            onChange={(e) => setWidth(e.target.value)}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item className={c.textField}>
          <TextField
            id="outlined-multiline-flexible"
            label="Height"
            value={_height}
            onChange={(e) => setHeight(e.target.value)}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item className={c.textField}>
          <TextField
            id="outlined-multiline-flexible"
            label="Start X"
            value={_startX}
            onChange={(e) => setStartX(e.target.value)}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item className={c.textField}>
          <TextField
            id="outlined-multiline-flexible"
            label="Start Y"
            value={_startY}
            onChange={(e) => setStartY(e.target.value)}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Button
          type="button"
          onClick={(e) => openColor(e)}
          style={{ backgroundColor: _color, width: 95, marginTop: 10 }}
        >
          Color
        </Button>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={colorModalOpen}
          onClose={closeColor}
          closeAfterTransition
          style={{ left: pos.x, top: pos.y - 250 }}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <SketchPicker
            color={_color}
            onChange={changeColor}
            presetColors={defaultColors}
          />
        </Modal>
      </Grid>

      {/* Submit Cancel Buttons */}
      {/* Submit Cancel Buttons */}
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        align="center"
      >
        <Button
          onClick={submit}
          style={{ marginTop: 10, margin: 15 }}
          variant="contained"
          disabled={loading}
        >
          {loading ? (
            <CircularProgress
              size={24}
              style={{ marginLeft: 20, marginRight: 20 }}
            />
          ) : (
            <Typography>Submit</Typography>
          )}
        </Button>
        <Button
          onClick={cancel}
          style={{ margin: 15, marginTop: -5 }}
          className={c.editButtons}
          variant="contained"
        >
          Cancel
        </Button>
      </Grid>
    </>
  );
});

export default OutputEdit;
