import { useState, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { observer } from "mobx-react-lite";
import { useFormik } from "formik";
import * as Yup from "yup";

import { StoreContext } from "../../../../stores/store.context";

import { createProject } from "../../../../graphql/mutations";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import { CircularProgress } from "@material-ui/core";
import { Typography } from "@material-ui/core";

const initialFormState = {
  name: "",
  projectBackgroundContent: 1,
};

const validationSchema = Yup.object({
  name: Yup.string().required("Required"),
});

const NewProjectForm = observer(() => {
  const { app, dialogs } = useContext(StoreContext);
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: initialFormState,
    validationSchema,
    onSubmit: (values) => {
      addProject();
    },
  });

  const handleClose = () => {
    dialogs.setNewProjectDialogIsOpen(false);
  };

  async function addProject() {
    setIsLoading(true);
    try {
      if (!formik.values.name) {
        setIsLoading(false);
        return;
      }
      const projectBackgroundContent = "id";
      const projectBackgroundContentGrid = true;
      const projectBackgroundContentLabels = true;
      const isPlaying = false;
      const isGridOn = true;
      const isLabelOn = true;
      const isStarredOn = true;
      const name = formik.values.name;
      const project = {
        name,
        projectBackgroundContent,
        projectBackgroundContentGrid,
        projectBackgroundContentLabels,
        isGridOn,
        isLabelOn,
        isPlaying,
        isStarredOn,
      };
      await API.graphql(graphqlOperation(createProject, { input: project }));
    } catch (err) {
      console.error(err);
    }
    handleClose();
    setIsLoading(false);
  }

  return (
    <Dialog
      // open={true}
      open={dialogs.newProjectDialogIsOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      style={{ minHeight: 400 }}
      BackdropProps={{
        style: { backgroundColor: "rgba(1,7,12,1)" },
      }}
    >
      <Grid
        container
        style={{ minWidth: 350, minHeight: 200 }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          style={{ width: "100%", height: "70%" }}
          justifyContent="center"
          alignItems="center"
        >
          <TextField
            autoFocus
            id="name"
            name="name"
            // placeholder="Name"
            // size="large"
            // margin="dense"
            onChange={formik.handleChange}
            value={formik.name}
            onBlur={formik.handleBlur}
            error={formik.touched.name}
            label="Name"
            style={{ width: "55%" }}
          />
        </Grid>
        <Grid
          container
          style={{ width: "60%", height: "100%" }}
          justifyContent="space-around"
        >
          <Button
            onClick={handleClose}
            color="primary"
            variant="outlined"
            style={{ width: 95 }}
          >
            Cancel
          </Button>
          <Button
            onClick={addProject}
            color="primary"
            variant="contained"
            disabled={isLoading}
            style={{ width: 95 }}
          >
            {isLoading ? (
              <CircularProgress size={24} />
            ) : (
              <Typography>Submit</Typography>
            )}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
});

export default NewProjectForm;
