import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  title: {
    paddingBottom: "20px",
    fontFamily: "Chivo",
    fontSize: 25,
  },
  fieldTitle: {
    paddingBottom: 0,
    fontFamily: "Chivo",
    fontWeight: 300,
  },
}));
