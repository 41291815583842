import React, { useState, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { observer } from "mobx-react-lite";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { createPanel } from "../../graphql/mutations";

import { StoreContext } from "../../stores/store.context";


const initialFormState = {
  make: "",
  model: "",
  pixelWidth: "",
  pixelHeight: "",
  moduleRows: "",
  moduleColumns: "",
};

const NewPanelDialog = observer((props) => {
  const { app } = useContext(StoreContext);

  const [formState, setFormState] = useState(initialFormState);

  const handleClose = () => {
    app.setNewPanelDialogIsOpen(false);
  };

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value });
  }

  async function handelNewScreen() {
    try {
      if (
        !formState.make ||
        !formState.model ||
        !formState.pixelWidth ||
        !formState.pixelHeight
      )
        return;
      const panel = { ...formState };
      await API.graphql(graphqlOperation(createPanel, { input: panel }));
      setFormState(initialFormState);
    } catch (err) { }
    handleClose();
  }

  return (
    <Dialog
      open={app.newPanelDialogIsOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      BackdropProps={{
        style: { backgroundColor: "rgba(0,27,44,0.97)" },
      }}
    >
      <DialogTitle id="form-dialog-title">New Screen</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          onChange={(event) =>
            setInput("make", event.target.value.toLowerCase())
          }
          value={formState.make}
          placeholder="Make"
          margin="dense"
          fullWidth
        />
        <TextField
          onChange={(event) =>
            setInput("model", event.target.value.toLowerCase())
          }
          value={formState.model}
          placeholder="Model"
          fullWidth
        />
        <TextField
          onChange={(event) => setInput("pixelWidth", event.target.value)}
          value={formState.pixelWidth}
          placeholder="pixelWidth"
          fullWidth
        />
        <TextField
          onChange={(event) => setInput("pixelHeight", event.target.value)}
          value={formState.pixelHeight}
          placeholder="pixelHeight"
          fullWidth
        />
        <TextField
          onChange={(event) => setInput("moduleColumns", event.target.value)}
          value={formState.moduleColumns}
          placeholder="moduleColumns"
          fullWidth
        />
        <TextField
          onChange={(event) => setInput("moduleRows", event.target.value)}
          value={formState.moduleRows}
          placeholder="moduleRows"
          fullWidth
        />
        <TextField
          onChange={(event) => setInput("weight", event.target.value)}
          value={formState.weight}
          placeholder="weight"
          fullWidth
        />
        <TextField
          onChange={(event) => setInput("maxWatt", event.target.value)}
          value={formState.maxWatt}
          placeholder="maxWatt"
          fullWidth
        />
        <TextField
          onChange={(event) => setInput("averageWatt", event.target.value)}
          value={formState.averageWatt}
          placeholder="averageWatt"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handelNewScreen} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default NewPanelDialog;
