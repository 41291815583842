import { useContext } from "react";

import { StoreContext } from '../../stores/store.context';

import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

import { useStyles } from "./AboutStyles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const About = () => {
  const classes = useStyles();

  const { app } = useContext(StoreContext);

  return (
    <Box>
      <Header />
      <Grid
        container
        alignItems=""
        justifyContent="center"
        style={{
          height: "100vh",
          backgroundColor: "",
        }}
      >
        <Paper
          className={classes.paperBackground}
          style={{ backgroundColor: app.color.white1 }}
        >
          <Grid
            container
            justifyContent="center"
            style={{ width: "100vw", marginTop: "20vh" }}
          >
            <Grid
              item
              align="center"
              style={{ backgroundColor: "" }}
              gutterBottom={true}
            >
              <Typography>About Us</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Footer />
    </Box>
  );
};

export default About;
