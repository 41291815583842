import { useEffect, useContext } from "react";
import { observer } from "mobx-react-lite";

import { StoreContext } from "../../../../stores/store.context";

export const Black = observer(() => {
  const { project } = useContext(StoreContext);

  return (
    <div>
      {project.screenList.map(
        (screen, index) =>
          screen.panel && <BackgroundColor screen={screen} key={index} />
      )}
    </div>
  );
});

function BackgroundColor(props) {
  const { user } = useContext(StoreContext);

  const { screen } = props;
  const output = user.outputPageOutput;

  const screenWidth = screen.panel.pixelWidth * screen.panelColumns;
  const screenHeight = screen.panel.pixelHeight * screen.panelRows;
  let startX = screen.startX - output.startX;
  let startY = screen.startY - output.startY;

  const canvasId = `${screen.id}-black`;

  useEffect(() => {
    const drawBlack = () => {
      const canvas = document.getElementById(canvasId);
      const ctx = canvas.getContext("2d");
      canvas.style.left = `${startX}px`;
      canvas.style.top = `${startY}px`;
      canvas.width = screenWidth;
      canvas.height = screenHeight;

      ctx.fillStyle = "rgba(0,0,0,.8)";
      ctx.fillRect(0, 0, screenWidth, screenHeight);
    };

    requestAnimationFrame(drawBlack);
  }, []);

  return (
    <div
      style={{
        position: "absolute",
        transformOrigin: `${startX}px ${startY}px`,
        transform: `rotate(${screen.rotation}deg)`,
      }}
    >
      <canvas
        id={canvasId}
        style={{
          position: "absolute",
        }}
      />
    </div>
  );
}
