import { makeAutoObservable } from "mobx";

export class Output {
  highlighted = false;

  constructor(output) {
    this.id = output.id;
    this.width = output.width;
    this.height = output.height;
    this.startX = output.startX;
    this.startY = output.startY;
    this.color = output.color;
    this.name = output.name;
    makeAutoObservable(this);
  }

  setHighlight(bool) {
    this.highlighted = bool;
  }
}
