import { observer } from "mobx-react-lite";
import { useEffect, useRef, useContext } from "react";

import { StoreContext } from "../../../../stores/store.context";

import "./style.css";

export const Scroll = () => {
  const { project } = useContext(StoreContext);

  const screenList = project.screenList;

  return (
    <div className="output-container">
      {screenList.map(
        (screen, i) => screen.panel && <Screen screen={screen} key={i} />
      )}
    </div>
  );
};

const Screen = observer((props) => {
  const { project, user } = useContext(StoreContext);
  const { screen } = props;
  const output = user.outputPageOutput;

  const screenWidth = screen.panel.pixelWidth * screen.panelColumns;
  const screenHeight = screen.panel.pixelHeight * screen.panelRows;
  let startX = screen.startX - output.startX;
  let startY = screen.startY - output.startY;

  const rotation = useRef(0);
  const timeoutRef = useRef();
  const drawPanel = useRef();

  const canvasId = `${screen.id}-black`;

  useEffect(() => {
    const canvas = document.getElementById(canvasId);
    const ctx = canvas.getContext("2d");

    canvas.style.left = `${screen.startX - output.startX}px`;
    canvas.style.top = `${screen.startY - output.startY}px`;
    canvas.width = screenWidth;
    canvas.height = screenHeight;

    drawPanel.current = () => {
      let panelArray = [];

      for (let i = 0; i < screen.panelRows; i++) {
        for (let j = 0; j < screen.panelColumns; j++) {
          let panel = {};
          panel.xpos = j * screen.panel.pixelWidth;
          panel.ypos = i * screen.panel.pixelHeight;
          panel.width = screen.panel.pixelWidth;
          panel.height = screen.panel.pixelHeight;
          panel.color = `hsl(${rotation.current + 60 * i + 60 * j}, 100%, 50%)`;
          panelArray.push(panel);
        }
      }

      panelArray.forEach((panel) => {
        ctx.fillStyle = panel.color;
        ctx.fillRect(panel.xpos, panel.ypos, panel.width, panel.height);
      });

      if (!project.isPlaying) {
        clearTimeout(timeoutRef.current);
        return;
      }

      rotation.current = rotation.current + 1;

      timeoutRef.current = setTimeout(
        () => requestAnimationFrame(drawPanel.current),
        38.6666
      );
    };

    requestAnimationFrame(drawPanel.current);

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [project.isPlaying]);

  return (
    <div
      style={{
        transformOrigin: `${startX}px ${startY}px`,
        transform: `rotate(${screen.rotation}deg)`,
      }}
    >
      <canvas id={canvasId} style={{ position: "absolute" }}></canvas>
    </div>
  );
});
