import { useEffect, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";

import { StoreContext } from "../stores/store.context";

import { listProjects } from "../graphql/queries";

const HomeAPI = () => {
  const { projects, project } = useContext(StoreContext)

  useEffect(() => {
    fetchProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project.screenList, project.outputList]);

  const fetchProjects = async () => {
    try {
      const projectData = await API.graphql(graphqlOperation(listProjects));
      const projectsReturned = projectData.data.listProjects.items;
      projectsReturned.sort((a, b) => {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      });
      projects.setList(projectsReturned)
    } catch (err) {
      console.error(err);
    }
  };

  return <></>;
};

export default HomeAPI;
