import { useCallback, useEffect } from "react";
import Paper from "@material-ui/core//Paper";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Auth } from "aws-amplify";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useStyles } from "./ConfirmStyle";

function Confirm() {
  const classes = useStyles();

  let initialState = {
    username: "",
    code: "",
  };

  const [notLoading, setNotLoading] = useState(true);
  const [passwordError, setPasswordError] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [formState, setFormState] = useState(initialState);
  const [codeError, setCodeError] = useState("");

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value });
  }

  const history = useHistory();
  const goToDashboard = () => history.push("/userOnMade/home/projects");

  async function resendConfirmationCode() {
    let username = formState.username;
    try {
      await Auth.resendSignUp(username);
    } catch (err) {
      console.log("error resending code: ", err);
    }
  }

  async function confirmSignUp() {
    setNotLoading(false);
    setButtonDisabled(true);
    let username = formState.username;
    let code = formState.code;

    try {
      let res = await Auth.confirmSignUp(username, code);
      console.log(res);
      goToDashboard();
    } catch (error) {
      setNotLoading(true);
      setInput("password", "");
      setCodeError(error.message);
      setButtonDisabled(false);
    }
  }

  const handleKey = useCallback(
    (e) => {
      if (e.code === "Enter") {
        confirmSignUp();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formState]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKey);

    return () => {
      document.removeEventListener("keydown", handleKey);
    };
  }, [handleKey]);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-around"
      style={{
        height: "100vh",
        flexDirection: "column",
        display: "flex",
      }}
    >
      <Paper
        elevation={3}
        style={{ backgroundColor: "white", padding: 43, paddingTop: 50 }}
      >
        <Grid container style={{ backgroundColor: "white", width: 400 }}>
          <Grid
            item
            xs={12}
            style={{ backgroundColor: "white", marginBottom: 5 }}
          >
            <Typography
              style={{ fontWeight: 800 }}
              className={classes.title}
              variant="h6"
            >
              Check your email for a verification code
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ backgroundColor: "white", paddingTop: 10 }}
          >
            <Typography className={classes.fieldTitle}>Username</Typography>
            <TextField
              id="username"
              label="Username"
              variant="outlined"
              fullWidth
              onChange={(event) =>
                parseInt(setInput("username", event.target.value))
              }
              value={formState.username}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ backgroundColor: "white", paddingTop: 20 }}
          >
            <Typography className={classes.fieldTitle}>
              Verification Code
            </Typography>
            <TextField
              id="password"
              label="code"
              variant="outlined"
              fullWidth
              onChange={(event) =>
                parseInt(setInput("code", event.target.value))
              }
              value={formState.code}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ backgroundColor: "white", paddingTop: 20 }}
          >
            <Typography className={classes.error}>{codeError}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ backgroundColor: "white", paddingTop: 25 }}
          >
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography className={classes.noAccount}>
                  Didn't receive a code?
                </Typography>
                <Button
                  onClick={resendConfirmationCode}
                  style={{ color: "black" }}
                >
                  Send another!
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  onClick={confirmSignUp}
                  disabled={buttonDisabled}
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    padding: 20,
                    paddingTop: 10,
                    paddingBottom: 10,
                  }}
                >
                  {notLoading ? (
                    <Typography>Confirm Account</Typography>
                  ) : (
                    <CircularProgress
                      size={20}
                      style={{ marginLeft: 20, marginRight: 20 }}
                    />
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

export default Confirm;
