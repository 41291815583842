export const defaultColors = [
  "#E401CD",
  // "#FF85B5",
  "#FA1407", // blue
  "#FF7A46",
  "#FF9A23",
  "#FFB800",
  "#FFDB20",
  "#FFF859",
  "#64FF4A",
  "#00E833",
  "#00B13D",
  "#4FFFE7",
  "#00E0FF",
  "#0193FA",
  "#005CFA",
  "#000DDD", // red
  // "#8850FF",
  "#6F00E5",
];
// export const defaultColors = [
//   "#FA1407", // red
//   "#FF510D",
//   "#F58400",
//   "#FFBC0D",
//   "#FFE400",
//   "#F7F052",
//   "#44AF69",
//   "#00FF90",
//   "#00FFDD",
//   "#00EBFF",
//   "#0193FA",
//   "#000FFF", // blue
//   "#005CFA",
//   "#7600F5",
//   "#DE0BC8",
//   "#FA0D6B",
// ];
